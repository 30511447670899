import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46bb3eb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_CustomerReturn = _resolveComponent("CustomerReturn")
  const _component_SupplierReturn = _resolveComponent("SupplierReturn")
  const _component_p_fieldset = _resolveComponent("p-fieldset")
  const _component_DeliveryNote = _resolveComponent("DeliveryNote")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CustomerReturn, {
      "ticket-details": _ctx.ticketDetails,
      onReloadTicketDetails: _ctx.handleReloadTicketEvent
    }, null, 8, ["ticket-details", "onReloadTicketDetails"]),
    _createVNode(_component_SupplierReturn, {
      "ticket-details": _ctx.ticketDetails,
      onReloadTicketDetails: _ctx.handleReloadTicketEvent
    }, null, 8, ["ticket-details", "onReloadTicketDetails"]),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(({
                                'receivingRepairPart': 'ticketReceivingRepairPart'
                        }), (referenceKey, translationKey) => {
      return _createVNode(_component_p_fieldset, {
        class: "mb-3",
        legend: _ctx.$t('labels.ticket.references.' +
                        translationKey),
        toggleable: true
      }, {
        default: _withCtx(() => [
          (_ctx.ticketDetails[referenceKey] && _ctx.ticketDetails[referenceKey].length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList((_ctx.ticketDetails[referenceKey] || []), (referenceItem) => {
                return (_openBlock(), _createElementBlock("div", {
                  innerHTML: _ctx.getReferenceItemLayout(referenceItem,
                                    _ctx.ticketDetails.url)
                }, null, 8, _hoisted_1))
              }), 256))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["legend"])
    }), 64)),
    _createVNode(_component_DeliveryNote, {
      "ticket-details": _ctx.ticketDetails,
      onReloadTicketDetails: _ctx.handleReloadTicketEvent
    }, null, 8, ["ticket-details", "onReloadTicketDetails"])
  ], 64))
}