import {ref, onMounted, computed} from 'vue';
import {FilterMatchMode} from 'primevue/api';
import {getAll} from '@/services/shipments';
import {Qalendar} from 'qalendar';
import 'qalendar/dist/style.css';
import {DateTime} from 'luxon';
import {useToast} from 'vue-toastification';
import {getSome} from '@/services/metadata';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Button from 'primevue/button';
import Panel from 'primevue/panel';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';

export default {
    components: {
        Qalendar,
        InputText,
        'p-multiselect': MultiSelect,
        'p-button': Button,
        Panel,
        IconField,
        InputIcon
    },
    setup() {
        onMounted(async () => {
            await getSome('customAttributeDefinition', 'attributeKey', [
                'Status_Miete',
                'Mietbeginn',
                'Mietende'
            ]).then((data: any) => {
                customAttributes.value = data.data;
                /*if (
                    customAttributes.value &&
                    customAttributes.value['Status_Miete'].selectableValues
                ) {
                    selectedRentalStatusOptions.value = ['-1'].concat(
                        customAttributes.value[
                            'Status_Miete'
                        ].selectableValues.map((item: any) => item.id)
                    );
                }*/
            });

            loadData();
        });

        const events = ref([]);
        const customAttributes = ref(null);
        const rentalStatusSelectorComponent = ref(null);

        const periodStart = ref(null);
        const periodEnd = ref(null);
        const loading = ref(false);
        const articleNumber = ref(null);
        const toast = useToast();
        const selectedRentalStatusOptions = ref([]);

        const loadData = () => {
            if (periodStart.value === null) {
                periodStart.value = DateTime.now()
                    .minus({months: 1})
                    .startOf('day');
                periodEnd.value = DateTime.now()
                    .plus({months: 1})
                    .startOf('day');
            }

            loading.value = true;

            getAll({
                rows: 1000,
                columns: ['shipmentItems'],
                filters: Object.assign(
                    {
                        shipmentType: {
                            value: 'CONSIGNMENT',
                            matchMode: FilterMatchMode.EQUALS
                        },
                        status: {
                            value: 'CANCELLED',
                            matchMode: FilterMatchMode.NOT_EQUALS
                        },
                        rentalStartDate: {
                            value: periodStart.value,
                            matchMode: FilterMatchMode.EQUALS
                        },
                        rentalEndDate: {
                            value: periodEnd.value,
                            matchMode: FilterMatchMode.EQUALS
                        },
                        rentalStatus: {
                            value:
                                selectedRentalStatusOptions.value.length > 0
                                    ? selectedRentalStatusOptions.value
                                    : null,
                            matchMode: FilterMatchMode.IN
                        }
                    },
                    articleNumber.value
                        ? {
                              'shipmentItems.articleNumber': {
                                  value: articleNumber.value,
                                  matchMode: FilterMatchMode.EQUALS
                              }
                          }
                        : {}
                )
            })
                .then((data: any) => {
                    const calendarItems: any = [];
                    data.data.items.forEach((element: any) => {
                        element.shipmentItems.forEach((shipmentItem: any) => {
                            if (
                                '12294287' === shipmentItem.articleNumber ||
                                (articleNumber.value &&
                                    shipmentItem.articleNumber !==
                                        articleNumber.value) ||
                                !element.customAttributes['Mietbeginn'] ||
                                !element.customAttributes['Mietende']
                            ) {
                                return;
                            }

                            const descriptionArray = [];
                            if (element.customAttributes['Status_Miete']) {
                                const valueDef =
                                    getSelectableValueDefinitionBasedOnRentalStatus(
                                        element.customAttributes['Status_Miete']
                                    );
                                if (valueDef?.value) {
                                    descriptionArray.push(
                                        '<div>Status: <b>' +
                                            valueDef.value +
                                            '</b></div>'
                                    );
                                }
                            }

                            descriptionArray.push(
                                '<div>Lieferung: <b>' +
                                    '<a href="' +
                                    element.url +
                                    'webapp/view/shipment/ShipmentDetailOutgoing.page?entityId=' +
                                    element.id +
                                    '" target="_blank">' +
                                    element.shipmentNumber +
                                    '</a></b></div>'
                            );

                            calendarItems.push({
                                title: shipmentItem.articleNumber,
                                topic: shipmentItem.article?.name,
                                time: {
                                    start: DateTime.fromMillis(
                                        element.customAttributes['Mietbeginn']
                                    ).toFormat('yyyy-MM-dd HH:mm'),
                                    end: DateTime.fromMillis(
                                        element.customAttributes['Mietende']
                                    ).toFormat('yyyy-MM-dd HH:mm')
                                },
                                color: getColorBasedOnRentalStatus(
                                    element.customAttributes['Status_Miete'] ||
                                        null
                                ),
                                id: shipmentItem.id,
                                description: descriptionArray.join('')
                            });
                        });
                    });

                    events.value = calendarItems;
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const getSelectableValueDefinitionBasedOnRentalStatus = (
            rentalStatusId: string | null
        ) => {
            return customAttributes.value['Status_Miete'].selectableValues.find(
                (item: any) => {
                    return item.id === rentalStatusId;
                }
            );
        };

        const getColorBasedOnRentalStatus = (rentalStatusId: string | null) => {
            const foundStatus =
                getSelectableValueDefinitionBasedOnRentalStatus(rentalStatusId);

            if (!foundStatus) {
                return 'blue';
            }

            switch (foundStatus.value) {
                case 'Bestätigt':
                    return 'red';
                case 'Wartend':
                    return 'yellow';
                case 'Retourniert':
                    return 'green';
                default:
                    return 'blue';
            }
        };

        const onPeriodUpdated = (event: any) => {
            periodStart.value = event.start;
            periodEnd.value = event.end;

            loadData();
        };

        const onModeUpdated = (event: any) => {
            periodStart.value = event.period.start;
            periodEnd.value = event.period.end;

            loadData();
        };

        const rentalStatusOptions = computed(() => {
            if (
                !customAttributes.value ||
                !customAttributes.value['Status_Miete'].selectableValues
            ) {
                return [];
            }

            return [{id: '-1', value: 'Kein Status'}].concat(
                customAttributes.value['Status_Miete'].selectableValues
            );
        });

        const onToggleColumnVisibility = (value: any) => {
            const cols = value.value || [];

            selectedRentalStatusOptions.value = cols.filter(
                (c: string, index: number) => {
                    return cols.indexOf(c) === index;
                }
            );
        };

        return {
            events,
            onPeriodUpdated,
            onModeUpdated,
            loadData,
            selectedDate: new Date(),
            articleNumber,
            rentalStatusOptions,
            rentalStatusSelectorComponent,
            selectedRentalStatusOptions,
            onToggleColumnVisibility,
            loading,
            config: {
                week: {
                    // Takes the value 'sunday' or 'monday'
                    // However, if startsOn is set to 'sunday' and nDays to 5, the week displayed will be Monday - Friday
                    startsOn: 'monday',
                    // Takes the values 5 or 7.
                    nDays: 7,
                    // Scroll to a certain hour on mounting a week. Takes any value from 0 to 23.
                    // This option is not compatible with the 'dayBoundaries'-option, and will simply be ignored if custom day boundaries are set.
                    scrollToHour: 5
                },
                // Takes any valid locale that the browser understands. However, not all locales have been thorougly tested in Qalendar
                // If no locale is set, the preferred browser locale will be used
                locale: 'de-DE',
                style: {
                    fontFamily: "'Roboto Condensed', sans-serif"
                },
                // if not set, the mode defaults to 'week'. The three available options are 'month', 'week' and 'day'
                // Please note, that only day and month modes are available for the calendar in mobile-sized wrappers (~700px wide or less, depending on your root font-size)
                defaultMode: 'month',
                // The silent flag can be added, to disable the development warnings. This will also bring a slight performance boost
                isSilent: true,
                disableModes: ['day'],
                showCurrentTime: true // Display a line indicating the current time
            }
        };
    }
};
