import {ref, toRefs, watch} from 'vue';
import TabPanel from 'primevue/tabpanel';
import LoadingPlugin from 'vue-loading-overlay';
import DataTable from 'primevue/datatable';
import ProductList from '@/components/grid/products/list.vue';
import {useToast} from 'vue-toastification';
import Column from 'primevue/column';
import {i18n} from '@/utils/i18n';
import Chips from 'primevue/chips';
import {bulkUpdateMetatags} from '@/services/products';
import BulkProductAction from './bulk-product-action.vue';
import Panel from 'primevue/panel';

export default {
    emits: ['close-dialog'],
    components: {
        TabPanel,
        ProductList,
        LoadingPlugin,
        Column,
        Chips,
        DataTable,
        BulkProductAction,
        Panel
    },
    props: {
        products: Array,
        productFilters: Object,
        productTotalRecords: Number,
        displayDialog: Boolean,
        isFilterRelated: Boolean,
        isRemove: Boolean
    },
    setup(props: any, context: any) {
        const {products, productFilters, productTotalRecords} = toRefs(props);
        const showDialog = ref(false);
        const isRemove = ref(false);
        const isFilterRelated = ref(false);
        const loading = ref(false);
        const tags = ref([]);

        const toast = useToast();

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            isRemove.value = args.isRemove;
            isFilterRelated.value = args.isFilterRelated || false;
        });

        const closeResponsive = (forceResfresh: boolean = false) => {
            tags.value = [];
            context.emit('close-dialog', forceResfresh);
        };

        const confirmClicked = () => {
            loading.value = true;

            bulkUpdateMetatags(
                products.value,
                productFilters.value,
                tags.value,
                isRemove.value,
                isFilterRelated.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t(
                            isFilterRelated.value
                                ? 'messages.cronJobExecutionScheduled'
                                : 'messages.changesSavedSuccessfully'
                        )
                    );
                    closeResponsive(true);
                    loading.value = false;
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    loading.value = false;
                });
        };

        return {
            products,
            productTotalRecords,
            isFilterRelated,
            showDialog,
            loading,
            closeResponsive,
            confirmClicked,
            isRemove,
            tags
        };
    }
};
