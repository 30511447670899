import {computed, ref} from 'vue';
import {i18n} from '@/utils/i18n';
import Button from 'primevue/button';
import OverlayPanel from 'primevue/overlaypanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
    components: {OverlayPanel, 'p-button': Button, DataTable, Column},

    setup() {
        const selectedLanguage = ref(i18n.global.locale);
        const opLanguages = ref(null);

        const flagIcon = computed(() => {
            return (
                'flag-' +
                (selectedLanguage.value && selectedLanguage.value !== 'en'
                    ? selectedLanguage.value
                    : 'gb')
            );
        });

        const changeLanguage = (langCode: 'de' | 'en') => {
            if (i18n.global.locale !== langCode) {
                i18n.global.locale = langCode;
                selectedLanguage.value = langCode;
            }
        };

        const toggleDataTable = (event: any) => {
            opLanguages.value.toggle(event);
        };

        return {
            flagIcon,
            selectedLanguage,
            changeLanguage,
            opLanguages,
            toggleDataTable,
            languages: [
                {
                    key: 'de',
                    flag: 'flag-de',
                    label: 'languages.german'
                },
                {
                    key: 'en',
                    flag: 'flag-gb',
                    label: 'languages.english'
                }
            ]
        };
    }
};
