<loading v-model:active="loading" />
<div class="m-2">
    <div class="flex align-items-center mb-5">
        <div class="flex flex-grow-1">
            <div
                v-bind="getRootProps()"
                class="import-wizard-container font-medium text-sm flex-grow-1 flex"
            >
                <input v-bind="getInputProps()" />
                <p v-if="acceptedFiles.length > 0" class="text-center w-full">
                    {{ dropZoneText }}
                </p>
                <p v-else class="text-center w-full">
                    {{ $t("labels.importWizardInfoText") }}
                </p>
            </div>
        </div>

        <div v-if="showUploadConfirmButton" class="flex-none flex ml-5">
            <p-button
                class="p-button-sm p-button-success"
                :label="$t('buttons.confirm')"
                @click="handleUpload"
            ></p-button>

            <p-button
                style="margin-left: 5px"
                class="p-button-sm p-button-danger"
                :label="$t('buttons.cancel')"
                @click="acceptedFiles.length = 0"
            ></p-button>
        </div>
    </div>
    <Carousel :value="images" :numVisible="3" :numScroll="3">
        <template #item="slotProps">
            <img
                v-if="slotProps.data"
                :src="'data:' + slotProps.data.mediaType + ';base64, ' + slotProps.data.content"
                :alt="slotProps.data.name"
        /></template>
    </Carousel>
</div>
