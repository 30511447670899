<BulkProductAction
    @close-dialog="closeResponsive"
    @confirm-clicked="confirmClicked"
    :products="products"
    :productTotalRecords="productTotalRecords"
    :displayDialog="showDialog"
    :isFilterRelated="isFilterRelated"
    :summaryTabVisible="tags.length > 0"
    :loading="loading"
    :dialogHeader="isRemove ? $t('labels.removeTags') : $t('labels.addTags')"
>
    <template #tab-panel-middle>
        <TabPanel :header="$t('labels.tags')">
            <div class="grid">
                <div class="col">
                    <Chips
                        v-model="tags"
                        :allowDuplicate="false"
                        :placeholder="$t('labels.metatagsSeparatorInfo')"
                        separator=","
                    />
                </div>
            </div>
        </TabPanel>
    </template>
    <template #summary-panel-header="slotProps">
        <Panel :header="$t('labels.tags')">
            <Tag severity="info" v-for="tag in tags" class="mt-1 mr-1">
                <span
                    >{{tag.toUpperCase().replace('TTC-PRODUCT-META-',
                    '')}}</span
                >
            </Tag>
        </Panel>
    </template>
    <template #summary-panel-middle>
        <h1 style="text-align: center">
            <template v-if="isRemove"
                ><font-awesome-icon
                    style="margin-left: 30px; color: #ef4444"
                    :icon="['fas', 'trash-can']"
                /><font-awesome-icon
                    style="margin-left: 30px; color: #ef4444"
                    :icon="['fas', 'trash-can']"
                /><font-awesome-icon
                    style="margin-left: 30px; color: #ef4444"
                    :icon="['fas', 'trash-can']"
                />
            </template>
            <template v-else>
                <font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-down']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-down']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-down']"
                />
            </template>
        </h1>
    </template>
</BulkProductAction>
