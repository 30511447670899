import {ref, toRefs, watch} from 'vue';
import TabPanel from 'primevue/tabpanel';
import LoadingPlugin from 'vue-loading-overlay';
import ProductList from '@/components/grid/products/list.vue';
import {useToast} from 'vue-toastification';
import {getAllShopRules} from '@/services/product-discounts';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';
import {bulkProcessRules} from '@/services/products';
import {i18n} from '@/utils/i18n';
import BulkProductAction from './bulk-product-action.vue';
import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import {FilterMatchMode} from 'primevue/api';

export default {
    emits: ['close-dialog'],
    components: {
        TabPanel,
        ProductList,
        LoadingPlugin,
        DataTable,
        Column,
        'p-checkbox': Checkbox,
        BulkProductAction,
        Panel,
        InputText,
        InputSwitch
    },
    props: {
        products: Array,
        productFilters: Object,
        productTotalRecords: Number,
        displayDialog: Boolean,
        isRemove: Boolean,
        isFilterRelated: Boolean
    },
    setup(props: any, context: any) {
        const {products, productFilters, productTotalRecords} = toRefs(props);
        const showDialog = ref(false);
        const isRemove = ref(false);
        const isFilterRelated = ref(false);
        const selectedRule = ref();
        const loading = ref(false);
        const filters = ref({
            name: {value: null, matchMode: FilterMatchMode.CONTAINS}
        });

        const toast = useToast();

        const shopRules = ref([]);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            isRemove.value = args.isRemove || false;
            isFilterRelated.value = args.isFilterRelated || false;
            if (showDialog.value) {
                loadShopRules();
            }
        });

        const loadShopRules = () => {
            getAllShopRules()
                .then((data) => {
                    shopRules.value = data.data.filter(
                        (item: any) =>
                            item.name.toLowerCase().indexOf('promo') !== -1
                    );
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const closeResponsive = (forceResfresh: boolean = false) => {
            selectedRule.value = null;
            context.emit('close-dialog', forceResfresh);
        };

        const confirmClicked = (startTime: string | null = null) => {
            loading.value = true;

            bulkProcessRules(
                products.value,
                productFilters.value,
                [selectedRule.value],
                isRemove.value,
                isFilterRelated.value,
                startTime
            )
                .then(() => {
                    toast.success(
                        i18n.global.t(
                            isFilterRelated.value || startTime
                                ? 'messages.cronJobExecutionScheduled'
                                : 'messages.changesSavedSuccessfully'
                        )
                    );
                    closeResponsive(true);
                    loading.value = false;
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    loading.value = false;
                });
        };

        return {
            products,
            productTotalRecords,
            isFilterRelated,
            filters,
            showDialog,
            loading,
            selectedRule,
            closeResponsive,
            confirmClicked,
            shopRules
        };
    }
};
