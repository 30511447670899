<div
    class="grid p-fluid"
    v-if="ticketDetails.customAttributes.service_repair_description"
>
    <div class="col field">
        <label>{{ $t('labels.ticket.service.repairDescription') }} </label>

        <p-textarea
            rows="4"
            :disabled="true"
            :value="ticketDetails.customAttributes.service_repair_description"
        />
    </div>
</div>
<div
    class="grid p-fluid"
    v-if="ticketDetails.customAttributes.service_internal_error_description"
>
    <div class="col field">
        <label
            >{{ $t('labels.ticket.service.internalErrorDescription') }}
        </label>

        <p-textarea
            rows="4"
            :disabled="true"
            :value="ticketDetails.customAttributes.service_internal_error_description"
        />
    </div>
</div>
<div
    class="grid p-fluid"
    v-if="ticketDetails.customAttributes.service_customer_error_description"
>
    <div class="col field">
        <label
            >{{ $t('labels.ticket.service.customerErrorDescription') }}
        </label>

        <p-textarea
            rows="4"
            :disabled="true"
            :value="ticketDetails.customAttributes.service_customer_error_description"
        />
    </div>
</div>
