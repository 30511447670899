import {computed, ref, toRefs, watch} from 'vue';
import TabPanel from 'primevue/tabpanel';
import LoadingPlugin from 'vue-loading-overlay';
import ProductList from '@/components/grid/products/list.vue';
import {useToast} from 'vue-toastification';
import {getAll} from '@/services/product-badges';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';
import {bulkProcessBadges} from '@/services/products';
import {i18n} from '@/utils/i18n';
import BulkProductAction from './bulk-product-action.vue';
import Panel from 'primevue/panel';
import store from '@/store';

export default {
    emits: ['close-dialog'],
    components: {
        TabPanel,
        ProductList,
        LoadingPlugin,
        DataTable,
        Column,
        'p-checkbox': Checkbox,
        BulkProductAction,
        Panel
    },
    props: {
        products: Array,
        productFilters: Object,
        productTotalRecords: Number,
        displayDialog: Boolean,
        isRemove: Boolean,
        isFilterRelated: Boolean
    },
    setup(props: any, context: any) {
        const {products, productFilters, productTotalRecords} = toRefs(props);
        const showDialog = ref(false);
        const isRemove = ref(false);
        const isFilterRelated = ref(false);
        const selectedBadges = ref([]);
        const loading = ref(false);

        const toast = useToast();

        const badges = ref([]);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            isRemove.value = args.isRemove || false;
            isFilterRelated.value = args.isFilterRelated || false;
            if (showDialog.value) {
                loadBadges();
            }
        });

        const importPlatform = computed(() => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            return platforms.find(
                (item: any) => item.value === currentPlatform
            );
        });

        const loadBadges = () => {
            getAll()
                .then((data) => {
                    badges.value = data.data.sort((a: any, b: any) => {
                        if (
                            ['vdd', 'vdl'].indexOf(
                                importPlatform.value?.threeLetterId
                            ) === -1
                        ) {
                            return a.name.localeCompare(b.name);
                        }
                        if (
                            a.name.indexOf('€') !== -1 &&
                            b.name.indexOf('€') === -1
                        )
                            return 1;

                        if (
                            a.name.indexOf('€') === -1 &&
                            b.name.indexOf('€') !== -1
                        )
                            return -1;
                        if (
                            a.name.indexOf('€') !== -1 &&
                            b.name.indexOf('€') !== -1 &&
                            parseInt(a.name.match(/\d/g).join(''), 10) !==
                                parseInt(b.name.match(/\d/g).join(''), 10)
                        ) {
                            return parseInt(a.name.match(/\d/g).join(''), 10) >
                                parseInt(b.name.match(/\d/g).join(''), 10)
                                ? 1
                                : -1;
                        }

                        return a.name.localeCompare(b.name);
                    });
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const closeResponsive = (forceResfresh: boolean = false) => {
            selectedBadges.value.length = 0;
            context.emit('close-dialog', forceResfresh);
        };

        const summaryTabVisible = computed(() => {
            return selectedBadges.value && selectedBadges.value.length > 0;
        });

        const onSelectionChange = (selection: Array<any>) => {
            selectedBadges.value = selection;
        };

        const confirmClicked = (startTime: string | null = null) => {
            loading.value = true;

            bulkProcessBadges(
                products.value,
                productFilters.value,
                selectedBadges.value,
                isRemove.value,
                isFilterRelated.value,
                startTime
            )
                .then(() => {
                    toast.success(
                        i18n.global.t(
                            isFilterRelated.value || startTime
                                ? 'messages.cronJobExecutionScheduled'
                                : 'messages.changesSavedSuccessfully'
                        )
                    );
                    closeResponsive(true);
                    loading.value = false;
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    loading.value = false;
                });
        };

        return {
            products,
            productTotalRecords,
            isFilterRelated,
            badges,
            showDialog,
            loading,
            selectedBadges,
            closeResponsive,
            confirmClicked,
            summaryTabVisible,
            onSelectionChange
        };
    }
};
