<PrimeDialog
    :header="$t('messages.pleaseConfirm')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '450px'}"
    @show="onShowDialog"
    @hide="confirmationInputValue = ''"
    :modal="true"
>
    <div class="flex justify-content-center">
        <span style="font-size: 8rem" class="pi pi-exclamation-triangle"></span>
    </div>
    <div
        class="mt-3"
        v-html="isMailDialog ? $t('messages.manualMailJobRunConfirmation') : $t('messages.manualCronJobRunConfirmation')"
    ></div>

    <div class="grid p-fluid mt-2">
        <div class="col field">
            <label>{{$t('labels.emailRecipient')}}</label>
            <InputText
                autocomplete="off"
                v-model="confirmationInputValue"
                ref="confirmationInputField"
            />
        </div>
    </div>
    <div class="w-full flex justify-content-between mt-2">
        <p-button severity="danger" @click="showDialog = false">
            {{ $t("buttons.cancel") }}
        </p-button>
        <p-button
            :disabled="!confirmationInputValue && isMailDialog"
            severity="success"
            @click="onConfirmClicked"
        >
            {{ $t("buttons.confirm") }}
        </p-button>
    </div>
</PrimeDialog>
