import {computed, ref, watch} from 'vue';
import Button from 'primevue/button';
import {useVuelidate} from '@vuelidate/core';
import AutoComplete from 'primevue/autocomplete';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import {getAll, updateGrouping} from '@/services/products';
import {FilterMatchMode} from 'primevue/api';
import store from '@/store';

export default {
    emits: ['reload-product'],
    components: {
        'p-button': Button,
        AutoComplete
    },
    props: {
        productDetails: Object
    },
    setup(props: any, context: any) {
        const submitted = ref(false);
        const productDetails = ref(null);
        const savingInProgress = ref(false);
        const toast = useToast();
        const filteredProductSuggestions = ref();

        const state = ref({
            productNumber: null
        });

        const rules = {
            productNumber: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, () => {
            productDetails.value = props.productDetails;

            state.value.productNumber = isTeltecProduct.value
                ? productDetails.value.core?.videodataProductNumber
                : productDetails.value.core?.teltecProductNumber;
        });

        const searchProducts = (event: any) => {
            getAll({
                first: 0,
                rows: 20,
                columns: ['id', 'articleNumber', 'name'],
                filters: {
                    name: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    articleNumber: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                filterConjunction: 'or',
                platform: isTeltecProduct.value ? 'videodata' : 'teltec'
            })
                .then((data) => {
                    if (data.data?.items) {
                        filteredProductSuggestions.value = data.data.items.map(
                            (item: {
                                id: string;
                                articleNumber: string;
                                name: string;
                            }) => {
                                return {
                                    label:
                                        '(' +
                                        item.articleNumber +
                                        ') ' +
                                        item.name,
                                    value: item.articleNumber
                                };
                            }
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const isTeltecProduct = computed(() => {
            return (
                productDetails.value.core?.teltecProductNumber ===
                productDetails.value.weclapp?.articleNumber
            );
        });

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            updateGrouping(
                productDetails.value.weclapp?.articleNumber ||
                    productDetails.value.shopware?.productNumber,
                {
                    productNumber:
                        state.value.productNumber.value ||
                        state.value.productNumber
                }
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    v$.value.$reset();
                    context.emit('reload-product');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            productDetails,
            filteredProductSuggestions,
            searchProducts,
            platofrms: store.getters['auth/platforms']
        };
    }
};
