import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dcdf5370"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-input-icon-left text-xs" }

export function render(_ctx, _cache) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_AutoComplete, {
      modelValue: _ctx.searchTerm,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.searchTerm) = $event)),
      suggestions: _ctx.filteredResults,
      placeholder: _ctx.$t('labels.enterSearchTerm'),
      onComplete: _ctx.loadData,
      onItemSelect: _ctx.onTermSelected,
      optionLabel: "name",
      optionValue: "id"
    }, null, 8, ["modelValue", "suggestions", "placeholder", "onComplete", "onItemSelect"])
  ]))
}