import {ref, onMounted, computed} from 'vue';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import LoadingPlugin from 'vue-loading-overlay';
import Panel from 'primevue/panel';
import Tag from 'primevue/tag';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';

import {
    cancelHeadlessShipment,
    getAllHeadless,
    getAvailableCarriersForHeadless,
    getCombinedHeadlessShipmentLabel,
    getSingleHeadlessShipmentLabel
} from '@/services/shipments';
import store from '@/store';
import CreateHeadlessShipmentDialog from '@/components/dialog/shipments/create-headless-shipment.vue';
import {i18n} from '@/utils/i18n';
import {DateTime} from 'luxon';
import countries from 'i18n-iso-countries';
import FileSaver from 'file-saver';
import {getAll} from '@/services/metadata';
import {useConfirm} from 'primevue/useconfirm';
import Button from 'primevue/button';

export default {
    components: {
        DataTable,
        Column,
        Tag,
        InputText,
        Panel,
        loading: LoadingPlugin,
        Dropdown,
        CreateHeadlessShipmentDialog,
        'p-button': Button
    },
    setup() {
        onMounted(() => {
            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'id',
                sortOrder: -1,
                filters: filters.value
            };

            loadLazyData();

            getAll(['warehouse'])
                .then((data: any) => {
                    warehouses.value = data.data?.warehouse || [];
                })
                .catch((error: any) => {
                    toast.error(error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
            getAvailableCarriersForHeadless().then((data) => {
                availableCarriers.value = Object.keys(data.data).map(
                    (item: any) => {
                        return {
                            label: item,
                            value: data.data[item]['weclapp-id']
                        };
                    }
                );
                loading.value = false;
            });
        });

        const dt = ref();
        const loading = ref(false);
        const totalRecords = ref(0);
        const toast = useToast();
        const lazyParams = ref(null);
        const showCreateDialog = ref(false);

        const headlessShipments = ref();
        const availableCarriers = ref([]);
        const warehouses = ref([]);

        const confirm = useConfirm();

        const filterDefintions: any = {
            customerName: {value: null, matchMode: FilterMatchMode.CONTAINS},
            referenceNumber: {value: null, matchMode: FilterMatchMode.CONTAINS},
            address: {value: null, matchMode: FilterMatchMode.CONTAINS},
            trackingNumber: {value: null, matchMode: FilterMatchMode.CONTAINS},
            carrierName: {value: null, matchMode: FilterMatchMode.CONTAINS},
            warehouseName: {value: null, matchMode: FilterMatchMode.CONTAINS}
        };

        const filters = ref(filterDefintions);

        const loadLazyData = () => {
            loading.value = true;

            getAllHeadless(lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    headlessShipments.value = data.data.items.map(
                        (item: any) => {
                            return {
                                ...item,
                                submittedAtFormatted: item.external?.submittedAt
                                    ? DateTime.fromSeconds(
                                          item.external.submittedAt
                                      )
                                          .setLocale(i18n.global.locale)
                                          .setZone(
                                              process.env
                                                  ?.VUE_APP_DEFAULT_TIME_ZONE
                                          )
                                          .toLocaleString(
                                              DateTime.DATETIME_MED_WITH_SECONDS
                                          )
                                    : null,
                                cancelledAtFormatted: item.external?.cancelledAt
                                    ? DateTime.fromSeconds(
                                          item.external.cancelledAt
                                      )
                                          .setLocale(i18n.global.locale)
                                          .setZone(
                                              process.env
                                                  ?.VUE_APP_DEFAULT_TIME_ZONE
                                          )
                                          .toLocaleString(
                                              DateTime.DATETIME_MED_WITH_SECONDS
                                          )
                                    : null,
                                runAtFormatted: item.job?.lastRunAt
                                    ? DateTime.fromSeconds(item.job.lastRunAt)
                                          .setLocale(i18n.global.locale)
                                          .setZone(
                                              process.env
                                                  ?.VUE_APP_DEFAULT_TIME_ZONE
                                          )
                                          .toLocaleString(
                                              DateTime.DATETIME_MED_WITH_SECONDS
                                          )
                                    : null,
                                country: countries.getName(
                                    item.countryCode,
                                    i18n.global.locale
                                )
                            };
                        }
                    );
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const voidShipment = (data: any) => {
            confirm.require({
                message: i18n.global.t('messages.cancelShipmentConfirmation', {
                    trackingNumber: data.external?.trackingNumber,
                    carrierName: data.carrierName
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    loading.value = true;
                    cancelHeadlessShipment(data.id)
                        .then(() => {
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );
                            loadLazyData();
                        })
                        .catch((error) => {
                            loading.value = false;
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        });
                }
            });
        };

        const onPage = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = filters.value;
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('headless-shipments-edit') !== -1;
        });

        const onCloseCreateDialog = () => {
            showCreateDialog.value = false;
            loadLazyData();
        };

        const showTrackingLabel = async (shipment: {id: string}) => {
            const file = await getSingleHeadlessShipmentLabel(shipment.id);

            const filename = file.headers['content-disposition']
                .split('filename=')[1]
                .split(';')[0];

            FileSaver.saveAs(
                new Blob([file.data], {
                    type: file.headers['content-type']
                }),
                filename
            );
        };

        const showTrackingLabelCombined = async (shipment: {id: string}) => {
            const file = await getCombinedHeadlessShipmentLabel(shipment.id);

            const filename = file.headers['content-disposition']
                .split('filename=')[1]
                .split(';')[0];

            FileSaver.saveAs(
                new Blob([file.data], {
                    type: file.headers['content-type']
                }),
                filename
            );
        };

        return {
            headlessShipments,
            dt,
            totalRecords,
            loading,
            filters,
            onPage,
            onFilter,
            onSort,
            editPermissionAvailable,
            showCreateDialog,
            onCloseCreateDialog,
            showTrackingLabel,
            showTrackingLabelCombined,
            availableCarriers,
            warehouses,
            voidShipment
        };
    }
};
