<PrimeDialog
    :header="$t('labels.shipment.upsDangerousGoodsData')"
    v-model:visible="showDialog"
    :closable="false"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '640px'}"
    :modal="true"
>
    <loading v-model:active="savingInProgress" />
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="grid p-fluid mb-3">
            <div class="field col">
                <label> {{$t('labels.shipment.upsChemicalId')}} </label>

                <p-dropdown
                    v-model="v$.chemicalId.$model"
                    :options="chemicalOptions"
                    optionLabel="idNumber"
                    optionValue="idNumber"
                    class="w-full"
                    @change="setDropdownValue('chemicalId', $event)"
                    :showClear="true"
                    :class="{'p-invalid':v$.chemicalId.$invalid && submitted}"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.chemicalId.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
                <small v-if="v$.chemicalId.$model" style="display: block">
                    {{ chemicalOptions.find((item) => item.idNumber ===
                    v$.chemicalId.$model).hazardousMaterialsDescription }}
                </small>
            </div>
        </div>
        <template v-if="v$.numberOfPackages.$model">
            <div
                class="grid p-fluid mb-3"
                v-for="(item, index) in state.weightCombined"
                :key="index"
            >
                <div class="col field">
                    <label>
                        {{$t('labels.productHome.netWeight')}} (Paket {{ index +
                        1 }})
                    </label>

                    <InputNumber
                        class="w-full"
                        :disabled="(v$.numberOfPackages.$model || 0) < 2"
                        autocomplete="off"
                        locale="de-DE"
                        mode="decimal"
                        showButtons
                        :minFractionDigits="2"
                        :step="0.001"
                        v-model="item.weight"
                        :class="{'p-invalid':submitted && v$.weightCombined.$each.$response.$data[index].weight.$invalid}"
                        @input="onPackageWeightChange(index, $event)"
                    />

                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.weightCombined.$each.$response.$errors[index].weight"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
        </template>
        <div class="grid p-fluid mb-3">
            <div class="col field">
                <label>
                    {{$t('labels.shipment.numberOfDangerousGoodsPackages')}}
                </label>

                <InputNumber
                    autocomplete="off"
                    v-model="v$.numberOfPackages.$model"
                    class="w-full"
                    showButtons
                    :min="1"
                    @input="onPackagesNumberChange"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.numberOfPackages.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="w-full flex justify-content-between">
            <p-button severity="danger" @click="onCancelClick">
                {{ $t("buttons.cancel") }}
            </p-button>

            <p-button severity="success" type="submit">
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </form>
</PrimeDialog>
