import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be4257ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "grid p-fluid mb-3"
}
const _hoisted_2 = { class: "col field" }
const _hoisted_3 = { class: "w-full flex justify-content-between mt-4" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.ticket.chooseSerialNumber'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[4] || (_cache[4] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '65vw'},
    closable: false,
    modal: true,
    onHide: _ctx.resetItems
  }, {
    default: _withCtx(() => [
      _createVNode(_component_loading, {
        active: _ctx.loading,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
      }, null, 8, ["active"]),
      (!_ctx.articleId)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.serialNumberExtern')), 1),
              _createVNode(_component_InputText, {
                class: "text-sm",
                onKeydown: _withKeys(_ctx.addCustomSerialNumber, ["enter"]),
                modelValue: _ctx.customSerialNumber,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.customSerialNumber) = $event))
              }, null, 8, ["onKeydown", "modelValue"])
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_DataTable, {
        class: "p-datatable-sm text-sm",
        stripedRows: "",
        paginator: _ctx.totalRecords > 10,
        rows: 10,
        lazy: _ctx.articleId !== null,
        ref: "dt",
        dataKey: "serialNumber",
        totalRecords: _ctx.totalRecords,
        value: _ctx.warehouseStockMovements,
        responsiveLayout: "scroll",
        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
        "rows-per-page-options": [10,20,50,100,200],
        "current-page-report-template": "{first} to {last} of {totalRecords}",
        selection: _ctx.selectedItems,
        "onUpdate:selection": _cache[2] || (_cache[2] = $event => ((_ctx.selectedItems) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            selectionMode: "multiple",
            headerStyle: "width: 3em"
          }),
          _createVNode(_component_Column, {
            field: "serialNumber",
            header: _ctx.$t('labels.productHome.serialNumberExtern')
          }, null, 8, ["header"])
        ]),
        _: 1
      }, 8, ["paginator", "lazy", "totalRecords", "value", "selection"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_p_button, {
          severity: "danger",
          textContent: _toDisplayString(_ctx.$t('buttons.cancel')),
          onClick: _cache[3] || (_cache[3] = $event => {_ctx.selectedItems = [];_ctx.showDialog = false;_ctx.closeResponsive();})
        }, null, 8, ["textContent"]),
        _createVNode(_component_p_button, {
          severity: "success",
          textContent: _toDisplayString(_ctx.$t('buttons.confirm')),
          disabled: _ctx.selectedItems.length < 1,
          onClick: _ctx.onConfirmButtonClicked
        }, null, 8, ["textContent", "disabled", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible", "onHide"]))
}