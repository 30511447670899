<Panel :header="$t('labels.rentalBoard')">
    <template #icons>
        <div class="flex justify-content-center align-items-center">
            <span class="p-input-icon-left">
                <IconField iconPosition="left">
                    <InputIcon class="pi pi-search"> </InputIcon>
                    <InputText
                        class="text-sm"
                        v-model.trim="articleNumber"
                        :placeholder="$t('labels.articleNumber')"
                        @keyup.enter="loadData"
                    />
                </IconField>
            </span>
            <p-multiselect
                class="text-sm ml-3"
                :modelValue="selectedRentalStatusOptions"
                ref="rentalStatusSelectorComponent"
                @change="onToggleColumnVisibility"
                :options="rentalStatusOptions"
                optionLabel="value"
                optionValue="id"
                :placeholder="$t('labels.rentalStatus')"
                style="width: 20em"
            >
                <template #footer>
                    <div class="w-full flex justify-content-between m-2">
                        <p-button
                            severity="danger"
                            class="text-sm"
                            @click="rentalStatusSelectorComponent.hide()"
                        >
                            {{ $t("buttons.cancel") }}
                        </p-button>
                        <p-button
                            severity="success"
                            class="text-sm"
                            @click="rentalStatusSelectorComponent.hide(); loadData()"
                        >
                            {{ $t("buttons.confirm") }}
                        </p-button>
                    </div>
                </template>
            </p-multiselect>
        </div>
    </template>
    <Qalendar
        :events="events"
        :config="config"
        @updated-period="onPeriodUpdated"
        @updated-mode="onModeUpdated"
        :selected-date="selectedDate"
        :is-loading="loading"
    />
</Panel>
