import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden" }
const _hoisted_2 = { class: "flex flex-column align-items-center justify-content-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { style: {"border-radius":"56px","padding":"0.3rem","background":"linear-gradient(\n                        180deg,\n                        var(--primary-color) 10%,\n                        rgba(33, 150, 243, 0) 30%\n                    )"} }
const _hoisted_5 = {
  class: "w-full surface-card py-8 px-5 sm:px-8",
  style: {"border-radius":"53px"}
}
const _hoisted_6 = { class: "text-center mb-5" }
const _hoisted_7 = { class: "text-600 font-medium" }
const _hoisted_8 = {
  class: "p-button-label",
  "data-pc-section": "label"
}

export function render(_ctx, _cache) {
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require('@/assets/img/' + _ctx.logoUrl+ '.png'),
        alt: "Teltec logo",
        class: "mb-5 w-14rem flex-shrink-0"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("labels.signInToStart")), 1)
          ]),
          _createElementVNode("form", {
            onSubmit: _cache[0] || (_cache[0] = _withModifiers((...args) => (_ctx.fetchRedirectData && _ctx.fetchRedirectData(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", null, [
              _createVNode(_component_p_button, {
                class: "w-full p-3 text-xl",
                type: "submit",
                block: "true",
                loading: _ctx.isAuthLoading,
                disabled: _ctx.isAuthLoading
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("labels.signIn")), 1)
                ]),
                _: 1
              }, 8, ["loading", "disabled"])
            ])
          ], 32)
        ])
      ])
    ])
  ]))
}