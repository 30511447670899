import {computed, ref, watch} from 'vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import useVuelidate from '@vuelidate/core';
import {helpers, maxLength, required, requiredIf} from '@vuelidate/validators';
import {announceAtShippingCarrier} from '@/services/shipments';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        InputText,
        'p-button': Button,
        loading: LoadingPlugin,
        'p-dropdown': Dropdown,
        'p-checkbox': Checkbox,
        InputNumber
    },
    props: {
        shipment: Object,
        groupedShipments: Array,
        displayDialog: Boolean
    },
    setup(props: any, context: any) {
        const shipment = ref(null);
        const groupedShipments = ref([]);
        const showDialog = ref(false);
        const toast = useToast();
        const savingInProgress = ref(false);
        const submitted = ref(false);

        const state = ref({
            deliveryCosts: 0,
            upsAccountNumber: null,
            upsSignature: false,
            upsAccessPointId: null,
            upsShipmentDescription: 'Broadcast Equipment',
            upsPackageDescription: 'Broadcasttechnik',
            packageWeight: null,
            shippingLabelsCount: null
        });

        const rules = {
            deliveryCosts: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return carrierType.value === 'ups';
                    })
                )
            },
            upsAccountNumber: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return (
                            state.value.deliveryCosts === 1 ||
                            state.value.deliveryCosts === 2
                        );
                    })
                )
            },
            upsSignature: {},
            upsAccessPointId: {},
            upsShipmentDescription: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return carrierType.value === 'ups';
                    })
                ),
                maxLength: helpers.withMessage(
                    i18n.global.t('messages.valueCantBeLongerThan', {
                        number: 50
                    }),
                    maxLength(50)
                )
            },
            upsPackageDescription: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return carrierType.value === 'ups';
                    })
                ),
                maxLength: helpers.withMessage(
                    i18n.global.t('messages.valueCantBeLongerThan', {
                        number: 35
                    }),
                    maxLength(35)
                )
            },
            packageWeight: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            shippingLabelsCount: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            }
        };

        const v$ = useVuelidate(rules, state);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            if (!showDialog.value) {
                return;
            }
            shipment.value = args.shipment;
            if (args.groupedShipments && args.groupedShipments.length > 0) {
                groupedShipments.value = args.groupedShipments;
            }

            if (args.shipment) {
                state.value.packageWeight = args.shipment.packageWeight
                    ? parseFloat(args.shipment.packageWeight || '0.0')
                    : null;
                state.value.shippingLabelsCount = args.shipment
                    .shippingLabelsCount
                    ? parseInt(args.shipment?.shippingLabelsCount || '1')
                    : null;
            } else if (
                args.groupedShipments &&
                args.groupedShipments.length > 0
            ) {
                state.value.packageWeight = 0.0;
                state.value.shippingLabelsCount = parseInt(
                    args.groupedShipments[0].shippingLabelsCount || '1'
                );
                args.groupedShipments.forEach((item: any) => {
                    state.value.packageWeight += parseFloat(
                        item.packageWeight || '0.0'
                    );
                });
            }
        });

        const carrierType = computed(() => {
            if (shipment.value) {
                return shipment.value?.shippingCarrierType;
            }

            if (groupedShipments.value && groupedShipments.value.length > 0) {
                return groupedShipments.value[0].shippingCarrierType;
            }

            return null;
        });

        const onCancelClick = (event: any) => {
            event.preventDefault();
            showDialog.value = false;
            submitted.value = false;
            state.value = {
                deliveryCosts: 0,
                upsAccountNumber: null,
                upsSignature: false,
                upsAccessPointId: null,
                upsShipmentDescription: 'Broadcast Equipment',
                upsPackageDescription: 'Broadcasttechnik',
                packageWeight: null,
                shippingLabelsCount: null
            };
            context.emit('close-dialog');
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;

            Object.assign(state.value, temp);
        };

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            try {
                savingInProgress.value = true;
                if (shipment.value) {
                    await announceAtShippingCarrier(
                        shipment.value?.shipmentNumber,
                        state.value
                    );
                } else {
                    const mainShipment = groupedShipments.value.shift();
                    await announceAtShippingCarrier(
                        mainShipment.shipmentNumber,
                        Object.assign(
                            {
                                groupedShipments: groupedShipments.value.map(
                                    (item: any) => item.shipmentNumber
                                )
                            },
                            state.value
                        )
                    );
                }
                toast.success(
                    i18n.global.t(
                        'messages.shipmentAcceptedByShippingCarrier',
                        {
                            carrierName: carrierType.value
                                ? carrierType.value.toUpperCase()
                                : 'UPS'
                        }
                    )
                );
                context.emit('close-dialog', true);
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
                context.emit('close-dialog');
            } finally {
                submitted.value = false;
                state.value = {
                    deliveryCosts: 0,
                    upsAccountNumber: null,
                    upsSignature: false,
                    upsAccessPointId: null,
                    upsShipmentDescription: 'Broadcast Equipment',
                    upsPackageDescription: 'Broadcasttechnik',
                    packageWeight: null,
                    shippingLabelsCount: null
                };
                savingInProgress.value = false;
                showDialog.value = false;
            }
        };

        return {
            showDialog,
            onCancelClick,
            handleSubmit,
            state,
            v$,
            submitted,
            savingInProgress,
            setDropdownValue,
            locale: i18n.global.locale,
            groupedShipments,
            carrierType
        };
    }
};
