import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ffac5264"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "grid"
}
const _hoisted_2 = { class: "col field" }
const _hoisted_3 = { class: "font-semibold" }
const _hoisted_4 = {
  key: 1,
  class: "grid"
}
const _hoisted_5 = { class: "col field" }
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = {
  key: 2,
  class: "grid"
}
const _hoisted_8 = { class: "col field" }
const _hoisted_9 = { class: "font-semibold" }
const _hoisted_10 = {
  key: 3,
  class: "grid"
}
const _hoisted_11 = { class: "col field" }
const _hoisted_12 = { class: "font-semibold" }
const _hoisted_13 = {
  key: 4,
  class: "grid"
}
const _hoisted_14 = { class: "col field" }
const _hoisted_15 = { class: "font-semibold" }
const _hoisted_16 = {
  key: 5,
  class: "grid"
}
const _hoisted_17 = { class: "col field" }
const _hoisted_18 = { class: "font-semibold" }
const _hoisted_19 = {
  key: 0,
  class: "grid"
}
const _hoisted_20 = { class: "col field" }
const _hoisted_21 = { class: "font-semibold" }
const _hoisted_22 = {
  key: 1,
  class: "grid"
}
const _hoisted_23 = { class: "col field" }
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = {
  key: 0,
  class: "grid"
}
const _hoisted_26 = { class: "col field" }
const _hoisted_27 = { class: "font-semibold" }
const _hoisted_28 = {
  key: 1,
  class: "grid"
}
const _hoisted_29 = { class: "col field" }
const _hoisted_30 = { class: "font-semibold" }
const _hoisted_31 = {
  key: 2,
  class: "grid"
}
const _hoisted_32 = { class: "col field" }
const _hoisted_33 = { class: "font-semibold" }

export function render(_ctx, _cache) {
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_TabView = _resolveComponent("TabView")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.sourceProduct?.name + ' [' + _ctx.sourceProduct?.articleNumber + ']',
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw', '640px': '90vw'},
    style: {width: '600px'},
    onHide: _ctx.closeDialog,
    modal: true,
    position: "top"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_p_button, {
        severity: "success",
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.handleSelection('full', $event)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('buttons.pairProductFull')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_p_button, {
        onClick: _cache[1] || (_cache[1] = $event => (_ctx.handleSelection('lite', $event)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('buttons.pairProductLite')), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_TabView, null, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('labels.productHome.masterData')
          }, {
            default: _withCtx(() => [
              (_ctx.sourceProduct?.articleNumber)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.articleNumber')), 1),
                      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.sourceProduct?.articleNumber), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.sourceProduct?.matchCode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.matchCode')), 1),
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.sourceProduct?.matchCode), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.sourceProduct?.ean)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ean')), 1),
                      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.sourceProduct?.ean), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.sourceProduct?.manufacturerPartNumber)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.mpn')), 1),
                      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.sourceProduct?.manufacturerPartNumber), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.vkPriceCalculation(_ctx.sourceProduct))
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.vkPrice')), 1),
                      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.vkPriceCalculation(_ctx.sourceProduct)), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.uvpPriceCalculation(_ctx.sourceProduct))
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.uvpPrice')), 1),
                      _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.uvpPriceCalculation(_ctx.sourceProduct)), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('labels.productDescription.productTexts')
          }, {
            default: _withCtx(() => [
              (_ctx.sourceProduct?.shortDescription1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productDescription.shortDescription')), 1),
                      _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.sourceProduct?.shortDescription1), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.sourceProduct?.description)
                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.description')), 1),
                      _createElementVNode("div", {
                        class: "font-semibold",
                        innerHTML: _ctx.sourceProduct?.description
                      }, null, 8, _hoisted_24)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          (_ctx.sourceProduct?.articleSupplySources && _ctx.sourceProduct.articleSupplySources.length > 0)
            ? (_openBlock(), _createBlock(_component_TabPanel, {
                key: 0,
                header: _ctx.$t('labels.primarySupplySource')
              }, {
                default: _withCtx(() => [
                  (_ctx.sourceProduct.articleSupplySources[0].articleNumber)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _createElementVNode("div", _hoisted_26, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.sku')), 1),
                          _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.sourceProduct.articleSupplySources[0].articleNumber), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.sourceProduct.articleSupplySources[0].supplierNumber)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.salesOrder.supplierNumber')), 1),
                          _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.sourceProduct.articleSupplySources[0].supplierNumber), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.ekPriceCalculation(_ctx.sourceProduct))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                        _createElementVNode("div", _hoisted_32, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ekPrice')), 1),
                          _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.ekPriceCalculation(_ctx.sourceProduct)), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["header", "visible", "onHide"]))
}