export default {
    shippingLocation: (state: {
        shippingLocation: Array<{platform: string; shippingLocation: string}>;
    }): Array<{platform: string; shippingLocation: string}> => {
        return JSON.parse(
            localStorage.getItem('shippingLocation') ||
                JSON.stringify(state.shippingLocation || [])
        );
    },

    openShipments: (state: {
        openShipments: {warehouseId: string};
    }): {warehouseId: string} => {
        return JSON.parse(
            localStorage.getItem('openShipments') ||
                JSON.stringify(state.openShipments || {})
        );
    }
};
