import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-478ab2eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "overlay-users" }
const _hoisted_3 = { class: "flex justify-content-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex justify-content-center mt-4 font-semibold" }
const _hoisted_6 = { class: "flex justify-content-center text-sm mt-1" }
const _hoisted_7 = {
  class: "flex mt-4",
  style: {"justify-content":"space-between"}
}

export function render(_ctx, _cache) {
  const _component_p_button = _resolveComponent("p-button")
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: "userIcon",
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.toggleUserPanel && _ctx.toggleUserPanel(...args)))
    }, [
      _createElementVNode("img", {
        src: (_ctx.profilePicture?.type && _ctx.profilePicture?.contents) ? ('data:' + _ctx.profilePicture.type + ';base64, ' + _ctx.profilePicture.contents) : require('@/assets/img/default-profile.png'),
        alt: "User Image"
      }, null, 8, _hoisted_1)
    ]),
    _createVNode(_component_OverlayPanel, {
      ref: "opUser",
      appendTo: "body",
      id: "overlay_panel_user"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: (_ctx.profilePicture?.type && _ctx.profilePicture?.contents) ? ('data:' + _ctx.profilePicture.type + ';base64, ' + _ctx.profilePicture.contents) : require('@/assets/img/default-profile.png'),
              alt: "User Image"
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.user && (_ctx.user.displayName)), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.memberSince")) + " ", 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.readableCreatedAtDate), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", null, [
              ((_ctx.user?.permissions || []).indexOf('weclapp-shipments-edit') !== -1)
                ? (_openBlock(), _createBlock(_component_p_button, {
                    key: 0,
                    onClick: _ctx.navigateToProfile
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("labels.profile")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_p_button, { onClick: _ctx.logout }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("labels.signOut")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 512)
  ], 64))
}