<PrimeDialog
    :header="sourceProduct?.name + ' [' + sourceProduct?.articleNumber + ']'"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '600px'}"
    @hide="closeDialog"
    :modal="true"
    position="top"
>
    <TabView>
        <TabPanel :header="$t('labels.productHome.masterData')">
            <div class="grid" v-if="sourceProduct?.articleNumber">
                <div class="col field">
                    <label>{{ $t('labels.articleNumber') }} </label>
                    <div class="font-semibold">
                        {{sourceProduct?.articleNumber}}
                    </div>
                </div>
            </div>
            <div class="grid" v-if="sourceProduct?.matchCode">
                <div class="col field">
                    <label>{{ $t('labels.productAdd.matchCode') }} </label>
                    <div class="font-semibold">
                        {{sourceProduct?.matchCode}}
                    </div>
                </div>
            </div>
            <div class="grid" v-if="sourceProduct?.ean">
                <div class="col field">
                    <label>{{ $t('labels.ean') }} </label>
                    <div class="font-semibold">{{sourceProduct?.ean}}</div>
                </div>
            </div>
            <div class="grid" v-if="sourceProduct?.manufacturerPartNumber">
                <div class="col field">
                    <label>{{ $t('labels.productAdd.mpn') }} </label>
                    <div class="font-semibold">
                        {{sourceProduct?.manufacturerPartNumber}}
                    </div>
                </div>
            </div>
            <div class="grid" v-if="vkPriceCalculation(sourceProduct)">
                <div class="col field">
                    <label>{{ $t('labels.vkPrice') }} </label>
                    <div class="font-semibold">
                        {{vkPriceCalculation(sourceProduct)}}
                    </div>
                </div>
            </div>
            <div class="grid" v-if="uvpPriceCalculation(sourceProduct)">
                <div class="col field">
                    <label>{{ $t('labels.productAdd.uvpPrice') }} </label>
                    <div class="font-semibold">
                        {{uvpPriceCalculation(sourceProduct)}}
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel :header="$t('labels.productDescription.productTexts')">
            <div class="grid" v-if="sourceProduct?.shortDescription1">
                <div class="col field">
                    <label
                        >{{ $t('labels.productDescription.shortDescription') }}
                    </label>
                    <div class="font-semibold">
                        {{sourceProduct?.shortDescription1}}
                    </div>
                </div>
            </div>
            <div class="grid" v-if="sourceProduct?.description">
                <div class="col field">
                    <label>{{ $t('labels.description') }} </label>
                    <div
                        class="font-semibold"
                        v-html="sourceProduct?.description"
                    ></div>
                </div>
            </div>
        </TabPanel>
        <TabPanel
            :header="$t('labels.primarySupplySource')"
            v-if="sourceProduct?.articleSupplySources && sourceProduct.articleSupplySources.length > 0"
        >
            <div
                class="grid"
                v-if="sourceProduct.articleSupplySources[0].articleNumber"
            >
                <div class="col field">
                    <label>{{ $t('labels.productAdd.sku') }} </label>
                    <div class="font-semibold">
                        {{sourceProduct.articleSupplySources[0].articleNumber}}
                    </div>
                </div>
            </div>
            <div
                class="grid"
                v-if="sourceProduct.articleSupplySources[0].supplierNumber"
            >
                <div class="col field">
                    <label>{{ $t('labels.salesOrder.supplierNumber') }} </label>
                    <div class="font-semibold">
                        {{sourceProduct.articleSupplySources[0].supplierNumber}}
                    </div>
                </div>
            </div>
            <div class="grid" v-if="ekPriceCalculation(sourceProduct)">
                <div class="col field">
                    <label>{{ $t('labels.ekPrice') }} </label>
                    <div class="font-semibold">
                        {{ekPriceCalculation(sourceProduct)}}
                    </div>
                </div>
            </div>
        </TabPanel>
    </TabView>

    <template #footer>
        <p-button severity="success" @click="handleSelection('full', $event)">
            {{$t('buttons.pairProductFull')}}
        </p-button>
        <p-button @click="handleSelection('lite', $event)"
            >{{$t('buttons.pairProductLite')}}
        </p-button>
    </template>
</PrimeDialog>
