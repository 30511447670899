<BulkProductAction
    @close-dialog="closeResponsive"
    @confirm-clicked="confirmClicked"
    :products="products"
    :productTotalRecords="productTotalRecords"
    :displayDialog="showDialog"
    :isFilterRelated="isFilterRelated"
    :summaryTabVisible="!!selectedCategories && Object.keys(selectedCategories).length > 0"
    :loading="loading"
    :dialogHeader="isRemove ? $t('labels.removeCategories') : $t('labels.addCategories')"
    canPostponeExecution
>
    <template #tab-panel-middle>
        <TabPanel :header="$t('labels.categories')">
            <p-tree
                :value="categories"
                selectionMode="multiple"
                @nodeExpand="onNodeExpand"
                @nodeSelect="onNodeSelect"
                @nodeUnselect="onNodeUnselect"
                v-model:selectionKeys="selectedCategories"
                :metaKeySelection="false"
                class="text-xs"
            ></p-tree>
        </TabPanel>
    </template>
    <template #summary-panel-header>
        <Panel class="mb-3">
            <template #header>
                <div class="font-weight-bold">
                    {{ $t('labels.categories') }}
                </div>
            </template>
            <DataTable
                class="p-datatable-sm text-sm"
                stripedRows
                :paginator="selectedCategoriesLabels && selectedCategoriesLabels.length > 20"
                :rows="20"
                :lazy="true"
                :value="selectedCategoriesLabels"
                responsiveLayout="scroll"
                paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rows-per-page-options="[10,20,50,100,200]"
                current-page-report-template="{first} to {last} of {totalRecords}"
            >
                <Column style="min-width: 12rem">
                    <template #body="{data}"> {{ data.label }} </template>
                </Column>
            </DataTable>
        </Panel>
    </template>
    <template #summary-panel-middle>
        <h1 style="text-align: center">
            <template v-if="isRemove"
                ><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'ban']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'ban']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'ban']"
                />
            </template>
            <template v-else>
                <font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-down']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-down']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-down']"
                />
            </template>
        </h1>
    </template>
</BulkProductAction>
