import LoadingPlugin from 'vue-loading-overlay';
import Carousel from 'primevue/carousel';
import PrimeButton from 'primevue/button';
import Image from 'primevue/image';
import Toolbar from 'primevue/toolbar';
import {computed, onMounted, ref, toRefs, watch} from 'vue';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import {useDropzone} from 'vue3-dropzone';
import {stripTagsAndTruncate} from '@/utils/helpers';
import {addImages, getImages} from '@/services/tickets';

export default {
    components: {
        Carousel,
        'p-button': PrimeButton,
        'p-image': Image,
        Toolbar,
        loading: LoadingPlugin
    },
    props: {
        ticketDetails: Object
    },
    setup(props: any) {
        const {ticketDetails} = toRefs(props);

        const toast = useToast();
        const loading = ref(false);
        const images = ref([]);

        onMounted(() => {
            getImages(ticketDetails.value?.ticketNumber).then((data: any) => {
                images.value = data.data;
            });
        });

        watch(props, () => {
            if (
                props.ticketDetails &&
                props.ticketDetails.ticketNumber !==
                    ticketDetails.value?.ticketNumber
            ) {
                ticketDetails.value = props.ticketDetails;
            }
        });

        const showUploadConfirmButton = computed(() => {
            return acceptFiles.value && acceptFiles.value.length > 0;
        });

        const copyToClipboard = (data: any, event: any) => {
            navigator.clipboard.writeText(data);
            event.stopPropagation();
            toast.success(i18n.global.t('labels.copied'), {
                timeout: 500
            });
        };

        const dropZoneText = computed(() => {
            if (
                !acceptFiles.value ||
                !acceptFiles.value.length ||
                acceptFiles.value.length < 1
            ) {
                return '';
            }
            return stripTagsAndTruncate(
                acceptFiles.value.map((u: any) => u.name).join(', '),
                200,
                '...'
            );
        });

        const onDrop = (files: any, rejectReasons: any) => {
            acceptFiles.value = files.sort(function (a: any, b: any) {
                return a.name.localeCompare(b.name, 'en', {
                    numeric: true,
                    sensitivity: 'base'
                });
            });
            if (rejectReasons.length > 0) {
                toast.error(rejectReasons[0]['errors'][0]['message']);
                acceptFiles.value.length = 0;
            }
        };

        const {getRootProps, getInputProps, ...rest} = useDropzone({
            onDrop,
            accept: ['.jpg', '.png']
        });

        const acceptFiles = ref([]);

        const handleUpload = () => {
            loading.value = true;

            addImages(ticketDetails.value?.ticketNumber, acceptFiles.value)
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    acceptFiles.value.length = 0;
                    getImages(ticketDetails.value?.ticketNumber).then(
                        (data: any) => {
                            images.value = data.data;
                        }
                    );
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        return {
            images,
            showUploadConfirmButton,
            acceptFiles,
            dropZoneText,
            getRootProps,
            getInputProps,
            ...rest,
            handleUpload,
            copyToClipboard,
            loading
        };
    }
};
