import {ILayoutConfig} from '@/interfaces/layout/config';
import router from '@/router';
import store from '@/store';
import {computed, onBeforeMount, ref, watch} from 'vue';

export default {
    name: 'app-menu-item',
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        root: {
            type: Boolean,
            default: true
        },
        parentItemKey: {
            type: String,
            default: null
        }
    },
    setup(props: any) {
        const user = computed(() => {
            return store.getters['auth/user'];
        });

        const platform = computed(() => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            return platforms.find(
                (item: any) => item.value === currentPlatform
            );
        });

        const isActiveMenu = ref(false);
        const itemKey = ref(null);

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        onBeforeMount(() => {
            itemKey.value = props.parentItemKey
                ? props.parentItemKey + '-' + props.item?.name
                : String(props.item?.name);

            const activeItem = layoutConfig.value.activeMenuItem;

            isActiveMenu.value =
                activeItem &&
                (props.item.name === activeItem ||
                    (props.item.children || []).some(
                        (ch: any) => ch.name === activeItem
                    ));
        });

        watch(
            () => layoutConfig.value.activeMenuItem,
            (newVal) => {
                isActiveMenu.value =
                    newVal === itemKey.value ||
                    newVal.startsWith(itemKey.value + '-');
            }
        );
        const itemClick = (event: any, item: any) => {
            if (item.disabled) {
                event.preventDefault();
                return;
            }

            if (
                (item.path || item.url) &&
                (layoutConfig.value.staticMenuMobileActive ||
                    layoutConfig.value.overlayMenuActive)
            ) {
                onMenuToggle();
            }

            if (item.command) {
                item.command({originalEvent: event, item: item});
            }

            store.dispatch('ui/changeLayout', {
                activeMenuItem: item.name
            });
        };

        const onMenuToggle = () => {
            if (layoutConfig.value.menuMode === 'overlay') {
                store.dispatch('ui/changeLayout', {
                    overlayMenuActive: !layoutConfig.value.overlayMenuActive
                });
            }

            if (window.innerWidth > 991) {
                store.dispatch('ui/changeLayout', {
                    staticMenuDesktopInactive:
                        !layoutConfig.value.staticMenuDesktopInactive
                });
            } else {
                store.dispatch('ui/changeLayout', {
                    staticMenuMobileActive:
                        !layoutConfig.value.staticMenuMobileActive
                });
            }
        };

        const checkActiveRoute = (item: any) => {
            return router.currentRoute.value.path === item.path;
        };

        return {
            user,
            platform,
            itemClick,
            isActiveMenu,
            checkActiveRoute,
            layoutConfig
        };
    }
};
