<div class="m-2">
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="formgrid grid p-fluid mt-3 mb-3">
            <div class="col field"></div>
            <div class="col-2 field">
                <SelectButton
                    v-model="v$.language.$model"
                    :options="[
                    {name: 'Deutsch', value: 'de'},
                    {name: 'Englisch', value: 'en'}
                ]"
                    optionLabel="name"
                    optionValue="value"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.language.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>

            <div class="col-1 field"></div>
            <div class="col-4 field">
                <SelectButton
                    v-model="v$.office.$model"
                    :options="[
                    {name: 'BPM', value: 'bpm'},
                    {name: 'VD', value: 'vd'},
                    {name: 'Mainz', value: 'mz'},
                    {name: 'Waschow', value: 'lwl'},
                    {name: 'TT-Retoure', value: 'tt-hh'}
                ]"
                    optionLabel="name"
                    optionValue="value"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.office.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="flex justify-content-end mt-3">
            <p-button
                :disabled="savingInProgress"
                severity="success"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
                type="submit"
            >
            </p-button>
        </div>
    </form>
</div>
