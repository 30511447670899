import Languages from './languages/languages.vue';
import User from './user/user.vue';
import Platforms from './platforms/platforms.vue';
import Search from './search/search.vue';
import Bookmarks from './bookmarks/bookmarks.vue';
import InputText from 'primevue/inputtext';
import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
import store from '@/store';
import {ILayoutConfig} from '@/interfaces/layout/config';
import LayoutSidebar from '../layout-sidebar/layout-sidebar.vue';
import {DateTime} from 'luxon';

export default {
    emits: ['ui-rerender-needed'],
    components: {
        Languages,
        Platforms,
        Bookmarks,
        User,
        Search,
        InputText,
        LayoutSidebar
    },

    setup() {
        const outsideClickListener = ref(null);
        const topbarMenuActive = ref(false);
        const layoutConfigSidebarVisible = ref(false);

        onMounted(() => {
            bindOutsideClickListener();
        });

        onBeforeUnmount(() => {
            unbindOutsideClickListener();
        });

        const onMenuToggle = () => {
            if (layoutConfig.value.menuMode === 'overlay') {
                store.dispatch('ui/changeLayout', {
                    overlayMenuActive: !layoutConfig.value.overlayMenuActive
                });
            }

            if (window.innerWidth > 991) {
                store.dispatch('ui/changeLayout', {
                    staticMenuDesktopInactive:
                        !layoutConfig.value.staticMenuDesktopInactive
                });
            } else {
                store.dispatch('ui/changeLayout', {
                    staticMenuMobileActive:
                        !layoutConfig.value.staticMenuMobileActive
                });
            }
        };

        const onTopBarMenuButton = () => {
            topbarMenuActive.value = !topbarMenuActive.value;
        };

        const topbarMenuClasses = computed(() => {
            return {
                'layout-topbar-menu-mobile-active': topbarMenuActive.value
            };
        });

        const logoUrl = computed(() => {
            return layoutConfig.value.darkTheme
                ? 'logo-group-white'
                : 'logo-group';
        });

        const bindOutsideClickListener = () => {
            if (!outsideClickListener.value) {
                outsideClickListener.value = (event: any) => {
                    if (isOutsideClicked(event)) {
                        topbarMenuActive.value = false;
                    }
                };
                document.addEventListener('click', outsideClickListener.value);
            }
        };
        const unbindOutsideClickListener = () => {
            if (outsideClickListener.value) {
                document.removeEventListener(
                    'click',
                    outsideClickListener.value
                );
                outsideClickListener.value = null;
            }
        };
        const isOutsideClicked = (event: any) => {
            if (!topbarMenuActive.value) return;

            const sidebarEl = document.querySelector('.layout-topbar-menu');
            const topbarEl = document.querySelector(
                '.layout-topbar-menu-button'
            );

            return !(
                sidebarEl.isSameNode(event.target) ||
                sidebarEl.contains(event.target) ||
                topbarEl.isSameNode(event.target) ||
                topbarEl.contains(event.target)
            );
        };

        const weekNumber = computed(() => {
            const dt = DateTime.utc();

            return dt.weekNumber;
        });

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        return {
            layoutConfig,
            onMenuToggle,
            onTopBarMenuButton,
            topbarMenuClasses,
            logoUrl,
            layoutConfigSidebarVisible,
            weekNumber
        };
    }
};
