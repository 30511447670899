<!-- Content Header (Page header) -->
<loading v-model:active="isLoading" />
<Panel :header="$t('labels.importWizard')">
    <div class="mb-3 formgrid grid p-fluid align-items-end">
        <div
            class="field"
            :class="{'col-11':identifier !== null, 'col-12': identifier === null}"
        >
            <label>{{ $t("labels.chooseWizardWorkflow") }}</label>
            <TreeSelect
                v-model="identifier"
                :options="importWizardOptions"
                selectionMode="single"
                :placeholder="$t('labels.none')"
                @change="onChange($event)"
            />
        </div>
        <div
            v-if="identifier !== null"
            class="field col-1"
            v-tooltip.right="$t('labels.downloadImportFileTemplate')"
        >
            <font-awesome-icon
                style="font-size: 2.5rem; margin-bottom: 4px"
                :icon="['fas', 'file-csv']"
                @click="downloadFileTemplate"
            />
        </div>
    </div>
    <div
        v-if="identifier !== null"
        v-bind="dropzone.getRootProps()"
        class="import-wizard-container"
    >
        <div style="text-align: center">
            <input v-bind="dropzone.getInputProps()" />
            <p v-if="dropzone.acceptedFiles.length > 0">
                {{ dropzone.acceptedFiles[0].name }}
            </p>
            <p v-else>{{ $t("labels.importWizardInfoText") }}</p>
        </div>
    </div>
    <div v-if="showUploadButton" class="mt-3">
        <div class="flex justify-content-end">
            <p-button @click="uploadFile">
                {{ $t("buttons.uploadFile") }}
            </p-button>
        </div>
        <!-- /.col -->
    </div>
    <div
        style="margin-top: 100px"
        v-if="savedFileId !== null && rowsToSkip.length < 1"
    >
        <header-mapping-grid
            :savedFileId="savedFileId"
            @column-mapping-saved="validateFile()"
        ></header-mapping-grid>
    </div>
    <div style="margin-top: 100px" v-if="rowsToSkip.length > 0">
        <validation-grid
            :savedFileId="savedFileId"
            :rowsToSkip="rowsToSkip"
            @import-result-received="importResultReceived"
        ></validation-grid>
    </div>
    <div v-show="importResult" class="mt-5">
        <span
            class="font-medium"
            ref="fileScheduledForImportTextPanel"
            v-html="$t('messages.fileScheduledForImportLong')"
        ></span>
        <i
            class="pi pi-times-circle mt-1 ml-1 text-red-600"
            style="cursor: pointer"
            @click="importResult = false;"
        ></i>
    </div>
</Panel>

<PrimeDialog
    :header="$t('messages.pleaseConfirm')"
    v-model:visible="showPlatformConfirmationDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    :closable="false"
    :modal="true"
    @show="onShowConfirmationDialog"
>
    <div class="card-body">
        <div>
            <h6
                v-if="isConfimationInputNeeded"
                v-html="$t('messages.importFileToPlatformConfirmationWithField', {
                    platform: importPlatform?.label, 
                    confirmationValue: importPlatform?.threeLetterId  })"
            ></h6>

            <h6
                v-else
                v-html="$t('messages.importFileToPlatformConfirmation', {
                    platform: importPlatform?.label })"
            ></h6>
        </div>
        <div class="mt-2 p-fluid" v-if="isConfimationInputNeeded">
            <InputText
                :placeholder="$t('labels.targetPlatform')"
                autocomplete="off"
                v-model="confirmationInputValue"
                ref="confirmationInputField"
            />
        </div>
        <div class="w-full mt-3 flex justify-content-between">
            <p-button
                severity="danger"
                @click="closePlatformConfirmationDialog"
            >
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button
                severity="success"
                @click="closePlatformConfirmationDialog($event, true)"
                :disabled="!confirmButtonEnabled"
            >
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </div>
</PrimeDialog>

<!-- /.content -->
