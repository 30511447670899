import {useToast} from 'vue-toastification';
import {useDropzone} from 'vue3-dropzone';
import {ref} from 'vue';

export default {
    emits: ['files-selected'],
    components: {},
    setup(props: any, context: any) {
        const toast = useToast();
        const acceptFiles = ref([]);

        const onDrop = (files: any, rejectReasons: any) => {
            acceptFiles.value = files;
            if (rejectReasons.length > 0) {
                toast.error(rejectReasons[0]['errors'][0]['message']);
                acceptFiles.value.length = 0;
            } else {
                context.emit('files-selected', acceptFiles.value);
            }
        };

        const {getRootProps, getInputProps, ...rest} = useDropzone({
            onDrop,
            accept: '.pdf',
            multiple: false
        });

        return {
            acceptFiles,
            getRootProps,
            getInputProps,
            ...rest
        };
    }
};
