export default {
    setShippingLocation: (
        state: {
            shippingLocation: Array<{
                platform: string;
                shippingLocation: string;
            }>;
        },
        payload: Array<{
            platform: string;
            shippingLocation: string;
        }>
    ): void => {
        state.shippingLocation = payload;
    },
    setOpenShipments: (
        state: {
            openShipments: {
                warehouseId: string;
            };
        },
        payload: {warehouseId: string}
    ): void => {
        state.openShipments = payload;
    }
};
