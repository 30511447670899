import {toRefs} from 'vue';
import Textarea from 'primevue/textarea';

export default {
    components: {
        'p-textarea': Textarea
    },
    props: {
        ticketDetails: Object
    },
    setup(props: any) {
        const {ticketDetails} = toRefs(props);

        return {
            ticketDetails
        };
    }
};
