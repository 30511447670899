import Fieldset from 'primevue/fieldset';
import {toRefs} from 'vue';
import {getReferenceItemLayout} from '@/services/tickets';
import CustomerReturn from './customer-return/customer-return.vue';
import SupplierReturn from './supplier-return/supplier-return.vue';
import DeliveryNote from './delivery-note/delivery-note.vue';

export default {
    emits: ['reload-ticket-details'],
    components: {
        'p-fieldset': Fieldset,
        CustomerReturn,
        SupplierReturn,
        DeliveryNote
    },
    props: {
        ticketDetails: Object
    },
    setup(props: any, context: any) {
        const {ticketDetails} = toRefs(props);

        const handleReloadTicketEvent = () => {
            context.emit('reload-ticket-details');
        };

        return {
            ticketDetails,
            handleReloadTicketEvent,
            getReferenceItemLayout: getReferenceItemLayout
        };
    }
};
