import {toRefs} from 'vue';

export default {
    props: {
        showEnterKey: Boolean
    },
    setup(props: any, context: any) {
        const {showEnterKey} = toRefs(props);

        const press = (key: any) => {
            context.emit('pressed', key);
        };

        const clear = () => {
            context.emit('pressed', null);
        };

        return {
            keys: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            keyTheme: 'btn-keyboard',
            buttonTheme: 'btn-danger',
            press,
            clear,
            showEnterKey
        };
    }
};
