<div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
        <img :src="require('@/assets/img/' + logoUrl+ '.png')" alt="logo" />
    </router-link>

    <button
        class="p-link layout-menu-button layout-topbar-button"
        @click="onMenuToggle"
    >
        <i class="pi pi-bars"></i>
    </button>

    <button
        class="p-link layout-topbar-menu-button layout-topbar-button"
        @click="onTopBarMenuButton"
    >
        <i class="pi pi-ellipsis-v"></i>
    </button>
    <div
        class="ml-5 hny-logo"
        v-if="!layoutConfig.darkTheme && weekNumber === 1"
    >
        <img
            :src="require('@/assets/img/fnj.png')"
            alt="fnj"
            style="height: 60px"
        />
    </div>
    <div class="layout-topbar-menu" :class="topbarMenuClasses">
        <div>
            <Search />
        </div>
        <div>
            <Platforms />
        </div>
        <div>
            <Bookmarks />
        </div>

        <div>
            <Languages />
        </div>

        <div class="mr-2">
            <User />
        </div>
        <button
            @click="layoutConfigSidebarVisible =true;"
            class="p-link layout-topbar-button"
        >
            <i class="pi pi-cog"></i>
        </button>
    </div>
</div>
<LayoutSidebar
    :visible="layoutConfigSidebarVisible"
    @close-sidebar="layoutConfigSidebarVisible =false"
></LayoutSidebar>
