import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-afcfd260"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "ml-3" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "ml-3" }
const _hoisted_6 = { class: "ml-3" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_p_button = _resolveComponent("p-button")
  const _component_Panel = _resolveComponent("Panel")
  const _component_AddTicketEmailDialog = _resolveComponent("AddTicketEmailDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_p_button, {
        severity: "success",
        onClick: _ctx.onAddRMARequestButtonClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(" + " + _toDisplayString(_ctx.$t("labels.ticket.rmaRequest")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.ticketDetails?.archivedEmails || []), (email) => {
      return (_openBlock(), _createBlock(_component_Panel, { class: "mb-3" }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(email.fromAddress) + " " + _toDisplayString(_ctx.$t("labels.at")) + " " + _toDisplayString(email.createdDate), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.toAddress')), 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(email.toAddresses?.join(';')), 1)
          ]),
          (email.ccAddresses && email.ccAddresses.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.ccAddress')), 1),
                _createElementVNode("span", _hoisted_5, _toDisplayString(email.ccAddresses?.join(';')), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.subject')), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(email.subject), 1)
          ]),
          _createElementVNode("div", {
            class: "mt-2",
            innerHTML: email.body
          }, null, 8, _hoisted_7)
        ]),
        _: 2
      }, 1024))
    }), 256)),
    _createVNode(_component_AddTicketEmailDialog, {
      "display-dialog": _ctx.showAddEmailDialog,
      "ticket-details": _ctx.ticketDetails,
      "predefined-values": {subject: _ctx.computedEmailSubject, fromAddress: _ctx.loggedInUser, toAddress: _ctx.ticketDetails?.email, body: _ctx.computedEmailBody},
      onCloseDialog: _ctx.closeAddTicketEmailDialog
    }, null, 8, ["display-dialog", "ticket-details", "predefined-values", "onCloseDialog"])
  ], 64))
}