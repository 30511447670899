import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Weclapp from './api/weclapp/weclapp.vue';
import Panel from 'primevue/panel';

export default {
    components: {
        TabPanel,
        TabView,
        Weclapp,
        Panel
    },
    setup() {
        return {};
    }
};
