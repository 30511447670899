import {createI18n} from 'vue-i18n';
import en from '@/translation/en.json';
import de from '@/translation/de.json';

export const i18n = createI18n({
    locale: 'de',
    messages: {de, en},
    fallbackLocale: 'en',
    globalInjection: true,
    warnHtmlInMessage: 'off'
});
