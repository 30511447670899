import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dffd23e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "grid formgrid p-fluid" }
const _hoisted_3 = { class: "field col" }
const _hoisted_4 = { class: "ml-2" }
const _hoisted_5 = { class: "field col-9" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "w-full flex justify-content-between mt-3" }

export function render(_ctx, _cache) {
  const _component_LoadingPlugin = _resolveComponent("LoadingPlugin")
  const _component_ProductList = _resolveComponent("ProductList")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_Panel = _resolveComponent("Panel")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")
  const _component_p_button = _resolveComponent("p-button")
  const _component_TabView = _resolveComponent("TabView")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.dialogHeader,
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[3] || (_cache[3] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '75vw'},
    onHide: _ctx.onCancelButtonClicked,
    modal: true,
    position: "top"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LoadingPlugin, {
        active: _ctx.loading,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
      }, null, 8, ["active"]),
      _createVNode(_component_TabView, null, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('labels.products')
          }, {
            default: _withCtx(() => [
              (_ctx.isFilterRelated)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_1, _toDisplayString(_ctx.$t('messages.changesWillBeAppliedToProducts', { number:
                _ctx.productTotalRecords})), 1))
                : (_openBlock(), _createBlock(_component_ProductList, {
                    key: 1,
                    products: _ctx.products,
                    scrollableHeight: '55vh'
                  }, null, 8, ["products"]))
            ]),
            _: 1
          }, 8, ["header"]),
          _renderSlot(_ctx.$slots, "tab-panel-middle", {}, undefined, true),
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('labels.summary'),
            disabled: !_ctx.showSummaryTab
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ScrollPanel, { style: {"height":"55vh"} }, {
                default: _withCtx(() => [
                  (_ctx.canPostponeExecution)
                    ? (_openBlock(), _createBlock(_component_Panel, {
                        key: 0,
                        header: _ctx.$t('labels.mail.execution'),
                        class: "mb-3"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("div", _hoisted_3, [
                              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('labels.immediateExecution')), 1),
                              _createVNode(_component_Checkbox, {
                                modelValue: _ctx.immediateExecution,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.immediateExecution) = $event)),
                                binary: "",
                                class: "ml-2 mb-1"
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_5, [
                              (!_ctx.immediateExecution)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.at')), 1),
                                    _createVNode(_component_Calendar, {
                                      modelValue: _ctx.startTime,
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.startTime) = $event)),
                                      showTime: "",
                                      dateFormat: "dd.mm.yy",
                                      minDate: new Date()
                                    }, null, 8, ["modelValue", "minDate"])
                                  ], 64))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["header"]))
                    : _createCommentVNode("", true),
                  _renderSlot(_ctx.$slots, "summary-panel-header", {
                    immediateExecution: _ctx.immediateExecution,
                    startTime: _ctx.startTime
                  }, undefined, true),
                  _renderSlot(_ctx.$slots, "summary-panel-middle", {}, undefined, true),
                  _renderSlot(_ctx.$slots, "summary-panel-footer", {}, () => [
                    _createVNode(_component_Panel, {
                      header: _ctx.$t('labels.products')
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isFilterRelated)
                          ? (_openBlock(), _createElementBlock("h5", _hoisted_6, _toDisplayString(_ctx.$t('messages.changesWillBeAppliedToProducts', {
                            number: _ctx.productTotalRecords})), 1))
                          : (_openBlock(), _createBlock(_component_ProductList, {
                              key: 1,
                              products: _ctx.products
                            }, null, 8, ["products"]))
                      ]),
                      _: 1
                    }, 8, ["header"])
                  ], true)
                ]),
                _: 3
              }),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_p_button, {
                  severity: "danger",
                  onClick: _ctx.onCancelButtonClicked
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_p_button, {
                  severity: "success",
                  onClick: _ctx.onConfirmButtonClicked,
                  textContent: _toDisplayString(_ctx.$t('buttons.confirm'))
                }, null, 8, ["onClick", "textContent"])
              ])
            ]),
            _: 3
          }, 8, ["header", "disabled"])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["header", "visible", "onHide"]))
}