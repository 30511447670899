<div id="bookmarkIcon" @click="toggleDataTable" class="flex align-items-start">
    <span>
        <font-awesome-icon
            :icon="['fas', 'star']"
            style="color: #ffc107; height: 1.3rem"
        />
    </span>
    <span style="margin-top: -0.75rem">
        <Badge :value="bookmarks?.length || 0" severity="danger"></Badge>
    </span>
</div>

<OverlayPanel ref="opBookmarks" appendTo="body" id="overlay_panel_bookmarks">
    <DataTable
        :value="bookmarks"
        :paginator="bookmarks?.length > 5"
        :rows="5"
        responsiveLayout="scroll"
    >
        <Column headerStyle="min-width:12rem;display:none;"
            ><template #body="{data}">
                <div
                    style="cursor: pointer"
                    @click="navigateToBookmark(data.path)"
                >
                    {{ $t(data.localizedTitle) || data.entityName }} {{
                    data.entityId }}
                    <p class="text-xs">{{data.description}}</p>
                </div>
            </template></Column
        >
        <Column headerStyle="max-width:3rem;display:none;"
            ><template #body="{data}">
                <i
                    class="pi pi-times-circle mt-3 mr-3 text-red-600"
                    style="cursor: pointer"
                    @click="removeBookmark(data.id)"
                ></i> </template
        ></Column>
    </DataTable>
</OverlayPanel>
