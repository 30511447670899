import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAllCandidatesForTicket = (
    input: any
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/sales-invoices?start=' +
            input.first +
            '&end=' +
            (input.first + input.rows) +
            '&sortField=' +
            (input.sortField ?? '') +
            '&sortOrder=' +
            (input.sortOrder === 1 ? 'asc' : 'desc') +
            '&columns=' +
            JSON.stringify(input.columns || []) +
            '&filters=' +
            encodeURIComponent(
                JSON.stringify(clearEmptyFilters(input.filters || {}))
            ) +
            '&filterConjunction=or'
    );
};
