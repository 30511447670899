<PrimeDialog
    :header="$t('labels.ticket.addNewQuotation', {ticketNumber: ticketDetails?.ticketNumber, ticketSubject: truncatedSubject})"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '65vw'}"
    modal
>
    <loading v-model:active="savingInProgress" />
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="grid p-fluid">
            <div class="col field">
                <label>{{ $t('labels.ticket.retoure.customer') }} </label>
                <div>{{ ticketCustomer }}</div>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col field">
                <label>{{ $t('labels.ticket.retoure.warehouse') }} </label>

                <p-dropdown
                    :filter="true"
                    v-model="v$.warehouseId.$model"
                    :options="warehouseOptions"
                    optionLabel="label"
                    optionValue="value"
                    @change="setDropdownValue('warehouseId', $event)"
                    :class="{'p-invalid':v$.warehouseId.$invalid && submitted}"
                    :showClear="true"
                    class="w-full"
                >
                </p-dropdown>
                <small
                    v-if="(v$.warehouseId.$invalid && submitted) || v$.warehouseId.$pending.$response"
                    class="p-error"
                    >{{v$.warehouseId.required.$message}}</small
                >
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col field">
                <label
                    >{{ $t('labels.ticket.repairQuotation.commission') }}
                </label>

                <InputText
                    icon="align-justify"
                    type="text"
                    autocomplete="off"
                    v-model="v$.commission.$model"
                    :class="{'p-invalid':v$.commission.$invalid && submitted}"
                />
                <small
                    v-if="(v$.commission.$invalid && submitted) || v$.commission.$pending.$response"
                    class="p-error"
                    >{{v$.commission.required.$message}}</small
                >
            </div>
        </div>

        <div class="w-full flex justify-content-between">
            <p-button
                severity="danger"
                :disabled="savingInProgress"
                v-text="$t('buttons.cancel')"
                @click="closeResponsive"
            >
            </p-button>

            <p-button
                :disabled="savingInProgress"
                severity="success"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
                type="submit"
            >
            </p-button>
        </div>
    </form>
</PrimeDialog>
