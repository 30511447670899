<BulkProductAction
    @close-dialog="closeResponsive"
    @confirm-clicked="confirmClicked"
    :products="products"
    :productTotalRecords="productTotalRecords"
    :displayDialog="showDialog"
    :isFilterRelated="isFilterRelated"
    :summaryTabVisible="summaryTabVisible"
    :loading="loading"
    :dialogHeader="$t('labels.addShopwareDocuments')"
>
    <template #tab-panel-middle>
        <TabPanel :header="$t('labels.documents')">
            <div class="grid formgrid p-fluid mb-3">
                <div class="field col">
                    <label>{{$t('labels.description')}}</label>
                    <InputText type="text" v-model="description" />
                </div>
            </div>

            <FileUpload @files-selected="onFileSelected"></FileUpload>
        </TabPanel>
    </template>
    <template #summary-panel-header>
        <Panel class="mb-3">
            <template #header>
                <div class="font-weight-bold">{{ $t('labels.documents') }}</div>
            </template>
            <DataTable
                class="p-datatable-sm text-sm"
                stripedRows
                :paginator="selectedFiles && selectedFiles.length > 20"
                :rows="20"
                :value="selectedFiles"
                responsiveLayout="scroll"
                paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rows-per-page-options="[10,20,50,100,200]"
                current-page-report-template="{first} to {last} of {totalRecords}"
            >
                <Column field="name" style="min-width: 12rem"> </Column>
                <Column style="min-width: 12rem"
                    ><template #body> {{ description }} </template>
                </Column>
            </DataTable>
        </Panel>
    </template>
    <template #summary-panel-middle>
        <h1 style="text-align: center">
            <font-awesome-icon
                style="margin-left: 30px"
                :icon="['fas', 'angle-double-down']"
            /><font-awesome-icon
                style="margin-left: 30px"
                :icon="['fas', 'angle-double-down']"
            /><font-awesome-icon
                style="margin-left: 30px"
                :icon="['fas', 'angle-double-down']"
            />
        </h1>
    </template>
</BulkProductAction>
