<p-dialog
    :header="selectedImages?.length > 1 ? $t('labels.deleteImages') : $t('labels.deleteImage')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    @hide="closeDialog"
    :modal="true"
>
    <h6>
        {{ $t('messages.deleteConfirmation', { item : selectedImages.map(u =>
        u.fileName + '.' + u.fileExtension).join(', ') })}}
    </h6>

    <div class="w-full flex justify-content-between">
        <p-button
            severity="danger"
            :disabled="savingInProgress"
            @click="closeDialog(null)"
        >
            {{ $t("buttons.cancel") }}
        </p-button>
        <p-button
            severity="success"
            :disabled="savingInProgress"
            @click="confirmDelete"
            v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
        >
        </p-button>
    </div>
</p-dialog>
