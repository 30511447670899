<span class="p-input-icon-left text-xs">
    <AutoComplete
        v-model="searchTerm"
        :suggestions="filteredResults"
        :placeholder="$t('labels.enterSearchTerm')"
        @complete="loadData"
        @item-select="onTermSelected"
        optionLabel="name"
        optionValue="id"
    />
</span>
