import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const getProfilePicture = (): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/user-profile-picture');
};

export const getAll = (): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/users');
};

export const addPermission = (
    username: string,
    permission: string
): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/users/' + username + '/permissions', {
        permission
    });
};

export const removePermission = (
    permission: string,
    username: string
): Promise<AxiosResponse> => {
    return apiClient.delete(
        '/api/v1/users/' + username + '/permissions/' + permission
    );
};
