import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const getAll = (input: any): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/system-logs?start=' +
            input.first +
            '&end=' +
            (input.first + input.rows) +
            '&sortField=' +
            (input.sortField ?? '') +
            '&sortOrder=' +
            (input.sortOrder === 1 ? 'asc' : 'desc') +
            '&filters=' +
            JSON.stringify(input.filters)
    );
};

export const submitAppError = async (error: {
    message: string;
    stack: string;
}): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/system-logs', error);
};
