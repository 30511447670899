<p-fieldset
    class="mb-3"
    :legend="$t('labels.ticket.references.customerReturn')"
    :toggleable="true"
>
    <div class="flex">
        <div
            class="grid p-fluid"
            v-if="ticketDetails.ticketCustomerReturn && ticketDetails.ticketCustomerReturn.length > 0"
            v-for="referenceItem in (ticketDetails.ticketCustomerReturn || [])"
        >
            <div class="col">
                <div>
                    <span
                        v-html="getReferenceItemLayout(referenceItem,
                                        ticketDetails.url)"
                    ></span>

                    <i
                        v-if="editPermissionAvailable"
                        class="pi pi-plus-circle text-green-600 ml-1"
                        @click="onCreateSupplierReturnClick(referenceItem, $event)"
                        v-tooltip.top="$t('labels.ticket.retoure.createSupplierReturn')"
                    ></i>
                    <i
                        v-if="editPermissionAvailable"
                        class="pi pi-plus-circle ml-1"
                        style="color: blueviolet"
                        @click="onCreateCompensationShipmentClick(referenceItem, $event)"
                        v-tooltip.top="$t('labels.ticket.retoure.createCompensationShipment')"
                    ></i>
                    <i
                        @click="createAndPrintRetoureLabel(referenceItem, $event)"
                        class="pi pi-book ml-2"
                        style="cursor: pointer"
                        v-tooltip.top="$t('labels.ticket.retoure.createAndPrintLabel')"
                    ></i>
                </div>
            </div>
        </div>

        <div
            class="flex justify-content-end"
            v-if="showAddNewRetoureButton && editPermissionAvailable"
        >
            <p-button severity="success" @click="onAddNewRetoureClick">
                {{ $t("buttons.addNewRetoure") }}
            </p-button>
        </div>
    </div>
</p-fieldset>
<AddRetoureWizard
    :display-add-retoure-dialog="showAddRetoureWizardDialog"
    :is-return-ticket="isReturnTicket"
    :ticket-details="ticketDetails"
    @close-dialog="closeAddNewRetoureDialog"
></AddRetoureWizard>
<AddTicketCommentDialog
    :display-dialog="showAddCommentDialog"
    :ticket-details="ticketDetails"
    :comment-default-value="commentDefaultValue"
    @close-dialog="closeAddTicketCommentDialog"
></AddTicketCommentDialog>
<CreateSupplierReturnDialog
    :display-dialog="showCreateSupplierReturnDialog"
    :ticket-details="ticketDetails"
    :retoure="selectedRetoure"
    @close-supplier-return-dialog="closeCreateSupplierReturnDialog"
></CreateSupplierReturnDialog>
<CreateCompensatonShipmentDialog
    :display-dialog="showCreateCompensationShipmentDialog"
    :ticket-details="ticketDetails"
    :retoure="selectedRetoure"
    @close-create-compensation-shipment-dialog="closeCreateCompensationShipmentDialog"
>
</CreateCompensatonShipmentDialog>
<SendDocumentToRemotePrinter
    :display-dialog="displaySendToRemotePrinterDialog"
    @close-dialog="closePrintDialogListener"
    :selected-document="selectedDocument"
></SendDocumentToRemotePrinter>
