import {DateTime} from 'luxon';
import {getProfilePicture} from '@/services/users';
import {computed, ref, watch} from 'vue';
import store from '@/store';
import {i18n} from '@/utils/i18n';
import Button from 'primevue/button';
import OverlayPanel from 'primevue/overlaypanel';
import Avatar from 'primevue/avatar';
import router from '@/router';

export default {
    components: {OverlayPanel, 'p-button': Button, Avatar},

    setup() {
        const profilePicture = ref(null);
        const opUser = ref(null);

        const user = computed(() => {
            return store.getters['auth/user'];
        });

        const logout = () => {
            store.dispatch('auth/logout');
        };

        const navigateToProfile = () => {
            router.push('/profile');
        };

        const readableCreatedAtDate = computed(() => {
            return user.value
                ? DateTime.fromSeconds(user.value.createdAt)
                      .setLocale(i18n.global.locale)
                      .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                      .toLocaleString(DateTime.DATETIME_MED)
                : null;
        });

        watch(user, async () => {
            if (user.value && user.value.username) {
                try {
                    const result = await getProfilePicture();
                    profilePicture.value = result.data;
                } catch (Exception) {
                    profilePicture.value = null;
                }
            }
        });

        const toggleUserPanel = (event: any) => {
            opUser.value.toggle(event);
        };

        return {
            user,
            logout,
            readableCreatedAtDate,
            profilePicture,
            opUser,
            toggleUserPanel,
            navigateToProfile
        };
    }
};
