<loading v-model:active="loading" />
<Panel :header="$t('labels.systemLog')">
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        paginator
        lazy
        scrollable
        scrollHeight="calc(100vh - 23rem)"
        :rows="20"
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="systemLogs"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
        @page="onPage"
        @filter="onFilter"
        @sort="onSort"
        @state-restore="onStateRestore"
        stateStorage="local"
        stateKey="system-log-list-state-session"
    >
        <Column
            field="type"
            :sortable="true"
            style="min-width: 10rem"
            :header="$t('labels.logType')"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel, filterCallback}">
                <Dropdown
                    v-model="filterModel.value"
                    :options="[{label: 'Emergency', value: '0'}, {label: 'Alert', value: '1'}, {label: 'Alert', value: '2'}, {label: 'Error', value: '3'}, {label: 'Warning', value: '4'}, {label: 'Notice', value: '5'}, {label: 'Info', value: '6'}, {label: 'Debug', value: '7'}]"
                    placeholder="Any"
                    optionLabel="label"
                    optionValue="value"
                    @change="filterCallback"
                    class="p-column-filter text-sm"
                    :showClear="true"
                >
                </Dropdown>
            </template>
            <template #body="{data}">
                <div :class="getTypeCellStyle(data.type)" class="product-badge">
                    {{getTypeCellContent(data.type)}}
                </div>
            </template>
        </Column>
        <Column
            field="date"
            :sortable="true"
            style="min-width: 10rem"
            :header="$t('labels.logTime')"
        />
        <Column
            field="event"
            style="min-width: 6rem"
            :header="$t('labels.logMessage')"
        />
    </DataTable>
</Panel>
