export default {
    setRemote: (
        context: any,
        payload: {
            platform: string;
            hardwareId: string;
            printer: string;
            printerSize: string;
        }
    ): void => {
        const currentState = context.getters['remote'];
        const existingItemIndex = currentState.findIndex((item: any) => {
            return item.platform === payload.platform;
        });

        if (existingItemIndex !== -1) {
            currentState[existingItemIndex] = payload;
        } else {
            currentState.push(payload);
        }
        context.commit('setRemote', currentState);
        localStorage.setItem('remotePrinter', JSON.stringify(currentState));
    },

    getHardwareIdForPlatform(
        context: any,
        platform: string | null
    ): string | null {
        if (!platform) {
            return null;
        }
        const items = context.getters['remote'];
        const existingItem = items.find(
            (item: any) => item.platform === platform
        );

        return existingItem?.hardwareId;
    },

    getPrinterForPlatform(
        context: any,
        platform: string | null
    ): string | null {
        if (!platform) {
            return null;
        }
        const items = context.getters['remote'];
        const existingItem = items.find(
            (item: any) => item.platform === platform
        );

        return existingItem?.printer;
    },
    getPrinterSizeForPlatform(
        context: any,
        platform: string | null
    ): string | null {
        if (!platform) {
            return null;
        }
        const items = context.getters['remote'];
        const existingItem = items.find(
            (item: any) => item.platform === platform
        );

        return existingItem?.printerSize;
    }
};
