import {markAsValidated} from '@/services/import';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import {useToast} from 'vue-toastification';
import Button from 'primevue/button';
import Loading from 'vue-loading-overlay';
import {computed, ref} from 'vue';
import store from '@/store';
import {ILayoutConfig} from '@/interfaces/layout/config';

export default {
    name: 'ValidationGrid',
    emits: ['import-result-received'],
    components: {
        DataTable,
        Column,
        loading: Loading,
        'p-button': Button
    },
    props: {
        savedFileId: Number,
        rowsToSkip: Array
    },
    setup(props: any, context: any) {
        const toast = useToast();

        const isLoading = ref(false);
        const totalRecords = ref(0);

        const processFile = async () => {
            isLoading.value = true;
            try {
                if (props.rowsToSkip.length > 0) {
                    await markAsValidated(props.savedFileId);
                }
                //const response = await runImport(props.savedFileId);
                context.emit('import-result-received', true);
            } catch (error: any) {
                context.emit('import-result-received', false);
                toast.error(error.message);
            } finally {
                isLoading.value = false;
            }
        };

        const discardFile = async () => {
            context.emit('import-result-received', false);
            isLoading.value = false;
        };

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        return {
            rowsToSkip: props.rowsToSkip,
            totalRecords,
            processFile,
            discardFile,
            isLoading,
            rowClass: () => {
                const colorVariant = layoutConfig.value?.darkTheme ? 800 : 200;
                return 'bg-red-' + colorVariant;
            }
        };
    }
};
