<loading v-model:active="loading" />
<Panel>
    <template #header>
        <h5 v-if="productDetails?.weclapp">
            <a
                :href="productDetails.weclapp.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + productDetails.weclapp.id"
                target="_blank"
                >{{ productId }}</a
            >
            <span class="spacer-dot"></span>
            {{ productDetails.weclapp.name}}
            <span class="spacer-dot"></span>
            {{ $t("labels.changelog") }}
        </h5>
    </template>

    <loading v-model:active="loading" />
    <Timeline :value="events" align="alternate" class="customized-timeline">
        <template #marker="slotProps">
            <span
                class="custom-marker shadow-2"
                :style="{backgroundColor: getBackgroundColorBasedOnHttpVerb(slotProps.item.action)}"
            >
                <i
                    :class="getIconBasedOnHttpVerb(slotProps.item.childEntityName)"
                ></i>
            </span>
        </template>
        <template #content="slotProps">
            <Card>
                <template #title>
                    {{
                    getTitleBasedOnChildEntity(slotProps.item.childEntityName)}}
                </template>

                <template #subtitle> {{slotProps.item.timestamp}} </template>
                <template #content>
                    <div
                        v-html="getContentBasedOnEventDetails(slotProps.item)"
                    ></div>
                </template>
            </Card>
        </template>
    </Timeline>
    <div v-if="!loading && events.length < 1">
        {{ $t("messages.noRecordsFound") }}
    </div>
</Panel>
