import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16edd3b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "text-gray-600"
}
const _hoisted_3 = { class: "font-weight-bold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "text-gray-600"
}
const _hoisted_6 = { style: {"text-align":"center"} }

export function render(_ctx, _cache) {
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_Panel = _resolveComponent("Panel")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_BulkProductAction = _resolveComponent("BulkProductAction")

  return (_openBlock(), _createBlock(_component_BulkProductAction, {
    onCloseDialog: _ctx.closeResponsive,
    onConfirmClicked: _ctx.confirmClicked,
    products: _ctx.products,
    productTotalRecords: _ctx.productTotalRecords,
    displayDialog: _ctx.showDialog,
    isFilterRelated: _ctx.isFilterRelated,
    summaryTabVisible: _ctx.summaryTabVisible,
    loading: _ctx.loading,
    dialogHeader: _ctx.isRemove ? _ctx.$t('labels.removeBadges') : _ctx.$t('labels.addBadges'),
    canPostponeExecution: ""
  }, {
    "tab-panel-middle": _withCtx(() => [
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('labels.badges')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DataTable, {
            class: "p-datatable-sm text-sm",
            stripedRows: "",
            paginator: "",
            scrollable: "",
            scrollHeight: "55vh",
            rows: 50,
            value: _ctx.badges,
            responsiveLayout: "scroll",
            "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
            "rows-per-page-options": [10,20,50,100,200],
            "current-page-report-template": "{first} to {last} of {totalRecords}"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, { headerStyle: "width: 3rem" }, {
                body: _withCtx(({data}) => [
                  (data.active)
                    ? (_openBlock(), _createBlock(_component_p_checkbox, {
                        key: 0,
                        value: data,
                        modelValue: _ctx.selectedBadges,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.selectedBadges) = $event))
                      }, null, 8, ["value", "modelValue"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "name",
                header: _ctx.$t('labels.name'),
                style: {"min-width":"12rem"}
              }, {
                body: _withCtx(({data}) => [
                  (data.active)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(data.name), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(data.name), 1))
                ]),
                _: 1
              }, 8, ["header"])
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    "summary-panel-header": _withCtx(() => [
      _createVNode(_component_Panel, { class: "mb-3" }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('labels.badges')), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_DataTable, {
            class: "p-datatable-sm text-sm",
            stripedRows: "",
            paginator: _ctx.selectedBadges && _ctx.selectedBadges.length > 20,
            rows: 20,
            value: _ctx.selectedBadges,
            responsiveLayout: "scroll",
            "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
            "rows-per-page-options": [10,20,50,100,200],
            "current-page-report-template": "{first} to {last} of {totalRecords}"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "name",
                style: {"min-width":"12rem"}
              }, {
                body: _withCtx(({data}) => [
                  (data.active)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(data.name), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(data.name), 1))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["paginator", "value"])
        ]),
        _: 1
      })
    ]),
    "summary-panel-middle": _withCtx(() => [
      _createElementVNode("h1", _hoisted_6, [
        (_ctx.isRemove)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'ban']
              }),
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'ban']
              }),
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'ban']
              })
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'angle-double-down']
              }),
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'angle-double-down']
              }),
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'angle-double-down']
              })
            ], 64))
      ])
    ]),
    _: 1
  }, 8, ["onCloseDialog", "onConfirmClicked", "products", "productTotalRecords", "displayDialog", "isFilterRelated", "summaryTabVisible", "loading", "dialogHeader"]))
}