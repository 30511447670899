import {onMounted, ref, toRefs, watch} from 'vue';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import {useVuelidate} from '@vuelidate/core';
import AutoComplete from 'primevue/autocomplete';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import {updateCompliance} from '@/services/products';
import {getSome} from '@/services/metadata';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import InputSwitch from 'primevue/inputswitch';
import ScrollPanel from 'primevue/scrollpanel';
import Textarea from 'primevue/textarea';

export default {
    emits: ['reload-product', 'form-dirty'],
    components: {
        'p-button': Button,
        'p-dropdown': Dropdown,
        'p-calendar': Calendar,
        'app-checkbox': Checkbox,
        'p-textarea': Textarea,
        InputNumber,
        AutoComplete,
        TriStateCheckbox,
        InputSwitch,
        ScrollPanel
    },
    props: {
        productDetails: Object,
        isBulkEdit: Boolean
    },
    setup(props: any, context: any) {
        const {isBulkEdit} = toRefs(props);
        const submitted = ref(false);
        const productDetails = ref(null);
        const savingInProgress = ref(false);
        const toast = useToast();
        const weeeOptions = ref([]);
        const battgClassOptions = ref([]);

        onMounted(() => {
            getSome('customAttributeDefinition', 'attributeKey', [
                'article_weee_relevant',
                'article_batteriegesetz_class'
            ])
                .then((data) => {
                    weeeOptions.value =
                        data.data[
                            'article_weee_relevant'
                        ]?.selectableValues?.map(
                            (item: {id: string; value: string}) => {
                                return {
                                    label: item.value,
                                    value: item.id
                                };
                            }
                        ) || [];

                    battgClassOptions.value =
                        data.data[
                            'article_batteriegesetz_class'
                        ]?.selectableValues?.map(
                            (item: {id: string; value: string}) => {
                                return {
                                    label: item.value,
                                    value: item.id
                                };
                            }
                        ) || [];
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        });

        const mapping = {
            conformityAvailable: 'ce_conformity_available',
            safetyInstructions: 'article_safety_instructions',
            emc: 'article_compliance_emc',
            atex: 'article_compliance_atex',
            doc: 'article_compliance_doc',
            germanManual: 'article_compliance_german_manual',
            germanInstructions: 'article_german_instructions',
            lastCheckDate: 'article_compliance_date_last_check',
            marketReady: 'article_market_ready',
            testProtocol: 'ce_test_protocol',
            red: 'article_compliance_red',
            lvd: 'article_compliance_lvd',
            rohs: 'article_compliance_rohs_reach',
            additionalInformation: 'article_compliance_additional_info',
            manufacturerAddress: 'article_compliance_manufacturer_address',
            battgClass: 'article_batteriegesetz_class',
            weeeClasification: 'article_weee_relevant'
        };

        const state = ref(
            Object.fromEntries(Object.keys(mapping).map((item) => [item, null]))
        );

        const rules = Object.fromEntries(
            Object.keys(mapping).map((item) => [item, {}])
        );

        const bulkEditSwitches = ref(
            Object.fromEntries(
                Object.keys(mapping).map((item) => [item, false])
            )
        );

        const v$ = useVuelidate(rules, state);

        watch(props, () => {
            productDetails.value = props.productDetails;

            initializeCustomAttributes();
        });

        watch(v$, (args) => {
            if (args.$anyDirty) {
                context.emit('form-dirty', state.value, bulkEditSwitches.value);
            } else {
                context.emit('form-dirty', null);
            }
        });

        watch(bulkEditSwitches.value, () => {
            context.emit('form-dirty', state.value, bulkEditSwitches.value);
        });

        const initializeCustomAttributes = () => {
            if (!productDetails.value) {
                return;
            }

            const flippedMapping = Object.fromEntries(
                Object.entries(mapping).map((a) => a.reverse())
            );

            [
                'article_compliance_doc',
                'article_compliance_german_manual',
                'article_german_instructions',
                'article_compliance_additional_info',
                'article_compliance_manufacturer_address',
                'article_batteriegesetz_class',
                'article_weee_relevant'
            ].forEach((item) => {
                const temp: {[k: string]: string} = {};
                const attributes =
                    productDetails.value.weclapp?.customAttributes;
                temp[flippedMapping[item]] = attributes[item] || null;

                Object.assign(state.value, temp);
            });

            [
                'ce_conformity_available',
                'article_safety_instructions',
                'article_compliance_emc',
                'article_compliance_atex',
                'article_market_ready',
                'ce_test_protocol',
                'article_compliance_red',
                'article_compliance_lvd',
                'article_compliance_rohs_reach'
            ].forEach((item) => {
                const temp: {[k: string]: boolean} = {};
                const attributes =
                    productDetails.value.weclapp?.customAttributes;
                temp[flippedMapping[item]] = attributes[item] || false;
                Object.assign(state.value, temp);
            });

            ['article_compliance_date_last_check'].forEach((item) => {
                const temp: {[k: string]: Date} = {};
                const attributes =
                    productDetails.value.weclapp?.customAttributes;
                temp[flippedMapping[item]] = attributes[item]
                    ? new Date(attributes[item])
                    : null;
                Object.assign(state.value, temp);
            });
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;
            Object.assign(state.value, temp);
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            updateCompliance(
                productDetails.value.weclapp?.articleNumber ||
                    productDetails.value.shopware?.productNumber,
                state.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    context.emit('reload-product');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            isBulkEdit,
            locale: i18n.global.locale,
            weeeOptions,
            battgClassOptions,
            setDropdownValue,
            bulkEditSwitches
        };
    }
};
