import {ref, watch} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

export default {
    emits: ['choice-confirmed'],
    components: {
        PrimeDialog: Dialog,
        'p-button': Button
    },
    props: {
        displayConfirmLeaveDialog: Boolean
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);

        watch(props, (args) => {
            showDialog.value = args.displayConfirmLeaveDialog;
        });

        const handleAnswer = (confirmed: boolean) => {
            showDialog.value = false;
            context.emit('choice-confirmed', confirmed);
        };

        return {
            showDialog,
            handleAnswer
        };
    }
};
