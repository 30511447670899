import InputText from 'primevue/inputtext';
import AutoComplete from 'primevue/autocomplete';
import {useToast} from 'vue-toastification';
import {ref} from 'vue';
import router from '@/router';
import {getGlobalSearchResults} from '@/services/search';

export default {
    components: {
        InputText,
        AutoComplete
    },

    setup() {
        const toast = useToast();

        const searchTerm = ref(null);

        const filteredResults = ref([]);

        const loadData = () => {
            if (!searchTerm.value) {
                toast.error('Please enter search term!');
                return;
            }
            getGlobalSearchResults(searchTerm.value)
                .then((data) => {
                    filteredResults.value = data.data?.items
                        ? data.data.items.map((item: any) => {
                              return {
                                  id: item.articleNumber,
                                  name:
                                      '(' +
                                      item.articleNumber +
                                      ') ' +
                                      item.name
                              };
                          })
                        : [];
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        };

        const onTermSelected = (event: any) => {
            searchTerm.value = null;
            router.push({
                name: 'ProductDetails',
                params: {id: event.value.id}
            });
        };

        return {
            loadData,
            searchTerm,
            filteredResults,
            onTermSelected
        };
    }
};
