import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0757533a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid p-fluid" }
const _hoisted_2 = { class: "col field" }
const _hoisted_3 = { class: "grid" }
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = { class: "grid" }
const _hoisted_6 = { class: "col field" }
const _hoisted_7 = { class: "w-full flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.printDocumentRemotely'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[8] || (_cache[8] = $event => ((_ctx.showDialog) = $event)),
    closable: false,
    breakpoints: {'960px': '75vw', '640px': '90vw'},
    style: {width: '640px'},
    modal: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_loading, {
        active: _ctx.savingInProgress,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.savingInProgress) = $event))
      }, null, 8, ["active"]),
      _createElementVNode("form", {
        onSubmit: _cache[7] || (_cache[7] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.hardwareId')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$.hardwareId.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.hardwareId.$model) = $event)),
              options: _ctx.osInstances || [],
              optionLabel: "name",
              optionValue: "id",
              filter: true,
              class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.hardwareId.$invalid && _ctx.submitted}]),
              onChange: _cache[2] || (_cache[2] = $event => (_ctx.setDropdownValue('hardwareId', $event))),
              showClear: true
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.hardwareId.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.printerName')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$.printer.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.printer.$model) = $event)),
              disabled: !_ctx.v$.hardwareId.$model,
              options: _ctx.printerOptions || [],
              filter: true,
              optionLabel: "id",
              optionValue: "id",
              class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.printer.$invalid && _ctx.submitted}]),
              onChange: _cache[4] || (_cache[4] = $event => (_ctx.setDropdownValue('printer', $event))),
              showClear: true
            }, null, 8, ["modelValue", "disabled", "options", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.printer.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.printerSize')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$.printerSize.$model,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.printerSize.$model) = $event)),
              disabled: !_ctx.v$.hardwareId.$model,
              options: _ctx.printerSizeOptions || [],
              optionLabel: "id",
              optionValue: "id",
              filter: true,
              class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.printerSize.$invalid && _ctx.submitted}]),
              onChange: _cache[6] || (_cache[6] = $event => (_ctx.setDropdownValue('printerSize', $event))),
              showClear: true
            }, null, 8, ["modelValue", "disabled", "options", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.printerSize.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_p_button, {
            severity: "danger",
            onClick: _ctx.onCancelClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_p_button, {
            severity: "success",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
            ]),
            _: 1
          })
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}