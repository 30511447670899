import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Column = _resolveComponent("Column")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_DataTable = _resolveComponent("DataTable")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DataTable, {
        class: "p-datatable-sm text-sm",
        rowClass: _ctx.rowClass,
        stripedRows: "",
        paginator: true,
        rows: 10,
        lazy: true,
        dataKey: "rowIndex",
        ref: "dt",
        totalRecords: _ctx.totalRecords,
        value: _ctx.logs,
        filters: _ctx.filters,
        "onUpdate:filters": _cache[1] || (_cache[1] = $event => ((_ctx.filters) = $event)),
        filterDisplay: "row",
        onPage: _cache[2] || (_cache[2] = $event => (_ctx.onPage($event))),
        onFilter: _cache[3] || (_cache[3] = $event => (_ctx.onFilter($event))),
        responsiveLayout: "scroll",
        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
        "rows-per-page-options": [10,20,50],
        "current-page-report-template": "{first} to {last} of {totalRecords}"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "rowIndex",
            style: {"min-width":"4rem"},
            header: _ctx.$t('labels.rowNumber'),
            filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
          }, {
            filter: _withCtx(({filterModel,filterCallback}) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: filterModel.value,
                "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                class: "p-column-filter text-sm",
                placeholder: "#"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            style: {"min-width":"16rem"},
            header: _ctx.$t('labels.rowContent')
          }, {
            body: _withCtx(({data}) => [
              _createTextVNode(_toDisplayString(data.content.split(';').filter(n => n.trim()).join(' | ')), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "status",
            style: {"min-width":"8rem"},
            header: _ctx.$t('labels.status'),
            filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
          }, {
            filter: _withCtx(({filterModel}) => [
              _createVNode(_component_Dropdown, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                options: [{label: _ctx.$t('labels.failed'), value: '0'}, {label: _ctx.$t('labels.skipped'), value: '1'}, {label: _ctx.$t('labels.invalid'), value: '2'}],
                placeholder: "Any",
                optionLabel: "label",
                optionValue: "value",
                class: "p-column-filter text-sm",
                showClear: true
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
            ]),
            body: _withCtx(({data}) => [
              _createElementVNode("div", {
                innerHTML: _ctx.getStatusCellContent(data)
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "errorMessage",
            style: {"min-width":"16rem"},
            header: _ctx.$t('labels.error')
          }, {
            filter: _withCtx(({filterModel,filterCallback}) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: filterModel.value,
                "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                class: "p-column-filter text-sm",
                placeholder: _ctx.$t('labels.error')
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["rowClass", "totalRecords", "value", "filters"])
    ])
  ], 64))
}