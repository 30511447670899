<loading v-model:active="loading" />
<Panel>
    <template #header>
        <div class="flex align-items-center">
            <span class="font-bold">
                {{$t('labels.printConfigurations')}}
            </span>
            <p-button
                class="ml-2"
                @click="onConfigurationAddEditClick(null)"
                size="small"
                severity="success"
            >
                {{ $t("buttons.addNewGeneric") }}
            </p-button>
        </div></template
    >
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        paginator
        lazy
        scrollable
        scrollHeight="calc(100vh - 23rem)"
        :rows="10"
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="systemLogs"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
        @page="onPage"
        @filter="onFilter"
        @sort="onSort"
    >
        <Column field="hardwareId" :header="$t('labels.hardwareId')"> </Column>
        <Column field="event" :header="$t('labels.event')">
            <template #body="{data}">
                <div v-if="data.event">{{ $t('events.' + data.event) }}</div>
            </template>
        </Column>
        <Column field="printerName" :header="$t('labels.printerName')" />
        <Column field="printerSize" :header="$t('labels.printerSize')" />
        <Column field="author" :header="$t('labels.createdBy')">
            <template #body="{data}">
                <ul v-if="data.author">
                    <li v-for="element in data.author">{{ element }}</li>
                </ul>
            </template>
        </Column>
        <Column field="entityName" :header="$t('labels.entityName')" />
        <Column field="attributeName" :header="$t('labels.attributeName')" />
        <Column field="attributeValue" :header="$t('labels.attributeValue')">
        </Column>
        <Column style="min-width: 5rem" v-if="editPermissionAvailable">
            <template #body="{data}">
                <div class="flex justify-content-center">
                    <i
                        class="pi pi-pencil"
                        @click="onConfigurationAddEditClick(data)"
                        style="cursor: pointer"
                    ></i>
                    <i
                        class="pi pi-trash text-red-600 ml-2"
                        @click="removeConfiguration(data)"
                        style="cursor: pointer"
                    ></i>
                </div>
            </template>
        </Column>
    </DataTable>
</Panel>
<AddOrEditPrintConfigurationDialog
    :display-dialog="showCreatePrintConfigurationDialog"
    @close-dialog="onCloseCreateDialog"
    :existing-configuration="existingConfiguration"
></AddOrEditPrintConfigurationDialog>
