import {ref, onMounted, computed} from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import LoadingPlugin from 'vue-loading-overlay';
import Panel from 'primevue/panel';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import {getAll, removePermission} from '@/services/users';
import store from '@/store';
import {checkIfArraysAreEqual} from '@/utils/helpers';
import {i18n} from '@/utils/i18n';
import {useConfirm} from 'primevue/useconfirm';
import AddUserPermissionDialog from '@/components/dialog/users/add-user-permission.vue';
import {DateTime} from 'luxon';

export default {
    components: {
        DataTable,
        Column,
        InputText,
        Panel,
        AddUserPermissionDialog,
        loading: LoadingPlugin
    },
    setup() {
        onMounted(() => {
            if (localStorage.getItem('user-list-state-session')) {
                return;
            }

            tableState.value = null;
            filters.value = Object.assign({}, filterDefintions);

            loadLazyData();
        });

        const dt = ref();
        const loading = ref(false);
        const totalRecords = ref(0);
        const toast = useToast();
        const confirm = useConfirm();
        const displayAddPermissionDialog = ref(false);
        const selectedUser = ref(null);

        const users = ref();

        const filterDefintions: any = {
            username: {value: null, matchMode: FilterMatchMode.CONTAINS},
            displayName: {value: null, matchMode: FilterMatchMode.CONTAINS}
        };

        const filters = ref(filterDefintions);
        const tableState = ref(null);

        const loadLazyData = () => {
            loading.value = true;

            getAll()
                .then((data) => {
                    totalRecords.value = data.data.total;
                    users.value = data.data.items.map((obj: any) => ({
                        ...obj,
                        createdAtFormatted: DateTime.fromSeconds(obj.createdAt)
                            .setLocale(i18n.global.locale)
                            .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                            .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
                        lastActivityFormatted: obj.lastActivity
                            ? DateTime.fromSeconds(obj.lastActivity)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString(
                                      DateTime.DATETIME_MED_WITH_SECONDS
                                  )
                            : ''
                    }));
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('users-edit') !== -1;
        });

        const onStateRestore = (event: any) => {
            tableState.value = Object.assign({}, event);

            const stateFilters = Object.keys(tableState.value?.filters || {});

            const filtersDefinitionKeys = Object.keys(filterDefintions)
                .slice()
                .sort();

            if (!checkIfArraysAreEqual(stateFilters, filtersDefinitionKeys)) {
                localStorage.removeItem('user-list-state-session');
                tableState.value = null;
                filters.value = Object.assign({}, filterDefintions);
            }

            loadLazyData();
        };

        const onRemoveRole = (permission: string, username: string) => {
            confirm.require({
                message: i18n.global.t('messages.removeRoleConfirmation', {
                    role: permission,
                    user: username
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    loading.value = true;
                    removePermission(permission, username)
                        .then(() => {
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );
                            loadLazyData();
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        })
                        .finally(() => {
                            loading.value = false;
                        });
                }
            });
        };

        const closeDialogListener = (reload: boolean = false) => {
            displayAddPermissionDialog.value = false;
            if (reload) {
                loadLazyData();
            }
        };

        return {
            users,
            dt,
            totalRecords,
            loading,
            filters,
            editPermissionAvailable,
            onStateRestore,
            onRemoveRole,
            displayAddPermissionDialog,
            closeDialogListener,
            selectedUser
        };
    }
};
