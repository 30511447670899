<div id="userIcon" @click="toggleUserPanel">
    <img
        :src="(profilePicture?.type && profilePicture?.contents) ? ('data:' + profilePicture.type + ';base64, ' + profilePicture.contents) : require('@/assets/img/default-profile.png')"
        alt="User Image"
    />
</div>
<OverlayPanel ref="opUser" appendTo="body" id="overlay_panel_user">
    <div class="overlay-users">
        <div class="flex justify-content-center">
            <img
                :src="(profilePicture?.type && profilePicture?.contents) ? ('data:' + profilePicture.type + ';base64, ' + profilePicture.contents) : require('@/assets/img/default-profile.png')"
                alt="User Image"
            />
        </div>

        <div class="flex justify-content-center mt-4 font-semibold">
            {{user && (user.displayName)}}
        </div>
        <div class="flex justify-content-center text-sm mt-1">
            <span>{{ $t("labels.memberSince") }}&nbsp;</span>
            <span>{{readableCreatedAtDate}}</span>
        </div>

        <div class="flex mt-4" style="justify-content: space-between">
            <div>
                <p-button
                    v-if="(user?.permissions || []).indexOf('weclapp-shipments-edit') !== -1"
                    @click="navigateToProfile"
                >
                    {{ $t("labels.profile") }}
                </p-button>
            </div>
            <div>
                <p-button @click="logout">
                    {{ $t("labels.signOut") }}
                </p-button>
            </div>
        </div>
    </div>
</OverlayPanel>
