<p-fieldset
    class="mb-3"
    :legend="$t('labels.ticket.references.orderPaidRepair')"
    :toggleable="true"
>
    <div class="flex">
        <div
            class="grid"
            v-if="ticketDetails.ticketOrderPaidRepair && ticketDetails.ticketOrderPaidRepair.length > 0"
            v-for="referenceItem in (ticketDetails.ticketOrderPaidRepair || [])"
        >
            <div class="col flex">
                <div>
                    <span
                        v-html="getReferenceItemLayout(referenceItem,
                                        ticketDetails.url)"
                    ></span>
                </div>
            </div>
        </div>

        <div
            class="flex justify-content-end"
            v-if="showAddNewQuotationButton && editPermissionAvailable"
        >
            <p-button severity="success" @click="onAddNewQuotationClick">
                {{ $t("buttons.addNewQuotation") }}
            </p-button>
        </div>
    </div>
</p-fieldset>
<AddRepairQuotation
    :display-add-quotation-dialog="showAddQuotationWizardDialog"
    :ticket-details="ticketDetails"
    @close-dialog="closeAddNewQuotationDialog"
></AddRepairQuotation>
