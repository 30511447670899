import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_GeneralStep = _resolveComponent("GeneralStep")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_BookedInWarehouseStep = _resolveComponent("BookedInWarehouseStep")
  const _component_TabView = _resolveComponent("TabView")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.savingInProgress,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.savingInProgress) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_TabView, {
      activeIndex: _ctx.activeTabIndex,
      "onUpdate:activeIndex": _cache[1] || (_cache[1] = $event => ((_ctx.activeTabIndex) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TabPanel, {
          header: _ctx.$t('labels.ticket.retoure.details'),
          disabled: !_ctx.step1Enabled
        }, {
          default: _withCtx(() => [
            _createVNode(_component_GeneralStep, {
              "is-return-ticket": _ctx.isReturnTicket,
              "ticket-details": _ctx.ticketDetails,
              onBackButtonClicked: _ctx.onBackButtonClicked,
              onNextButtonClicked: _ctx.onNextButtonClicked
            }, null, 8, ["is-return-ticket", "ticket-details", "onBackButtonClicked", "onNextButtonClicked"])
          ]),
          _: 1
        }, 8, ["header", "disabled"]),
        _createVNode(_component_TabPanel, {
          header: _ctx.$t('labels.ticket.retoure.items.items'),
          disabled: !_ctx.step2Enabled
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BookedInWarehouseStep, {
              "selected-order-number": _ctx.formValues?.orderNumber,
              "is-return-ticket": _ctx.isReturnTicket,
              savingInProgress: _ctx.savingInProgress,
              onBackButtonClicked: _ctx.onBackButtonClicked,
              onNextButtonClicked: _ctx.onNextButtonClicked
            }, null, 8, ["selected-order-number", "is-return-ticket", "savingInProgress", "onBackButtonClicked", "onNextButtonClicked"])
          ]),
          _: 1
        }, 8, ["header", "disabled"])
      ]),
      _: 1
    }, 8, ["activeIndex"])
  ], 64))
}