import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "layout-menu" }
const _hoisted_2 = {
  key: 1,
  class: "menu-separator"
}

export function render(_ctx, _cache) {
  const _component_app_menu_item = _resolveComponent("app-menu-item")

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item, i) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: item }, [
        (!item.separator)
          ? (_openBlock(), _createBlock(_component_app_menu_item, {
              key: 0,
              item: item
            }, null, 8, ["item"]))
          : _createCommentVNode("", true),
        (item.separator)
          ? (_openBlock(), _createElementBlock("li", _hoisted_2))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}