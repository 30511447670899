import {ref, onMounted, computed} from 'vue';
import {getAll} from '@/services/incoming-goods';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import LoadingPlugin from 'vue-loading-overlay';
import Panel from 'primevue/panel';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import {getPlatformCellContent} from '@/utils/helpers';
import Calendar from 'primevue/calendar';
import {DateTime} from 'luxon';
import {i18n} from '@/utils/i18n';
import FilterMenu from '@/components/filter-menu/filter-menu.vue';
import store from '@/store';
import BookInternalIncomingGoods from '@/components/dialog/incoming-goods/book-internal-incoming-goods.vue';

export default {
    components: {
        DataTable,
        Column,
        InputText,
        Dropdown,
        Panel,
        loading: LoadingPlugin,
        Calendar,
        FilterMenu,
        BookInternalIncomingGoods
    },
    setup() {
        onMounted(() => {
            if (localStorage.getItem('incoming-goods-list-state-session')) {
                return;
            }

            tableState.value = null;
            filters.value = Object.assign({}, filterDefintions);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'createdDate',
                sortOrder: -1,
                filters: filters.value,
                allEnvs: false
            };

            loadLazyData();
        });

        const dt = ref();
        const loading = ref(false);
        const totalRecords = ref(0);
        const lazyParams: any = ref({});
        const toast = useToast();
        const showBookIntenalDialog = ref(false);
        const selectedIncomingGoodsNumber = ref(null);

        const incomingGoods = ref();

        const filterDefintions: any = {
            incomingGoodsNumber: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            status: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            incomingGoodsType: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            sender: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            salesOrder: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            purchaseOrder: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            warehouse: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            relatedShipmentId: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            createdDate: {value: null, matchMode: FilterMatchMode.DATE_IS}
        };

        const filters = ref(filterDefintions);
        const tableState = ref(null);

        const loadLazyData = () => {
            loading.value = true;

            getAll(lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    incomingGoods.value = data.data.items.map((obj: any) => ({
                        ...obj,
                        createdDateFormatted: DateTime.fromMillis(
                            obj.createdDate
                        )
                            .setLocale(i18n.global.locale)
                            .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                            .toLocaleString(DateTime.DATETIME_MED)
                    }));
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const onPage = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = filters.value;
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onStateRestore = (event: any) => {
            tableState.value = Object.assign({}, event);

            const stateFilters = Object.keys(tableState.value?.filters || {});

            const filtersDefinitionKeys = Object.keys(filterDefintions)
                .slice()
                .sort();

            if (
                !stateFilters.every((val) =>
                    filtersDefinitionKeys.includes(val)
                )
            ) {
                localStorage.removeItem('incoming-goods-list-state-session');
                tableState.value = null;
                filters.value = Object.assign({}, filterDefintions);
            } else {
                filters.value = Object.assign(
                    {},
                    filterDefintions,
                    event.filters || {}
                );
            }

            lazyParams.value = {
                first: 0,
                rows: event.rows || dt.value.rows,
                sortField: event.sortField || 'createdDate',
                sortOrder: event.sortOrder || -1,
                filters: filters.value,
                allEnvs: false
            };

            loadLazyData();
        };

        const clearFilters = () => {
            filters.value = Object.assign({}, filterDefintions);
            onFilter();
        };

        const applyFilters = (savedFilters: any) => {
            const savedFilterKeys = Object.keys(savedFilters || {}).filter(
                (e) => e !== 'view'
            );

            const filtersDefinitionKeys = Object.keys(filterDefintions)
                .slice()
                .sort();

            if (
                savedFilterKeys.every((val) =>
                    filtersDefinitionKeys.includes(val)
                )
            ) {
                filters.value = Object.assign({}, filterDefintions, {
                    ...savedFilters
                });
                onFilter();
            }
        };

        const outputSenderInGrid = (data: any) => {
            let output = '';
            if (data.senderCustomerNumber && data.senderSupplierNumber) {
                output += `${i18n.global.t(
                    'labels.shipment.recipientCustomer'
                )}/${i18n.global.t('labels.shipment.recipientSupplier')} [${
                    data.senderCustomerNumber
                }/${data.senderSupplierNumber}]`;
            } else if (data.senderCustomerNumber) {
                output += `${i18n.global.t(
                    'labels.shipment.recipientCustomer'
                )} [${data.senderCustomerNumber}]`;
            } else if (data.senderSupplierNumber) {
                output += `${i18n.global.t(
                    'labels.shipment.recipientSupplier'
                )} [${data.senderSupplierNumber}]`;
            } else {
                return '';
            }

            if (data.senderParty?.company) {
                output += ` ${data.senderParty.company}`;
            } else if (
                data.senderParty?.lastName &&
                data.senderParty?.firstName
            ) {
                output += ` ${data.senderParty.lastName}, ${data.senderParty.firstName}`;
            }

            return output;
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return (
                user?.permissions?.indexOf('weclapp-incoming-goods-edit') !== -1
            );
        });

        const onInternalBookingClick = (incomingGoodsNumber: string) => {
            selectedIncomingGoodsNumber.value = incomingGoodsNumber;
            showBookIntenalDialog.value = true;
        };

        const onCloseInternalBookingDialog = (payload: any) => {
            showBookIntenalDialog.value = false;

            if (payload?.needsRefresh) {
                loadLazyData();
            }
        };

        return {
            incomingGoods,
            dt,
            totalRecords,
            loading,
            lazyParams,
            filters,
            onPage,
            onFilter,
            onSort,
            getPlatformCellContent: getPlatformCellContent,
            onStateRestore,
            showBookIntenalDialog,
            selectedIncomingGoodsNumber,
            clearFilters,
            applyFilters,
            outputSenderInGrid,
            editPermissionAvailable,
            onInternalBookingClick,
            onCloseInternalBookingDialog
        };
    }
};
