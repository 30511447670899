<loading v-model:active="savingInProgress" />
<PrimeDialog
    :header="dialogHeaderLabel"
    v-model:visible="showDialog"
    :closable="false"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '900px'}"
    :modal="true"
>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="grid font-bold">
            <div class="col-3">{{$t('labels.article')}}</div>
            <div class="col-2">{{$t('labels.shipment.shipmentQuantity')}}</div>
            <div class="col-3">Quelllagerplatz</div>
            <div class="col-3">Ziellagerplatz</div>
            <div class="col-1"></div>
        </div>
        <template v-for="(item, index) in state.shipmentItems" :key="index">
            <div class="grid mt-3">
                <div class="col-3">
                    <AutoComplete
                        v-model="item.articleNumber"
                        :placeholder="$t('labels.article')"
                        :suggestions="filteredProducts"
                        :class="{'p-invalid':submitted && v$.shipmentItems.$each.$response.$data[index].articleNumber.$invalid}"
                        @complete="searchProducts($event)"
                        @item-select="dropdownSelect(index, $event)"
                        :dropdown="true"
                        optionLabel="label"
                        optionValue="value"
                        forceSelection
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.shipmentItems.$each.$response.$errors[index].articleNumber"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                    <small class="font-weight-bold"
                        >{{item.orderItem?.title || item.article?.name}}</small
                    >
                </div>
                <div class="col-2">
                    <InputNumber
                        :locale="locale"
                        :class="{'p-invalid':submitted && v$.shipmentItems.$each.$response.$data[index].shipmentQuantity.$invalid}"
                        autocomplete="off"
                        v-model="item.shipmentQuantity"
                        :disabled="item.serialNumberRequired"
                    />
                    {{item.unitName}}
                    <span
                        v-if="item.serialNumberRequired && item.sourceStoragePlaceId"
                        class="pi pi-plus-circle text-green-600"
                        @click="openAddSerialNumberDialog(index, item)"
                    ></span>
                    <ul v-if="item.selectedSerialNumbers.length > 0">
                        <li
                            class="text-sm"
                            v-for="element in item.selectedSerialNumbers"
                        >
                            {{ element}}
                            <span
                                class="pi pi-times-circle text-red-600 mt-1"
                                @click="removeSerialNumberItem(index, element, $event)"
                            ></span>
                        </li>
                    </ul>

                    <small
                        v-if="submitted"
                        style="display: block"
                        class="p-error"
                        v-for="error in v$.shipmentItems.$each.$response.$errors[index].shipmentQuantity"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.shipmentItems.$each.$response.$errors[index].selectedSerialNumbers"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
                <div class="col-3">
                    <p-dropdown
                        v-if="isCustomerCompensationShipment"
                        v-model="customerCompensationSourceWarehouseId"
                        :options="warehouses || []"
                        optionLabel="name"
                        optionValue="id"
                        @change="item.sourceStoragePlaceId = null"
                        class="mb-3"
                    >
                    </p-dropdown>
                    <TreeSelect
                        class="w-full"
                        v-model="item.sourceStoragePlaceId"
                        :options="storagePlaces.filter((item) => item.warehouseId === sourceWarehouseId)"
                        @nodeExpand="onNodeExpand"
                        :disabled="isCustomerCompensationShipment && !customerCompensationSourceWarehouseId"
                        :class="{'p-invalid':submitted && v$.shipmentItems.$each.$response.$data[index].sourceStoragePlaceId.$invalid}"
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        class="p-error"
                        v-for="error in v$.shipmentItems.$each.$response.$errors[index].sourceStoragePlaceId"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
                <div class="col-3">
                    <TreeSelect
                        class="w-full"
                        v-model="item.destinationStoragePlaceId"
                        :options="storagePlaces.filter((item) => item.warehouseId === targetWarehouseId)"
                        @nodeExpand="onNodeExpand"
                        :class="{'p-invalid':submitted && v$.shipmentItems.$each.$response.$data[index].destinationStoragePlaceId.$invalid}"
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        class="p-error"
                        v-for="error in v$.shipmentItems.$each.$response.$errors[index].destinationStoragePlaceId"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
                <!--<div class="col-1" style="padding-top: 12px">
                    <span
                        v-if="state.shipmentItems.length > 1 || index > 0"
                        class="pi pi-trash float-right"
                        style="color: red"
                        @click="removeShipmentItem(index);"
                    ></span>
                    <span
                        v-if="state.shipmentItems.length - 1 <= index"
                        class="pi pi-plus mr-2 float-right"
                        style="color: green"
                        @click="addShipmentItem"
                    ></span>
                </div>-->
            </div>
            <div class="grid">
                <div class="col">
                    <small style="display: block"
                        >{{stripTagsAndTruncate(item.orderItem?.description ||
                        item.article?.description)}}</small
                    >
                </div>
            </div>
        </template>
        <div class="w-full flex justify-content-between mt-4">
            <p-button
                severity="danger"
                v-text="$t('buttons.cancel')"
                @click="onCancelButtonClicked"
            >
            </p-button>

            <p-button
                severity="success"
                v-text="$t('buttons.confirm')"
                type="submit"
            >
            </p-button>
        </div>
    </form>
</PrimeDialog>
<AddSerialNumber
    :display-add-serial-number-dialog="showAddSerialNumberDialog"
    :row-index="clickedRowIndex"
    :warehouse-stock-movement-filters="addSerialNumberDialogFilters"
    :article-id="clickedRow?.article?.id"
    @close-dialog="closeDialogListener"
>
</AddSerialNumber>
