import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const signalHeartBeat = async (
    entityName: string,
    entityId: string | string[]
): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/user-activity', {
        entityName,
        entityId
    });
};

export const setEntityWeclappVersion = async (
    entityName: string,
    entityId: string | string[],
    weclappVersion: string
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/user-activity', {
        entityName,
        entityId,
        weclappVersion
    });
};

export const getCollidingUsers = async (
    entityName: string,
    entityId: string | string[]
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/user-activity/colliding-users?entityName=' +
            entityName +
            '&entityId=' +
            entityId
    );
};

export const isWeclappVersionColliding = async (
    entityName: string,
    entityId: string | string[]
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/user-activity/colliding-version?entityName=' +
            entityName +
            '&entityId=' +
            entityId
    );
};
