import {computed, onMounted, ref, toRefs} from 'vue';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Column from 'primevue/column';
import LoadingPlugin from 'vue-loading-overlay';

import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import {getSingle} from '@/services/import-logs';
import {i18n} from '@/utils/i18n';
import store from '@/store';
import {ILayoutConfig} from '@/interfaces/layout/config';
export default {
    emits: ['on-selection-change'],
    components: {
        DataTable,
        Column,
        Dropdown,
        InputText,
        loading: LoadingPlugin
    },
    props: {
        importId: Number
    },
    setup(props: any) {
        const {importId} = toRefs(props);

        onMounted(() => {
            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'rowIndex',
                sortOrder: 1,
                filters: filters.value
            };

            loadLazyData();
        });

        const totalRecords = ref(0);
        const loading = ref(false);
        const logs = ref();
        const dt = ref();
        const lazyParams: any = ref({});
        const toast = useToast();

        const onPage = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = Object.assign(
                filters.value,
                props.preDefinedFilters || {}
            );
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const loadLazyData = () => {
            loading.value = true;
            getSingle(importId.value, lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    logs.value = data.data.items;
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const filterDefintions: any = {
            rowIndex: {value: null, matchMode: FilterMatchMode.EQUALS},
            status: {value: null, matchMode: FilterMatchMode.CONTAINS},
            errorMessage: {value: null, matchMode: FilterMatchMode.CONTAINS}
        };

        const filters = ref(filterDefintions);

        const getStatusCellContent = (data: any) => {
            if (data?.errorMessage) {
                switch (data.status) {
                    case 1:
                        return i18n.global.t('labels.skipped');
                    case 2:
                        return i18n.global.t('labels.invalid');
                    default:
                        return i18n.global.t('labels.failed');
                }
            }
        };

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        return {
            filters,
            dt,
            logs,
            loading,
            loadLazyData,
            totalRecords,
            onFilter,
            onPage,
            onSort,
            rowClass: (data: any) => {
                const colorVariant = layoutConfig.value?.darkTheme ? 800 : 200;
                return data && data.errorMessage
                    ? 'bg-red-' + colorVariant
                    : 'bg-green-' + colorVariant;
            },
            getStatusCellContent
        };
    }
};
