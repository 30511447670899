import {computed, ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import useVuelidate from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import Dropdown from 'primevue/dropdown';
import {getChemicalData} from '@/services/ups';
import {invokeUpsAcceptancePreCheck} from '@/services/shipments';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        'p-button': Button,
        'p-dropdown': Dropdown,
        InputNumber,
        loading: LoadingPlugin
    },
    props: {
        displayDialog: Boolean,
        shipmentItems: Array,
        shipment: Object
    },
    setup(props: any, context: any) {
        const {shipmentItems, shipment} = toRefs(props);
        const showDialog = ref(false);
        const toast = useToast();
        const savingInProgress = ref(false);
        const submitted = ref(false);
        const chemicalOptions = ref([]);

        const state = ref({
            chemicalId: null,
            weightCombined: [],
            numberOfPackages: 1
        });

        const initialWeight = computed(() => {
            return (shipmentItems?.value || [])
                .filter((item: any) => {
                    return item.article_flag_akku_groesser_100wh;
                })
                .reduce(
                    (accumulator: any, currentValue: any) =>
                        accumulator +
                        parseInt(currentValue.quantity) *
                            (currentValue.article?.articleGrossWeight || 0),
                    0
                );
        });

        const rules = {
            chemicalId: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            weightCombined: {
                $each: helpers.forEach({
                    weight: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        ),
                        totalEqualToInitial: helpers.withMessage(
                            () => {
                                return i18n.global.t(
                                    'messages.totalWeightNotEqualToInitial',
                                    {
                                        initialWeight:
                                            initialWeight.value.toFixed(3),

                                        totalWeight:
                                            totalWeight.value.toFixed(3)
                                    }
                                );
                            },
                            () => {
                                return (
                                    Math.round(totalWeight.value * 100000) ===
                                    Math.round(initialWeight.value * 100000)
                                );
                            }
                        )
                    }
                })
            },
            numberOfPackages: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            }
        };

        const v$ = useVuelidate(rules, state, {$scope: false});

        const totalWeight = computed(() =>
            (state?.value?.weightCombined || []).reduce(
                (accumulator: any, currentValue: any) =>
                    accumulator + parseFloat(currentValue.weight),
                0
            )
        );

        watch(props, async (args) => {
            showDialog.value = args.displayDialog;
            if (
                showDialog.value &&
                shipmentItems.value &&
                shipmentItems.value.length > 0
            ) {
                state.value.weightCombined = [
                    {
                        weight: initialWeight.value
                    }
                ];
            }

            if (showDialog.value && shipment.value?.warehouse?.name) {
                ['UN3480', 'UN3481'].forEach((chemicalId: string) => {
                    getChemicalData(chemicalId, shipment.value.warehouse.name)
                        .then((data) => {
                            if (
                                !chemicalOptions.value.some(
                                    (item: any) => item.idNumber === chemicalId
                                )
                            ) {
                                chemicalOptions.value.push(data.data);

                                chemicalOptions.value.sort((a, b) =>
                                    a.idNumber.localeCompare(b.idNumber)
                                );
                            }
                        })
                        .catch((error) => {
                            toast.error(error.message);
                        });
                });
            }
        });

        const onCancelClick = (event: any) => {
            event.preventDefault();
            showDialog.value = false;
            submitted.value = false;
            state.value = {
                chemicalId: null,
                weightCombined: [],
                numberOfPackages: 1
            };
            context.emit('close-dialog');
        };

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            try {
                await invokeUpsAcceptancePreCheck(
                    shipment.value.shipmentNumber,
                    state.value
                );
                toast.success(
                    i18n.global.t('messages.changesSavedSuccessfully')
                );
                context.emit('close-dialog', state.value);
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
                context.emit('close-dialog');
            } finally {
                submitted.value = false;
                state.value = {
                    chemicalId: null,
                    weightCombined: [],
                    numberOfPackages: 1
                };
                savingInProgress.value = false;
                showDialog.value = false;
            }
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;

            Object.assign(state.value, temp);
        };

        const onPackagesNumberChange = (event: {value: number}) => {
            if (event.value < state.value.weightCombined.length) {
                state.value.weightCombined.pop();
            } else if (event.value > state.value.weightCombined.length) {
                state.value.weightCombined.push({
                    weight: 0
                });
            }

            state.value.weightCombined.forEach((item) => {
                item.weight = parseFloat(
                    (
                        initialWeight.value / state.value.weightCombined.length
                    ).toFixed(3)
                );
            });
        };

        const onPackageWeightChange = (packageIndex: number) => {
            let remainingWeight = initialWeight.value;
            state.value.weightCombined.forEach((item, index) => {
                if (packageIndex >= index) {
                    remainingWeight -= parseFloat(item.weight);
                    return true;
                }
                item.weight = parseFloat(
                    (
                        remainingWeight /
                        (state.value.weightCombined.length - packageIndex - 1)
                    ).toFixed(3)
                );
            });
        };

        return {
            showDialog,
            chemicalOptions,
            onCancelClick,
            handleSubmit,
            state,
            v$,
            submitted,
            savingInProgress,
            setDropdownValue,
            onPackagesNumberChange,
            onPackageWeightChange
        };
    }
};
