<loading v-model:active="loading" />
<div class="col-12">
    <DataTable
        class="p-datatable-sm text-sm"
        :rowClass="rowClass"
        stripedRows
        :paginator="true"
        :rows="10"
        :lazy="true"
        dataKey="id"
        ref="dt"
        :totalRecords="totalRecords"
        :value="logs"
        v-model:filters="filters"
        filterDisplay="row"
        @page="onPage($event)"
        @filter="onFilter($event)"
        @sort="onSort($event)"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:expandedRows="expandedRows"
    >
        <Column :expander="true" headerStyle="width: 3em" />
        <Column
            v-if="entityColumnHeader"
            style="min-width: 4rem"
            :header="entityColumnHeader"
            field="entityInstanceFriendlyId"
            :sortable="true"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    placeholder="#"
                />
            </template>
            <template #body="{data}">
                <div v-html="getEntityColumnContent(data)"></div>
            </template>
        </Column>
        <Column
            field="emailToAddress"
            :header="$t('labels.mail.toAddress')"
            :sortable="true"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.mail.toAddress')"
                />
            </template>
        </Column>
        <Column
            field="emailCcAddress"
            :header="$t('labels.mail.ccAddress')"
            :sortable="true"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.mail.ccAddress')"
                />
            </template>
        </Column>
        <Column
            field="emailAttachment"
            :header="$t('labels.mail.attachment')"
            :sortable="true"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.mail.attachment')"
                />
            </template>
            <template #body="{data}">
                {{data.emailAttachment}}
                <i
                    v-if="data?.hasAttachmentContent"
                    style="cursor: pointer"
                    class="pi pi-download text-sm ml-2"
                    @click="downloadAttachment(data.id)"
                ></i>
            </template>
        </Column>
        <Column
            field="createdAt"
            :header="$t('labels.logTime')"
            :sortable="true"
            dataType="date"
        >
            <template #filter="{filterModel}">
                <Calendar
                    v-model="filterModel.value"
                    dateFormat="mm/dd/yy"
                    placeholder="mm/dd/yyyy"
                />
            </template>
            <template #body="{data}">{{data.createdAtFormatted}}</template>
        </Column>
        <Column
            field="error"
            dataType="boolean"
            style="min-width: 16rem"
            :header="$t('labels.error')"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
        </Column>
        <template #expansion="slotProps">
            <div v-if="!slotProps.data?.error">
                <div>
                    <label>{{ $t('labels.mail.toAddress') }} </label>
                    <span class="ml-3">
                        {{slotProps.data?.emailToAddress}}</span
                    >
                </div>
                <div v-if="slotProps.data?.emailCcAddress">
                    <label>{{ $t('labels.mail.ccAddress') }} </label>
                    <span class="ml-3">
                        {{slotProps.data?.emailCcAddress}}</span
                    >
                </div>
                <div>
                    <label>{{ $t('labels.mail.subject') }} </label>
                    <span class="ml-3"> {{slotProps.data?.emailSubject}}</span>
                </div>
                <div v-if="slotProps.data?.emailAttachment">
                    <label>{{ $t('labels.mail.attachment') }} </label>
                    <span class="ml-3">
                        {{slotProps.data?.emailAttachment}}</span
                    >
                    <i
                        v-if="slotProps.data?.hasAttachmentContent"
                        style="cursor: pointer"
                        class="pi pi-download text-sm ml-2"
                        @click="downloadAttachment(slotProps.data.id)"
                    ></i>
                </div>
                <p-divider />
                <div v-html="slotProps.data?.emailBody"></div>
            </div>
        </template>
    </DataTable>
</div>
