import {ref, onMounted} from 'vue';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import LoadingPlugin from 'vue-loading-overlay';
import Panel from 'primevue/panel';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import {checkIfArraysAreEqual} from '@/utils/helpers';
import {getAll} from '@/services/system-logs';

export default {
    components: {
        DataTable,
        Column,
        Panel,
        loading: LoadingPlugin,
        Dropdown
    },
    setup() {
        onMounted(() => {
            if (localStorage.getItem('system-log-list-state-session')) {
                return;
            }

            tableState.value = null;
            filters.value = Object.assign({}, filterDefintions);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'date',
                sortOrder: -1,
                filters: filters.value
            };

            loadLazyData();
        });

        const dt = ref();
        const loading = ref(false);
        const totalRecords = ref(0);
        const toast = useToast();
        const lazyParams = ref(null);

        const systemLogs = ref();

        const filterDefintions: any = {
            type: {value: null, matchMode: FilterMatchMode.CONTAINS}
        };

        const filters = ref(filterDefintions);
        const tableState = ref(null);

        const loadLazyData = () => {
            loading.value = true;

            getAll(lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    systemLogs.value = data.data.items;
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const getTypeCellStyle = (value: any) => {
            if (!value) {
                return '';
            }

            switch (value) {
                case 0:
                case 1:
                case 2:
                case 3:
                    return 'text-red-400';
                case 4:
                case 5:
                    return 'text-orange-400';
                case 6:
                    return 'text-blue-400';
                case 7:
                    return 'text-green-400';

                default:
                    return 'text-primary';
            }
        };

        const getTypeCellContent = (value: any) => {
            if (!value) {
                return '';
            }

            switch (value) {
                case 0:
                    return 'Emergency';
                case 1:
                    return 'Alert';
                case 2:
                    return 'Critical';
                case 3:
                    return 'Error';
                case 4:
                    return 'Warning';
                case 5:
                    return 'Notice';
                case 6:
                    return 'Info';
                case 7:
                    return 'Debug';

                default:
                    return '';
            }
        };

        const onStateRestore = (event: any) => {
            tableState.value = Object.assign({}, event);

            const stateFilters = Object.keys(tableState.value?.filters || {});

            const filtersDefinitionKeys = Object.keys(filterDefintions)
                .slice()
                .sort();

            if (!checkIfArraysAreEqual(stateFilters, filtersDefinitionKeys)) {
                localStorage.removeItem('system-log-list-state-session');
                tableState.value = null;
                filters.value = Object.assign({}, filterDefintions);
            }

            lazyParams.value = {
                first: 0,
                rows: event.rows || dt.value.rows,
                sortField: event.sortField || 'date',
                sortOrder: event.sortOrder || -1,
                filters: event.filters
            };

            loadLazyData();
        };

        const onPage = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = filters.value;
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        return {
            systemLogs,
            dt,
            totalRecords,
            loading,
            filters,
            onStateRestore,
            onPage,
            onFilter,
            onSort,
            getTypeCellContent,
            getTypeCellStyle
        };
    }
};
