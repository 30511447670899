<div class="layout-footer justify-content-between">
    <div>
        <strong>
            <span>{{ $t("labels.copyright") }} &copy; {{currentYear}}</span>
            <a
                href="https://teltec.de"
                target="_blank"
                rel="noopener noreferrer"
            >
                <span>&nbsp;Teltec</span>
            </a>
            <span>.</span>
        </strong>
    </div>
    <div>
        <b>{{ $t("labels.version") }}</b>
        <span>:&nbsp;{{version}}</span>
        <span v-if="serverVersion"> (Server: {{serverVersion}})</span>
    </div>
</div>
