<loading v-model:active="loading" />
<Panel :header="$t('labels.dimensionsAcquisition')">
    <!-- Main content -->
    <template v-if="productDetails?.weclapp">
        <div class="mb-5">
            <DataTable
                class="p-datatable-sm"
                stripedRows
                :lazy="false"
                ref="dt"
                dataKey="id"
                responsiveLayout="scroll"
                :value="[productDetails]"
            >
                <Column
                    style="min-width: 9rem"
                    :header="$t('labels.articleNumber')"
                >
                    <template #body="{data}">
                        {{data.weclapp.articleNumber}}
                    </template>
                </Column>
                <Column style="min-width: 9rem" :header="$t('labels.name')">
                    <template #body="{data}"> {{data.weclapp.name}} </template>
                </Column>
                <Column style="min-width: 9rem" :header="$t('labels.ean')">
                    <template #body="{data}"> {{data.weclapp.ean}} </template>
                </Column>
            </DataTable>
        </div>
        <div class="grid p-fluid mt-5 mb-5">
            <div class="col">
                <div class="grid">
                    <div class="col field">
                        <label
                            >{{ $t('labels.productHome.grossWeight') }}
                        </label>

                        <InputText
                            name="articleGrossWeight"
                            pattern="^\d*(,\d{0,3})?$"
                            :placeholder="$t('labels.productHome.grossWeight')"
                            autocomplete="off"
                            @focus.native="onElementFocused"
                            v-on:keydown="onElementKeyDown"
                            v-model="v$.articleGrossWeight.$model"
                            :class="{'p-invalid':v$.articleGrossWeight.$invalid && submitted}"
                            ref="articleWeightInputField"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.articleGrossWeight.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
                <div class="grid">
                    <div class="col field">
                        <label>{{ $t('labels.ean') }} </label>

                        <InputText
                            name="ean"
                            :placeholder="$t('labels.ean')"
                            autocomplete="off"
                            @focus.native="onElementFocused"
                            v-model="v$.ean.$model"
                            :class="{'p-invalid':v$.ean.$invalid && submitted}"
                            ref="articleEanInputField"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.ean.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
                <div class="grid">
                    <div class="col field">
                        <label>{{ $t('labels.productHome.length') }} </label>
                        <InputText
                            pattern="^\d*(,\d{0,1})?$"
                            name="articleLength"
                            v-on:keydown="onElementKeyDown"
                            :placeholder="$t('labels.productHome.length')"
                            autocomplete="off"
                            @focus.native="onElementFocused"
                            v-model="v$.articleLength.$model"
                            :class="{'p-invalid':v$.articleLength.$invalid && submitted}"
                            ref="articleLengthInputField"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.articleLength.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>

                    <div class="col field">
                        <label>{{ $t('labels.productHome.width') }} </label>

                        <InputText
                            pattern="^\d*(,\d{0,1})?$"
                            name="articleWidth"
                            v-on:keydown="onElementKeyDown"
                            :placeholder="$t('labels.productHome.width')"
                            autocomplete="off"
                            @focus.native="onElementFocused"
                            v-model="v$.articleWidth.$model"
                            :class="{'p-invalid':v$.articleWidth.$invalid && submitted}"
                            ref="articleWidthInputField"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.articleWidth.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>

                    <div class="col field">
                        <label>{{ $t('labels.productHome.height') }} </label>

                        <InputText
                            pattern="^\d*(,\d{0,1})?$"
                            name="articleHeight"
                            v-on:keydown="onElementKeyDown"
                            :placeholder="$t('labels.productHome.height')"
                            autocomplete="off"
                            @focus.native="onElementFocused"
                            v-model="v$.articleHeight.$model"
                            :class="{'p-invalid':v$.articleHeight.$invalid && submitted}"
                            ref="articleHeightInputField"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.articleHeight.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
                <div class="grid">
                    <div class="col field">
                        <label
                            >{{ $t('labels.productHome.containsAerosols') }}
                        </label>

                        <app-checkbox
                            v-model="v$.article_flag_aerosole.$model"
                            binary
                            class="ml-2 mb-1"
                        />
                    </div>
                </div>
                <div class="grid">
                    <div class="col field">
                        <label
                            >{{ $t('labels.productHome.dangerousGoods') }}
                        </label>

                        <app-checkbox
                            v-model="v$.article_flag_akku_groesser_100wh.$model"
                            binary
                            class="ml-2 mb-1"
                        />
                    </div>
                </div>
                <div class="grid">
                    <div class="col field">
                        <label
                            >{{ $t('labels.productHome.dangerousGoodsSmall') }}
                        </label>
                        <app-checkbox
                            v-model="v$.article_flag_akku_kleiner_100wh.$model"
                            binary
                            class="ml-2 mb-1"
                        />
                    </div>
                </div>
            </div>
            <div class="col-2"></div>
            <div class="col-5">
                <div style="height: 20%"></div>
                <div style="height: 80%">
                    <app-keyboard
                        @pressed="onKeyboardPressed"
                        :showEnterKey="true"
                    />
                </div>
            </div>
        </div>
        <div class="w-full flex justify-content-between">
            <router-link
                v-if="originalStoragePlace && originalPath"
                :to="{
                                    name: 'ProductsWithIncompleteDimensions',
                                    query: {storagePlace: originalStoragePlace, path: originalPath.join('|')}
                                }"
            >
                <p-button severity="danger" :disabled="savingInProgress">
                    {{ $t("buttons.dimensionsAcquisitionCancel") }}
                </p-button>
            </router-link>
            <router-link
                v-else
                :to="{
                                    name: 'ProductsWithIncompleteDimensions'
                                }"
            >
                <p-button severity="danger" :disabled="savingInProgress">
                    {{ $t("buttons.dimensionsAcquisitionCancel") }}
                </p-button>
            </router-link>
            <!--<p-button
                severity="danger"
                :disabled="savingInProgress"
                @click="handleCancel"
            >
                {{ $t("buttons.dimensionsAcquisitionCancel") }}
            </p-button>-->
            <p-button
                :disabled="savingInProgress"
                severity="success"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.dimensionsAcquisitionConfirm')"
                @click="handleSubmit(!v$.$invalid)"
            >
            </p-button>
        </div>
    </template>
</Panel>
