import {ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import LogList from '@/components/grid/import-wizard/logs/list-lazy.vue';
export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        LogList
    },
    props: {
        importId: Number,
        dialogTitle: String,
        displayLogsDialog: Boolean
    },
    setup(props: any) {
        const {importId, dialogTitle} = toRefs(props);
        const showDialog = ref(false);

        watch(props, (args) => {
            showDialog.value = args.displayLogsDialog;
        });

        return {
            importId,
            showDialog,
            dialogTitle
        };
    }
};
