import {ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import {getAll} from '@/services/warhouse-stock-movements';
import {useToast} from 'vue-toastification';
import LoadingPlugin from 'vue-loading-overlay';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

export default {
    components: {
        PrimeDialog: Dialog,
        loading: LoadingPlugin,
        DataTable,
        Column,
        'p-button': Button,
        InputText
    },
    emits: ['close-dialog'],
    props: {
        displayAddSerialNumberDialog: Boolean,
        rowIndex: Number,
        articleId: Number,
        warehouseStockMovementFilters: Object
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const loading = ref(false);
        const totalRecords = ref(0);
        const warehouseStockMovements = ref([]);
        const toast = useToast();
        const selectedItems = ref([]);
        const customSerialNumber = ref();
        const {warehouseStockMovementFilters, rowIndex, articleId} =
            toRefs(props);

        watch(props, async (args) => {
            if (
                args.displayAddSerialNumberDialog &&
                showDialog.value !== args.displayAddSerialNumberDialog
            ) {
                if (articleId.value) {
                    loadLazyData();
                }
            }
            showDialog.value = args.displayAddSerialNumberDialog;
        });

        const closeResponsive = () => {
            context.emit('close-dialog', {
                selectedItems: [],
                rowIndex: null
            });
        };

        const onConfirmButtonClicked = () => {
            showDialog.value = false;
            context.emit('close-dialog', {
                selectedItems: selectedItems.value,
                rowIndex: rowIndex.value
            });
            selectedItems.value = [];
        };

        const resetItems = () => {
            customSerialNumber.value = null;
            warehouseStockMovements.value = [];
            totalRecords.value = 0;
            selectedItems.value = [];
        };

        const loadLazyData = () => {
            loading.value = true;

            getAll({
                first: 0,
                rows: 20,
                filters: warehouseStockMovementFilters.value
            })
                .then((data) => {
                    totalRecords.value = data.data.total;
                    warehouseStockMovements.value = data.data.items;
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const addCustomSerialNumber = () => {
            if (!customSerialNumber.value) {
                return;
            }

            warehouseStockMovements.value.push({
                serialNumber: customSerialNumber.value
            });
            totalRecords.value += 1;
            selectedItems.value.push({
                serialNumber: customSerialNumber.value
            });

            customSerialNumber.value = null;
        };

        return {
            showDialog,
            closeResponsive,
            onConfirmButtonClicked,
            warehouseStockMovements,
            totalRecords,
            loading,
            selectedItems,
            resetItems,
            articleId,
            addCustomSerialNumber,
            customSerialNumber
        };
    }
};
