import {computed, onMounted, ref} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import {useVuelidate} from '@vuelidate/core';
import {useToast} from 'vue-toastification';
import {required} from '@vuelidate/validators';
import {
    getWeclappApiCredentials,
    updateWeclappApiCredentials
} from '@/services/auth';
import {i18n} from '@/utils/i18n';

export default {
    components: {
        InputText,
        'p-button': Button,
        PrimeDialog: Dialog
    },
    props: {
        productDetails: Object
    },
    setup() {
        onMounted(() => {
            loadLazyData();
        });

        const apiCredentials = ref(null);
        const dialogVisible = ref(false);
        const selectedPlatform = ref(null);

        const dialogHeader = computed(() => {
            return selectedPlatform.value?.label + ' - Weclapp';
        });

        const submitted = ref(false);
        const savingInProgress = ref(false);
        const toast = useToast();

        const state = ref({
            token: null
        });

        const rules = {
            token: {required}
        };

        const v$ = useVuelidate(rules, state);

        const loadLazyData = () => {
            getWeclappApiCredentials()
                .then((data) => {
                    apiCredentials.value = data.data;
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const handleDialogOpen = (
            event: any,
            platform: {value: string; label: string}
        ) => {
            submitted.value = false;
            selectedPlatform.value = platform;
            dialogVisible.value = true;
        };

        const onHideDialog = () => {
            selectedPlatform.value = null;
            Object.assign(state.value, {
                token: null
            });
            dialogVisible.value = false;
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            updateWeclappApiCredentials(
                selectedPlatform.value.value,
                state.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully')
                    );
                    apiCredentials.value = null;
                    onHideDialog();
                    loadLazyData();
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        return {
            apiCredentials,
            v$,
            submitted,
            savingInProgress,
            handleSubmit,
            dialogVisible,
            dialogHeader,
            handleDialogOpen,
            onHideDialog
        };
    }
};
