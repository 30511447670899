import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAll = (input: any): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/warehouse-stock-movements?start=' +
            (input.first || 0) +
            '&end=' +
            ((input.first || 0) + (input.rows || 10)) +
            '&sortField=' +
            (input.sortField || '') +
            '&sortOrder=' +
            ((input.sortOrder || 1) === 1 ? 'asc' : 'desc') +
            '&columns=' +
            JSON.stringify(input.columns || []) +
            '&filters=' +
            encodeURIComponent(
                JSON.stringify(clearEmptyFilters(input.filters || {}))
            ) +
            '&filterConjunction=' +
            (input.filterConjunction || 'and')
    );
};
