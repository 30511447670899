<div class="vuertual-numeric-keyboard rounded">
    <button
        v-for="key in keys"
        :key="key"
        @click="press(key)"
        class="btn shadow-none"
        :class="keyTheme"
    >
        {{ key }}
    </button>
    <button class="btn shadow-none" :class="keyTheme" @click="press(',')">
        ,
    </button>
    <button class="btn shadow-none" :class="keyTheme" @click="press(0)">
        0
    </button>
    <button class="btn shadow-none" :class="keyTheme" @click="clear()">
        &larr;
    </button>
    <template v-if="showEnterKey">
        <div></div>
        <button
            class="btn shadow-none"
            :class="keyTheme"
            @click="press('enter')"
        >
            &crarr;
        </button>
        <div></div>
    </template>
</div>
