<PrimeDialog
    :showHeader="false"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '75vw'}"
    :modal="true"
    position="top"
>
    <LoadingPlugin v-model:active="loading" />
    <TabView>
        <TabPanel v-if="isShopware5Instance && !isRemove">
            <template #header>
                <i class="mr-3 mb-1 pi pi-sitemap"></i>
                <span>{{ $t('labels.categories') }}</span>
            </template>
            <p-tree
                :value="categories"
                selectionMode="multiple"
                @nodeExpand="onNodeExpand"
                @nodeSelect="onNodeSelect"
                @nodeUnselect="onNodeUnselect"
                v-model:selectionKeys="selectedCategories"
                :metaKeySelection="false"
                class="text-xs"
            ></p-tree>
        </TabPanel>
        <TabPanel :disabled="!showSummaryTab">
            <template #header>
                <i
                    class="mr-3 mb-1"
                    :class="isRemove ? 'pi pi-circle-fill text-red-600' : 'pi pi-circle-fill text-green-600'"
                ></i>
                <span
                    >{{ $t('labels.setProducts' + (isRemove ? 'Offline' :
                    'Online')) }}</span
                >
            </template>
            <ScrollPanel style="height: 65vh" class="w-full">
                <Panel :header="$t('labels.mail.execution')" class="mb-3">
                    <div class="grid formgrid p-fluid">
                        <div class="field col">
                            <label class="ml-2"
                                >{{$t('labels.immediateExecution')}}</label
                            >
                            <p-checkbox
                                v-model="immediateExecution"
                                binary
                                class="ml-2 mb-1"
                            />
                        </div>
                        <div class="field col-9">
                            <template v-if="!immediateExecution">
                                <label>{{ $t('labels.at') }} </label>

                                <Calendar
                                    v-model="startTime"
                                    showTime
                                    dateFormat="dd.mm.yy"
                                    :minDate="new Date()"
                                />
                            </template>
                        </div>
                    </div>
                </Panel>

                <Panel :header="$t('labels.products')">
                    <h5 v-if="isFilterRelated">
                        {{ $t('messages.changesWillBeAppliedToProducts', {
                        number: productTotalRecords}) }}
                    </h5>
                    <ProductList v-else :products="products" />
                </Panel>
            </ScrollPanel>
            <div class="w-full flex justify-content-between mt-2">
                <p-button severity="danger" @click="closeResponsive(false)">
                    {{ $t("buttons.cancel") }}
                </p-button>
                <p-button
                    severity="success"
                    @click="onBulkProcessOnlineStatus"
                    v-text="$t('buttons.confirm')"
                >
                </p-button>
            </div>
        </TabPanel>
    </TabView>
</PrimeDialog>
