import {ref, watch} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import {useVuelidate} from '@vuelidate/core';
import AutoComplete from 'primevue/autocomplete';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import {updateBStockData} from '@/services/products';

export default {
    emits: ['reload-product'],
    components: {
        InputText,
        'p-button': Button,
        'p-dropdown': Dropdown,
        AutoComplete
    },
    props: {
        productDetails: Object
    },
    setup(props: any, context: any) {
        const submitted = ref(false);
        const productDetails = ref(null);
        const savingInProgress = ref(false);
        const toast = useToast();

        const state = ref({
            title: null,
            link: null,
            target: null
        });

        const rules = {
            title: {},
            link: {},
            target: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, () => {
            productDetails.value = props.productDetails;

            if (
                !productDetails.value ||
                !productDetails.value.shopware?.bStock
            ) {
                return;
            }

            Object.keys(productDetails.value.shopware.bStock).forEach(
                (item) => {
                    const temp: {[k: string]: string} = {};
                    temp[item] = productDetails.value.shopware.bStock[item];

                    Object.assign(state.value, temp);
                }
            );
        });

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;
            Object.assign(state.value, temp);
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            updateBStockData(
                productDetails.value.weclapp?.articleNumber ||
                    productDetails.value.shopware?.productNumber,
                state.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    context.emit('reload-product');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            productDetails,
            locale: i18n.global.locale,
            setDropdownValue
        };
    }
};
