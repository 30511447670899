import router from '@/router';
import {getAllForSingleProduct} from '@/services/product-changelog';
import {getSingle} from '@/services/products';
import {i18n} from '@/utils/i18n';
import {onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';
import {useToast} from 'vue-toastification';
import Timeline from 'primevue/timeline';
import Card from 'primevue/card';
import LoadingPlugin from 'vue-loading-overlay';
import {DateTime} from 'luxon';
import Panel from 'primevue/panel';

export default {
    components: {
        Timeline,
        Card,
        loading: LoadingPlugin,
        Panel
    },
    setup() {
        const route = useRoute();
        const toast = useToast();
        const loading = ref(false);
        const productDetails = ref();
        const events = ref([]);

        onMounted(() => {
            loadProductDetails();
        });

        const loadProductDetails = () => {
            loading.value = true;
            getSingle(route.params.id)
                .then((data) => {
                    if (data?.data?.weclapp) {
                        productDetails.value = data.data;
                        getAllForSingleProduct(route.params.id)
                            .then((changelog) => {
                                events.value = changelog.data.map(
                                    (changeLogItem: any) => {
                                        return Object.assign(changeLogItem, {
                                            timestamp: DateTime.fromSeconds(
                                                changeLogItem.timestamp
                                            )
                                                .setLocale(i18n.global.locale)
                                                .setZone(
                                                    process.env
                                                        ?.VUE_APP_DEFAULT_TIME_ZONE
                                                )
                                                .toLocaleString(
                                                    DateTime.DATETIME_MED_WITH_SECONDS
                                                )
                                        });
                                    }
                                );
                            })
                            .catch((error) => {
                                toast.error(
                                    error.response?.data?.error || error.message
                                );
                            })
                            .finally(() => {
                                loading.value = false;
                            });
                    } else {
                        toast.error(i18n.global.t('messages.noSuchProduct'));
                        router.push({
                            name: 'Products'
                        });
                        loading.value = false;
                    }
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.response?.data?.error || error.message);
                });
        };

        const getIconBasedOnHttpVerb = (childEntity: string | null): string => {
            switch (childEntity) {
                case 'accessory':
                    return 'pi pi-sitemap';
                case 'badge':
                    return 'pi pi-tags';
                case 'category':
                    return 'pi pi-sitemap';
                case 'image':
                    return 'pi pi-images';
                case 'video':
                    return 'pi pi-youtube';
                case 'link':
                    return 'pi pi-external-link';
                case 'document':
                    return 'pi pi-file-pdf';
                default:
                    return 'pi pi-tags';
            }
        };

        const getBackgroundColorBasedOnHttpVerb = (verb: string): string => {
            switch (verb) {
                case 'PUT':
                    return '#0069d9';
                case 'DELETE':
                    return '#c82333';
                default:
                    return '#28a745';
            }
        };

        const getTitleBasedOnChildEntity = (
            childEntity: string | null
        ): string => {
            switch (childEntity) {
                case 'accessory':
                    return i18n.global.t('labels.articleAccessories');
                case 'badge':
                    return i18n.global.t('labels.badges');
                case 'category':
                    return i18n.global.t('labels.categories');
                case 'image':
                    return i18n.global.t('labels.images');
                case 'video':
                    return i18n.global.t('labels.videos');
                case 'link':
                    return i18n.global.t('labels.links');
                case 'document':
                    return i18n.global.t('labels.documents');
                default:
                    return i18n.global.t('labels.products');
            }
        };

        const getContentBasedOnEventDetails = (event: {
            childEntityName: string | null;
            action: string;
            payload: string;
            displayName: string;
        }): string => {
            switch (event.childEntityName) {
                case 'accessory': {
                    const itemPayload = JSON.parse(event.payload);

                    return i18n.global.t(
                        'changelog.product.accessory.' +
                            event.action.toLowerCase(),
                        {
                            user: event.displayName,
                            count: Array.isArray(itemPayload)
                                ? itemPayload.length
                                : 1,
                            data: Array.isArray(itemPayload)
                                ? itemPayload
                                      .map((item) => {
                                          return typeof item === 'string'
                                              ? item
                                              : item.articleNumber;
                                      })
                                      .join(', ')
                                : itemPayload.newAccessory
                                ? itemPayload.existingAccessory.articleNumber +
                                  '->' +
                                  itemPayload.newAccessory.articleNumber
                                : itemPayload.existingAccessory.articleNumber
                        }
                    );
                }
                case 'badge':
                    return i18n.global.t(
                        'changelog.product.badge.' + event.action.toLowerCase(),
                        {
                            user: event.displayName,
                            count: JSON.parse(event.payload).length
                        }
                    );
                case 'category':
                    return i18n.global.t(
                        'changelog.product.category.' +
                            event.action.toLowerCase(),
                        {
                            user: event.displayName,
                            count: JSON.parse(event.payload).length
                        }
                    );
                case 'image': {
                    if ('PUT' === event.action) {
                        const itemPayload = JSON.parse(event.payload);

                        return i18n.global.t(
                            'changelog.product.image.' +
                                (itemPayload.imageIds ? 'reorder' : 'cover'),
                            {
                                user: event.displayName
                            }
                        );
                    }
                    return i18n.global.t(
                        'changelog.product.image.' + event.action.toLowerCase(),
                        {
                            user: event.displayName,
                            data:
                                'DELETE' === event.action
                                    ? JSON.parse(event.payload).fileName
                                    : event.payload
                        }
                    );
                }
                case 'video':
                    return i18n.global.t(
                        'changelog.product.video.' + event.action.toLowerCase(),
                        {
                            user: event.displayName,
                            data: event.payload
                        }
                    );
                case 'link': {
                    return i18n.global.t(
                        'changelog.product.link.' + event.action.toLowerCase(),
                        {
                            user: event.displayName,
                            data:
                                'DELETE' === event.action
                                    ? event.payload
                                    : JSON.parse(event.payload).title ||
                                      JSON.parse(event.payload).existing?.title
                        }
                    );
                }
                case 'document':
                    return i18n.global.t(
                        'changelog.product.link.' + event.action.toLowerCase(),
                        {
                            user: event.displayName,
                            data:
                                'PUT' === event.action
                                    ? JSON.parse(event.payload).oldTitle +
                                      '->' +
                                      JSON.parse(event.payload).newTitle
                                    : event.payload
                        }
                    );
                default: {
                    const itemPayload = JSON.parse(event.payload);
                    return i18n.global.t(
                        'changelog.product.' +
                            (itemPayload.description
                                ? 'description'
                                : 'basicInfo') +
                            '.' +
                            event.action.toLowerCase(),
                        {
                            user: event.displayName,
                            data: JSON.parse(event.payload)
                        }
                    );
                }
            }
        };

        return {
            productId: route.params.id,
            loading,
            productDetails,
            events,
            getIconBasedOnHttpVerb,
            getBackgroundColorBasedOnHttpVerb,
            getTitleBasedOnChildEntity,
            getContentBasedOnEventDetails
        };
    }
};
