<li :class="{'layout-root-menuitem': root, 'active-menuitem': isActiveMenu}">
    <div v-if="root" class="layout-menuitem-root-text">
        <span class="layout-menuitem-text"
            ><template v-if="item.name">{{ $t(item.name) }}</template></span
        >
    </div>
    <a
        v-if="(!item.path || item.children) && (typeof item.isVisible !== 'function' || item.isVisible(user, platform))"
        :href="item.url"
        @click="itemClick($event, item)"
        :class="item.class"
        :target="item.target"
        tabindex="0"
    >
        <font-awesome-icon
            v-if="item.iconClass"
            class="layout-menuitem-icon"
            :icon="item.iconClass"
        />
        <img
            v-else-if="item.iconPath"
            class="layout-menuitem-icon fas custom-menu-icon"
            :class="{'dark-icon': layoutConfig.darkTheme}"
            :src="require(`@/assets/img/${item.iconPath}`)"
        />
        <span class="layout-menuitem-text"
            ><template v-if="item.name">{{ $t(item.name) }}</template></span
        >
        <i
            class="pi pi-fw pi-angle-down layout-submenu-toggler"
            v-if="item.children"
        ></i>
    </a>
    <router-link
        v-if="item.path && !item.children && (typeof item.isVisible !== 'function' || item.isVisible(user, platform))"
        @click="itemClick($event, item)"
        :class="[item.class, {'active-route': checkActiveRoute(item)}]"
        tabindex="0"
        :to="item.path"
    >
        <font-awesome-icon
            v-if="item.iconClass"
            class="layout-menuitem-icon"
            :icon="item.iconClass"
        />
        <img
            v-else-if="item.iconPath"
            class="layout-menuitem-icon fas custom-menu-icon"
            :class="{'dark-icon': layoutConfig.darkTheme}"
            :src="require(`@/assets/img/${item.iconPath}`)"
        />
        <span class="layout-menuitem-text"
            ><template v-if="item.name">{{ $t(item.name) }}</template></span
        >
        <i
            class="pi pi-fw pi-angle-down layout-submenu-toggler"
            v-if="item.children"
        ></i>
    </router-link>
    <Transition
        v-if="item.children && (typeof item.isVisible !== 'function' || item.isVisible(user, platform))"
        name="layout-submenu"
    >
        <ul v-show="root ? true : isActiveMenu" class="layout-submenu">
            <app-menu-item
                v-for="(child, i) in item.children"
                :item="child"
                :parentItemKey="item.name"
                :root="false"
            ></app-menu-item>
        </ul>
    </Transition>
</li>
