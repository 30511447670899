import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55110044"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-2" }
const _hoisted_2 = { class: "formgrid grid p-fluid" }
const _hoisted_3 = { class: "field col" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "mt-3 flex justify-content-end" }

export function render(_ctx, _cache) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.productDetails?.core?.teltecProductNumber !== _ctx.productDetails?.weclapp?.articleNumber)
            ? (_openBlock(), _createElementBlock("label", _hoisted_4, _toDisplayString(_ctx.$t('labels.teltecProductNumber')), 1))
            : (_openBlock(), _createElementBlock("label", _hoisted_5, _toDisplayString(_ctx.$t('labels.videodataProductNumber')), 1)),
          _createElementVNode("div", null, [
            _createVNode(_component_AutoComplete, {
              modelValue: _ctx.v$.productNumber.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.productNumber.$model) = $event)),
              suggestions: _ctx.filteredProductSuggestions,
              onComplete: _cache[1] || (_cache[1] = $event => (_ctx.searchProducts($event))),
              dropdown: true,
              optionLabel: "label",
              forceSelection: "",
              class: _normalizeClass({'p-invalid':_ctx.v$.productNumber.$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "suggestions", "class"]),
            (_ctx.submitted)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.productNumber.$silentErrors, (error) => {
                    return (_openBlock(), _createElementBlock("small", {
                      style: {"display":"block"},
                      class: "p-error",
                      key: error
                    }, _toDisplayString(error.$message), 1))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_p_button, {
          severity: "success",
          disabled: _ctx.savingInProgress,
          type: "submit",
          textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm'))
        }, null, 8, ["disabled", "textContent"])
      ])
    ], 32)
  ]))
}