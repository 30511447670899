import {computed, onMounted, ref, toRefs, watch} from 'vue';
import TabPanel from 'primevue/tabpanel';
import LoadingPlugin from 'vue-loading-overlay';
import ProductList from '@/components/grid/products/list.vue';
import {useToast} from 'vue-toastification';
import Panel from 'primevue/panel';
import Column from 'primevue/column';
import {i18n} from '@/utils/i18n';
import Compliance from '@/pages/products/details/compliance/compliance.vue';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import {bulkUpdateCompliance} from '@/services/products';
import ToggleButton from 'primevue/togglebutton';
import {getSome} from '@/services/metadata';
import BulkProductAction from './bulk-product-action.vue';

export default {
    emits: ['close-dialog'],
    components: {
        TabPanel,
        ProductList,
        LoadingPlugin,
        Panel,
        Column,
        Compliance,
        TriStateCheckbox,
        ToggleButton,
        BulkProductAction
    },
    props: {
        products: Array,
        productFilters: Object,
        productTotalRecords: Number,
        displayDialog: Boolean,
        isFilterRelated: Boolean
    },
    setup(props: any, context: any) {
        const {products, productFilters, productTotalRecords} = toRefs(props);
        const showDialog = ref(false);
        const isFilterRelated = ref(false);
        const loading = ref(false);
        const complianceInput = ref(null);
        const bulkEditSwitches = ref(null);

        const toast = useToast();

        const weeeOptions = ref([]);
        const battgClassOptions = ref([]);

        onMounted(() => {
            getSome('customAttributeDefinition', 'attributeKey', [
                'article_weee_relevant',
                'article_batteriegesetz_class'
            ])
                .then((data) => {
                    weeeOptions.value =
                        data.data[
                            'article_weee_relevant'
                        ]?.selectableValues?.map(
                            (item: {id: string; value: string}) => {
                                return {
                                    label: item.value,
                                    value: item.id
                                };
                            }
                        ) || [];

                    battgClassOptions.value =
                        data.data[
                            'article_batteriegesetz_class'
                        ]?.selectableValues?.map(
                            (item: {id: string; value: string}) => {
                                return {
                                    label: item.value,
                                    value: item.id
                                };
                            }
                        ) || [];
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        });

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            isFilterRelated.value = args.isFilterRelated || false;
        });

        const closeResponsive = (forceResfresh: boolean = false) => {
            complianceInput.value = null;
            bulkEditSwitches.value = null;
            context.emit('close-dialog', forceResfresh);
        };

        const onFormDirty = (input: any, bulkEditSwitchesInput: any) => {
            complianceInput.value = input;
            bulkEditSwitches.value = bulkEditSwitchesInput;
        };

        const filteredComplianceInput = computed(() => {
            if (!complianceInput.value) {
                return null;
            }

            return Object.fromEntries(
                Object.entries(complianceInput.value).filter(
                    (item: Array<any>) => {
                        if (
                            [
                                'conformityAvailable',
                                'safetyInstructions',
                                'emc',
                                'atex',
                                'marketReady',
                                'testProtocol',
                                'red',
                                'lvd',
                                'rohs'
                            ].includes(item[0])
                        ) {
                            return item[1] !== null;
                        }

                        return bulkEditSwitches.value[item[0]] === true;
                    }
                )
            );
        });

        const summaryTabVisible = computed(() => {
            return (
                filteredComplianceInput.value &&
                Object.keys(filteredComplianceInput.value).length > 0
            );
        });

        const confirmClicked = (startTime: string | null = null) => {
            loading.value = true;

            bulkUpdateCompliance(
                products.value,
                productFilters.value,
                filteredComplianceInput.value,
                isFilterRelated.value,
                startTime
            )
                .then(() => {
                    toast.success(
                        i18n.global.t(
                            isFilterRelated.value || startTime
                                ? 'messages.cronJobExecutionScheduled'
                                : 'messages.changesSavedSuccessfully'
                        )
                    );
                    closeResponsive(true);
                    loading.value = false;
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    loading.value = false;
                });
        };

        return {
            products,
            productTotalRecords,
            isFilterRelated,
            showDialog,
            loading,
            closeResponsive,
            confirmClicked,
            summaryTabVisible,
            onFormDirty,
            filteredComplianceInput,
            locale: i18n.global.locale,
            weeeOptions,
            battgClassOptions
        };
    }
};
