<PrimeDialog
    :header="existingConfiguration ? $t('labels.editPrintConfiguration') : $t('labels.addPrintConfiguration')"
    v-model:visible="showDialog"
    :closable="false"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '640px'}"
    :modal="true"
>
    <loading v-model:active="savingInProgress" />
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="formgrid grid p-fluid mb-3">
            <div class="field col">
                <label> {{$t('labels.hardwareId')}} </label>
                <div>
                    <p-dropdown
                        v-model="v$.hardwareId.$model"
                        :options="osInstances || []"
                        optionLabel="name"
                        optionValue="name"
                        :disabled="existingConfiguration"
                        :filter="true"
                        @change="setDropdownValue('hardwareId', $event)"
                        :showClear="!existingConfiguration"
                        :class="{'p-invalid':v$.hardwareId.$invalid && submitted}"
                    >
                    </p-dropdown>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.hardwareId.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="field col">
                <label> {{$t('labels.event')}} </label>
                <div>
                    <p-dropdown
                        v-model="v$.event.$model"
                        :options="eventOptions || []"
                        optionLabel="label"
                        optionValue="id"
                        @change="setDropdownValue('event', $event)"
                        :showClear="!existingConfiguration"
                        :disabled="existingConfiguration"
                        :class="{'p-invalid':v$.event.$invalid && submitted}"
                    >
                    </p-dropdown>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.event.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
        </div>
        <div class="formgrid grid p-fluid mb-3">
            <div class="field col">
                <label> {{$t('labels.printerName')}} </label>
                <div>
                    <p-dropdown
                        v-model="v$.printer.$model"
                        :disabled="!v$.hardwareId.$model"
                        :options="printerOptions || []"
                        :filter="true"
                        optionLabel="id"
                        optionValue="id"
                        class="w-full"
                        @change="setDropdownValue('printer', $event)"
                        :showClear="true"
                        :class="{'p-invalid':v$.printer.$invalid && submitted}"
                    >
                    </p-dropdown>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.printer.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="field col">
                <label> {{$t('labels.printerSize')}} </label>
                <div>
                    <p-dropdown
                        v-model="v$.printerSize.$model"
                        :disabled="!v$.hardwareId.$model"
                        :options="printerSizeOptions || []"
                        optionLabel="id"
                        optionValue="id"
                        :filter="true"
                        class="w-full"
                        @change="setDropdownValue('printerSize', $event)"
                        :showClear="true"
                        :class="{'p-invalid':v$.printerSize.$invalid && submitted}"
                    >
                    </p-dropdown>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.printerSize.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
        </div>
        <div class="formgrid grid p-fluid mb-3">
            <div class="field col">
                <label> {{$t('labels.createdBy')}} </label>
                <div>
                    <p-multiselect
                        class="text-sm w-full"
                        filter
                        v-model="v$.author.$model"
                        :options="userOptions"
                        optionLabel="name"
                        optionValue="username"
                    >
                    </p-multiselect>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.author.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="field col">
                <label> {{$t('labels.entityName')}} </label>
                <p-input
                    disabled
                    autocomplete="off"
                    v-model="v$.entityName.$model"
                    :class="{'p-invalid':v$.entityName.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.entityName.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="formgrid grid p-fluid mb-3">
            <div class="field field col">
                <label> {{$t('labels.attributeName')}} </label>
                <div>
                    <p-dropdown
                        v-model="v$.attributeName.$model"
                        :disabled="existingConfiguration || !v$.event.$model"
                        :options="attributeNameOptions || []"
                        :filter="true"
                        optionLabel="attributeKey"
                        optionValue="attributeKey"
                        class="w-full"
                        @change="setDropdownValue('attributeName', $event)"
                        :showClear="!existingConfiguration"
                        :class="{'p-invalid':v$.attributeName.$invalid && submitted}"
                    >
                    </p-dropdown>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.attributeName.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="field col">
                <label> {{$t('labels.attributeValue')}} </label>
                <p-input
                    :disabled="!v$.event.$model"
                    autocomplete="off"
                    v-model="v$.attributeValue.$model"
                    :class="{'p-invalid':v$.attributeValue.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.attributeValue.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="w-full flex justify-content-between">
            <p-button severity="danger" @click="onCancelClick">
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button type="submit" severity="success">
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </form>
</PrimeDialog>
