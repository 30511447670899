import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51bcb710"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-3 formgrid grid p-fluid align-items-end" }
const _hoisted_2 = {
  key: 0,
  class: "field col-1"
}
const _hoisted_3 = { style: {"text-align":"center"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 1,
  class: "mt-3"
}
const _hoisted_7 = { class: "flex justify-content-end" }
const _hoisted_8 = {
  key: 2,
  style: {"margin-top":"100px"}
}
const _hoisted_9 = {
  key: 3,
  style: {"margin-top":"100px"}
}
const _hoisted_10 = { class: "mt-5" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 0,
  class: "mt-2 p-fluid"
}
const _hoisted_16 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_TreeSelect = _resolveComponent("TreeSelect")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_p_button = _resolveComponent("p-button")
  const _component_header_mapping_grid = _resolveComponent("header-mapping-grid")
  const _component_validation_grid = _resolveComponent("validation-grid")
  const _component_Panel = _resolveComponent("Panel")
  const _component_InputText = _resolveComponent("InputText")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.isLoading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.isLoading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.importWizard')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["field", {'col-11':_ctx.identifier !== null, 'col-12': _ctx.identifier === null}])
          }, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t("labels.chooseWizardWorkflow")), 1),
            _createVNode(_component_TreeSelect, {
              modelValue: _ctx.identifier,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.identifier) = $event)),
              options: _ctx.importWizardOptions,
              selectionMode: "single",
              placeholder: _ctx.$t('labels.none'),
              onChange: _cache[2] || (_cache[2] = $event => (_ctx.onChange($event)))
            }, null, 8, ["modelValue", "options", "placeholder"])
          ], 2),
          (_ctx.identifier !== null)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_font_awesome_icon, {
                  style: {"font-size":"2.5rem","margin-bottom":"4px"},
                  icon: ['fas', 'file-csv'],
                  onClick: _ctx.downloadFileTemplate
                }, null, 8, ["onClick"])
              ])), [
                [
                  _directive_tooltip,
                  _ctx.$t('labels.downloadImportFileTemplate'),
                  void 0,
                  { right: true }
                ]
              ])
            : _createCommentVNode("", true)
        ]),
        (_ctx.identifier !== null)
          ? (_openBlock(), _createElementBlock("div", _mergeProps({ key: 0 }, _ctx.dropzone.getRootProps(), { class: "import-wizard-container" }), [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("input", _normalizeProps(_guardReactiveProps(_ctx.dropzone.getInputProps())), null, 16),
                (_ctx.dropzone.acceptedFiles.length > 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.dropzone.acceptedFiles[0].name), 1))
                  : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t("labels.importWizardInfoText")), 1))
              ])
            ], 16))
          : _createCommentVNode("", true),
        (_ctx.showUploadButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_p_button, { onClick: _ctx.uploadFile }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.uploadFile")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.savedFileId !== null && _ctx.rowsToSkip.length < 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_header_mapping_grid, {
                savedFileId: _ctx.savedFileId,
                onColumnMappingSaved: _cache[3] || (_cache[3] = $event => (_ctx.validateFile()))
              }, null, 8, ["savedFileId"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.rowsToSkip.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_validation_grid, {
                savedFileId: _ctx.savedFileId,
                rowsToSkip: _ctx.rowsToSkip,
                onImportResultReceived: _ctx.importResultReceived
              }, null, 8, ["savedFileId", "rowsToSkip", "onImportResultReceived"])
            ]))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_10, [
          _createElementVNode("span", {
            class: "font-medium",
            ref: "fileScheduledForImportTextPanel",
            innerHTML: _ctx.$t('messages.fileScheduledForImportLong')
          }, null, 8, _hoisted_11),
          _createElementVNode("i", {
            class: "pi pi-times-circle mt-1 ml-1 text-red-600",
            style: {"cursor":"pointer"},
            onClick: _cache[4] || (_cache[4] = $event => {_ctx.importResult = false;})
          })
        ], 512), [
          [_vShow, _ctx.importResult]
        ])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_PrimeDialog, {
      header: _ctx.$t('messages.pleaseConfirm'),
      visible: _ctx.showPlatformConfirmationDialog,
      "onUpdate:visible": _cache[7] || (_cache[7] = $event => ((_ctx.showPlatformConfirmationDialog) = $event)),
      breakpoints: {'960px': '75vw'},
      style: {width: '50vw'},
      closable: false,
      modal: true,
      onShow: _ctx.onShowConfirmationDialog
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", null, [
            (_ctx.isConfimationInputNeeded)
              ? (_openBlock(), _createElementBlock("h6", {
                  key: 0,
                  innerHTML: _ctx.$t('messages.importFileToPlatformConfirmationWithField', {
                    platform: _ctx.importPlatform?.label, 
                    confirmationValue: _ctx.importPlatform?.threeLetterId  })
                }, null, 8, _hoisted_13))
              : (_openBlock(), _createElementBlock("h6", {
                  key: 1,
                  innerHTML: _ctx.$t('messages.importFileToPlatformConfirmation', {
                    platform: _ctx.importPlatform?.label })
                }, null, 8, _hoisted_14))
          ]),
          (_ctx.isConfimationInputNeeded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(_component_InputText, {
                  placeholder: _ctx.$t('labels.targetPlatform'),
                  autocomplete: "off",
                  modelValue: _ctx.confirmationInputValue,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.confirmationInputValue) = $event)),
                  ref: "confirmationInputField"
                }, null, 8, ["placeholder", "modelValue"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_p_button, {
              severity: "danger",
              onClick: _ctx.closePlatformConfirmationDialog
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_p_button, {
              severity: "success",
              onClick: _cache[6] || (_cache[6] = $event => (_ctx.closePlatformConfirmationDialog($event, true))),
              disabled: !_ctx.confirmButtonEnabled
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["header", "visible", "onShow"])
  ], 64))
}