import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "ml-3" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "ml-3" }
const _hoisted_8 = { class: "ml-3" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "ml-3" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Column = _resolveComponent("Column")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_p_divider = _resolveComponent("p-divider")
  const _component_DataTable = _resolveComponent("DataTable")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DataTable, {
        class: "p-datatable-sm text-sm",
        rowClass: _ctx.rowClass,
        stripedRows: "",
        paginator: true,
        rows: 10,
        lazy: true,
        dataKey: "id",
        ref: "dt",
        totalRecords: _ctx.totalRecords,
        value: _ctx.logs,
        filters: _ctx.filters,
        "onUpdate:filters": _cache[1] || (_cache[1] = $event => ((_ctx.filters) = $event)),
        filterDisplay: "row",
        onPage: _cache[2] || (_cache[2] = $event => (_ctx.onPage($event))),
        onFilter: _cache[3] || (_cache[3] = $event => (_ctx.onFilter($event))),
        onSort: _cache[4] || (_cache[4] = $event => (_ctx.onSort($event))),
        responsiveLayout: "scroll",
        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
        "rows-per-page-options": [10,20,50],
        "current-page-report-template": "{first} to {last} of {totalRecords}",
        expandedRows: _ctx.expandedRows,
        "onUpdate:expandedRows": _cache[5] || (_cache[5] = $event => ((_ctx.expandedRows) = $event))
      }, {
        expansion: _withCtx((slotProps) => [
          (!slotProps.data?.error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.toAddress')), 1),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(slotProps.data?.emailToAddress), 1)
                ]),
                (slotProps.data?.emailCcAddress)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.ccAddress')), 1),
                      _createElementVNode("span", _hoisted_7, _toDisplayString(slotProps.data?.emailCcAddress), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.subject')), 1),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(slotProps.data?.emailSubject), 1)
                ]),
                (slotProps.data?.emailAttachment)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.attachment')), 1),
                      _createElementVNode("span", _hoisted_10, _toDisplayString(slotProps.data?.emailAttachment), 1),
                      (slotProps.data?.hasAttachmentContent)
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            style: {"cursor":"pointer"},
                            class: "pi pi-download text-sm ml-2",
                            onClick: $event => (_ctx.downloadAttachment(slotProps.data.id))
                          }, null, 8, _hoisted_11))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_p_divider),
                _createElementVNode("div", {
                  innerHTML: slotProps.data?.emailBody
                }, null, 8, _hoisted_12)
              ]))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            expander: true,
            headerStyle: "width: 3em"
          }),
          (_ctx.entityColumnHeader)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 0,
                style: {"min-width":"4rem"},
                header: _ctx.entityColumnHeader,
                field: "entityInstanceFriendlyId",
                sortable: true
              }, {
                filter: _withCtx(({filterModel,filterCallback}) => [
                  _createVNode(_component_InputText, {
                    type: "text",
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                    onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                    class: "p-column-filter text-sm",
                    placeholder: "#"
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
                ]),
                body: _withCtx(({data}) => [
                  _createElementVNode("div", {
                    innerHTML: _ctx.getEntityColumnContent(data)
                  }, null, 8, _hoisted_2)
                ]),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Column, {
            field: "emailToAddress",
            header: _ctx.$t('labels.mail.toAddress'),
            sortable: true
          }, {
            filter: _withCtx(({filterModel,filterCallback}) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: filterModel.value,
                "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                class: "p-column-filter text-sm",
                placeholder: _ctx.$t('labels.mail.toAddress')
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "emailCcAddress",
            header: _ctx.$t('labels.mail.ccAddress'),
            sortable: true
          }, {
            filter: _withCtx(({filterModel,filterCallback}) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: filterModel.value,
                "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                class: "p-column-filter text-sm",
                placeholder: _ctx.$t('labels.mail.ccAddress')
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "emailAttachment",
            header: _ctx.$t('labels.mail.attachment'),
            sortable: true
          }, {
            filter: _withCtx(({filterModel,filterCallback}) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: filterModel.value,
                "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                class: "p-column-filter text-sm",
                placeholder: _ctx.$t('labels.mail.attachment')
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
            ]),
            body: _withCtx(({data}) => [
              _createTextVNode(_toDisplayString(data.emailAttachment) + " ", 1),
              (data?.hasAttachmentContent)
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    style: {"cursor":"pointer"},
                    class: "pi pi-download text-sm ml-2",
                    onClick: $event => (_ctx.downloadAttachment(data.id))
                  }, null, 8, _hoisted_3))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "createdAt",
            header: _ctx.$t('labels.logTime'),
            sortable: true,
            dataType: "date"
          }, {
            filter: _withCtx(({filterModel}) => [
              _createVNode(_component_Calendar, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                dateFormat: "mm/dd/yy",
                placeholder: "mm/dd/yyyy"
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            body: _withCtx(({data}) => [
              _createTextVNode(_toDisplayString(data.createdAtFormatted), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "error",
            dataType: "boolean",
            style: {"min-width":"16rem"},
            header: _ctx.$t('labels.error')
          }, {
            filter: _withCtx(({filterModel, filterCallback}) => [
              _createVNode(_component_TriStateCheckbox, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                onChange: $event => (filterCallback())
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["rowClass", "totalRecords", "value", "filters", "expandedRows"])
    ])
  ], 64))
}