import {ILayoutConfig} from '@/interfaces/layout/config';

export default {
    changeLayout: (context: any, payload: ILayoutConfig): void => {
        const currentState = context.getters['layoutConfig'];
        localStorage.setItem(
            'layoutConfig',
            JSON.stringify(Object.assign(currentState, payload))
        );

        context.commit('changeLayout', payload);
    },

    addBookmark: (
        context: any,
        payload: {
            id: string;
            entityId: string;
            entityName: string;
            path: string;
        }
    ): void => {
        const currentState = context.getters['bookmarks'];
        const existingItemIndex = currentState.findIndex((item: any) => {
            return item.id === payload.id;
        });

        if (existingItemIndex === -1) {
            currentState.push(payload);
        }
        context.commit('setBookmarks', currentState);
    },
    deleteBookmark: (
        context: any,
        payload: {
            id: string;
        }
    ): void => {
        const currentState = context.getters['bookmarks'];
        context.commit(
            'setBookmarks',
            currentState.filter((item: any) => item.id !== payload.id)
        );
    },
    getBookmarks: (
        context: any
    ): Array<{
        id: string;
        entityId: string;
        entityName: string;
        path: string;
    }> => context.getters['bookmarks']
};
