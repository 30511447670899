import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e232721"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _mergeProps(_ctx.getRootProps(), { class: "import-wizard-container" }), [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", _normalizeProps(_guardReactiveProps(_ctx.getInputProps())), null, 16),
      (_ctx.acceptedFiles.length > 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.acceptedFiles.map(u => u.name).join(', ')), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t("labels.importWizardInfoText")), 1))
    ])
  ], 16))
}