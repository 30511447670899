export default {
    remote: (state: {
        remote: Array<{
            platform: string;
            hardwareId: string;
            printer: string;
            printerSize: string;
        }>;
    }): Array<{
        platform: string;
        hardwareId: string;
        printer: string;
        printerSize: string;
    }> => {
        return JSON.parse(
            localStorage.getItem('remotePrinter') ||
                JSON.stringify(state.remote || [])
        );
    }
};
