import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cafd4d88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-content-end" }
const _hoisted_2 = { class: "mr-3" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_Column = _resolveComponent("Column")
  const _component_TreeSelect = _resolveComponent("TreeSelect")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_ProgressBar = _resolveComponent("ProgressBar")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _component_ImportResultOverview = _resolveComponent("ImportResultOverview")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.importLog')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("labels.allEnvironments")), 1),
          _createElementVNode("div", null, [
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.allEnvs,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.allEnvs) = $event)),
              onInput: _ctx.onAllEnvsSwitch
            }, null, 8, ["modelValue", "onInput"])
          ])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_DataTable, {
            class: "p-datatable-sm text-sm",
            stripedRows: "",
            paginator: "",
            rows: 20,
            scrollable: "",
            scrollHeight: "calc(100vh - 25rem)",
            lazy: "",
            ref: "dt",
            dataKey: "id",
            totalRecords: _ctx.totalRecords,
            value: _ctx.logs,
            responsiveLayout: "scroll",
            "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
            "rows-per-page-options": [10,20,50],
            "current-page-report-template": "{first} to {last} of {totalRecords}",
            filters: _ctx.filters,
            "onUpdate:filters": _cache[2] || (_cache[2] = $event => ((_ctx.filters) = $event)),
            filterDisplay: "row",
            onPage: _cache[3] || (_cache[3] = $event => (_ctx.onPage($event))),
            onFilter: _cache[4] || (_cache[4] = $event => (_ctx.onFilter($event))),
            onSort: _cache[5] || (_cache[5] = $event => (_ctx.onSort($event))),
            expandedRows: _ctx.expandedRows,
            "onUpdate:expandedRows": _cache[6] || (_cache[6] = $event => ((_ctx.expandedRows) = $event)),
            stateStorage: "local",
            stateKey: "log-list-state-session",
            onStateRestore: _ctx.onStateRestore
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "importPlatform",
                header: _ctx.$t('labels.environment'),
                sortable: true,
                style: {"min-width":"8rem"}
              }, {
                body: _withCtx(({data}) => [
                  _createElementVNode("div", {
                    innerHTML: _ctx.getPlatformCellContent(data.importPlatform)
                  }, null, 8, _hoisted_3)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "importTargetSystem",
                sortable: false,
                style: {"min-width":"6rem"}
              }, {
                body: _withCtx(({data}) => [
                  _createElementVNode("div", {
                    innerHTML: _ctx.getPlatformCellContent(data.importTargetSystem)
                  }, null, 8, _hoisted_4)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "subjectWorkflowCombo",
                style: {"min-width":"6rem"},
                header: _ctx.$t('labels.wizardWorkflow'),
                sortable: true,
                filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
              }, {
                body: _withCtx(({data}) => [
                  _createElementVNode("div", null, [
                    (_ctx.getWorkflowCellContent(data.subjectWorkflowCombo))
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: _normalizeClass(_ctx.getWorkflowCellTextColor(data.subjectWorkflowCombo))
                        }, _toDisplayString(_ctx.$t(_ctx.getWorkflowCellContent(data.subjectWorkflowCombo))), 3))
                      : _createCommentVNode("", true)
                  ])
                ]),
                filter: _withCtx(({filterModel, filterCallback}) => [
                  _createVNode(_component_TreeSelect, {
                    class: "w-full",
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                    onChange: $event => (filterCallback()),
                    options: _ctx.importWizardOptions,
                    selectionMode: "single",
                    placeholder: "Any"
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options"])
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "fileName",
                sortable: true,
                style: {"min-width":"10rem"},
                header: _ctx.$t('labels.filename')
              }, {
                filter: _withCtx(({filterModel,filterCallback}) => [
                  _createVNode(_component_InputText, {
                    type: "text",
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                    onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                    class: "p-column-filter text-sm",
                    placeholder: _ctx.$t('labels.filename')
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "status",
                header: _ctx.$t('labels.status'),
                sortable: true,
                style: {"min-width":"6rem"},
                filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
              }, {
                body: _withCtx(({data}) => [
                  (_ctx.getStatusCellContent(data))
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(_ctx.getStatusCellClass(data))
                      }, _toDisplayString(_ctx.$t(_ctx.getStatusCellContent(data))), 3))
                    : _createCommentVNode("", true)
                ]),
                filter: _withCtx(({filterModel, filterCallback}) => [
                  _createVNode(_component_Dropdown, {
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                    options: [{label: _ctx.$t('labels.aborted'), value: 'aborted', color: 'red'}, {label: _ctx.$t('labels.scheduled'), value: 'scheduled', color: 'orange'}, {label: _ctx.$t('labels.inProgress'), value: 'inProgress', color: 'lightgreen'}, {label: _ctx.$t('labels.done'), value: 'done', color: 'darkgreen'}],
                    placeholder: "Any",
                    optionLabel: "label",
                    optionValue: "value",
                    onChange: $event => (filterCallback()),
                    class: "p-column-filter text-sm"
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('labels.progress'),
                style: {"min-width":"12rem"}
              }, {
                body: _withCtx(({data}) => [
                  _createVNode(_component_ProgressBar, {
                    style: {"height":"1.3em"},
                    value: Math.floor((data.processedRecords * 100) / data.validRecords)
                  }, null, 8, ["value"])
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('labels.result'),
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({data}) => [
                  data
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _withDirectives((_openBlock(), _createElementBlock("span", null, [
                          _createTextVNode(_toDisplayString(data.validRecords + data.rowsToSkipNumber) + " / " + _toDisplayString(data.rowsToSkipNumber) + " / " + _toDisplayString(data.processedRecords) + " / " + _toDisplayString(data.importedRecords), 1)
                        ])), [
                          [
                            _directive_tooltip,
                            _ctx.$t('labels.importLogsResultHeaderTooltip'),
                            void 0,
                            { top: true }
                          ]
                        ]),
                        (data?.processEndedAt)
                          ? _withDirectives((_openBlock(), _createElementBlock("i", {
                              key: 0,
                              class: "pi pi-book text-sm ml-2",
                              onClick: $event => (_ctx.handleLogsClick($event, data))
                            }, null, 8, _hoisted_6)), [
                              [
                                _directive_tooltip,
                                _ctx.$t('labels.logs'),
                                void 0,
                                { top: true }
                              ]
                            ])
                          : _createCommentVNode("", true),
                        (data?.processEndedAt)
                          ? _withDirectives((_openBlock(), _createElementBlock("i", {
                              key: 1,
                              class: "pi pi-download text-sm ml-2",
                              onClick: $event => (_ctx.downloadResultFile($event, data))
                            }, null, 8, _hoisted_7)), [
                              [
                                _directive_tooltip,
                                _ctx.$t('labels.resultFileExport'),
                                void 0,
                                { top: true }
                              ]
                            ])
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                style: {"min-width":"8rem"},
                field: "importedAt",
                header: _ctx.$t('labels.importedAt'),
                sortable: true
              }, {
                body: _withCtx(({data}) => [
                  _createTextVNode(_toDisplayString(data.importedAtFormatted), 1)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                style: {"min-width":"8rem"},
                field: "uploadedBy",
                header: _ctx.$t('labels.uploadedBy'),
                sortable: true
              }, {
                filter: _withCtx(({filterModel,filterCallback}) => [
                  _createVNode(_component_InputText, {
                    type: "text",
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                    onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                    class: "p-column-filter text-sm",
                    placeholder: _ctx.$t('labels.email')
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                ]),
                _: 1
              }, 8, ["header"])
            ]),
            _: 1
          }, 8, ["totalRecords", "value", "filters", "expandedRows", "onStateRestore"])
        ])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_ImportResultOverview, {
      "import-id": _ctx.selectedImportRecord?.id,
      "dialog-title": _ctx.dialogTitle,
      "display-logs-dialog": _ctx.displayLogsDialog,
      onHide: _ctx.hideLogsDialog
    }, null, 8, ["import-id", "dialog-title", "display-logs-dialog", "onHide"])
  ], 64))
}