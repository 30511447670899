import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAll = (
    input: any,
    jobType: string = 'regular'
): Promise<AxiosResponse> => {
    const filters = Object.assign(
        {type: {value: jobType, matchMode: 'equals'}},
        clearEmptyFilters(input.filters)
    );

    return apiClient.get(
        '/api/v1/jobs/crons?start=' +
            input.first +
            '&end=' +
            (input.first + input.rows) +
            '&sortField=' +
            (input.sortField ?? '') +
            '&sortOrder=' +
            (input.sortOrder === 1 ? 'asc' : 'desc') +
            '&filters=' +
            JSON.stringify(filters)
    );
};

export const getJobLogs = (id: number, input: any): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/jobs/crons/' +
            id +
            '/logs?start=' +
            input.first +
            '&end=' +
            (input.first + input.rows) +
            '&sortField=' +
            (input.sortField ?? '') +
            '&sortOrder=' +
            (input.sortOrder === 1 ? 'asc' : 'desc') +
            '&filters=' +
            JSON.stringify(clearEmptyFilters(input.filters))
    );
};

export const getJobMailLogs = (
    id: number,
    input: any
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/jobs/crons/' +
            id +
            '/mail-logs?start=' +
            input.first +
            '&end=' +
            (input.first + input.rows) +
            '&sortField=' +
            (input.sortField ?? '') +
            '&sortOrder=' +
            (input.sortOrder === 1 ? 'asc' : 'desc') +
            '&filters=' +
            JSON.stringify(clearEmptyFilters(input.filters))
    );
};

export const scheduleSingle = (
    id: number,
    recipientAddress: string
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/jobs/crons/' + id + '/schedule',
        JSON.stringify({recipientAddress})
    );
};

export const createNew = (
    name: string,
    description?: string,
    payload?: any
): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/jobs/crons', {
        name,
        description,
        payload
    });
};

export const updateSingle = (
    id: number,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/jobs/crons/' + id, JSON.stringify(payload));
};

export const getLogResultFile = (
    cronId: number,
    cronLogId: number,
    isError: boolean = false
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/jobs/crons/' +
            cronId +
            '/logs/' +
            cronLogId +
            '/result-file' +
            (isError ? '?error=true' : ''),
        {
            responseType: 'blob'
        }
    );
};

export const getMailLogResultFile = (
    cronId: number,
    mailLogId: number
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/jobs/crons/' +
            cronId +
            '/mail-logs/' +
            mailLogId +
            '/result-file',
        {
            responseType: 'blob'
        }
    );
};
