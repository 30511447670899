import {getRedirectUrl, loginByAuth} from '@/services/auth';
import Button from 'primevue/button';
import {useToast} from 'vue-toastification';
import {computed, onMounted, onUnmounted, ref} from 'vue';
import store from '@/store';
import {ILayoutConfig} from '@/interfaces/layout/config';

export default {
    name: 'login',
    components: {
        'p-button': Button
    },

    setup() {
        const appElement = ref(null);
        const isAuthLoading = ref(false);
        const toast = useToast();

        onMounted(async () => {
            if (azureState.value) {
                try {
                    const token = await loginByAuth(azureState.value);
                    store.dispatch('auth/login', token);
                } catch (error: any) {
                    appElement.value = document.getElementById(
                        'app'
                    ) as HTMLElement;
                    appElement.value.classList.add('login-page');
                } finally {
                    localStorage.removeItem('azureState');
                }
            } else {
                appElement.value = document.getElementById(
                    'app'
                ) as HTMLElement;
                appElement.value.classList.add('login-page');
            }
        });

        onUnmounted(() => {
            if (appElement.value) {
                (appElement.value as HTMLElement).classList.remove(
                    'login-page'
                );
            }
        });

        const azureState = computed(() => {
            return localStorage.getItem('azureState');
        });

        const fetchRedirectData = async () => {
            try {
                isAuthLoading.value = true;
                const redirectData = await getRedirectUrl();
                store.dispatch('auth/redirectToExternalAuth', redirectData);
            } catch (error: any) {
                toast.error(error.message);
                isAuthLoading.value = false;
            }
        };

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        const logoUrl = computed(() => {
            return layoutConfig.value.darkTheme
                ? 'logo-group-white'
                : 'logo-group';
        });

        return {
            azureState,
            appElement,
            isAuthLoading,
            fetchRedirectData,
            logoUrl
        };
    }
};
