<BulkProductAction
    @close-dialog="closeResponsive"
    @confirm-clicked="confirmClicked"
    :products="products"
    :productTotalRecords="productTotalRecords"
    :displayDialog="showDialog"
    :isFilterRelated="isFilterRelated"
    :summaryTabVisible="summaryTabVisible"
    :loading="loading"
    :dialogHeader="$t('labels.editPromotions')"
    canPostponeExecution
>
    <template #tab-panel-middle>
        <TabPanel :header="$t('labels.promotions')">
            <Promotions @form-dirty="onFormDirty" isBulkEdit></Promotions>
        </TabPanel>
    </template>
    <template #summary-panel-header="slotProps">
        <Panel class="mb-3">
            <template #header>
                <div class="font-weight-bold">
                    {{ $t('labels.adjustments') }}
                </div>
            </template>
            <template v-if="filteredPromotionsInput">
                <div
                    v-for="item in Object.entries(filteredPromotionsInput)"
                    class="grid p-fluid"
                >
                    <div class="col field">
                        <label
                            v-if="['internalNote','internalNoteSupplySource'].indexOf(item[0]) !== -1"
                            >{{ $t('labels.productDescription.' + item[0])
                            }}</label
                        >
                        <label v-else
                            >{{ $t('labels.productPromotions.' + item[0])
                            }}</label
                        >

                        <template v-if="item[1] === true || item[1] === false">
                            <TriStateCheckbox
                                v-model="item[1]"
                            ></TriStateCheckbox>
                        </template>
                        <template v-else-if="item[1] instanceof Date"
                            >{{ item[1].toLocaleDateString(locale) ||
                            $t('messages.valueWillBeSetToNull').toUpperCase()
                            }}</template
                        >
                        <template v-else
                            >{{item[1] ||
                            $t('messages.valueWillBeSetToNull').toUpperCase()
                            }}</template
                        >
                    </div>
                </div>
            </template>
        </Panel>
    </template>
    <template #summary-panel-middle>
        <h1 style="text-align: center">
            <font-awesome-icon
                style="margin-left: 30px"
                :icon="['fas', 'angle-double-down']"
            /><font-awesome-icon
                style="margin-left: 30px"
                :icon="['fas', 'angle-double-down']"
            /><font-awesome-icon
                style="margin-left: 30px"
                :icon="['fas', 'angle-double-down']"
            />
        </h1>
    </template>
</BulkProductAction>
