import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAll = (input: any): Promise<AxiosResponse> => {
    const rows = input.rows || 10;
    const params = Object.assign(
        {},
        {
            allEnvs:
                typeof input.allEnvs !== 'undefined' ? input.allEnvs : false,
            ownOnly: input.ownOnly ?? false,
            start: input.first || 0,
            end: (input.first || 0) + rows,
            sortField: input.sortField || '',
            sortOrder: (input.sortOrder || 1) === 1 ? 'asc' : 'desc',
            columns: JSON.stringify(input.columns || []),
            filters: encodeURIComponent(
                JSON.stringify(clearEmptyFilters(input.filters || {}))
            ),
            filterConjunction: input.filterConjunction || 'and'
        }
    );

    const queryString = Object.keys(params)
        .map((key) => key + '=' + params[key as keyof typeof params])
        .join('&');

    return apiClient.get('/api/v1/imports?=' + queryString);
};

export const getSingle = (id: number, input: any): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/imports/' +
            id +
            '/logs' +
            '?start=' +
            input.first +
            '&end=' +
            (input.first + input.rows) +
            '&filters=' +
            JSON.stringify(clearEmptyFilters(input.filters))
    );
};

export const getResultFile = (id: number): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/imports/' + id + '/logs-export', {
        responseType: 'blob'
    });
};
