<div class="m-2">
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="formgrid grid p-fluid mt-3 mb-3">
            <div class="col field">
                <label>{{ $t('labels.bStock.title') }} </label>

                <p-dropdown
                    v-model="v$.title.$model"
                    :options="['Auch als Demo erhältlich', 'Auch als OpenBox erhältlich', 'Auch als Used erhältlich']"
                    showClear
                    @change="setDropdownValue('title', $event)"
                />
            </div>

            <div class="field col">
                <label>{{ $t('labels.bStock.link') }} </label>
                <InputText v-model="v$.link.$model" />
            </div>

            <div class="field col">
                <label>{{ $t('labels.bStock.target') }} </label>
                <p-dropdown
                    v-model="v$.target.$model"
                    :options="[{value:'1',label:'_blank'},{value:'2',label:'_parent'}]"
                    optionLabel="label"
                    optionValue="value"
                    showClear
                    @change="setDropdownValue('target', $event)"
                />
            </div>
        </div>
        <div class="flex justify-content-end mt-3">
            <p-button
                :disabled="savingInProgress"
                severity="success"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
                type="submit"
            >
            </p-button>
        </div>
    </form>
</div>
