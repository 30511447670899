import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02202fd9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid p-fluid" }
const _hoisted_2 = { class: "col field" }
const _hoisted_3 = { class: "grid p-fluid" }
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = { class: "grid p-fluid" }
const _hoisted_7 = { class: "col field" }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = { class: "w-full flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.ticket.addNewQuotation', {ticketNumber: _ctx.ticketDetails?.ticketNumber, ticketSubject: _ctx.truncatedSubject}),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[5] || (_cache[5] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '65vw'},
    modal: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_loading, {
        active: _ctx.savingInProgress,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.savingInProgress) = $event))
      }, null, 8, ["active"]),
      _createElementVNode("form", {
        onSubmit: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.retoure.customer')), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.ticketCustomer), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.retoure.warehouse')), 1),
            _createVNode(_component_p_dropdown, {
              filter: true,
              modelValue: _ctx.v$.warehouseId.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.warehouseId.$model) = $event)),
              options: _ctx.warehouseOptions,
              optionLabel: "label",
              optionValue: "value",
              onChange: _cache[2] || (_cache[2] = $event => (_ctx.setDropdownValue('warehouseId', $event))),
              class: _normalizeClass([{'p-invalid':_ctx.v$.warehouseId.$invalid && _ctx.submitted}, "w-full"]),
              showClear: true
            }, null, 8, ["modelValue", "options", "class"]),
            ((_ctx.v$.warehouseId.$invalid && _ctx.submitted) || _ctx.v$.warehouseId.$pending.$response)
              ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.v$.warehouseId.required.$message), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.repairQuotation.commission')), 1),
            _createVNode(_component_InputText, {
              icon: "align-justify",
              type: "text",
              autocomplete: "off",
              modelValue: _ctx.v$.commission.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.commission.$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$.commission.$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "class"]),
            ((_ctx.v$.commission.$invalid && _ctx.submitted) || _ctx.v$.commission.$pending.$response)
              ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.v$.commission.required.$message), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_p_button, {
            severity: "danger",
            disabled: _ctx.savingInProgress,
            textContent: _toDisplayString(_ctx.$t('buttons.cancel')),
            onClick: _ctx.closeResponsive
          }, null, 8, ["disabled", "textContent", "onClick"]),
          _createVNode(_component_p_button, {
            disabled: _ctx.savingInProgress,
            severity: "success",
            textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm')),
            type: "submit"
          }, null, 8, ["disabled", "textContent"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}