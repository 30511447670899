import Fieldset from 'primevue/fieldset';
import {computed, ref, toRefs} from 'vue';
import Button from 'primevue/button';
import {getReferenceItemLayout} from '@/services/tickets';
import AddRepairQuotation from '@/components/dialog/tickets/add-repair-quotation.vue';
import store from '@/store';

export default {
    emits: ['reload-ticket-details'],
    components: {
        'p-fieldset': Fieldset,
        'p-button': Button,
        AddRepairQuotation
    },
    props: {
        ticketDetails: Object
    },
    setup(props: any, context: any) {
        const {ticketDetails} = toRefs(props);
        const showAddQuotationWizardDialog = ref(false);
        const commentDefaultValue = ref(null);

        const showAddNewQuotationButton = computed(() => {
            return (
                ticketDetails.value &&
                ticketDetails.value.assignedUser &&
                ticketDetails.value.customer?.id
            );
        });

        const onAddNewQuotationClick = () => {
            showAddQuotationWizardDialog.value = true;
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('weclapp-tickets-edit') !== -1;
        });

        const closeAddNewQuotationDialog = (event: any) => {
            showAddQuotationWizardDialog.value = false;

            if (event) {
                context.emit('reload-ticket-details');
            }
        };

        return {
            ticketDetails,
            getReferenceItemLayout: getReferenceItemLayout,
            showAddQuotationWizardDialog,
            closeAddNewQuotationDialog,
            onAddNewQuotationClick,
            showAddNewQuotationButton,
            commentDefaultValue,
            editPermissionAvailable
        };
    }
};
