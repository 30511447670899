import {ref, watch} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
export default {
    emits: ['confirmed'],
    components: {
        PrimeDialog: Dialog,
        'p-button': Button
    },
    props: {
        displayDialog: Boolean
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
        });

        const handleConfirmation = (confirmed: boolean = true) => {
            showDialog.value = false;
            context.emit('confirmed', confirmed);
        };

        return {
            showDialog,
            handleConfirmation
        };
    }
};
