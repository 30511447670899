import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const add = async (
    entityName: string,
    entityId: string | string[],
    path: string,
    description?: string | null
): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/bookmarks', {
        entityName,
        entityId,
        path,
        description
    });
};

export const remove = async (bookmarkId: string): Promise<AxiosResponse> => {
    return apiClient.delete('/api/v1/bookmarks/' + bookmarkId);
};

export const getAll = async (): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/bookmarks');
};
