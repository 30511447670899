import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import Keyboard from '@/components/keyboard/keyboard.vue';
import InputText from 'primevue/inputtext';
import {computed, onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';
import LoadingPlugin from 'vue-loading-overlay';
import {useToast} from 'vue-toastification';
import router from '@/router';
import {useVuelidate} from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import {i18n} from '@/utils/i18n';
import {getSingle, updateDimensions} from '@/services/products';
import Panel from 'primevue/panel';

export default {
    components: {
        'p-button': Button,
        'app-checkbox': Checkbox,
        'app-keyboard': Keyboard,
        loading: LoadingPlugin,
        DataTable,
        Column,
        Panel,
        InputText
    },
    setup() {
        const route = useRoute();
        const loading = ref(false);
        const toast = useToast();
        const productDetails = ref(null);

        const keyboardElement = ref(null);
        const shipmentNumberInputField = ref(null);
        const articleWeightInputField = ref(null);
        const articleHeightInputField = ref(null);
        const articleWidthInputField = ref(null);
        const articleLengthInputField = ref(null);
        const articleEanInputField = ref(null);

        const inputFieldFocusSwitchTimeout = ref(null);

        const displayConfirmation = ref(false);

        const dt = ref();
        const expandedRows = ref([]);

        onMounted(() => {
            loadProductDetails();
        });

        const state = ref({
            articleGrossWeight: null,
            articleLength: null,
            articleWidth: null,
            articleHeight: null,
            ean: null,
            article_flag_akku_groesser_100wh: false,
            article_flag_aerosole: false,
            article_flag_akku_kleiner_100wh: false
        });

        const rules = {
            articleGrossWeight: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            articleLength: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            articleWidth: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                ),
                lessThanLength: helpers.withMessage(
                    i18n.global.t('messages.valueNeedsToBeLessThanLength'),
                    (value: any) => {
                        return (
                            !value ||
                            !state.value?.articleLength ||
                            parseFloat(value.replace(',', '.')) <=
                                parseFloat(
                                    state.value.articleLength.replace(',', '.')
                                )
                        );
                    }
                )
            },
            articleHeight: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                ),
                lessThanLength: helpers.withMessage(
                    i18n.global.t('messages.valueNeedsToBeLessThanLength'),
                    (value: any) => {
                        return (
                            !value ||
                            !state.value?.articleLength ||
                            parseFloat(value.replace(',', '.')) <=
                                parseFloat(
                                    state.value.articleLength.replace(',', '.')
                                )
                        );
                    }
                )
            },
            ean: {},
            article_flag_akku_groesser_100wh: {},
            article_flag_aerosole: {},
            article_flag_akku_kleiner_100wh: {}
        };

        const v$ = useVuelidate(rules, state);
        const submitted = ref(false);
        const savingInProgress = ref(false);

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;
            const toSubmit = Object.assign({}, state.value);

            [
                'articleGrossWeight',
                'articleHeight',
                'articleWidth',
                'articleLength'
            ].forEach((item: string) => {
                toSubmit[item as keyof typeof toSubmit] = parseFloat(
                    toSubmit[item as keyof typeof toSubmit].replace(',', '.')
                );
            });

            updateDimensions(
                productDetails.value.weclapp?.articleNumber,
                toSubmit
            )
                .then((data) => {
                    savingInProgress.value = false;
                    if (data?.data?.shopwareFailed) {
                        toast.warning(
                            i18n.global.t('messages.changesSavedOnlyInWeclapp')
                        );
                    } else {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully'),
                            {
                                timeout: 500
                            }
                        );
                    }

                    router.push(
                        originalStoragePlace.value && originalPath.value
                            ? {
                                  name: 'ProductsWithIncompleteDimensions',
                                  query: {
                                      storagePlace: originalStoragePlace.value,
                                      path: originalPath.value.join('|')
                                  }
                              }
                            : {
                                  name: 'ProductsWithIncompleteDimensions'
                              }
                    );
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    savingInProgress.value = false;
                });
        };

        const initializeCustomAttributes = () => {
            if (!productDetails.value) {
                return;
            }

            [
                'article_flag_akku_groesser_100wh',
                'article_flag_aerosole',
                'article_flag_akku_kleiner_100wh'
            ].forEach((item) => {
                const temp: {[k: string]: boolean} = {};
                const attributes =
                    productDetails.value.weclapp?.customAttributes;
                temp[item] = attributes[item] || null;
                Object.assign(state.value, temp);
            });
        };

        const loadProductDetails = async () => {
            loading.value = true;
            getSingle(route.params.id)
                .then((data) => {
                    if (data?.data?.weclapp) {
                        productDetails.value = data.data;
                        Object.assign(state.value, {
                            articleGrossWeight: productDetails.value.weclapp
                                ?.articleGrossWeight
                                ? productDetails.value.weclapp.articleGrossWeight
                                      .toString()
                                      .replace('.', ',')
                                : null,
                            articleLength: productDetails.value.weclapp
                                ?.articleLength
                                ? (
                                      productDetails.value.weclapp
                                          .articleLength * 100
                                  )
                                      .toFixed(1)
                                      .replace('.', ',')
                                : null,
                            articleWidth: productDetails.value.weclapp
                                ?.articleWidth
                                ? (
                                      productDetails.value.weclapp
                                          .articleWidth * 100
                                  )
                                      .toFixed(1)
                                      .replace('.', ',')
                                : null,
                            articleHeight: productDetails.value.weclapp
                                ?.articleHeight
                                ? (
                                      productDetails.value.weclapp
                                          .articleHeight * 100
                                  )
                                      .toFixed(1)
                                      .replace('.', ',')
                                : null,
                            ean: productDetails.value.weclapp?.ean || null
                        });

                        setTimeout(() => {
                            let inputField: HTMLInputElement =
                                articleLengthInputField.value
                                    ?.$el as HTMLInputElement;

                            const isEan =
                                state.value.articleGrossWeight &&
                                state.value.articleLength &&
                                state.value.articleWidth &&
                                state.value.articleHeight;

                            if (state.value.articleGrossWeight) {
                                if (state.value.articleLength) {
                                    inputField = (
                                        state.value.articleWidth
                                            ? state.value.articleHeight
                                                ? articleEanInputField.value
                                                      ?.$el
                                                : articleHeightInputField.value
                                                      ?.$el
                                            : articleWidthInputField.value?.$el
                                    ) as HTMLInputElement;
                                } else {
                                    inputField = articleLengthInputField.value
                                        ?.$el as HTMLInputElement;
                                }
                            } else {
                                inputField = articleWeightInputField.value
                                    ?.$el as HTMLInputElement;
                            }

                            inputField.focus();
                            inputField.select();
                            if (!isEan) {
                                inputField.setAttribute(
                                    'data-highlighted',
                                    true.toString()
                                );
                            }
                        }, 100);

                        initializeCustomAttributes();
                    } else {
                        toast.error(i18n.global.t('messages.noSuchProduct'));
                        router.push({
                            name: 'ProductsWithIncompleteDimensions'
                        });
                    }
                })
                .catch((error) => {
                    if (error.response?.status === 404) {
                        toast.error(i18n.global.t('messages.noSuchProduct'));
                    } else {
                        toast.error(error.message);
                    }
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const onElementKeyDown = (event: KeyboardEvent) => {
            event.preventDefault();
        };

        const onKeyboardPressed = (key: string | null) => {
            if (!keyboardElement.value) {
                return;
            }

            const highlighted =
                keyboardElement.value.getAttribute('data-highlighted');

            let currentValue: string = highlighted
                ? ''
                : keyboardElement.value.value;

            keyboardElement.value.focus();

            if (highlighted) {
                keyboardElement.value.removeAttribute('data-highlighted');
            }

            if (key === null) {
                currentValue = currentValue.substring(
                    0,
                    currentValue.length - 1
                );
            } else if (key === ',') {
                if (currentValue.indexOf(',') === -1) {
                    currentValue = currentValue ? `${currentValue},` : '0,';
                }
            } else if (key !== 'enter') {
                currentValue = `${currentValue}${key}`;
            }

            if (
                !keyboardElement.value.pattern ||
                new RegExp(keyboardElement.value.pattern).test(currentValue)
            ) {
                keyboardElement.value.value = currentValue;
                Object.assign(state.value, {
                    [keyboardElement.value.name]: currentValue
                });
            }

            if (
                key === 'enter' &&
                [
                    'articleGrossWeight',
                    'articleLength',
                    'articleWidth',
                    'articleHeight'
                ].indexOf(keyboardElement.value.name) !== -1
            ) {
                if (inputFieldFocusSwitchTimeout.value) {
                    clearTimeout(inputFieldFocusSwitchTimeout.value);
                }

                inputFieldFocusSwitchTimeout.value = setTimeout(() => {
                    let inputField: HTMLInputElement = null;
                    let isEan = false;
                    switch (keyboardElement.value.name) {
                        case 'articleGrossWeight':
                            inputField = articleLengthInputField.value
                                ?.$el as HTMLInputElement;
                            break;
                        case 'articleLength':
                            inputField = articleWidthInputField.value
                                ?.$el as HTMLInputElement;
                            break;
                        case 'articleWidth':
                            inputField = articleHeightInputField.value
                                ?.$el as HTMLInputElement;
                            break;
                        default:
                            inputField = articleEanInputField.value
                                ?.$el as HTMLInputElement;
                            isEan = true;
                            break;
                    }

                    inputField.focus();
                    inputField.select();
                    if (!isEan) {
                        inputField.setAttribute(
                            'data-highlighted',
                            true.toString()
                        );
                    }
                }, 100);
            }
        };

        const onElementFocused = (event: any) => {
            keyboardElement.value = event.target;
        };

        const originalPath = computed(() => {
            if (history?.state?.originalData) {
                const originalData = JSON.parse(history.state.originalData);
                return originalData['path'];
            }
            return null;
        });

        const originalStoragePlace = computed(() => {
            if (history?.state?.originalData) {
                const originalData = JSON.parse(history.state.originalData);
                return Object.keys(originalData['singleStoragePlace'])[0];
            }
            return null;
        });

        return {
            loading,
            productDetails,
            onElementKeyDown,
            onKeyboardPressed,
            onElementFocused,
            dt,
            expandedRows,
            state,
            v$,
            submitted,
            savingInProgress,
            handleSubmit,
            displayConfirmation,
            shipmentNumberInputField,
            articleWeightInputField,
            articleHeightInputField,
            articleWidthInputField,
            articleLengthInputField,
            articleEanInputField,
            originalPath,
            originalStoragePlace
        };
    }
};
