import {onMounted, onUnmounted, ref, toRefs} from 'vue';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Column from 'primevue/column';
import LoadingPlugin from 'vue-loading-overlay';
import ProgressBar from 'primevue/progressbar';
import Calendar from 'primevue/calendar';

import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import {getLogResultFile, getJobLogs} from '@/services/crons';
import FileSaver from 'file-saver';
import {DateTime} from 'luxon';
export default {
    emits: ['on-selection-change'],
    components: {
        DataTable,
        Column,
        Dropdown,
        InputText,
        ProgressBar,
        loading: LoadingPlugin,
        Calendar
    },
    props: {
        cron: Object
    },
    setup(props: any) {
        const {cron} = toRefs(props);

        onMounted(() => {
            lazyParams.value = {
                first: 0,
                rows: dt.value?.rows || 10,
                sortField: 'id',
                sortOrder: -1,
                filters: filters.value
            };

            loadLazyData();

            if (interval.value !== null) {
                clearInterval(interval.value);
            }

            interval.value = setInterval(() => {
                if (needsRefresh.value) {
                    loadLazyData();
                }
            }, 5000);
        });

        onUnmounted(() => {
            if (interval.value !== null) {
                clearInterval(interval.value);
            }
        });

        const totalRecords = ref(0);
        const loading = ref(false);
        const logs = ref();
        const dt = ref();
        const lazyParams: any = ref({});
        const toast = useToast();

        const interval = ref(null);
        const needsRefresh = ref(false);

        const onPage = (event: any) => {
            if (!lazyParams.value) {
                lazyParams.value = event;
            } else {
                lazyParams.value.first = event.first || 0;
                lazyParams.value.rows = event.rows || dt.value.rows;
            }
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = Object.assign(
                filters.value,
                props.preDefinedFilters || {}
            );
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const loadLazyData = () => {
            loading.value = true;
            getJobLogs(cron.value.id, lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    logs.value = data.data.items;
                    logs.value = data.data.items.map((obj: any) => ({
                        ...obj,
                        processStartedAtFormatted: obj.processStartedAt
                            ? DateTime.fromSeconds(obj.processStartedAt)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString(
                                      DateTime.DATETIME_MED_WITH_SECONDS
                                  )
                            : '',
                        processEndedAtFormatted: obj.processEndedAt
                            ? DateTime.fromSeconds(obj.processEndedAt)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString(
                                      DateTime.DATETIME_MED_WITH_SECONDS
                                  )
                            : ''
                    }));
                    loading.value = false;
                    const inProgress = data.data.items.find((element: any) => {
                        return (
                            element.processStartedAt && !element.processEndedAt
                        );
                    });

                    needsRefresh.value = !!inProgress;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const filterDefintions: any = {
            initiatedBy: {value: null, matchMode: FilterMatchMode.CONTAINS},
            processStartedAt: {value: null, matchMode: FilterMatchMode.DATE_IS},
            status: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            }
        };
        const filters = ref(filterDefintions);

        const getStatusCellStyle = (data: any) => {
            if (!data) {
                return '';
            }

            if (!data?.processStartedAt) {
                return 'text-orange-400';
            }

            if (!data?.processEndedAt) {
                return 'text-blue-400';
            }

            return 'text-green-400';
        };

        const getStatusCellContent = (data: any) => {
            if (!data) {
                return '';
            }

            if (!data?.processStartedAt) {
                return i18n.global.t('labels.scheduled');
            }

            if (!data?.processEndedAt) {
                return i18n.global.t('labels.inProgress');
            }

            return i18n.global.t('labels.done');
        };

        const downloadResultFile = async (event: any, data: any) => {
            try {
                const file = await getLogResultFile(cron.value.id, data.id);
                const filename =
                    cron.value.name.replaceAll(' ', '-') +
                    '-' +
                    DateTime.fromSeconds(data.processStartedAt)
                        .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                        .toFormat('yyyy-LL-dd TT')
                        .replaceAll(' ', '-') +
                    '.csv';
                FileSaver.saveAs(
                    new Blob([file.data], {type: 'text/csv;charset=utf-8'}),
                    filename
                );
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
            }
        };

        const downloadResultErrorFile = async (event: any, data: any) => {
            try {
                const file = await getLogResultFile(
                    cron.value.id,
                    data.id,
                    true
                );
                const filename =
                    cron.value.name.replaceAll(' ', '-') +
                    '-' +
                    DateTime.fromSeconds(data.processStartedAt)
                        .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                        .toFormat('yyyy-LL-dd TT')
                        .replaceAll(' ', '-') +
                    '-error.csv';
                FileSaver.saveAs(
                    new Blob([file.data], {type: 'text/csv;charset=utf-8'}),
                    filename
                );
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
            }
        };

        return {
            filters,
            dt,
            logs,
            loading,
            loadLazyData,
            totalRecords,
            onFilter,
            onPage,
            onSort,
            getStatusCellContent,
            getStatusCellStyle,
            downloadResultFile,
            downloadResultErrorFile
        };
    }
};
