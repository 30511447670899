<p-fieldset
    class="mb-3"
    :legend="$t('labels.ticket.references.deliveryNote')"
    :toggleable="true"
>
    <div
        class="grid p-fluid"
        v-if="ticketDetails.ticketDeliveryNote && ticketDetails.ticketDeliveryNote.length > 0"
        v-for="referenceItem in (ticketDetails.ticketDeliveryNote || [])"
    >
        <div class="col">
            <span
                v-html="getReferenceItemLayout(referenceItem,
                                        ticketDetails.url)"
            ></span>

            <span
                v-if="editPermissionAvailable && referenceItem.status === 'NEW'"
                v-tooltip.top="$t('labels.ticket.deliveryNote.bookAndSendCompensationShipment')"
                @click="onCreateCompensationShipmentClick(referenceItem, $event)"
                style="cursor: pointer"
            >
                <i class="pi pi-check-square ml-1"></i>
                <i class="pi pi-truck ml-1"></i>
            </span>
            <span>
                <i
                    v-if="referenceItem.documents && referenceItem.documents.some((item) => {return item.name.startsWith('SH') || item.name.startsWith('LS');})"
                    @click="printDocument(referenceItem.documents.find((item) => {return item.name.startsWith('SH') || item.name.startsWith('LS');}))"
                    class="pi pi-print ml-2"
                    style="cursor: pointer"
                    v-tooltip.top="$t('labels.printSpecificDocumentRemotely', {name: referenceItem.documents.find((item) => {return item.name.startsWith('SH') ||  item.name.startsWith('LS');}).name})"
                ></i>
            </span>
        </div>
    </div>
</p-fieldset>
<BookTicketShipment
    :display-dialog="showBookTicketShipmentDialog"
    :is-return-ticket="isReturnTicket"
    :ticket-details="ticketDetails"
    :shipment="selectedShipment"
    @close-dialog="closeBookTicketShipmentDialog"
></BookTicketShipment>
<AddTicketCommentDialog
    :display-dialog="showAddCommentDialog"
    :ticket-details="ticketDetails"
    :comment-default-value="commentDefaultValue"
    @close-dialog="closeAddTicketCommentDialog"
></AddTicketCommentDialog>
<SendDocumentToRemotePrinter
    :display-dialog="displaySendToRemotePrinterDialog"
    @close-dialog="closePrintDialogListener"
    :selected-document="selectedDocument"
>
</SendDocumentToRemotePrinter>
