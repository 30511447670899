import {computed, ref, toRefs, watch} from 'vue';
import {useVuelidate} from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import {useToast} from 'vue-toastification';
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import AutoComplete from 'primevue/autocomplete';
import Dialog from 'primevue/dialog';
import AddSerialNumber from '@/components/dialog/tickets/add-serial-number.vue';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import TreeSelect from 'primevue/treeselect';

import {getAll as getAllProducts} from '@/services/products';
import {getAllUsingFilters} from '@/services/storage-locations';
import {FilterMatchMode} from 'primevue/api';
import {stripTagsAndTruncate} from '@/utils/helpers';
import {bookTicketShipment} from '@/services/tickets';
import {getAll, getSome} from '@/services/metadata';

export default {
    emits: ['close-dialog'],
    props: {
        displayDialog: Boolean,
        isReturnTicket: Boolean,
        ticketDetails: Object,
        shipment: Object
    },
    components: {
        'p-button': Button,
        'p-dropdown': Dropdown,
        InputNumber,
        AutoComplete,
        AddSerialNumber,
        PrimeDialog: Dialog,
        loading: LoadingPlugin,
        TreeSelect
    },
    setup(props: any, context: any) {
        const {isReturnTicket, ticketDetails} = toRefs(props);
        const shipmentInsance = ref(null);
        const submitted = ref(false);
        const filteredProducts = ref();
        const toast = useToast();
        const showAddSerialNumberDialog = ref(false);
        const clickedRowIndex = ref(null);
        const clickedRow = ref(null);
        const showDialog = ref(false);
        const needsRefresh = ref(false);
        const savingInProgress = ref(false);

        const customerCompensationSourceWarehouseId = ref(null);
        const warehouses = ref([]);

        const storagePlaces = ref([]);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            shipmentInsance.value = args.shipment;
            if (shipmentInsance.value?.shipmentItems) {
                state.value = Object.assign(state.value, {
                    shipmentItems: shipmentInsance.value?.shipmentItems.map(
                        (item: {
                            id: string;
                            article: any;
                            articleNumber: string;
                            quantity: string;
                            title: string;
                            unitName: string;
                            serialNumbers: Array<string>;
                        }) => {
                            return {
                                id: item.id,
                                article: item.article,
                                selectedSerialNumbers: item.serialNumbers,
                                articleNumber: item.articleNumber,
                                shipmentQuantity: item.quantity
                                    ? parseInt(item.quantity)
                                    : null,
                                unitName: item.unitName,
                                serialNumberRequired:
                                    item.article?.serialNumberRequired || false,
                                sourceStoragePlaceId: null as string,
                                destinationStoragePlaceId: null as string
                            };
                        }
                    )
                });

                getAll(['warehouse'], false).then((data: any) => {
                    warehouses.value = data.data['warehouse'];
                    storagePlaces.value = data.data['warehouse'].map(
                        (item: any) => {
                            return {
                                key: 'warehouse' + item.id,
                                label: '[' + item.name + ']',
                                data: '[' + item.name + ']',
                                leaf: false,
                                selectable: false,
                                warehouseId: item.id
                            };
                        }
                    );
                });
            }
        });

        const searchProducts = (event: any) => {
            getAllProducts({
                first: 0,
                rows: 20,
                columns: [
                    'id',
                    'articleNumber',
                    'name',
                    'serialNumberRequired'
                ],
                filters: {
                    name: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    articleNumber: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                filterConjunction: 'or'
            })
                .then((data) => {
                    if (data.data?.items) {
                        filteredProducts.value = data.data.items.map(
                            (item: {
                                id: string;
                                articleNumber: string;
                                name: string;
                            }) => {
                                return {
                                    label:
                                        '(' +
                                        item.articleNumber +
                                        ') ' +
                                        item.name,
                                    value: item.articleNumber
                                };
                            }
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const openAddSerialNumberDialog = (rowIndex: number, item: any) => {
            clickedRowIndex.value = rowIndex;
            clickedRow.value = item; // item.article?.id;
            showAddSerialNumberDialog.value = true;
        };

        const state = ref({
            shipmentItems: []
        });

        const rules = {
            shipmentItems: {
                $each: helpers.forEach({
                    articleNumber: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    },
                    shipmentQuantity: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        ),
                        greaterThanZero: helpers.withMessage(
                            i18n.global.t('messages.valueCannotBeZero'),
                            (value: any) => {
                                return value === null || value > 0;
                            }
                        )
                    },
                    selectedSerialNumbers: {
                        required: helpers.withMessage(
                            i18n.global.t(
                                'messages.tooLessSerialNumbersSelected'
                            ),
                            (value: any, row: any) => {
                                return (
                                    !row.serialNumberRequired ||
                                    value.length >= row.shipmentQuantity
                                );
                            }
                        )
                    },
                    sourceStoragePlaceId: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    },
                    destinationStoragePlaceId: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    }
                })
            }
        };

        const v$ = useVuelidate(rules, state);

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            if (
                !state.value.shipmentItems ||
                state.value.shipmentItems.length < 1
            ) {
                toast.error(
                    i18n.global.t('messages.pleaseAddAtLeastOneRetourePosition')
                );
                return;
            }

            savingInProgress.value = true;

            needsRefresh.value = true;

            try {
                await bookTicketShipment(
                    ticketDetails.value.ticketNumber,
                    shipmentInsance.value.shipmentNumber,
                    state.value.shipmentItems.map((item: any) => {
                        return {
                            id: item.id,
                            serialNumbers: item.selectedSerialNumbers,
                            articleNumber: item.articleNumber,
                            shipmentQuantity: item.shipmentQuantity,
                            sourceStoragePlaceId: Object.keys(
                                item.sourceStoragePlaceId
                            )[0],

                            destinationStoragePlaceId: Object.keys(
                                item.destinationStoragePlaceId
                            )[0]
                        };
                    })
                );
                if (isCustomerCompensationShipment.value) {
                    toast.success(i18n.global.t('messages.customerReturnSent'));
                } else if (
                    'SUPPLIER_RETURN' === shipmentInsance.value?.shipmentType
                ) {
                    toast.success(i18n.global.t('messages.supplierReturnSent'));
                }

                context.emit('close-dialog', {
                    needsRefresh: true,
                    success: true
                });
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
            } finally {
                savingInProgress.value = false;
            }
        };

        const onCancelButtonClicked = (event: any) => {
            event.preventDefault();
            context.emit('close-dialog', {
                needsRefresh: needsRefresh.value
            });
        };

        const addShipmentItem = () => {
            state.value.shipmentItems.push({
                id: null,
                article: null,
                selectedSerialNumbers: [],
                articleNumber: null,
                shipmentQuantity: null,
                serialNumberRequired: false,
                unitName: 'Stk.',
                sourceStoragePlaceId: null,
                destinationStoragePlaceId: null
            });
        };
        const removeShipmentItem = (index: number) => {
            state.value.shipmentItems.splice(index, 1);
        };

        const removeSerialNumberItem = (
            index: number,
            serialNumber: string
        ) => {
            const serialNumbers = state.value.shipmentItems[
                index
            ].selectedSerialNumbers.filter(
                (item: any) => item !== serialNumber
            );

            state.value.shipmentItems[index] = Object.assign(
                state.value.shipmentItems[index],
                {
                    selectedSerialNumbers: serialNumbers,
                    shipmentQuantity: serialNumbers.length
                }
            );
        };

        const closeDialogListener = (event: any) => {
            showAddSerialNumberDialog.value = false;

            if (event.rowIndex === null) {
                return;
            }

            state.value.shipmentItems[event.rowIndex] = Object.assign(
                state.value.shipmentItems[event.rowIndex],
                {
                    selectedSerialNumbers: event.selectedItems.map(
                        (item: any) => item.serialNumber
                    ),
                    shipmentQuantity: event.selectedItems.length
                }
            );
        };

        const dropdownSelect = async (index: number, event: any) => {
            const product = await getAllProducts({
                first: 0,
                rows: 1,
                columns: [
                    'id',
                    'articleNumber',
                    'name',
                    'description',
                    'serialNumberRequired'
                ],
                filters: {
                    articleNumber: {
                        value: event?.value?.value || '',
                        matchMode: FilterMatchMode.EQUALS
                    }
                }
            });

            state.value.shipmentItems[index] = Object.assign(
                state.value.shipmentItems[index],
                {
                    serialNumberRequired:
                        product.data.items[0].serialNumberRequired,
                    articleNumber: product.data.items[0].articleNumber,
                    shipmentQuantity: 0
                }
            );
        };

        const sourceRetoure = computed(() => {
            return ticketDetails.value?.ticketCustomerReturn &&
                ticketDetails.value.ticketCustomerReturn.length > 0
                ? ticketDetails.value.ticketCustomerReturn.find(
                      (item: any) => item.status === 'INCOMING_MOVED_INTO_STORE'
                  )
                : null;
        });

        const sourceWarehouseId = computed(() => {
            return isCustomerCompensationShipment.value
                ? customerCompensationSourceWarehouseId.value
                : sourceRetoure.value?.warehouse?.id;
        });

        const targetWarehouseId = computed(() => {
            return shipmentInsance.value?.warehouse?.id;
        });

        const isCustomerCompensationShipment = computed(
            () =>
                'CUSTOMER_COMPENSATION' === shipmentInsance.value?.shipmentType
        );

        const addSerialNumberDialogFilters = computed(() => {
            return {
                storagePlaceId: {
                    value: clickedRow.value?.sourceStoragePlaceId
                        ? Object.keys(clickedRow.value?.sourceStoragePlaceId)[0]
                        : null,
                    matchMode: FilterMatchMode.EQUALS
                },
                articleId: {
                    value: clickedRow.value?.article?.id,
                    matchMode: FilterMatchMode.EQUALS
                },
                quantity: {
                    value: 0,
                    matchMode: FilterMatchMode.GREATER_THAN
                }
            };
        });

        const dialogHeaderLabel = computed(() => {
            if (isCustomerCompensationShipment.value) {
                return i18n.global.t('labels.ticket.deliveryNote.items');
            }

            return 'SUPPLIER_RETURN' === shipmentInsance.value?.shipmentType
                ? i18n.global.t('labels.ticket.supplierReturn.items')
                : '';
        });

        const onNodeExpand = (node: any) => {
            if (!node.children) {
                expandNode(node);
            }
        };

        const expandNode = (node: any) => {
            if (node.warehouseId) {
                getAllUsingFilters({
                    first: 0,
                    rows: 100,
                    filters: {
                        warehouseId: {
                            value: node.warehouseId,
                            matchMode: FilterMatchMode.EQUALS
                        }
                    }
                })
                    .then((data) => {
                        node.children = data.data
                            .map(
                                (item: {
                                    id: string;
                                    name: string;
                                    shortIdentifier?: string;
                                    blockStoragePlaces: Array<{id: string}>;
                                    shelves: Array<{id: string}>;
                                }) => {
                                    return {
                                        key: 'storageLocation' + item.id,
                                        label:
                                            item.shortIdentifier ?? item.name,
                                        data: item.name,
                                        leaf: false,
                                        selectable: false,
                                        blockStoragePlaces:
                                            item.blockStoragePlaces.map(
                                                (bs) => bs.id
                                            ),
                                        shelves: item.shelves.map(
                                            (shelf) => shelf.id
                                        )
                                    };
                                }
                            )
                            .sort(sortFn);
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            } else if (
                typeof node.blockStoragePlaces !== 'undefined' &&
                typeof node.shelves !== 'undefined'
            ) {
                const resolvedPromisesArray: Array<any> = [];
                if (node.blockStoragePlaces.length > 200) {
                    toast.warning(
                        i18n.global.t(
                            'messages.loadingOnlyFirst200BlockStoragePlaces'
                        )
                    );
                }

                if (node.shelves.length > 200) {
                    toast.warning(
                        i18n.global.t('messages.loadingOnlyFirst200Shelves')
                    );
                }

                if (node.blockStoragePlaces.length > 0) {
                    resolvedPromisesArray.push(
                        Promise.resolve(
                            getSome(
                                'storagePlace',
                                'id',
                                node.blockStoragePlaces.splice(0, 200),
                                false,
                                ['storageLocationId']
                            )
                        )
                    );
                }

                if (node.shelves.length > 0) {
                    resolvedPromisesArray.push(
                        Promise.resolve(
                            getSome(
                                'shelf',
                                'id',
                                node.shelves.splice(0, 200),
                                false
                            )
                        )
                    );
                }

                Promise.all(resolvedPromisesArray)
                    .then((data) => {
                        let values: any = [];
                        data.forEach((item) => {
                            values = values.concat(Object.values(item.data));
                        });

                        node.children = values
                            .map((item: any) => {
                                return {
                                    key:
                                        (item.storagePlaces ? 'shelf' : '') +
                                        item.id,
                                    label: item.shortIdentifier || item.name,
                                    data: item.shortIdentifier || item.name,
                                    leaf:
                                        typeof item.storagePlaces ===
                                        'undefined',
                                    selectable:
                                        typeof item.storagePlaces ===
                                        'undefined',
                                    storagePlaces: (
                                        item.storagePlaces || []
                                    ).map((bs: any) => bs.id)
                                };
                            })
                            .sort(sortFn);
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    });
            } else if (
                typeof node.storagePlaces !== 'undefined' &&
                node.storagePlaces.length > 0
            ) {
                getSome('storagePlace', 'id', node.storagePlaces, false, [
                    'storageLocationId',
                    'shelfId',
                    'shelfStorageLocationId'
                ])
                    .then((data) => {
                        const values: Array<{
                            id: string;
                            name: string;
                        }> = Object.values(data.data);

                        node.children = values
                            .map((item) => {
                                return {
                                    key: item.id,
                                    label: item.name,
                                    data: item.name,
                                    leaf: true,
                                    selectable: true
                                };
                            })
                            .sort(sortFn);
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            }
        };

        const sortFn = (a: any, b: any) => {
            const nameA = a.label.toUpperCase();
            const nameB = b.label.toUpperCase();

            if (nameA < nameB) {
                return -1;
            }
            return nameA > nameB ? 1 : 0;
        };

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            onCancelButtonClicked,
            isReturnTicket,
            addShipmentItem,
            removeShipmentItem,
            searchProducts,
            locale: i18n.global.locale,
            filteredProducts,
            showAddSerialNumberDialog,
            clickedRowIndex,
            clickedRow,
            openAddSerialNumberDialog,
            closeDialogListener,
            removeSerialNumberItem,
            dropdownSelect,
            stripTagsAndTruncate: stripTagsAndTruncate,
            showDialog,
            savingInProgress,
            addSerialNumberDialogFilters,
            dialogHeaderLabel,
            isCustomerCompensationShipment,
            customerCompensationSourceWarehouseId,
            warehouses,
            storagePlaces,
            sourceWarehouseId,
            targetWarehouseId,
            onNodeExpand
        };
    }
};
