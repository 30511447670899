import mutations from './mutations';
import actions from './actions';
import getters from './getters';
const printerModule: {
    namespaced: boolean;
    state: {
        remote: Array<{
            platform: string;
            hardwareId: string;
            printer: string;
            printerSize: string;
        }>;
    };
    mutations: any;
    actions: any;
    getters: any;
} = {
    namespaced: true,
    state: {
        remote: []
    },
    mutations,
    actions,
    getters
};

export default printerModule;
