import mutations from './mutations';
import actions from './actions';
import getters from './getters';
const shipmentModule: {
    namespaced: boolean;
    state: {
        shippingLocation: Array<{platform: string; shippingLocation: string}>;
        openShipments: {warehouseId: string};
    };
    mutations: any;
    actions: any;
    getters: any;
} = {
    namespaced: true,
    state: {
        shippingLocation: [],
        openShipments: {warehouseId: null}
    },
    mutations,
    actions,
    getters
};

export default shipmentModule;
