<loading v-model:active="loading" />
<div class="col-12">
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        :paginator="true"
        :rows="10"
        :lazy="true"
        dataKey="id"
        ref="dt"
        :totalRecords="totalRecords"
        :value="logs"
        v-model:filters="filters"
        filterDisplay="row"
        @page="onPage($event)"
        @filter="onFilter($event)"
        @sort="onSort($event)"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
    >
        <Column
            style="min-width: 8rem"
            field="initiatedBy"
            :header="$t('labels.initiatedBy')"
            :sortable="true"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.email')"
                />
            </template>
        </Column>
        <Column
            field="processStartedAt"
            :header="$t('labels.logTime')"
            :sortable="true"
            dataType="date"
        >
            <template #filter="{filterModel}">
                <Calendar
                    v-model="filterModel.value"
                    dateFormat="mm/dd/yy"
                    placeholder="mm/dd/yyyy"
                />
            </template>
            <template #body="{data}"
                >{{data.processStartedAtFormatted}}</template
            >
        </Column>
        <Column
            field="status"
            :header="$t('labels.status')"
            :sortable="true"
            style="min-width: 6rem"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel, filterCallback}">
                <Dropdown
                    v-model="filterModel.value"
                    :options="[{label: $t('labels.scheduled'), value: 'scheduled', color: 'orange'}, {label: $t('labels.inProgress'), value: 'inProgress', color: 'lightgreen'}, {label: $t('labels.done'), value: 'done', color: 'darkgreen'}]"
                    placeholder="Any"
                    optionLabel="label"
                    optionValue="value"
                    class="p-column-filter text-sm"
                    :showClear="true"
                    @change="filterCallback"
                >
                </Dropdown>
            </template>
            <template #body="{data}">
                <div>
                    <span :class="getStatusCellStyle(data)">
                        {{getStatusCellContent(data)}}</span
                    >
                </div>
            </template></Column
        >
        <Column :header="$t('labels.progress')" style="min-width: 12rem"
            ><template #body="{data}">
                <ProgressBar
                    v-if="data.processStartedAt"
                    style="height: 1.3em"
                    :value="data.totalRecords && data.processedRecords ? Math.floor((data.processedRecords * 100) / data.totalRecords) : 0"
                /> </template
        ></Column>
        <Column
            :header="$t('labels.sentResultTo')"
            style="min-width: 9rem"
            field="emailToAddress"
        ></Column>
        <Column style="min-width: 6rem">
            <template #body="{data}">
                <span
                    v-if="data.resultFileError"
                    v-tooltip="$t('labels.errorFileExport')"
                >
                    <font-awesome-icon
                        class="mr-2"
                        style="font-size: 1.5rem; color: red"
                        :icon="['fas', 'file-csv']"
                        @click="downloadResultErrorFile($event, data)"
                    />
                </span>
                <span
                    v-if="data.resultFile"
                    v-tooltip="$t('labels.resultFileExport')"
                >
                    <font-awesome-icon
                        class="mr-2"
                        style="font-size: 1.5rem; color: darkgreen"
                        :icon="['fas', 'file-csv']"
                        @click="downloadResultFile($event, data)"
                    />
                </span>
            </template>
        </Column>
    </DataTable>
</div>
