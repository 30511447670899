import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const getAllForSingleProduct = (
    productId: string | string[]
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/entity-change-log?entityName=product&entityId=' + productId
    );
};

export const getAllForMultipleProducts = (
    productId: string[]
): Promise<AxiosResponse> => {
    let endpoint = '/api/v1/entity-change-log?entityName=product';

    productId.forEach((item) => {
        endpoint += '&entityId[]=' + item;
    });

    return apiClient.get(endpoint);
};
