<PrimeDialog
    :header="$t('labels.bookIncomingGoodsItems')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :modal="true"
    :closable="false"
>
    <loading v-model:active="loading" />
    <DataTable
        :class="{'p-datatable-sm': true, 'p-datatable-fixed-height': incomingGoodsItems.length > 9}"
        stripedRows
        ref="dt"
        dataKey="id"
        :value="incomingGoodsItems"
        responsiveLayout="scroll"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        v-model:selection="selectedItems"
        v-model:editingRows="editingRows"
        filterDisplay="row"
        editMode="row"
        @row-edit-save="onCellEditComplete"
    >
        <Column
            :selectionMode="editingRows.length < 1 ? 'multiple' : null"
            headerStyle="width: 3em"
        ></Column>
        <Column
            field="id"
            :sortable="true"
            :header="$t('labels.articleNumber')"
            style="min-width: 14rem"
            :filterMatchModeOptions="[{label: 'Contains', value: articleComboFilter}]"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="'#, ' + $t('labels.ean') + ', ' + $t('labels.mpn')"
                />
            </template>
            <template #body="{data}"
                ><div class="text-bold">{{data.articleNumber}}</div>
                <div>{{data.article?.name}}</div>
                <div class="mt-1 text-sm" v-if="data.article?.ean">
                    {{ $t('labels.ean') }} :
                    <b>{{ data.article?.ean }}</b>
                </div>
                <div
                    class="mt-1 text-sm"
                    v-if="data.article?.manufacturerPartNumber"
                >
                    {{$t('labels.mpn')}} :
                    <b>{{ data.article?.manufacturerPartNumber }}</b>
                </div>
            </template>
        </Column>
        <Column
            field="quantity"
            :header="$t('labels.shipment.shipmentQuantity')"
            style="min-width: 9rem"
        >
            <template #editor="{ data, field }">
                <InputNumber
                    :locale="locale"
                    autocomplete="off"
                    v-model="data.quantity"
                    :showButtons="!data.serialNumberRequired"
                    :min="1"
                    :max="data.maxQuantity"
                    :disabled="data.serialNumberRequired"
                />
                {{data.unitName}}
            </template>
            <template #body="{data}"
                ><template v-if="data.serialNumberRequired"
                    >{{data.selectedSerialNumbers.length}}
                    {{data.unitName}}</template
                >
                <template v-else
                    ><div style="cursor: pointer">
                        {{data.quantity}} {{data.unitName}}
                    </div></template
                >
            </template>
        </Column>
        <Column
            field="originalSerialNumbers"
            :header="$t('labels.serialNumbers')"
            style="min-width: 9rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="p-column-filter text-sm"
                    placeholder="#"
                />
            </template>
            <template #body="{data}">
                <template v-if="data.originalSerialNumbers.length > 0">
                    <div
                        :class="{'mt-2': index > 0}"
                        v-for="(element, index) in
                        data.originalSerialNumbers"
                    >
                        {{ element}}
                        <span
                            v-if="data.originalSerialNumbers.length > 1 && data.selectedSerialNumbers.includes(element)"
                            class="pi pi-times-circle text-red-600"
                            style="
                                position: relative;
                                top: 1px;
                                cursor: pointer;
                            "
                            @click="removeSerialNumberItem(data.id, element, $event)"
                        ></span>
                        <span
                            v-else-if="data.originalSerialNumbers.length > 1"
                            class="pi pi-plus-circle text-green-600"
                            style="
                                position: relative;
                                top: 1px;
                                cursor: pointer;
                            "
                            @click="addSerialNumberItem(data.id, element, $event)"
                        ></span>
                    </div>
                </template>
            </template>
        </Column>
        <Column
            field="targetWarehouseName"
            :header="$t('labels.shipment.warehouse')"
            style="min-width: 6rem"
        >
        </Column>
        <Column
            field="targetWarehouseLevel"
            :header="$t('labels.shipment.warehouseLevel')"
            style="min-width: 9rem"
        >
            <template #editor="{ data, field }">
                <AutoComplete
                    v-model="data[field]"
                    :suggestions="warehouseLevels || []"
                    @complete="searchWarehouseLevels"
                    optionLabel="label"
                    optionValue="value"
                    forceSelection
                    placeholder="Neuer Lagerort"
                >
                </AutoComplete>
            </template>
            <template #body="{data}"
                ><div style="cursor: pointer">
                    {{ resolveWarehouseLevel(data.targetWarehouseLevel) }}
                </div></template
            >
        </Column>
        <Column
            rowEditor
            style="width: 10%; min-width: 5rem"
            bodyStyle="text-align:center"
        ></Column>
    </DataTable>
    <div class="w-full mt-3 flex justify-content-between">
        <p-button severity="danger" @click="onCancelButtonClicked">
            {{ $t("buttons.cancel") }}
        </p-button>
        <p-button
            severity="success"
            :disabled="selectedItems < 1"
            @click="handleSubmit"
        >
            {{ $t("buttons.confirm") }}
        </p-button>
    </div>
</PrimeDialog>
