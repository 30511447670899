<loading v-model:active="isLoading" />
<h6>
    {{ $t("labels.validationResultInvalidRecords") }}:
    <span class="badge badge-danger">{{Object.keys(rowsToSkip).length}}</span>
</h6>
<DataTable
    class="p-datatable-sm text-sm"
    :rowClass="rowClass"
    stripedRows
    :paginator="true"
    :rows="10"
    dataKey="id"
    :totalRecords="totalRecords"
    :value="rowsToSkip"
    responsiveLayout="scroll"
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rows-per-page-options="[10,20,50]"
    current-page-report-template="{first} to {last} of {totalRecords}"
>
    <Column
        field="rowIndex"
        headerStyle="width: 10%"
        :header="$t('labels.CSVRowNumber')"
    >
    </Column>
    <Column
        field="reason"
        headerStyle="width: 60%"
        :header="$t('labels.validationError')"
    />
    <Column headerStyle="width: 30%" :header="$t('labels.resolution')">
        <template #body="{data}">
            {{ $t('messages.rowWillBeSkipped') }}
        </template>
    </Column>
</DataTable>
<div class="w-full mt-3 flex justify-content-between">
    <p-button severity="danger" @click="discardFile">
        {{ $t("buttons.discardFile") }}
    </p-button>
    <p-button severity="success" @click="processFile">
        {{ $t("buttons.processFile") }}
    </p-button>
</div>
