<div class="m-2">
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="formgrid grid p-fluid">
            <div class="field col">
                <label
                    v-if="productDetails?.core?.teltecProductNumber !== productDetails?.weclapp?.articleNumber"
                    >{{ $t('labels.teltecProductNumber') }}
                </label>
                <label v-else>{{ $t('labels.videodataProductNumber') }} </label>
                <div>
                    <AutoComplete
                        v-model="v$.productNumber.$model"
                        :suggestions="filteredProductSuggestions"
                        @complete="searchProducts($event)"
                        :dropdown="true"
                        optionLabel="label"
                        forceSelection
                        :class="{'p-invalid':v$.productNumber.$invalid && submitted}"
                    >
                    </AutoComplete>
                    <div v-if="submitted">
                        <small
                            style="display: block"
                            v-for="error in v$.productNumber.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3 flex justify-content-end">
            <p-button
                severity="success"
                :disabled="savingInProgress"
                type="submit"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
            >
            </p-button>
        </div>
    </form>
</div>
