import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a8c9e35"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-content-center align-items-center" }
const _hoisted_2 = { class: "p-input-icon-left" }
const _hoisted_3 = { class: "w-full flex justify-content-between m-2" }

export function render(_ctx, _cache) {
  const _component_InputIcon = _resolveComponent("InputIcon")
  const _component_InputText = _resolveComponent("InputText")
  const _component_IconField = _resolveComponent("IconField")
  const _component_p_button = _resolveComponent("p-button")
  const _component_p_multiselect = _resolveComponent("p-multiselect")
  const _component_Qalendar = _resolveComponent("Qalendar")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createBlock(_component_Panel, {
    header: _ctx.$t('labels.rentalBoard')
  }, {
    icons: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_IconField, { iconPosition: "left" }, {
            default: _withCtx(() => [
              _createVNode(_component_InputIcon, { class: "pi pi-search" }),
              _createVNode(_component_InputText, {
                class: "text-sm",
                modelValue: _ctx.articleNumber,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.articleNumber) = $event)),
                modelModifiers: { trim: true },
                placeholder: _ctx.$t('labels.articleNumber'),
                onKeyup: _withKeys(_ctx.loadData, ["enter"])
              }, null, 8, ["modelValue", "placeholder", "onKeyup"])
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_p_multiselect, {
          class: "text-sm ml-3",
          modelValue: _ctx.selectedRentalStatusOptions,
          ref: "rentalStatusSelectorComponent",
          onChange: _ctx.onToggleColumnVisibility,
          options: _ctx.rentalStatusOptions,
          optionLabel: "value",
          optionValue: "id",
          placeholder: _ctx.$t('labels.rentalStatus'),
          style: {"width":"20em"}
        }, {
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_p_button, {
                severity: "danger",
                class: "text-sm",
                onClick: _cache[1] || (_cache[1] = $event => (_ctx.rentalStatusSelectorComponent.hide()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_p_button, {
                severity: "success",
                class: "text-sm",
                onClick: _cache[2] || (_cache[2] = $event => {_ctx.rentalStatusSelectorComponent.hide(); _ctx.loadData()})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "onChange", "options", "placeholder"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Qalendar, {
        events: _ctx.events,
        config: _ctx.config,
        onUpdatedPeriod: _ctx.onPeriodUpdated,
        onUpdatedMode: _ctx.onModeUpdated,
        "selected-date": _ctx.selectedDate,
        "is-loading": _ctx.loading
      }, null, 8, ["events", "config", "onUpdatedPeriod", "onUpdatedMode", "selected-date", "is-loading"])
    ]),
    _: 1
  }, 8, ["header"]))
}