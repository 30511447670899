import {IAuthState} from '@/interfaces/state';
import {IUser} from '@/interfaces/user';

export default {
    setUser: (state: IAuthState, payload: IUser): void => {
        state.user = payload;
    },
    setPlatform: (state: IAuthState, payload: string): void => {
        state.platform = payload;
    },
    setPlatforms: (
        state: IAuthState,
        payload: Array<{label: string; value: string; threeLetterId?: string}>
    ): void => {
        state.platforms = payload;
    },
    setAutoSaveProductDescription: (
        state: IAuthState,
        payload: boolean
    ): void => {
        state.autoSaveProductDescription = payload;
    }
};
