<!-- Content Header (Page header) -->
<loading v-model:active="loading" />
<Panel class="panel-container-datatable">
    <template #header>
        <h5>
            {{ $t("labels.purchaseOrderItems") }}
            <template v-if="purchaseOrderDetails">
                <span class="spacer-dot"></span
                ><a
                    :href="purchaseOrderDetails.url + 'webapp/view/purchase/purchaseOrderDetail.page?entityId=' + purchaseOrderDetails.id"
                    target="_blank"
                    >{{ purchaseOrderNumber }}</a
                >
                <span class="spacer-dot"></span>
                <span
                    >{{getCurrencyFormatted(purchaseOrderDetails.netAmount,
                    purchaseOrderDetails?.supplier?.currencyName)}}</span
                >
                <span class="spacer-dot"></span>
                <a
                    v-if="purchaseOrderDetails.supplier"
                    :href="purchaseOrderDetails.url + 'webapp/view/party/PartyDetail.page?entityId=' + purchaseOrderDetails.supplier.id"
                    target="_blank"
                    >{{purchaseOrderDetails.supplier.name}}
                    [{{purchaseOrderDetails.supplier.supplierNumber}}]</a
                >
            </template>
        </h5>
    </template>
    <DataTable
        class="p-datatable-sm"
        stripedRows
        :lazy="false"
        editMode="cell"
        @cell-edit-complete="onCellEditComplete"
        ref="dt"
        dataKey="id"
        :value="purchaseOrderDetails?.purchaseOrderItems || []"
        responsiveLayout="scroll"
    >
        <Column
            field="positionNumber"
            style="min-width: 2rem"
            :header="$t('labels.position')"
        >
        </Column>
        <Column
            field="title"
            style="min-width: 9rem"
            :header="$t('labels.article')"
        >
            <template #body="{data}">
                <div class="text-sm">
                    <template v-if="data.title.length > 60">
                        <span v-tooltip.top="data.title"
                            >{{stripTagsAndTruncate(data.title, 60, '...')
                            }}</span
                        >
                    </template>
                    <template v-else> {{data.title}} </template>
                    [<a
                        :href="purchaseOrderDetails?.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.article?.id"
                        target="_blank"
                        >{{ data.articleNumber }}</a
                    >]
                </div>
                <div class="text-xs">
                    <b
                        >{{$t('labels.createdAt')}}
                        {{getDateFormatted(data.article?.createdDate) }}<span
                            v-if="ekPriceCalculation(data.article)"
                            class="ml-3"
                            >EK {{ekPriceCalculation(data.article)}}</span
                        ></b
                    >
                </div>
            </template>
        </Column>
        <Column field="minimumStockQuantity" header="Min">
            <template #body="{data}">
                <div style="cursor: pointer">
                    {{ data.minimumStockQuantity }}
                </div>
            </template>
            <template #editor="{ data, field }">
                <InputNumber
                    :locale="locale"
                    v-model="data[field]"
                    showButtons
                    :min="0"
                    :disabled="!editingAvailable"
                />
            </template>
        </Column>
        <Column field="targetStockQuantity" header="Max">
            <template #body="{data}">
                <div style="cursor: pointer">
                    {{ data.targetStockQuantity }}
                </div>
            </template>
            <template #editor="{ data, field }">
                <InputNumber
                    :locale="locale"
                    v-model="data[field]"
                    showButtons
                    :min="0"
                    :disabled="!editingAvailable"
                />
            </template>
        </Column>
        <Column field="minMaxDate">
            <template #header>
                MinMaxAkt
                <i
                    class="pi pi-refresh"
                    style="padding-left: 7px; cursor: pointer"
                    @click="bulkUpdateMinMaxDate"
                ></i>
            </template>
            <template #body="{data}">
                <div v-if="data.minMaxDate">
                    {{ getDateFormatted(data.minMaxDate) }}
                </div>
            </template>
        </Column>
        <Column field="quantity" header="BE-Menge">
            <template #body="{data}">
                <div style="cursor: pointer">{{ data.quantity }}</div>
            </template>
            <template #editor="{ data, field }">
                <InputNumber
                    :locale="locale"
                    v-model="data[field]"
                    showButtons
                    :min="0"
                    :disabled="!editingAvailable"
                />
            </template>
        </Column>
        <Column header="Dispo">
            <template #body="{data}">
                <div>
                    <div>
                        {{parseInt(getStatisticsForArticleNumber(purchaseOrderDetails?.statistics,
                        data.articleNumber)?.extraInfo?.orderedQuantity || 0)+
                        parseInt(getStatisticsForArticleNumber(purchaseOrderDetails?.statistics,
                        data.articleNumber)?.extraInfo?.stockQuantity || 0) -
                        parseInt(getStatisticsForArticleNumber(purchaseOrderDetails?.statistics,
                        data.articleNumber)?.extraInfo?.plannedSalesQuantity ||
                        0) }}
                    </div>
                </div>
            </template>
        </Column>
        <Column header="Positionswert">
            <template #body="{data}">
                {{getCurrencyFormatted(data.netAmount,
                purchaseOrderDetails?.supplier?.currencyName)}}
            </template>
        </Column>

        <Column header="VK-RG (3M)">
            <template #body="{data}">
                {{getStatisticsForArticleNumber(purchaseOrderDetails?.statistics,
                data.articleNumber)?.salesInvoices?.lastThreeMonthsItemCount}} /
                {{getStatisticsForArticleNumber(purchaseOrderDetails?.statistics,
                data.articleNumber)?.salesInvoices?.lastThreeMonthsCount}}
            </template>
        </Column>
        <Column header="VK-RG (12M)">
            <template #body="{data}">
                {{getStatisticsForArticleNumber(purchaseOrderDetails?.statistics,
                data.articleNumber)?.salesInvoices?.itemCount}} /
                {{getStatisticsForArticleNumber(purchaseOrderDetails?.statistics,
                data.articleNumber)?.salesInvoices?.count}}
            </template>
        </Column>
        <Column header="BE (12M)">
            <template #body="{data}">
                <div>
                    <div>
                        {{getStatisticsForArticleNumber(purchaseOrderDetails?.statistics,
                        data.articleNumber)?.purchaseOrders?.itemCount}}
                    </div>
                </div>
            </template>
        </Column>
        <Column header="Letzte BE">
            <template #body="{data}">
                <div>
                    <div>
                        {{getDateFormatted(getStatisticsForArticleNumber(purchaseOrderDetails?.statistics,
                        data.articleNumber)?.purchaseOrders?.lastPurchaseOrderDate)}}
                    </div>
                </div>
            </template>
        </Column>
        <Column>
            <template #body="{data}">
                <div class="flex justify-content-center">
                    <i
                        class="pi pi-trash text-red-600 ml-2"
                        @click="handleRemoveItemClick(data)"
                        style="cursor: pointer"
                    ></i>
                </div>
            </template>
        </Column>
    </DataTable>
</Panel>
