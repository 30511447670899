<PrimeDialog
    :header="$t('labels.printSpecificDocumentRemotely', {name : selectedDocument?.name})"
    v-model:visible="showDialog"
    :closable="false"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '640px'}"
    :modal="true"
>
    <loading v-model:active="savingInProgress" />
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="grid p-fluid">
            <div class="col field">
                <label> {{$t('labels.hardwareId')}} </label>

                <p-dropdown
                    v-model="v$.hardwareId.$model"
                    :options="osInstances || []"
                    optionLabel="name"
                    optionValue="id"
                    :filter="true"
                    class="w-full"
                    @change="setDropdownValue('hardwareId', $event)"
                    :showClear="true"
                    :class="{'p-invalid':v$.hardwareId.$invalid && submitted}"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.hardwareId.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col field">
                <label> {{$t('labels.printerName')}} </label>

                <p-dropdown
                    v-model="v$.printer.$model"
                    :disabled="!v$.hardwareId.$model"
                    :options="printerOptions || []"
                    :filter="true"
                    optionLabel="id"
                    optionValue="id"
                    class="w-full"
                    @change="setDropdownValue('printer', $event)"
                    :showClear="true"
                    :class="{'p-invalid':v$.printer.$invalid && submitted}"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.printer.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid p-fluid" v-if="printerSizeSelectable">
            <div class="col field">
                <label> {{$t('labels.printerSize')}} </label>

                <p-dropdown
                    v-model="v$.printerSize.$model"
                    :disabled="!v$.hardwareId.$model"
                    :options="printerSizeOptions || []"
                    optionLabel="id"
                    optionValue="id"
                    :filter="true"
                    class="w-full"
                    @change="setDropdownValue('printerSize', $event)"
                    :showClear="true"
                    :class="{'p-invalid':v$.printerSize.$invalid && submitted}"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.printerSize.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="mt-3 flex justify-content-between">
            <p-button severity="danger" @click="onCancelClick">
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button severity="success" type="submit">
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </form>
</PrimeDialog>
