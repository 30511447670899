import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {i18n} from '@/utils/i18n';
import {AxiosResponse} from 'axios';

export const getSingle = (
    ticketNumber: string | string[]
): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/tickets/' + ticketNumber);
};

export const getAll = (input: any): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/tickets?allEnvs=' +
            input.allEnvs +
            '&start=' +
            input.first +
            '&end=' +
            (input.first + input.rows) +
            '&sortField=' +
            (input.sortField ?? '') +
            '&sortOrder=' +
            (input.sortOrder === 1 ? 'asc' : 'desc') +
            '&filters=' +
            encodeURIComponent(JSON.stringify(clearEmptyFilters(input.filters)))
    );
};

export const getStatusCellStyle = (value: string) => {
    if (!value) {
        return '';
    }
    let color = 'black';
    switch (value) {
        case 'Noch nicht zugewiesen':
            color = 'pink';
            break;
        case 'Bestätigt':
        case 'Gerät in interner Reparatur':
            color = 'blue';
            break;
        case 'Zugewiesen':
            color = 'lightgreen';
            break;
        case 'In Bearbeitung':
            color = 'orange';
            break;
        case 'Wartend':
        case 'Warten auf Kundenreaktion':
            color = 'purple';
            break;
        case 'Gelöst':
            color = 'darkgreen';
            break;
        case 'Keine Lösung':
        case 'Kunde wartet auf Reaktion':
            color = 'red';
            break;
        case 'Kunde wartet auf Gutschrift':
            color = 'brown';
            break;
        case 'Geschlossen':
            color = 'lightblue';
            break;
        case 'Warten auf Einsendung':
            color = 'violet';
            break;
    }

    return 'background-color:' + color + '; color:white;';
};

export const getPriorityCellStyle = (value: string) => {
    if (!value) {
        return '';
    }
    let color = 'green';
    switch (value.toLowerCase()) {
        case 'hoch':
            color = 'red';
            break;
        case 'normal':
            color = 'orange';
            break;
    }

    return 'color:' + color + ';';
};

export const getReferenceItemLayout = (referenceItem: any, url: string) => {
    const properties = Object.keys(referenceItem);

    if (properties.includes('incomingGoodsNumber')) {
        return (
            '<a href="' +
            url +
            'webapp/view/shipment/ShipmentDetailArrival.page?entityId=' +
            referenceItem.id +
            '"target="_blank">' +
            i18n.global.t(
                'labels.ticket.references.incomingGoods.' +
                    referenceItem.incomingGoodsType
            ) +
            ' ' +
            referenceItem.incomingGoodsNumber +
            '</a>' +
            '&nbsp;(' +
            i18n.global.t(
                'labels.ticket.retoure.status.' + referenceItem.status
            ) +
            ')'
        );
    }

    if (properties.includes('quotationNumber')) {
        return (
            '<a href="' +
            url +
            'webapp/view/offer/OfferDetail.page?entityId=' +
            referenceItem.id +
            '"target="_blank">' +
            i18n.global.t('labels.ticket.references.quotation') +
            ' ' +
            referenceItem.quotationNumber +
            '</a>'
        );
    }

    if (properties.includes('invoiceNumber')) {
        return (
            '<a href="' +
            url +
            'webapp/view/invoicing/transactionData/debtor/salesInvoiceDetail.page?entityId=' +
            referenceItem.id +
            '"target="_blank">' +
            i18n.global.t('labels.ticket.references.invoice') +
            ' ' +
            referenceItem.invoiceNumber +
            '</a>'
        );
    }

    if (properties.includes('orderNumber')) {
        return (
            '<a href="' +
            url +
            'webapp/view/op/order/salesOrderDetail.page?entityId=' +
            referenceItem.id +
            '"target="_blank">' +
            i18n.global.t('labels.ticket.references.salesOrder') +
            ' ' +
            referenceItem.orderNumber +
            '</a>'
        );
    }

    if (properties.includes('shipmentNumber')) {
        return (
            '<a href="' +
            url +
            'webapp/view/shipment/ShipmentDetailOutgoing.page?entityId=' +
            referenceItem.id +
            '"target="_blank">' +
            i18n.global.t(
                'labels.ticket.references.shipment.' +
                    referenceItem.shipmentType
            ) +
            ' ' +
            referenceItem.shipmentNumber +
            '</a>' +
            '&nbsp;(' +
            i18n.global.t(
                'labels.shipment.statusOptions.' + referenceItem.status
            ) +
            ')'
        );
    }

    if (properties.includes('purchaseOrderNumber')) {
        return (
            '<a href="' +
            url +
            'webapp/view/purchase/purchaseOrderDetail.page?entityId=' +
            referenceItem.id +
            '"target="_blank">' +
            i18n.global.t(
                'labels.ticket.references.purchaseOrder.' +
                    referenceItem.purchaseOrderType
            ) +
            ' ' +
            referenceItem.purchaseOrderNumber +
            '</a>'
        );
    }

    return '';
};

export const addNewRetoure = async (
    data: any,
    ticketNumber: string
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/tickets/' + ticketNumber + '/incoming-goods',
        Object.assign(data, {
            incomingGoodsType: 'CUSTOMER_RETURN'
        })
    );
};

export const addNewRepairQuotation = async (
    data: any,
    ticketNumber: string
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/tickets/' + ticketNumber + '/repair-quotation',
        data
    );
};

export const addNewComment = async (
    ticketNumber: string,
    comment: string
): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/tickets/' + ticketNumber + '/comment', {
        comment
    });
};

export const addNewEmail = async (
    ticketNumber: string,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/tickets/' + ticketNumber + '/email',
        payload
    );
};

export const sendUpsLabelToCustomer = async (
    ticketNumber: string,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/tickets/' + ticketNumber + '/send-ups-label-to-customer',
        payload
    );
};

export const createSupplierReturn = async (
    ticketNumber: string,
    incomingGoodsNumber: string,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/tickets/' +
            ticketNumber +
            '/incoming-goods/' +
            incomingGoodsNumber +
            '/supplier-return',
        payload
    );
};

export const createCompensationShipment = async (
    ticketNumber: string,
    incomingGoodsNumber: string,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/tickets/' +
            ticketNumber +
            '/incoming-goods/' +
            incomingGoodsNumber +
            '/compensation-shipment',
        payload
    );
};

export const createLabel = async (
    ticketNumber: string,
    incomingGoodsNumber: string
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/tickets/' +
            ticketNumber +
            '/incoming-goods/' +
            incomingGoodsNumber +
            '/create-label'
    );
};

export const bookTicketShipment = async (
    ticketNumber: string,
    shipmentNumber: string,
    items: Array<{
        id: string;
        serialNumbers: Array<string>;
        articleNumber: string;
        shipmentQuantity: number;
        sourceStoragePlaceId: string;
        destinationStoragePlaceId: string;
    }>
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/tickets/' +
            ticketNumber +
            '/shipment/' +
            shipmentNumber +
            '/book-and-send',
        {items}
    );
};

export const getImages = async (
    ticketNumber: string
): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/tickets/' + ticketNumber + '/images');
};

export const addImages = async (
    ticketNumber: string,
    files: Array<any>
): Promise<AxiosResponse> => {
    const formData = new FormData();
    files.forEach((file: any) => {
        formData.append('file[]', file);
    });

    return apiClient.post(
        '/api/v1/tickets/' + ticketNumber + '/images',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
};

export const updateDetails = async (
    ticketNumber: string,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/tickets/' + ticketNumber, payload);
};
