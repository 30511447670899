import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fb77657"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid p-fluid mb-3" }
const _hoisted_2 = { class: "field col" }
const _hoisted_3 = {
  key: 1,
  style: {"display":"block"}
}
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = { class: "grid p-fluid mb-3" }
const _hoisted_6 = { class: "col field" }
const _hoisted_7 = { class: "w-full flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.shipment.upsDangerousGoodsData'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[5] || (_cache[5] = $event => ((_ctx.showDialog) = $event)),
    closable: false,
    breakpoints: {'960px': '75vw', '640px': '90vw'},
    style: {width: '640px'},
    modal: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_loading, {
        active: _ctx.savingInProgress,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.savingInProgress) = $event))
      }, null, 8, ["active"]),
      _createElementVNode("form", {
        onSubmit: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsChemicalId')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$.chemicalId.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.chemicalId.$model) = $event)),
              options: _ctx.chemicalOptions,
              optionLabel: "idNumber",
              optionValue: "idNumber",
              class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.chemicalId.$invalid && _ctx.submitted}]),
              onChange: _cache[2] || (_cache[2] = $event => (_ctx.setDropdownValue('chemicalId', $event))),
              showClear: true
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.chemicalId.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true),
            (_ctx.v$.chemicalId.$model)
              ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.chemicalOptions.find((item) => item.idNumber ===
                    _ctx.v$.chemicalId.$model).hazardousMaterialsDescription), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.v$.numberOfPackages.$model)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.state.weightCombined, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "grid p-fluid mb-3",
                key: index
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.netWeight')) + " (Paket " + _toDisplayString(index +
                        1) + ") ", 1),
                  _createVNode(_component_InputNumber, {
                    class: _normalizeClass(["w-full", {'p-invalid':_ctx.submitted && _ctx.v$.weightCombined.$each.$response.$data[index].weight.$invalid}]),
                    disabled: (_ctx.v$.numberOfPackages.$model || 0) < 2,
                    autocomplete: "off",
                    locale: "de-DE",
                    mode: "decimal",
                    showButtons: "",
                    minFractionDigits: 2,
                    step: 0.001,
                    modelValue: item.weight,
                    "onUpdate:modelValue": $event => ((item.weight) = $event),
                    onInput: $event => (_ctx.onPackageWeightChange(index, $event))
                  }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue", "class", "onInput"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.weightCombined.$each.$response.$errors[index].weight, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 128))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.numberOfDangerousGoodsPackages')), 1),
            _createVNode(_component_InputNumber, {
              autocomplete: "off",
              modelValue: _ctx.v$.numberOfPackages.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.numberOfPackages.$model) = $event)),
              class: "w-full",
              showButtons: "",
              min: 1,
              onInput: _ctx.onPackagesNumberChange
            }, null, 8, ["modelValue", "onInput"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.numberOfPackages.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_p_button, {
            severity: "danger",
            onClick: _ctx.onCancelClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_p_button, {
            severity: "success",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
            ]),
            _: 1
          })
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}