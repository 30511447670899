import {ILayoutConfig} from '@/interfaces/layout/config';

export default {
    bookmarks: (state: {
        bookmarks: Array<{
            id: string;
            entityId: string;
            entityName: string;
            path: string;
        }>;
        layoutConfig: ILayoutConfig;
    }): Array<{
        id: string;
        entityId: string;
        entityName: string;
        path: string;
    }> => state.bookmarks,
    layoutConfig: (state: {layoutConfig: ILayoutConfig}): ILayoutConfig =>
        state.layoutConfig
};
