import {computed, onMounted, ref, toRefs} from 'vue';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Column from 'primevue/column';
import LoadingPlugin from 'vue-loading-overlay';
import Divider from 'primevue/divider';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Calendar from 'primevue/calendar';

import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import {getJobMailLogs, getMailLogResultFile} from '@/services/crons';
import {i18n} from '@/utils/i18n';
import {DateTime} from 'luxon';
import store from '@/store';
import {ILayoutConfig} from '@/interfaces/layout/config';
import FileSaver from 'file-saver';
export default {
    emits: ['on-selection-change'],
    components: {
        DataTable,
        Column,
        Dropdown,
        InputText,
        loading: LoadingPlugin,
        'p-divider': Divider,
        TriStateCheckbox,
        Calendar
    },
    props: {
        cron: Object
    },
    setup(props: any) {
        const {cron} = toRefs(props);

        onMounted(() => {
            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'id',
                sortOrder: -1,
                filters: filters.value
            };

            loadLazyData();
        });

        const totalRecords = ref(0);
        const loading = ref(false);
        const logs = ref();
        const dt = ref();
        const lazyParams: any = ref({});
        const toast = useToast();

        const expandedRows = ref([]);

        const onPage = (event: any) => {
            if (!lazyParams.value) {
                lazyParams.value = event;
            } else {
                lazyParams.value.first = event.first || 0;
                lazyParams.value.rows = event.rows || dt.value.rows;
            }
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = Object.assign(
                filters.value,
                props.preDefinedFilters || {}
            );
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            lazyParams.value.filters = Object.assign(
                filters.value,
                props.preDefinedFilters || {}
            );
            loadLazyData();
        };

        const loadLazyData = () => {
            loading.value = true;
            getJobMailLogs(cron.value.id, lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    logs.value = data.data.items.map((obj: any) => ({
                        ...obj,
                        createdAtFormatted: DateTime.fromSeconds(obj.createdAt)
                            .setLocale(i18n.global.locale)
                            .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                            .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
                    }));
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const filterDefintions: any = {
            entityInstanceFriendlyId: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            emailToAddress: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            emailCcAddress: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            emailAttachment: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            error: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            createdAt: {
                value: null,
                matchMode: FilterMatchMode.DATE_IS
            }
        };

        const filters = ref(filterDefintions);

        const entityColumnHeader = computed(() => {
            if (
                !logs.value ||
                !logs.value.some((item: any) => item.entityName)
            ) {
                return null;
            }

            const one = logs.value.find((item: any) => item.entityName);
            switch (one.entityName || '') {
                default:
                    return i18n.global.t('labels.shipment.shipmentNumber');
            }
        });

        const getEntityColumnContent = (item: any) => {
            switch (item.entityName || '') {
                default:
                    return (
                        '<a href="' +
                        item.url +
                        'webapp/view/shipment/ShipmentDetailOutgoing.page?entityId=' +
                        item.entityInstanceId +
                        '" target="_blank">' +
                        item.entityInstanceFriendlyId +
                        '</a>'
                    );
            }
        };

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        const downloadAttachment = async (id: number) => {
            const file = await getMailLogResultFile(cron.value.id, id);

            const filename = file.headers['content-disposition']
                .split('filename=')[1]
                .split(';')[0];

            FileSaver.saveAs(
                new Blob([file.data], {
                    type: file.headers['content-type']
                }),
                filename
            );
        };

        return {
            filters,
            dt,
            logs,
            loading,
            loadLazyData,
            totalRecords,
            onFilter,
            onPage,
            onSort,
            rowClass: (data: any) => {
                const colorVariant = layoutConfig.value?.darkTheme ? 800 : 200;
                return data && data.error
                    ? 'bg-red-' + colorVariant
                    : 'bg-green-' + colorVariant;
            },
            expandedRows,
            entityColumnHeader,
            getEntityColumnContent,
            downloadAttachment
        };
    }
};
