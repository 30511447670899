import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ae51810"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "text-sm"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 1,
  class: "text-sm"
}
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_FilterMenu = _resolveComponent("FilterMenu")
  const _component_Column = _resolveComponent("Column")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _component_BookInternalIncomingGoods = _resolveComponent("BookInternalIncomingGoods")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.incomingGoods')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: "",
          rows: 20,
          scrollable: "",
          scrollHeight: "calc(100vh - 25rem)",
          lazy: "",
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.incomingGoods,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[1] || (_cache[1] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          onPage: _cache[2] || (_cache[2] = $event => (_ctx.onPage($event))),
          onFilter: _cache[3] || (_cache[3] = $event => (_ctx.onFilter($event))),
          onSort: _cache[4] || (_cache[4] = $event => (_ctx.onSort($event))),
          onStateRestore: _ctx.onStateRestore,
          stateStorage: "local",
          stateKey: "incoming-goods-list-state-session"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              style: {"min-width":"1rem"},
              class: "tt-filter-menu-th"
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_FilterMenu, {
                  "parent-component-id": "incoming-goods-list",
                  "parent-filters": _ctx.filters,
                  onClearFilters: _ctx.clearFilters,
                  onApplyFilters: _ctx.applyFilters
                }, null, 8, ["parent-filters", "onClearFilters", "onApplyFilters"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "incomingGoodsNumber",
              sortable: "",
              style: {"min-width":"8rem"},
              header: _ctx.$t('labels.incomingGoodsNumber')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onInput: $event => (filterCallback()),
                  class: "p-column-filter text-sm",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("a", {
                  href: data.url + 'webapp/view/shipment/ShipmentDetailArrival.page?entityId=' + data.id,
                  target: "_blank"
                }, _toDisplayString(data.incomingGoodsNumber), 9, _hoisted_1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "createdDate",
              dataType: "date",
              sortable: true,
              header: _ctx.$t('labels.createdAt'),
              style: {"min-width":"10rem"}
            }, {
              filter: _withCtx(({filterModel}) => [
                _createVNode(_component_Calendar, {
                  class: "p-column-filter text-xs",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  dateFormat: "dd.mm.yy"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx((data) => [
                _createTextVNode(_toDisplayString(new Date(data.data.createdDate).toLocaleDateString("de-DE")), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "sender",
              header: _ctx.$t('labels.shipment.sender'),
              style: {"min-width":"10rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.shipment.sender')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("a", {
                  class: "text-xs",
                  href: data.url + 'webapp/view/party/PartyDetail.page?entityId=' + data.recipientParty?.id,
                  target: "_blank"
                }, _toDisplayString(_ctx.outputSenderInGrid(data)), 9, _hoisted_2)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.status'),
              style: {"min-width":"10rem"},
              field: "status",
              sortable: "",
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("span", {
                  class: _normalizeClass({'shipped': 'INCOMING_SHIPPED' === data.status, 'moved-into-store': 'INCOMING_MOVED_INTO_STORE' === data.status, 'cancelled' : 'INCOMING_CANCELLED' === data.status })
                }, _toDisplayString(_ctx.$t('labels.ticket.retoure.status.' + data.status)), 3)
              ]),
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_Dropdown, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  options: ['INCOMING_MOVED_INTO_STORE', 'INCOMING_SHIPPED', 'INCOMING_CANCELLED'],
                  placeholder: "Any",
                  class: "p-column-filter text-sm",
                  onChange: $event => (filterCallback())
                }, {
                  value: _withCtx((slotProps) => [
                    (slotProps.value)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('labels.ticket.retoure.status.' +
                            slotProps.value)), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(slotProps.placeholder), 1))
                  ]),
                  option: _withCtx((slotProps) => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('labels.ticket.retoure.status.' +
                            slotProps.option)), 1)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "salesOrder",
              header: _ctx.$t('labels.shipment.orderNumber'),
              style: {"min-width":"10rem"}
            }, {
              body: _withCtx(({data}) => [
                (data.salesOrder)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: data.url + 'webapp/view/op/order/salesOrderDetail.page?entityId=' + data.salesOrder.id,
                      target: "_blank"
                    }, _toDisplayString(data.salesOrder.orderNumber), 9, _hoisted_5))
                  : _createCommentVNode("", true)
              ]),
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.incomingGoodsType'),
              style: {"min-width":"10rem"},
              field: "incomingGoodsType",
              sortable: "",
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("span", {
                  class: _normalizeClass({'text-blue-600': 'STANDARD' === data.incomingGoodsType, 'text-purple-600': 'SUPPLIER_COMPENSATION' === data.incomingGoodsType, 'text-orange-600' : 'INTERNAL' === data.incomingGoodsType, 'text-red-600': 'CUSTOMER_RETURN' === data.incomingGoodsType })
                }, _toDisplayString(_ctx.$t('labels.ticket.references.incomingGoods.' +
                    data.incomingGoodsType)), 3)
              ]),
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_Dropdown, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  options: ['STANDARD', 'INTERNAL', 'SUPPLIER_COMPENSATION', 'CUSTOMER_RETURN'],
                  placeholder: "Any",
                  class: "p-column-filter text-sm",
                  onChange: $event => (filterCallback())
                }, {
                  value: _withCtx((slotProps) => [
                    (slotProps.value)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: _normalizeClass({'text-blue-600': 'STANDARD' === slotProps.value, 'text-purple-600': 'SUPPLIER_COMPENSATION' === slotProps.value, 'text-orange-600' : 'INTERNAL' === slotProps.value, 'text-red-600': 'CUSTOMER_RETURN' === slotProps.value })
                        }, _toDisplayString(_ctx.$t('labels.ticket.references.incomingGoods.' +
                            slotProps.value)), 3))
                      : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(slotProps.placeholder), 1))
                  ]),
                  option: _withCtx((slotProps) => [
                    _createElementVNode("span", {
                      class: _normalizeClass({'text-blue-600': 'STANDARD' === slotProps.option, 'text-purple-600': 'SUPPLIER_COMPENSATION' === slotProps.option, 'text-orange-600' : 'INTERNAL' === slotProps.option, 'text-red-600': 'CUSTOMER_RETURN' === slotProps.option })
                    }, _toDisplayString(_ctx.$t('labels.ticket.references.incomingGoods.' +
                            slotProps.option)), 3)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "relatedShipmentId",
              header: _ctx.$t('labels.incomingGoodsRelatedShipment'),
              style: {"min-width":"10rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onInput: $event => (filterCallback()),
                  class: "p-column-filter text-xs",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
              ]),
              body: _withCtx(({data}) => [
                (data.relatedShipment)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: data.url + 'webapp/view/shipment/ShipmentDetailOutgoing.page?entityId=' + data.relatedShipment.id,
                      target: "_blank"
                    }, _toDisplayString(data.relatedShipment.shipmentNumber), 9, _hoisted_7))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "purchaseOrder",
              header: _ctx.$t('labels.incomingGoodsPurchaseOrder'),
              style: {"min-width":"10rem"}
            }, {
              body: _withCtx(({data}) => [
                (data.purchaseOrder)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      class: "text-xs",
                      href: data.url + 'webapp/view/purchase/purchaseOrderDetail.page?entityId=' + data.purchaseOrder.id,
                      target: "_blank"
                    }, _toDisplayString(data.purchaseOrder.purchaseOrderNumber), 9, _hoisted_8))
                  : _createCommentVNode("", true)
              ]),
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "warehouse",
              header: _ctx.$t('labels.ticket.retoure.warehouse'),
              style: {"min-width":"10rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.shipment.warehouse')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                (data.warehouse?.name)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(data.warehouse?.name), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, null, {
              body: _withCtx(({data}) => [
                (_ctx.editPermissionAvailable && data.status === 'INCOMING_SHIPPED' && data.incomingGoodsType === 'INTERNAL')
                  ? _withDirectives((_openBlock(), _createElementBlock("span", {
                      key: 0,
                      onClick: $event => (_ctx.onInternalBookingClick(data.incomingGoodsNumber, $event)),
                      style: {"cursor":"pointer"}
                    }, [
                      _createVNode(_component_font_awesome_icon, { icon: ['fas', 'angle-double-down'] })
                    ], 8, _hoisted_10)), [
                      [
                        _directive_tooltip,
                        _ctx.$t('labels.bookIncomingGoodsItems'),
                        void 0,
                        { left: true }
                      ]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "filters", "onStateRestore"])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_BookInternalIncomingGoods, {
      "display-dialog": _ctx.showBookIntenalDialog,
      "incoming-goods-number": _ctx.selectedIncomingGoodsNumber,
      onCloseDialog: _ctx.onCloseInternalBookingDialog
    }, null, 8, ["display-dialog", "incoming-goods-number", "onCloseDialog"])
  ], 64))
}