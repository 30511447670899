import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Column = _resolveComponent("Column")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_ProgressBar = _resolveComponent("ProgressBar")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_DataTable = _resolveComponent("DataTable")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DataTable, {
        class: "p-datatable-sm text-sm",
        stripedRows: "",
        paginator: true,
        rows: 10,
        lazy: true,
        dataKey: "id",
        ref: "dt",
        totalRecords: _ctx.totalRecords,
        value: _ctx.logs,
        filters: _ctx.filters,
        "onUpdate:filters": _cache[1] || (_cache[1] = $event => ((_ctx.filters) = $event)),
        filterDisplay: "row",
        onPage: _cache[2] || (_cache[2] = $event => (_ctx.onPage($event))),
        onFilter: _cache[3] || (_cache[3] = $event => (_ctx.onFilter($event))),
        onSort: _cache[4] || (_cache[4] = $event => (_ctx.onSort($event))),
        responsiveLayout: "scroll",
        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
        "rows-per-page-options": [10,20,50],
        "current-page-report-template": "{first} to {last} of {totalRecords}"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            style: {"min-width":"8rem"},
            field: "initiatedBy",
            header: _ctx.$t('labels.initiatedBy'),
            sortable: true
          }, {
            filter: _withCtx(({filterModel,filterCallback}) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: filterModel.value,
                "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                class: "p-column-filter text-sm",
                placeholder: _ctx.$t('labels.email')
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "processStartedAt",
            header: _ctx.$t('labels.logTime'),
            sortable: true,
            dataType: "date"
          }, {
            filter: _withCtx(({filterModel}) => [
              _createVNode(_component_Calendar, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                dateFormat: "mm/dd/yy",
                placeholder: "mm/dd/yyyy"
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            body: _withCtx(({data}) => [
              _createTextVNode(_toDisplayString(data.processStartedAtFormatted), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "status",
            header: _ctx.$t('labels.status'),
            sortable: true,
            style: {"min-width":"6rem"},
            filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
          }, {
            filter: _withCtx(({filterModel, filterCallback}) => [
              _createVNode(_component_Dropdown, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                options: [{label: _ctx.$t('labels.scheduled'), value: 'scheduled', color: 'orange'}, {label: _ctx.$t('labels.inProgress'), value: 'inProgress', color: 'lightgreen'}, {label: _ctx.$t('labels.done'), value: 'done', color: 'darkgreen'}],
                placeholder: "Any",
                optionLabel: "label",
                optionValue: "value",
                class: "p-column-filter text-sm",
                showClear: true,
                onChange: filterCallback
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
            ]),
            body: _withCtx(({data}) => [
              _createElementVNode("div", null, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.getStatusCellStyle(data))
                }, _toDisplayString(_ctx.getStatusCellContent(data)), 3)
              ])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('labels.progress'),
            style: {"min-width":"12rem"}
          }, {
            body: _withCtx(({data}) => [
              (data.processStartedAt)
                ? (_openBlock(), _createBlock(_component_ProgressBar, {
                    key: 0,
                    style: {"height":"1.3em"},
                    value: data.totalRecords && data.processedRecords ? Math.floor((data.processedRecords * 100) / data.totalRecords) : 0
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('labels.sentResultTo'),
            style: {"min-width":"9rem"},
            field: "emailToAddress"
          }, null, 8, ["header"]),
          _createVNode(_component_Column, { style: {"min-width":"6rem"} }, {
            body: _withCtx(({data}) => [
              (data.resultFileError)
                ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, [
                    _createVNode(_component_font_awesome_icon, {
                      class: "mr-2",
                      style: {"font-size":"1.5rem","color":"red"},
                      icon: ['fas', 'file-csv'],
                      onClick: $event => (_ctx.downloadResultErrorFile($event, data))
                    }, null, 8, ["onClick"])
                  ])), [
                    [_directive_tooltip, _ctx.$t('labels.errorFileExport')]
                  ])
                : _createCommentVNode("", true),
              (data.resultFile)
                ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_3, [
                    _createVNode(_component_font_awesome_icon, {
                      class: "mr-2",
                      style: {"font-size":"1.5rem","color":"darkgreen"},
                      icon: ['fas', 'file-csv'],
                      onClick: $event => (_ctx.downloadResultFile($event, data))
                    }, null, 8, ["onClick"])
                  ])), [
                    [_directive_tooltip, _ctx.$t('labels.resultFileExport')]
                  ])
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["totalRecords", "value", "filters"])
    ])
  ], 64))
}