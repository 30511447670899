import {computed, onMounted, ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import useVuelidate from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import Dropdown from 'primevue/dropdown';
import {getAll} from '@/services/metadata';
import {sendUpsLabelToRemotePrinter} from '@/services/shipments';
import store from '@/store';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        'p-button': Button,
        'p-dropdown': Dropdown,
        loading: LoadingPlugin
    },
    props: {
        displayDialog: Boolean,
        shipmentNumber: String,
        trackingNumber: String
    },
    setup(props: any, context: any) {
        const {shipmentNumber, trackingNumber} = toRefs(props);
        const showDialog = ref(false);
        const toast = useToast();
        const savingInProgress = ref(false);
        const submitted = ref(false);
        const osInstances = ref([]);
        const printerSizeOptions = ref([]);
        const loading = ref(false);

        const state = ref({
            hardwareId: null,
            printer: null,
            printerSize: null
        });

        onMounted(() => {
            getAll(['weclappOS', 'paperSize'], false).then(
                async (data: any) => {
                    osInstances.value = data.data['weclappOS'];
                    printerSizeOptions.value = (
                        data.data['paperSize'] || []
                    ).map((option: string) => {
                        return {
                            id: option
                        };
                    });
                    state.value.hardwareId = await store.dispatch(
                        'printer/getHardwareIdForPlatform',
                        store.getters['auth/platform']
                    );

                    state.value.printer = await store.dispatch(
                        'printer/getPrinterForPlatform',
                        store.getters['auth/platform']
                    );

                    state.value.printerSize = await store.dispatch(
                        'printer/getPrinterSizeForPlatform',
                        store.getters['auth/platform']
                    );
                }
            );
        });

        const rules = {
            hardwareId: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            printer: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            printerSize: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            }
        };

        const v$ = useVuelidate(rules, state);

        watch(props, async (args) => {
            showDialog.value = args.displayDialog;
            if (showDialog.value) {
                if (osInstances.value.length > 0) {
                    state.value.hardwareId = await store.dispatch(
                        'printer/getHardwareIdForPlatform',
                        store.getters['auth/platform']
                    );
                }

                if (printerOptions.value.length > 0) {
                    state.value.printer = await store.dispatch(
                        'printer/getPrinterForPlatform',
                        store.getters['auth/platform']
                    );
                }

                if (printerSizeOptions.value.length > 0) {
                    state.value.printerSize = await store.dispatch(
                        'printer/getPrinterSizeForPlatform',
                        store.getters['auth/platform']
                    );
                }
            }
        });

        const onCancelClick = (event: any) => {
            event.preventDefault();
            showDialog.value = false;
            submitted.value = false;
            state.value = {
                hardwareId: null,
                printer: null,
                printerSize: null
            };
            context.emit('close-dialog');
        };

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            try {
                savingInProgress.value = true;
                await sendUpsLabelToRemotePrinter(
                    shipmentNumber.value,
                    trackingNumber.value,
                    state.value
                );
                toast.success(
                    i18n.global.t('messages.changesSavedSuccessfully')
                );
                context.emit('close-dialog', true);
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
                context.emit('close-dialog');
            } finally {
                submitted.value = false;
                state.value = {
                    hardwareId: null,
                    printer: null,
                    printerSize: null
                };
                savingInProgress.value = false;
                showDialog.value = false;
            }
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;

            Object.assign(state.value, temp);

            const platform = store.getters['auth/platform'];
            store.dispatch('printer/setRemote', {
                platform,
                hardwareId: state.value.hardwareId,
                printer: state.value.printer,
                printerSize: state.value.printerSize
            });
        };

        const printerOptions = computed(() => {
            if (!state.value.hardwareId) {
                return [];
            }

            return osInstances.value
                .find((item: any) => {
                    return item.id === state.value.hardwareId;
                })
                .printerNames.map((option: string) => {
                    return {
                        id: option
                    };
                });
        });

        return {
            showDialog,
            loading,
            osInstances,
            onCancelClick,
            handleSubmit,
            state,
            v$,
            submitted,
            savingInProgress,
            setDropdownValue,
            printerOptions,
            printerSizeOptions
        };
    }
};
