export default {
    setShippingLocation: (
        context: any,
        payload: {platform: string; shippingLocation: string}
    ): void => {
        const currentState = context.getters['shippingLocation'];
        const existingItemIndex = currentState.findIndex((item: any) => {
            return item.platform === payload.platform;
        });

        if (existingItemIndex !== -1) {
            currentState[existingItemIndex] = payload;
        } else {
            currentState.push(payload);
        }
        context.commit('setShippingLocation', currentState);
        localStorage.setItem('shippingLocation', JSON.stringify(currentState));
    },

    setOpenShipments: (context: any, payload: {warehouseId: string}): void => {
        context.commit('setOpenShipments', payload);
        localStorage.setItem('openShipments', JSON.stringify(payload));
    },

    getLocationForPlatform(context: any, platform: string | null): string {
        if (!platform) {
            return '1';
        }
        const items = context.getters['shippingLocation'];
        const existingItem = items.find(
            (item: any) => item.platform === platform
        );

        return existingItem ? existingItem.shippingLocation : '1';
    },

    getOpenShipments(context: any): {warehouseId: string} {
        return JSON.parse(
            localStorage.getItem('openShipments') ||
                JSON.stringify(context.getters['openShipments'] || {})
        );
    }
};
