export default {
    setRemote: (
        state: {
            remote: Array<{
                platform: string;
                hardwareId: string;
                printer: string;
                printerSize: string;
            }>;
        },
        payload: Array<{
            platform: string;
            hardwareId: string;
            printer: string;
            printerSize: string;
        }>
    ): void => {
        state.remote = payload;
    }
};
