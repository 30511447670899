import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex align-items-center" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "text-xs" }
const _hoisted_4 = { class: "text-xs" }
const _hoisted_5 = { class: "text-xs" }
const _hoisted_6 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_7 = {
  key: 1,
  class: "text-xs"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 0,
  class: "text-xs fint-bold text-red-600"
}
const _hoisted_11 = { style: {"text-transform":"uppercase"} }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "text-xs" }
const _hoisted_15 = { class: "text-xs" }
const _hoisted_16 = { class: "font-bold" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_p_button = _resolveComponent("p-button")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Column = _resolveComponent("Column")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _component_CreateHeadlessShipmentDialog = _resolveComponent("CreateHeadlessShipmentDialog")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, null, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('labels.shipment.headlessShipments')), 1),
          (_ctx.editPermissionAvailable)
            ? (_openBlock(), _createBlock(_component_p_button, {
                key: 0,
                class: "ml-2",
                onClick: _cache[1] || (_cache[1] = $event => {_ctx.showCreateDialog = true;}),
                size: "small",
                severity: "success"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("buttons.addNewGeneric")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: true,
          lazy: true,
          rows: 20,
          scrollable: "",
          scrollHeight: "calc(100vh - 25rem)",
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.headlessShipments,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[2] || (_cache[2] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          onPage: _ctx.onPage,
          onFilter: _ctx.onFilter,
          onSort: _ctx.onSort
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "customerName",
              header: _ctx.$t('labels.customer'),
              sortable: ""
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "address",
              header: _ctx.$t('labels.address'),
              sortable: ""
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("b", null, _toDisplayString(data.address), 1)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("b", null, _toDisplayString(data.zipCode) + " " + _toDisplayString(data.city), 1)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("b", null, _toDisplayString(data.country), 1)
                  ]),
                  (data.phoneNumber)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(data.phoneNumber), 1))
                    : _createCommentVNode("", true),
                  (data.emailAddress)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(data.emailAddress), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "referenceNumber",
              sortable: "",
              header: _ctx.$t('labels.shipment.referenceNumber')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "carrierName",
              sortable: "",
              header: _ctx.$t('labels.shipment.shippingCarrier')
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_Dropdown, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  options: _ctx.availableCarriers,
                  placeholder: "Any",
                  optionLabel: "label",
                  optionValue: "value",
                  onChange: filterCallback,
                  class: "p-column-filter text-sm",
                  showClear: true
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "warehouseName",
              sortable: "",
              header: _ctx.$t('labels.shipment.warehouse')
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_Dropdown, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  options: _ctx.warehouses,
                  placeholder: "Any",
                  optionLabel: "name",
                  optionValue: "id",
                  onChange: filterCallback,
                  class: "p-column-filter text-sm",
                  showClear: true
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: "Tracking #",
              field: "trackingNumber",
              sortable: "",
              style: {"min-width":"6rem","max-width":"fit-content"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(data.external?.trackingNumber) + " ", 1),
                  _withDirectives(_createElementVNode("i", {
                    class: "pi pi-instagram ml-1",
                    style: {"margin-top":"2px"},
                    onClick: $event => (_ctx.showTrackingLabel(data))
                  }, null, 8, _hoisted_8), [
                    [
                      _directive_tooltip,
                      _ctx.$t('labels.shipment.showShippingCarrierLabel', {carrierName:  data.carrierName}),
                      void 0,
                      { top: true }
                    ]
                  ]),
                  (_ctx.editPermissionAvailable && !data.cancelledAtFormatted)
                    ? _withDirectives((_openBlock(), _createElementBlock("i", {
                        key: 0,
                        onClick: $event => (_ctx.voidShipment(data)),
                        class: "pi pi-ban ml-2 text-red-600",
                        style: {"margin-top":"2px","cursor":"pointer"}
                      }, null, 8, _hoisted_9)), [
                        [
                          _directive_tooltip,
                          _ctx.$t('labels.shipment.cancelShippingCarrierShipment', {carrierName: data.carrierName}),
                          void 0,
                          { left: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ]),
                (data.cancelledAtFormatted)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('labels.shipment.statusOptions.CANCELLED')) + " " + _toDisplayString(data.cancelledAtFormatted), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              sortable: "",
              style: {"min-width":"9rem","max-width":"fit-content"}
            }, {
              body: _withCtx(({data}) => [
                (data.import?.fileName)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createTextVNode(_toDisplayString(data.import.fileName) + " ", 1),
                      _withDirectives(_createElementVNode("i", {
                        class: "pi pi-file-pdf ml-1 text-red-600",
                        onClick: $event => (_ctx.showTrackingLabelCombined(data))
                      }, null, 8, _hoisted_13), [
                        [
                          _directive_tooltip,
                          _ctx.$t('labels.shipment.showCombinedLabels'),
                          void 0,
                          { left: true }
                        ]
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("b", null, _toDisplayString(data.runAtFormatted), 1)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createTextVNode(_toDisplayString(_ctx.$t('labels.shipment.weight')) + ": ", 1),
                  _createElementVNode("span", _hoisted_16, _toDisplayString(data.weight), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "filters", "onPage", "onFilter", "onSort"])
      ]),
      _: 1
    }),
    _createVNode(_component_CreateHeadlessShipmentDialog, {
      displayDialog: _ctx.showCreateDialog,
      onCloseDialog: _ctx.onCloseCreateDialog
    }, null, 8, ["displayDialog", "onCloseDialog"])
  ], 64))
}