import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.systemLog')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: "",
          lazy: "",
          scrollable: "",
          scrollHeight: "calc(100vh - 23rem)",
          rows: 20,
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.systemLogs,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[1] || (_cache[1] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          onPage: _ctx.onPage,
          onFilter: _ctx.onFilter,
          onSort: _ctx.onSort,
          onStateRestore: _ctx.onStateRestore,
          stateStorage: "local",
          stateKey: "system-log-list-state-session"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "type",
              sortable: true,
              style: {"min-width":"10rem"},
              header: _ctx.$t('labels.logType'),
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_Dropdown, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  options: [{label: 'Emergency', value: '0'}, {label: 'Alert', value: '1'}, {label: 'Alert', value: '2'}, {label: 'Error', value: '3'}, {label: 'Warning', value: '4'}, {label: 'Notice', value: '5'}, {label: 'Info', value: '6'}, {label: 'Debug', value: '7'}],
                  placeholder: "Any",
                  optionLabel: "label",
                  optionValue: "value",
                  onChange: filterCallback,
                  class: "p-column-filter text-sm",
                  showClear: true
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("div", {
                  class: _normalizeClass([_ctx.getTypeCellStyle(data.type), "product-badge"])
                }, _toDisplayString(_ctx.getTypeCellContent(data.type)), 3)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "date",
              sortable: true,
              style: {"min-width":"10rem"},
              header: _ctx.$t('labels.logTime')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "event",
              style: {"min-width":"6rem"},
              header: _ctx.$t('labels.logMessage')
            }, null, 8, ["header"])
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "filters", "onPage", "onFilter", "onSort", "onStateRestore"])
      ]),
      _: 1
    }, 8, ["header"])
  ], 64))
}