<loading v-model:active="loading" />
<Panel :header="$t('labels.importLog')">
    <div class="flex justify-content-end">
        <span class="mr-3">{{ $t("labels.allEnvironments") }}</span>
        <div>
            <InputSwitch v-model="allEnvs" @input="onAllEnvsSwitch" />
        </div>
    </div>

    <div>
        <DataTable
            class="p-datatable-sm text-sm"
            stripedRows
            paginator
            :rows="20"
            scrollable
            scrollHeight="calc(100vh - 25rem)"
            lazy
            ref="dt"
            dataKey="id"
            :totalRecords="totalRecords"
            :value="logs"
            responsiveLayout="scroll"
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rows-per-page-options="[10,20,50]"
            current-page-report-template="{first} to {last} of {totalRecords}"
            v-model:filters="filters"
            filterDisplay="row"
            @page="onPage($event)"
            @filter="onFilter($event)"
            @sort="onSort($event)"
            v-model:expandedRows="expandedRows"
            stateStorage="local"
            stateKey="log-list-state-session"
            @state-restore="onStateRestore"
        >
            <!--<Column :expander="true" headerStyle="width: 3em" />-->
            <!--<Column
                            field="id"
                            :sortable="true"
                            headerStyle="width: 10%"
                            :header="$t('labels.id')"
                            :filterMatchModeOptions="matchModesNumeric"
                        >
                            <template #filter="{filterModel,filterCallback}">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @keydown.enter="filterCallback()"
                                    class="p-column-filter text-sm"
                                    placeholder="#"
                                />
                            </template>
                        </Column>-->
            <Column
                field="importPlatform"
                :header="$t('labels.environment')"
                :sortable="true"
                style="min-width: 8rem"
                ><template #body="{data}">
                    <div
                        v-html="getPlatformCellContent(data.importPlatform)"
                    ></div> </template
            ></Column>
            <Column
                field="importTargetSystem"
                :sortable="false"
                style="min-width: 6rem"
                ><template #body="{data}">
                    <div
                        v-html="getPlatformCellContent(data.importTargetSystem)"
                    ></div> </template
            ></Column>
            <Column
                field="subjectWorkflowCombo"
                style="min-width: 6rem"
                :header="$t('labels.wizardWorkflow')"
                :sortable="true"
                :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
                ><template #body="{data}">
                    <div>
                        <span
                            v-if="getWorkflowCellContent(data.subjectWorkflowCombo)"
                            :class="getWorkflowCellTextColor(data.subjectWorkflowCombo)"
                            >{{$t(getWorkflowCellContent(data.subjectWorkflowCombo))}}</span
                        >
                    </div> </template
                ><template #filter="{filterModel, filterCallback}">
                    <TreeSelect
                        class="w-full"
                        v-model="filterModel.value"
                        @change="filterCallback()"
                        :options="importWizardOptions"
                        selectionMode="single"
                        placeholder="Any"
                    /> </template
            ></Column>
            <Column
                field="fileName"
                :sortable="true"
                style="min-width: 10rem"
                :header="$t('labels.filename')"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-sm"
                        :placeholder="$t('labels.filename')"
                    />
                </template>
            </Column>
            <Column
                field="status"
                :header="$t('labels.status')"
                :sortable="true"
                style="min-width: 6rem"
                :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
                ><template #body="{data}">
                    <span
                        v-if="getStatusCellContent(data)"
                        :class="getStatusCellClass(data)"
                        >{{$t(getStatusCellContent(data))}}</span
                    > </template
                ><template #filter="{filterModel, filterCallback}">
                    <Dropdown
                        v-model="filterModel.value"
                        :options="[{label: $t('labels.aborted'), value: 'aborted', color: 'red'}, {label: $t('labels.scheduled'), value: 'scheduled', color: 'orange'}, {label: $t('labels.inProgress'), value: 'inProgress', color: 'lightgreen'}, {label: $t('labels.done'), value: 'done', color: 'darkgreen'}]"
                        placeholder="Any"
                        optionLabel="label"
                        optionValue="value"
                        @change="filterCallback()"
                        class="p-column-filter text-sm"
                    >
                    </Dropdown> </template
            ></Column>
            <Column :header="$t('labels.progress')" style="min-width: 12rem"
                ><template #body="{data}">
                    <ProgressBar
                        style="height: 1.3em"
                        :value="Math.floor((data.processedRecords * 100) / data.validRecords)"
                    /> </template
            ></Column>
            <Column :header="$t('labels.result')" style="min-width: 10rem"
                ><template #body="{data}">
                    <div v-if="data">
                        <span
                            v-tooltip.top="$t('labels.importLogsResultHeaderTooltip')"
                        >
                            {{ data.validRecords + data.rowsToSkipNumber }} / {{
                            data.rowsToSkipNumber }} / {{ data.processedRecords
                            }} / {{ data.importedRecords }}
                        </span>
                        <i
                            v-if="data?.processEndedAt"
                            class="pi pi-book text-sm ml-2"
                            v-tooltip.top="$t('labels.logs')"
                            @click="handleLogsClick($event, data)"
                        ></i>
                        <i
                            v-if="data?.processEndedAt"
                            class="pi pi-download text-sm ml-2"
                            v-tooltip.top="$t('labels.resultFileExport')"
                            @click="downloadResultFile($event, data)"
                        ></i>
                    </div> </template
            ></Column>
            <Column
                style="min-width: 8rem"
                field="importedAt"
                :header="$t('labels.importedAt')"
                :sortable="true"
            >
                <template #body="{data}"
                    >{{ data.importedAtFormatted}}</template
                >
            </Column>
            <Column
                style="min-width: 8rem"
                field="uploadedBy"
                :header="$t('labels.uploadedBy')"
                :sortable="true"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-sm"
                        :placeholder="$t('labels.email')"
                    />
                </template>
            </Column>
        </DataTable>
    </div>
</Panel>

<ImportResultOverview
    :import-id="selectedImportRecord?.id"
    :dialog-title="dialogTitle"
    :display-logs-dialog="displayLogsDialog"
    @hide="hideLogsDialog"
/>
