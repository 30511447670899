import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_LogList = _resolveComponent("LogList")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.dialogTitle,
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[0] || (_cache[0] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '70vw'},
    modal: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LogList, { "import-id": _ctx.importId }, null, 8, ["import-id"])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}