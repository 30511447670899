import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import {ILayoutConfig} from '@/interfaces/layout/config';

const uiModule: {
    namespaced: boolean;
    state: {
        bookmarks: object[];
        layoutConfig: ILayoutConfig;
    };
    mutations: any;
    actions: any;
    getters: any;
} = {
    namespaced: true,
    state: {
        layoutConfig: localStorage.getItem('layoutConfig')
            ? JSON.parse(localStorage.getItem('layoutConfig'))
            : {
                  darkTheme: false,
                  menuMode: 'static',
                  theme: 'lara-light-indigo',
                  scale: 14,
                  activeMenuItem: null,
                  staticMenuDesktopInactive: false,
                  overlayMenuActive: false,
                  profileSidebarVisible: false,
                  configSidebarVisible: false,
                  staticMenuMobileActive: false,
                  menuHoverActive: false
              },
        bookmarks: []
    },
    mutations,
    actions,
    getters
};

export default uiModule;
