import {getAll, getResultFile} from '@/services/import-logs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import TreeSelect from 'primevue/treeselect';
import InputSwitch from 'primevue/inputswitch';
import ProgressBar from 'primevue/progressbar';
import {FilterMatchMode} from 'primevue/api';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import {computed, onMounted, onUnmounted, ref} from 'vue';
import {useToast} from 'vue-toastification';
import {checkIfArraysAreEqual, getPlatformCellContent} from '@/utils/helpers';
import ImportResultOverview from '@/components/dialog/import-wizard/logs/import-result-overview.vue';
import {evaluateChildNodes, wizardOptions} from '@/utils/import-wizard/options';
import store from '@/store';
import FileSaver from 'file-saver';
import {DateTime} from 'luxon';
import Panel from 'primevue/panel';

export default {
    name: 'ImportLogs',
    components: {
        DataTable,
        Column,
        InputText,
        Dropdown,
        TreeSelect,
        InputSwitch,
        Panel,
        ProgressBar,
        ImportResultOverview,
        loading: LoadingPlugin
    },
    setup() {
        onMounted(() => {
            if (localStorage.getItem('log-list-state-session')) {
                return;
            }

            tableState.value = null;
            filters.value = Object.assign({}, filterDefintions);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'id',
                sortOrder: -1,
                filters: filters.value,
                allEnvs: false
            };

            loadLazyData();

            if (interval.value !== null) {
                clearInterval(interval.value);
            }

            interval.value = setInterval(() => {
                if (needsRefresh.value) {
                    loadLazyData();
                }
            }, 5000);
        });

        onUnmounted(() => {
            if (interval.value !== null) {
                clearInterval(interval.value);
            }
        });

        const dt = ref();
        const loading = ref(false);
        const allEnvs = ref(false);
        const totalRecords = ref(0);
        const lazyParams: any = ref({});
        const expandedRows = ref([]);
        const interval = ref(null);
        const needsRefresh = ref(false);
        const toast = useToast();

        const logs = ref();
        const tableState = ref(null);

        const selectedImportRecord = ref(null);
        const displayLogsDialog = ref(false);

        const filterDefintions: any = {
            fileName: {value: null, matchMode: FilterMatchMode.CONTAINS},
            subjectWorkflowCombo: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            status: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            uploadedBy: {value: null, matchMode: FilterMatchMode.CONTAINS}
        };

        const filters = ref(filterDefintions);

        const loadLazyData = () => {
            loading.value = true;
            lazyParams.value.allEnvs = allEnvs.value;

            getAll(lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    logs.value = data.data.items.map((obj: any) => ({
                        ...obj,
                        subjectWorkflowCombo:
                            obj.importSubject + '-' + obj.importWorkflow,
                        importedAtFormatted: DateTime.fromSeconds(
                            obj.importedAt
                        )
                            .setLocale(i18n.global.locale)
                            .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                            .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
                    }));
                    loading.value = false;
                    const inProgress = data.data.items.find((element: any) => {
                        return element.validatedAt && !element.processEndedAt;
                    });

                    needsRefresh.value = !!inProgress;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const onPage = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = filters.value;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onAllEnvsSwitch = () => {
            loadLazyData();
        };

        const matchModesNumeric = [
            {label: 'Equals', value: FilterMatchMode.EQUALS},
            {label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS},
            {label: 'Less Than', value: FilterMatchMode.LESS_THAN},
            {
                label: 'Less or Equal',
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {label: 'Greater Than', value: FilterMatchMode.GREATER_THAN},
            {
                label: 'Greater or Equal',
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            }
        ];

        const getWorkflowCellTextColor = (value: any) => {
            if (!value) {
                return '';
            }

            const test = value.split('-');
            return test[test.length - 1] === 'add'
                ? 'text-green-600'
                : test[test.length - 1] === 'edit'
                ? 'text-orange-600'
                : '';
        };

        const getWorkflowCellContent = (value: any) => {
            if (!value) {
                return '';
            }

            switch (value) {
                case 'articles-add':
                    return 'labels.importArticles';
                case 'articles-edit':
                    return 'labels.updateArticles';
                case 'accessories-add':
                    return 'labels.importAccessories';
                case 'article-supply-sources-edit':
                    return 'labels.updateArticleSupplySources';
                case 'images-edit':
                    return 'labels.importProductImages';
                case 'paid-invoices-add':
                    return 'labels.importPaidInvoices';
                case 'credit-limit-abc-edit':
                    return 'labels.updateCreditLimitABC';
                case 'credit-limit-datev-edit':
                    return 'labels.updateCreditLimitDatev';
                case 'debtor-data-edit':
                    return 'labels.compareDebtorData';
                case 'headless-shipments-add':
                    return 'labels.importHeadlessShipmentsCustomerAddresses';
                case 'stock-add':
                    return 'labels.importStock';
                case 'stock-block-add':
                    return 'labels.importStockBlock';
                case 'shelf-add':
                    return 'labels.importShelves';
                case 'barcodes-add':
                    return 'labels.importBarcodes';
                case 'badges-add':
                    return 'labels.importBadges';
                case 'images-add':
                    return 'labels.importProductImages';
                case 'links-add':
                    return 'labels.importProductLinks';
                case 'documents-add':
                    return 'labels.importProductDocuments';
                case 'videos-add':
                    return 'labels.importProductVideos';
                case 'customer-addresses-edit':
                    return 'labels.importCustomerAddresses';
                case 'supplier-addresses-edit':
                    return 'labels.importSupplierAddresses';
                default:
                    return '';
            }
        };

        const getStatusCellContent = (data: any) => {
            if (!data) {
                return '';
            }
            if (!data?.validatedAt) {
                return 'labels.aborted';
            }

            if (!data?.processStartedAt) {
                return 'labels.scheduled';
            }

            if (!data?.processEndedAt) {
                return 'labels.inProgress';
            }

            return 'labels.done';
        };

        const getStatusCellClass = (data: any) => {
            if (!data) {
                return '';
            }
            if (!data?.validatedAt) {
                return 'text-red-400';
            }

            if (!data?.processStartedAt) {
                return 'text-orange-400';
            }

            if (!data?.processEndedAt) {
                return 'text-blue-400';
            }

            return 'text-green-400';
        };

        const onStateRestore = (event: any) => {
            tableState.value = Object.assign({}, event);

            const stateFilters = Object.keys(tableState.value?.filters || {});

            const filtersDefinitionKeys = Object.keys(filterDefintions)
                .slice()
                .sort();

            if (!checkIfArraysAreEqual(stateFilters, filtersDefinitionKeys)) {
                localStorage.removeItem('log-list-state-session');
                tableState.value = null;
                filters.value = Object.assign({}, filterDefintions);
            }

            lazyParams.value = {
                first: 0,
                rows: event.rows || dt.value.rows,
                sortField: event.sortField || 'id',
                sortOrder: event.sortOrder || -1,
                filters: event.filters,
                allEnvs: false
            };

            loadLazyData();

            if (interval.value !== null) {
                clearInterval(interval.value);
            }

            interval.value = setInterval(() => {
                if (needsRefresh.value) {
                    loadLazyData();
                }
            }, 5000);
        };

        const handleLogsClick = (event: any, data: any) => {
            selectedImportRecord.value = data;
            displayLogsDialog.value = true;
        };

        const dialogTitle = computed(() => {
            return (
                i18n.global.t('labels.importResult') +
                ' ' +
                selectedImportRecord.value?.fileName
            );
        });

        const downloadResultFile = async (event: any, data: any) => {
            try {
                const file = await getResultFile(data.id);
                const filename =
                    data.id + '-logs-export-' + data.importPlatform + '.csv';
                FileSaver.saveAs(
                    new Blob([file.data], {type: 'text/csv;charset=utf-8'}),
                    filename
                );
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
            }
        };

        const hideLogsDialog = () => {
            displayLogsDialog.value = false;
        };

        const importWizardOptions = computed(() => {
            const currentUser = store.getters['auth/user'];
            return evaluateChildNodes(wizardOptions, currentUser);
        });

        return {
            logs,
            dt,
            totalRecords,
            loading,
            allEnvs,
            lazyParams,
            filters,
            expandedRows,
            matchModesNumeric,
            onPage,
            onFilter,
            onSort,
            onAllEnvsSwitch,
            getPlatformCellContent: getPlatformCellContent,
            getWorkflowCellContent,
            getStatusCellContent,
            onStateRestore,
            selectedImportRecord,
            displayLogsDialog,
            handleLogsClick,
            dialogTitle,
            hideLogsDialog,
            importWizardOptions,
            downloadResultFile,
            getWorkflowCellTextColor,
            getStatusCellClass
        };
    }
};
