import store from '@/store';
import packageInfo from '../../../../package.json';
import {DateTime} from 'luxon';
import {computed} from 'vue';

export default {
    setup() {
        const serverVersion = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('users-edit') !== -1
                ? user?.serverVersion
                : null;
        });

        return {
            version: packageInfo.version,
            serverVersion,
            currentYear: DateTime.now().toFormat('y')
        };
    }
};
