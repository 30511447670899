import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48b6826a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "font-weight-bold" }
const _hoisted_2 = { class: "grid mb-1" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "col-8" }
const _hoisted_5 = { style: {"text-align":"center"} }

export function render(_ctx, _cache) {
  const _component_Compliance = _resolveComponent("Compliance")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_Panel = _resolveComponent("Panel")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_BulkProductAction = _resolveComponent("BulkProductAction")

  return (_openBlock(), _createBlock(_component_BulkProductAction, {
    onCloseDialog: _ctx.closeResponsive,
    onConfirmClicked: _ctx.confirmClicked,
    products: _ctx.products,
    productTotalRecords: _ctx.productTotalRecords,
    displayDialog: _ctx.showDialog,
    isFilterRelated: _ctx.isFilterRelated,
    summaryTabVisible: _ctx.summaryTabVisible,
    loading: _ctx.loading,
    dialogHeader: _ctx.$t('labels.editCompliance'),
    canPostponeExecution: ""
  }, {
    "tab-panel-middle": _withCtx(() => [
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('labels.compliance')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Compliance, {
            onFormDirty: _ctx.onFormDirty,
            isBulkEdit: ""
          }, null, 8, ["onFormDirty"])
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    "summary-panel-header": _withCtx((slotProps) => [
      _createVNode(_component_Panel, { class: "mb-3" }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('labels.adjustments')), 1)
        ]),
        default: _withCtx(() => [
          (_ctx.filteredComplianceInput)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(Object.entries(_ctx.filteredComplianceInput), (item) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productCompliance.' + item[0])), 1)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    (item[1] === true || item[1] === false)
                      ? (_openBlock(), _createBlock(_component_TriStateCheckbox, {
                          key: 0,
                          modelValue: item[1],
                          "onUpdate:modelValue": $event => ((item[1]) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : (item[1] instanceof Date)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(item[1].toLocaleDateString(_ctx.locale) ||
                            _ctx.$t('messages.valueWillBeSetToNull').toUpperCase()), 1)
                          ], 64))
                        : (item[0]==='weeeClasification')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              _createTextVNode(_toDisplayString(_ctx.weeeOptions.find(i => i.value ===
                            item[1])?.label ||
                            _ctx.$t('messages.valueWillBeSetToNull').toUpperCase()), 1)
                            ], 64))
                          : (item[0]==='battgClass')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                _createTextVNode(_toDisplayString(_ctx.battgClassOptions.find(i => i.value ===
                            item[1])?.label ||
                            _ctx.$t('messages.valueWillBeSetToNull').toUpperCase()), 1)
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                                _createTextVNode(_toDisplayString(item[1] ||
                            _ctx.$t('messages.valueWillBeSetToNull').toUpperCase()), 1)
                              ], 64))
                  ])
                ]))
              }), 256))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    "summary-panel-middle": _withCtx(() => [
      _createElementVNode("h1", _hoisted_5, [
        _createVNode(_component_font_awesome_icon, {
          style: {"margin-left":"30px"},
          icon: ['fas', 'angle-double-down']
        }),
        _createVNode(_component_font_awesome_icon, {
          style: {"margin-left":"30px"},
          icon: ['fas', 'angle-double-down']
        }),
        _createVNode(_component_font_awesome_icon, {
          style: {"margin-left":"30px"},
          icon: ['fas', 'angle-double-down']
        })
      ])
    ]),
    _: 1
  }, 8, ["onCloseDialog", "onConfirmClicked", "products", "productTotalRecords", "displayDialog", "isFilterRelated", "summaryTabVisible", "loading", "dialogHeader"]))
}