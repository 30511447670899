<loading v-model:active="loading" />
<div class="col-12">
    <DataTable
        class="p-datatable-sm text-sm"
        :rowClass="rowClass"
        stripedRows
        :paginator="true"
        :rows="10"
        :lazy="true"
        dataKey="rowIndex"
        ref="dt"
        :totalRecords="totalRecords"
        :value="logs"
        v-model:filters="filters"
        filterDisplay="row"
        @page="onPage($event)"
        @filter="onFilter($event)"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
    >
        <Column
            field="rowIndex"
            style="min-width: 4rem"
            :header="$t('labels.rowNumber')"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    placeholder="#"
                />
            </template>
        </Column>
        <Column style="min-width: 16rem" :header="$t('labels.rowContent')">
            <template #body="{data}">
                {{ data.content.split(';').filter(n => n.trim()).join(' | ') }}
            </template>
        </Column>

        <Column
            field="status"
            style="min-width: 8rem"
            :header="$t('labels.status')"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel}">
                <Dropdown
                    v-model="filterModel.value"
                    :options="[{label: $t('labels.failed'), value: '0'}, {label: $t('labels.skipped'), value: '1'}, {label: $t('labels.invalid'), value: '2'}]"
                    placeholder="Any"
                    optionLabel="label"
                    optionValue="value"
                    class="p-column-filter text-sm"
                    :showClear="true"
                >
                </Dropdown>
            </template>
            <template #body="{data}">
                <div v-html="getStatusCellContent(data)"></div>
            </template>
        </Column>
        <Column
            field="errorMessage"
            style="min-width: 16rem"
            :header="$t('labels.error')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.error')"
                />
            </template>
        </Column>
    </DataTable>
</div>
