<BulkProductAction
    @close-dialog="closeResponsive"
    @confirm-clicked="confirmClicked"
    :products="products"
    :productTotalRecords="productTotalRecords"
    :displayDialog="showDialog"
    :isFilterRelated="isFilterRelated"
    :summaryTabVisible="selectedRule"
    :loading="loading"
    :dialogHeader="isRemove ? $t('labels.removeProductsFromShopRuleConditions') : $t('labels.addProductsToShopRuleConditions')"
    canPostponeExecution
>
    <template #tab-panel-middle>
        <TabPanel :header="$t('labels.shopRuleConditions')">
            <DataTable
                class="p-datatable-sm text-sm"
                stripedRows
                v-model:selection="selectedRule"
                :paginator="shopRules && shopRules.length > 10"
                :rows="10"
                :value="shopRules"
                v-model:filters="filters"
                filterDisplay="row"
                responsiveLayout="scroll"
                paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rows-per-page-options="[10,20,50,100,200]"
                current-page-report-template="{first} to {last} of {totalRecords}"
            >
                <Column
                    selectionMode="single"
                    headerStyle="width: 3rem"
                ></Column>
                <Column
                    field="name"
                    :header="$t('labels.name')"
                    style="min-width: 12rem"
                >
                    <template #filter="{filterModel,filterCallback}">
                        <InputText
                            type="text"
                            v-model="filterModel.value"
                            @keydown.enter="filterCallback()"
                            class="p-column-filter text-xs"
                        />
                    </template>
                </Column>
            </DataTable>
        </TabPanel>
    </template>
    <template #summary-panel-header>
        <Panel class="mb-3">
            <template #header>
                <div class="font-bold">
                    {{ $t('labels.shopRuleConditions') }}
                </div>
            </template>
            <div>{{selectedRule?.name}}</div>
        </Panel>
    </template>
    <template #summary-panel-middle>
        <h1 style="text-align: center">
            <template v-if="isRemove"
                ><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'ban']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'ban']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'ban']"
                />
            </template>
            <template v-else>
                <font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-up']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-up']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-up']"
                />
            </template>
        </h1>
    </template>
</BulkProductAction>
