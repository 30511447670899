import {ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import LogList from '@/components/grid/jobs/logs/cron-mail-logs-list-lazy.vue';
export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        LogList
    },
    props: {
        cron: Object,
        displayLogsDialog: Boolean
    },
    setup(props: any) {
        const {cron} = toRefs(props);
        const showDialog = ref(false);

        watch(props, (args) => {
            showDialog.value = args.displayLogsDialog;
        });

        return {
            cron,
            showDialog
        };
    }
};
