<PrimeDialog
    :header="$t('labels.ticket.chooseSerialNumber')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '65vw'}"
    :closable="false"
    :modal="true"
    @hide="resetItems"
>
    <loading v-model:active="loading" />
    <div class="grid p-fluid mb-3" v-if="!articleId">
        <div class="col field">
            <label>{{ $t('labels.productHome.serialNumberExtern') }}</label>
            <InputText
                class="text-sm"
                @keydown.enter="addCustomSerialNumber"
                v-model="customSerialNumber"
            />
        </div>
    </div>

    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        :paginator="totalRecords > 10"
        :rows="10"
        :lazy="articleId !== null"
        ref="dt"
        dataKey="serialNumber"
        :totalRecords="totalRecords"
        :value="warehouseStockMovements"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50,100,200]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:selection="selectedItems"
    >
        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
        <Column
            field="serialNumber"
            :header="$t('labels.productHome.serialNumberExtern')"
        >
        </Column>
    </DataTable>

    <div class="w-full flex justify-content-between mt-4">
        <p-button
            severity="danger"
            v-text="$t('buttons.cancel')"
            @click="selectedItems = [];showDialog = false;closeResponsive();"
        >
        </p-button>

        <p-button
            severity="success"
            v-text="$t('buttons.confirm')"
            :disabled="selectedItems.length < 1"
            @click="onConfirmButtonClicked"
        >
        </p-button>
    </div>
</PrimeDialog>
