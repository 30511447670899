<BulkProductAction
    @close-dialog="closeResponsive"
    @confirm-clicked="confirmClicked"
    :products="products"
    :productTotalRecords="productTotalRecords"
    :displayDialog="showDialog"
    :isFilterRelated="isFilterRelated"
    :summaryTabVisible="tags.length > 0"
    :loading="loading"
    :dialogHeader="isRemove ? $t('labels.removeMetatags') : $t('labels.addMetatags')"
>
    <template #tab-panel-middle>
        <TabPanel :header="$t('labels.productHome.metaTagsSEO')">
            <div class="grid">
                <div class="col">
                    <Chips
                        v-model="tags"
                        :allowDuplicate="false"
                        :placeholder="$t('labels.metatagsSeparatorInfo')"
                        separator=","
                    />
                </div>
            </div>
        </TabPanel>
    </template>
    <template #summary-panel-header="slotProps">
        <Panel :header="$t('labels.productHome.metaTagsSEO')">
            <DataTable
                class="p-datatable-sm text-sm"
                stripedRows
                :paginator="tags && tags.length > 20"
                :rows="20"
                :lazy="true"
                :value="tags.map((item) => { return { tag: item};})"
                responsiveLayout="scroll"
                paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rows-per-page-options="[10,20,50,100,200]"
                current-page-report-template="{first} to {last} of {totalRecords}"
            >
                <Column field="tag" />
            </DataTable>
        </Panel>
    </template>
    <template #summary-panel-middle>
        <h1 style="text-align: center">
            <template v-if="isRemove"
                ><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'ban']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'ban']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'ban']"
                />
            </template>
            <template v-else>
                <font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-down']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-down']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-down']"
                />
            </template>
        </h1>
    </template>
</BulkProductAction>
