import {ref, onMounted, computed, onUnmounted} from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import InputSwitch from 'primevue/inputswitch';
import LoadingPlugin from 'vue-loading-overlay';
import Panel from 'primevue/panel';
import TreeSelect from 'primevue/treeselect';
import {FilterMatchMode, FilterService} from 'primevue/api';
import {useToast} from 'vue-toastification';
import {getAllWithIncompleteDimensions} from '@/services/products';
import {getAll, getSome} from '@/services/metadata';
import store from '@/store';
import router from '@/router';
import {getAllUsingFilters} from '@/services/storage-locations';
import {i18n} from '@/utils/i18n';
import {useRoute} from 'vue-router';

export default {
    components: {
        DataTable,
        Column,
        InputText,
        Dropdown,
        InputSwitch,
        Panel,
        TreeSelect,
        TriStateCheckbox,
        loading: LoadingPlugin
    },
    setup() {
        onMounted(() => {
            filters.value = Object.assign({}, filterDefinitions);
            lazyParams.value = {
                first: 0,
                rows: dt.value?.rows || 20,
                sortField: 'article.name',
                sortOrder: 1,
                filters: filters.value
            };

            FilterService.register(
                measurementVerifiedFilter.value,
                (value, filter) => {
                    return value === filter;
                }
            );

            loadWarehouses();
        });

        onUnmounted(() => {
            if (interval.value !== null) {
                clearInterval(interval.value);
            }
        });

        const dt = ref();
        const loading = ref(false);
        const totalRecords = ref(0);
        const storagePlaceTotalRecords = ref(null);
        const lazyParams: any = ref({});
        const toast = useToast();
        const interval = ref(null);
        const audio = new Audio(require('@/assets/audio/open-shipments.mp3'));

        const measurementVerifiedFilter = ref('measurementVerifiedFilter');

        const singleStoragePlace = ref(null);
        const selectedNode = ref(null);

        const storagePlaces = ref([]);

        const products = ref([]);

        const route = useRoute();

        const nonLazyFilterDefinitions: any = {
            'article.articleNumber': {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            'article.name': {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            'article.ean': {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            'article.manufacturerPartNumber': {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            'article.measurementVerified': {
                value: false,
                matchMode: measurementVerifiedFilter.value
            },
            serialNumber: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }
        };

        const filterDefinitions: any = {
            'article.articleNumber': {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            'article.name': {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            'article.ean': {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            'article.manufacturerPartNumber': {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            serialNumber: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            storagePlaceId: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            }
        };

        const filters = ref(filterDefinitions);

        const nonLazyFilters = ref(nonLazyFilterDefinitions);

        const loadLazyData = (playSound: boolean = true) => {
            if (interval.value !== null) {
                clearInterval(interval.value);
            }

            loading.value = true;

            filters.value.storagePlaceId.value = singleStoragePlace.value
                ? Object.keys(singleStoragePlace.value)[0]
                : null;

            getAllWithIncompleteDimensions(lazyParams.value)
                .then((data) => {
                    const previousProducts = (products.value || []).map(
                        (item: any) => ({
                            ...item
                        })
                    );

                    totalRecords.value = data.data.total;
                    if (!storagePlaceTotalRecords.value) {
                        storagePlaceTotalRecords.value = data.data.total;
                    }
                    products.value = (data.data.items || []).map(
                        (item: any) => {
                            return Object.assign({}, item, {
                                article: Object.assign({}, item.article, {
                                    measurementVerified:
                                        item.article?.systemTags &&
                                        item.article.systemTags.indexOf(
                                            'ARTICLE_MEASUREMENT_VERIFIED'
                                        ) !== -1
                                })
                            });
                        }
                    );
                    loading.value = false;

                    const hasNewProductsArray =
                        previousProducts.length < 1
                            ? []
                            : products.value.filter((item: any) => {
                                  return (
                                      !previousProducts.some(
                                          (previousProduct: any) =>
                                              previousProduct.articleNumber ===
                                              item.articleNumber
                                      ) &&
                                      item.article.systemTags.indexOf(
                                          'ARTICLE_MEASUREMENT_VERIFIED'
                                      ) === -1
                                  );
                              });

                    if (playSound && hasNewProductsArray.length > 0) {
                        const promise = audio.play();
                        if (promise !== undefined) {
                            promise
                                .then(() => {
                                    // Autoplay started
                                })
                                .catch((error: any) => {
                                    toast.error(error.message);
                                });
                        }
                    }
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                })
                .finally(() => {
                    if (interval.value !== null) {
                        clearInterval(interval.value);
                    }

                    interval.value = setInterval(() => {
                        loadLazyData();
                    }, 30000);
                });
        };

        const loadWarehouses = () => {
            getAll(['warehouse'], false).then(async (data: any) => {
                storagePlaces.value = data.data['warehouse'].map(
                    (item: any) => {
                        return {
                            key: 'warehouse' + item.id,
                            label: '[' + item.name + ']',
                            data: '[' + item.name + ']',
                            leaf: false,
                            selectable: false,
                            warehouseId: item.id
                        };
                    }
                );

                if (route?.query?.path) {
                    const pathArray = (route?.query?.path as string).split('|');
                    const pathWarehouseId = pathArray.shift();
                    if (
                        storagePlaces.value.some(
                            (item) => item.warehouseId === pathWarehouseId
                        )
                    ) {
                        const warehouseNode = storagePlaces.value.find(
                            (item) => item.warehouseId === pathWarehouseId
                        );

                        expandNode(
                            warehouseNode,
                            route?.query?.storagePlace,
                            pathArray
                        );
                    }
                }
            });
        };

        const onNodeExpand = (node: any) => {
            if (!node.children) {
                expandNode(node);
            }
        };

        const expandNode = (
            node: any,
            searchStorageLocation?: any,
            pathArray?: any
        ) => {
            if (node.warehouseId) {
                getAllUsingFilters({
                    first: 0,
                    rows: 100,
                    filters: {
                        warehouseId: {
                            value: node.warehouseId,
                            matchMode: FilterMatchMode.EQUALS
                        }
                    }
                })
                    .then((data) => {
                        node.children = data.data
                            .map(
                                (item: {
                                    id: string;
                                    name: string;
                                    shortIdentifier?: string;
                                    blockStoragePlaces: Array<{id: string}>;
                                    shelves: Array<{id: string}>;
                                }) => {
                                    return {
                                        key: 'storageLocation' + item.id,
                                        label:
                                            item.shortIdentifier ?? item.name,
                                        data: item.name,
                                        leaf: false,
                                        selectable: true,
                                        blockStoragePlaces:
                                            item.blockStoragePlaces.map(
                                                (bs) => bs.id
                                            ),
                                        shelves: item.shelves.map(
                                            (shelf) => shelf.id
                                        ),
                                        path: (node.path || []).concat([
                                            node.warehouseId
                                        ])
                                    };
                                }
                            )
                            .sort(sortFn);

                        if (searchStorageLocation) {
                            const chN = node.children.find(
                                (chNode: any) =>
                                    chNode.key === searchStorageLocation
                            );
                            if (chN) {
                                singleStoragePlace.value = {
                                    [searchStorageLocation]: true
                                };
                                onNodeSelect(chN);
                                loadLazyData();
                            } else {
                                const pathShiftedArray = [].concat(pathArray);
                                const pathKeyId = pathShiftedArray.shift();
                                node.children.forEach((chNode: any) => {
                                    if (pathKeyId === chNode.key) {
                                        expandNode(
                                            chNode,
                                            searchStorageLocation,
                                            pathShiftedArray
                                        );
                                    }
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            } else if (
                typeof node.blockStoragePlaces !== 'undefined' &&
                typeof node.shelves !== 'undefined'
            ) {
                const resolvedPromisesArray: Array<any> = [];
                if (node.blockStoragePlaces.length > 200) {
                    toast.warning(
                        i18n.global.t(
                            'messages.loadingOnlyFirst200BlockStoragePlaces'
                        )
                    );
                }

                if (node.shelves.length > 200) {
                    toast.warning(
                        i18n.global.t('messages.loadingOnlyFirst200Shelves')
                    );
                }

                if (node.blockStoragePlaces.length > 0) {
                    resolvedPromisesArray.push(
                        Promise.resolve(
                            getSome(
                                'storagePlace',
                                'id',
                                node.blockStoragePlaces.splice(0, 200),
                                false,
                                ['storageLocationId']
                            )
                        )
                    );
                }

                if (node.shelves.length > 0) {
                    resolvedPromisesArray.push(
                        Promise.resolve(
                            getSome(
                                'shelf',
                                'id',
                                node.shelves.splice(0, 200),
                                false
                            )
                        )
                    );
                }

                Promise.all(resolvedPromisesArray)
                    .then((data) => {
                        let values: any = [];
                        data.forEach((item) => {
                            values = values.concat(Object.values(item.data));
                        });

                        node.children = values
                            .map((item: any) => {
                                return {
                                    key:
                                        (item.storagePlaces ? 'shelf' : '') +
                                        item.id,
                                    label: item.shortIdentifier || item.name,
                                    data: item.shortIdentifier || item.name,
                                    leaf:
                                        typeof item.storagePlaces ===
                                        'undefined',
                                    selectable: true,
                                    storagePlaces: (
                                        item.storagePlaces || []
                                    ).map((bs: any) => bs.id),
                                    path: (node.path || []).concat([node.key])
                                };
                            })
                            .sort(sortFn);

                        if (searchStorageLocation) {
                            const chN = node.children.find(
                                (chNode: any) =>
                                    chNode.key === searchStorageLocation
                            );
                            if (chN) {
                                singleStoragePlace.value = {
                                    [searchStorageLocation]: true
                                };
                                onNodeSelect(chN);
                                loadLazyData();
                            } else {
                                const pathShiftedArray = [].concat(pathArray);
                                const pathKeyId = pathShiftedArray.shift();
                                node.children.forEach((chNode: any) => {
                                    if (pathKeyId === chNode.key) {
                                        expandNode(
                                            chNode,
                                            searchStorageLocation,
                                            pathShiftedArray
                                        );
                                    }
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    });
            } else if (
                typeof node.storagePlaces !== 'undefined' &&
                node.storagePlaces.length > 0
            ) {
                getSome('storagePlace', 'id', node.storagePlaces, false, [
                    'storageLocationId',
                    'shelfId',
                    'shelfStorageLocationId'
                ])
                    .then((data) => {
                        const values: Array<{
                            id: string;
                            name: string;
                        }> = Object.values(data.data);

                        node.children = values
                            .map((item) => {
                                return {
                                    key: item.id,
                                    label: item.name,
                                    data: item.name,
                                    leaf: true,
                                    selectable: true,
                                    path: (node.path || []).concat([node.key])
                                };
                            })
                            .sort(sortFn);

                        if (searchStorageLocation) {
                            const chN = node.children.find(
                                (chNode: any) =>
                                    chNode.key === searchStorageLocation
                            );
                            if (chN) {
                                singleStoragePlace.value = {
                                    [searchStorageLocation]: true
                                };
                                onNodeSelect(chN);
                                loadLazyData();
                            } else {
                                const pathShiftedArray = [].concat(pathArray);
                                const pathKeyId = pathShiftedArray.shift();
                                node.children.forEach((chNode: any) => {
                                    if (pathKeyId === chNode.key) {
                                        expandNode(
                                            chNode,
                                            searchStorageLocation,
                                            pathShiftedArray
                                        );
                                    }
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            }
        };

        const sortFn = (a: any, b: any) => {
            const nameA = a.label.toUpperCase();
            const nameB = b.label.toUpperCase();

            if (nameA < nameB) {
                return -1;
            }
            return nameA > nameB ? 1 : 0;
        };

        const showDimensionColumn = computed(() => {
            return (
                products.value &&
                products.value.some(
                    (item: any) =>
                        (item.article && item.article.articleGrossWeight) ||
                        (item.article && item.article.articleLength) ||
                        item.article.articleHeight ||
                        item.article.articleWidth
                )
            );
        });

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('products-edit') !== -1;
        });

        const onRowClick = (event: any) => {
            const user = store.getters['auth/user'];
            if (user?.permissions?.indexOf('products-edit') !== -1) {
                router.push({
                    name: 'ProductsWithIncompleteDimensionsDetails',
                    params: {id: event.data.articleNumber},
                    state: {
                        originalData: JSON.stringify({
                            singleStoragePlace: singleStoragePlace.value,
                            path: selectedNode.value?.path
                        })
                    }
                });
            }
        };

        const onPage = (event: any) => {
            lazyParams.value = event;
            loadLazyData(false);
        };

        const onFilter = () => {
            lazyParams.value.filters = filters.value;
            lazyParams.value.first = 0;
            loadLazyData(false);
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            loadLazyData(false);
        };

        const onChangeStorageLocation = () => {
            storagePlaceTotalRecords.value = null;

            filters.value = Object.assign({}, filterDefinitions);

            nonLazyFilters.value = Object.assign({}, nonLazyFilterDefinitions);

            loadLazyData(false);
        };

        const onNodeSelect = (node: any) => {
            selectedNode.value = node;
        };

        return {
            singleStoragePlace,
            products,
            dt,
            totalRecords,
            loading,
            lazyParams,
            filters,
            showDimensionColumn,
            storagePlaces,
            onNodeExpand,
            onNodeSelect,
            selectedNode,
            warehouseLevelMatchModes: [
                {label: 'Equals', value: FilterMatchMode.EQUALS}
            ],
            loadLazyData,
            editPermissionAvailable,
            onRowClick,
            onPage,
            onFilter,
            onSort,
            nonLazyFilters,
            storagePlaceTotalRecords,
            onChangeStorageLocation,
            measurementVerifiedFilter
        };
    }
};
