<Panel :header="$t('labels.profile')">
    <TabView class="tabview-custom text-sm" ref="tabview4">
        <TabPanel>
            <template #header>
                <i class="pi pi-key"></i>
                <span>{{ $t("labels.apiCredentials") }}</span>
            </template>
            <TabView class="tabview-custom text-sm" ref="tabview4">
                <TabPanel>
                    <template #header>
                        <i class="pi pi-heart"></i>
                        <span>Weclapp</span>
                    </template>
                    <Weclapp />
                </TabPanel>
            </TabView>
        </TabPanel>
    </TabView>
</Panel>
