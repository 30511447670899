import {clearEmptyFilters} from '@/utils/helpers';
import {i18n} from '@/utils/i18n';
import TieredMenu from 'primevue/tieredmenu';
import {computed, onMounted, ref, toRefs} from 'vue';
import AddFilterDialog from '../dialog/filter/add-filter.vue';
import {add, getAll, remove} from '@/services/filters';
import {useToast} from 'vue-toastification';
import store from '@/store';
import {useConfirm} from 'primevue/useconfirm';

export default {
    name: 'filter-menu',
    emits: ['clear-filters', 'apply-filters'],
    components: {
        TieredMenu,
        AddFilterDialog
    },
    props: {
        parentComponentId: String,
        parentFilters: Object
    },
    setup(props: any, context: any) {
        const {parentComponentId, parentFilters} = toRefs(props);

        const displayAddFilterDialog = ref(false);
        const savingInProgress = ref(false);
        const personalFilters = ref([]);
        const publicFilters = ref([]);

        const toast = useToast();
        const confirm = useConfirm();

        onMounted(() => {
            loadFilters();
        });

        const user = computed(() => {
            return store.getters['auth/user'];
        });

        const loadFilters = () => {
            getAll({componentId: parentComponentId.value})
                .then((data) => {
                    personalFilters.value = data.data
                        .filter((item: any) => !item.public)
                        .sort((a: any, b: any) => a.name.localeCompare(b.name));
                    publicFilters.value = data.data
                        .filter((item: any) => item.public)
                        .sort((a: any, b: any) => a.name.localeCompare(b.name));
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        };

        const menu = ref();
        const items = computed(() => [
            {
                label: i18n.global.t('buttons.savedFilters'),
                icon: 'pi pi-history',
                disabled:
                    personalFilters.value.length < 1 &&
                    publicFilters.value.length < 1,
                items: [
                    {
                        label: i18n.global.t('labels.personal'),
                        icon: 'pi pi-fw pi-user',
                        disabled: personalFilters.value.length < 1,
                        items: personalFilters.value.map((item) => {
                            return {
                                label: item.name,
                                icon: 'pi pi-fw pi-filter',
                                items: [
                                    {
                                        label: i18n.global.t('buttons.apply'),
                                        icon: 'pi pi-check',
                                        command: () => {
                                            context.emit(
                                                'apply-filters',
                                                item.filters
                                            );
                                        }
                                    },
                                    {
                                        label: i18n.global.t('buttons.clear'),
                                        icon: 'pi pi-times-circle',
                                        command: () => {
                                            confirm.require({
                                                message: i18n.global.t(
                                                    'messages.deleteConfirmation',
                                                    {
                                                        item: item.name
                                                    }
                                                ),
                                                header: i18n.global.t(
                                                    'messages.pleaseConfirm'
                                                ),
                                                icon: 'pi pi-exclamation-triangle',
                                                acceptLabel:
                                                    i18n.global.t('labels.yes'),
                                                rejectLabel:
                                                    i18n.global.t('labels.no'),
                                                accept: () => {
                                                    remove(item.id)
                                                        .then(() => {
                                                            toast.success(
                                                                i18n.global.t(
                                                                    'messages.changesSavedSuccessfully'
                                                                )
                                                            );
                                                            loadFilters();
                                                        })
                                                        .catch((error) => {
                                                            toast.error(
                                                                error.response
                                                                    ?.data
                                                                    ?.error ||
                                                                    error.message
                                                            );
                                                        });
                                                }
                                            });
                                        }
                                    }
                                ]
                            };
                        })
                    },
                    {
                        label: i18n.global.t('labels.public'),
                        icon: 'pi pi-fw pi-building',
                        disabled: publicFilters.value.length < 1,
                        items: publicFilters.value.map((item) => {
                            return {
                                label: item.name + ' (' + item.username + ')',
                                icon: 'pi pi-fw pi-filter',
                                items: [
                                    {
                                        label: i18n.global.t('buttons.apply'),
                                        icon: 'pi pi-check',
                                        disabled: false,
                                        command: () => {
                                            context.emit(
                                                'apply-filters',
                                                item.filters
                                            );
                                        }
                                    },
                                    {
                                        label: i18n.global.t('buttons.clear'),
                                        icon: 'pi pi-times-circle',
                                        disabled:
                                            item.username !==
                                            user.value?.username,
                                        command: () => {
                                            confirm.require({
                                                message: i18n.global.t(
                                                    'messages.deleteConfirmation',
                                                    {
                                                        item: item.name
                                                    }
                                                ),
                                                header: i18n.global.t(
                                                    'messages.pleaseConfirm'
                                                ),
                                                icon: 'pi pi-exclamation-triangle',
                                                acceptLabel:
                                                    i18n.global.t('labels.yes'),
                                                rejectLabel:
                                                    i18n.global.t('labels.no'),
                                                accept: () => {
                                                    remove(item.id)
                                                        .then(() => {
                                                            toast.success(
                                                                i18n.global.t(
                                                                    'messages.changesSavedSuccessfully'
                                                                )
                                                            );
                                                            loadFilters();
                                                        })
                                                        .catch((error) => {
                                                            toast.error(
                                                                error.response
                                                                    ?.data
                                                                    ?.error ||
                                                                    error.message
                                                            );
                                                        });
                                                }
                                            });
                                        }
                                    }
                                ]
                            };
                        })
                    }
                ]
            },
            {
                label: i18n.global.t('buttons.saveCurrentFilters'),
                icon: 'pi pi-save',
                disabled:
                    Object.keys(clearEmptyFilters(parentFilters.value || {}))
                        .length < 1,
                command: () => {
                    displayAddFilterDialog.value = true;
                }
            },
            {
                separator: true
            },
            {
                label: i18n.global.t('buttons.emptyCurrentFilters'),
                icon: 'pi pi-filter-slash',
                disabled:
                    Object.keys(clearEmptyFilters(parentFilters.value || {}))
                        .length < 1,
                command: () => {
                    context.emit('clear-filters');
                }
            }
        ]);

        const toggle = (event: any) => {
            menu.value.toggle(event);
        };

        const executeAdding = (payload: any) => {
            savingInProgress.value = true;

            add(parentComponentId.value, payload, parentFilters.value)
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully')
                    );
                    displayAddFilterDialog.value = false;
                    loadFilters();
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const onCloseAddDialog = (payload: any) => {
            if (!payload) {
                displayAddFilterDialog.value = false;
                return;
            }

            if (
                !publicFilters.value.some(
                    (publicFilter: any) =>
                        publicFilter.name === payload?.name &&
                        publicFilter.username === user.value?.username
                ) &&
                !personalFilters.value.some(
                    (personalFilter: any) =>
                        personalFilter.name === payload?.name
                )
            ) {
                executeAdding(payload);
                return;
            }

            confirm.require({
                message: i18n.global.t('messages.filterExists', {
                    item: payload.name
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    executeAdding(payload);
                }
            });
        };

        return {
            menu,
            items,
            toggle,
            displayAddFilterDialog,
            savingInProgress,
            onCloseAddDialog
        };
    }
};
