import TreeSelect from 'primevue/treeselect';
import {ref, computed, defineAsyncComponent} from 'vue';
import {useToast} from 'vue-toastification';
import Loading from 'vue-loading-overlay';
import {evaluateChildNodes, wizardOptions} from '@/utils/export-wizard/options';
import store from '@/store';
import {createNew} from '@/services/crons';
import {i18n} from '@/utils/i18n';
import {IExportWizardItem} from '@/interfaces/export-wizard/export-wizard-item';
import Button from 'primevue/button';
import Panel from 'primevue/panel';

export default {
    name: 'ExportWizard',
    components: {
        'p-button': Button,
        loading: Loading,
        TreeSelect,
        Panel
    },
    setup() {
        const toast = useToast();
        const identifier = ref(null);
        const isLoading = ref(false);
        const componentName = ref();

        const exportButtonEnabled = ref(false);
        const requiredData = ref();

        const findNodeBasedOnKey = (
            nodes: Array<IExportWizardItem>,
            key: string
        ): IExportWizardItem | null => {
            if (
                nodes.some((singleNode: any) => {
                    return singleNode.key === key;
                })
            ) {
                return nodes.find((singleNode: any) => {
                    return singleNode.key === key;
                });
            }

            let result: IExportWizardItem | null = null;

            nodes.forEach((singleNode) => {
                if (result === null && singleNode.children) {
                    result = findNodeBasedOnKey(singleNode.children, key);
                }
            });

            return result;
        };

        const initiateExport = async () => {
            isLoading.value = true;

            const optionNode = findNodeBasedOnKey(
                wizardOptions,
                Object.keys(identifier.value)[0]
            );

            try {
                if (optionNode?.onInitiateExport) {
                    await optionNode.onInitiateExport(requiredData.value);
                } else {
                    await createNew('Export Wizard', optionNode?.label, {
                        id: Object.keys(identifier.value)[0],
                        data: requiredData.value
                    });
                    toast.success(
                        i18n.global.t('messages.exportExecutionScheduled')
                    );
                }
                identifier.value = null;
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
            } finally {
                isLoading.value = false;
            }
        };

        const exportWizardOptions = computed(() => {
            const currentUser = store.getters['auth/user'];
            return evaluateChildNodes(wizardOptions, currentUser);
        });

        const importPlatform = computed(() => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            return platforms.find(
                (item: any) => item.value === currentPlatform
            );
        });

        const comp = computed(() => {
            const test = componentName.value;

            return defineAsyncComponent(
                () => import(`@/components/export-wizard/${test}.vue`)
            );
        });

        const onChange = (value: any) => {
            exportButtonEnabled.value = false;
            requiredData.value = null;

            if (!value) {
                componentName.value = null;
                return;
            }

            componentName.value = Object.keys(value)[0]
                .replaceAll('--', '++')
                .replaceAll('-', '/')
                .replaceAll('++', '-');
        };

        const onRequiredDataEntered = (value: any) => {
            exportButtonEnabled.value = !!value;
            requiredData.value = value || null;
        };

        return {
            initiateExport,
            exportButtonEnabled,
            identifier,
            isLoading,
            exportWizardOptions,
            importPlatform,
            componentName,
            comp,
            onChange,
            onRequiredDataEntered
        };
    }
};
