<loading v-model:active="loading" />
<Panel>
    <template #header>
        <div class="flex align-items-center">
            <span class="font-bold">
                {{$t('labels.shipment.headlessShipments')}}
            </span>
            <p-button
                v-if="editPermissionAvailable"
                class="ml-2"
                @click="showCreateDialog = true;"
                size="small"
                severity="success"
            >
                {{ $t("buttons.addNewGeneric") }}
            </p-button>
        </div></template
    >
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        :paginator="true"
        :lazy="true"
        :rows="20"
        scrollable
        scrollHeight="calc(100vh - 25rem)"
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="headlessShipments"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
        @page="onPage"
        @filter="onFilter"
        @sort="onSort"
    >
        <Column field="customerName" :header="$t('labels.customer')" sortable>
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                />
            </template>
        </Column>
        <Column field="address" :header="$t('labels.address')" sortable>
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                />
            </template>
            <template #body="{data}">
                <div>
                    <div class="text-xs">
                        <b>{{data.address}}</b>
                    </div>
                    <div class="text-xs">
                        <b>{{data.zipCode}} {{data.city}}</b>
                    </div>
                    <div class="text-xs">
                        <b>{{data.country}}</b>
                    </div>
                    <div class="text-xs" v-if="data.phoneNumber">
                        {{data.phoneNumber}}
                    </div>
                    <div class="text-xs" v-if="data.emailAddress">
                        {{data.emailAddress}}
                    </div>
                </div>
            </template>
        </Column>
        <Column
            field="referenceNumber"
            sortable
            :header="$t('labels.shipment.referenceNumber')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                />
            </template>
        </Column>
        <Column
            field="carrierName"
            sortable
            :header="$t('labels.shipment.shippingCarrier')"
        >
            <template #filter="{filterModel, filterCallback}">
                <Dropdown
                    v-model="filterModel.value"
                    :options="availableCarriers"
                    placeholder="Any"
                    optionLabel="label"
                    optionValue="value"
                    @change="filterCallback"
                    class="p-column-filter text-sm"
                    :showClear="true"
                >
                </Dropdown>
            </template>
        </Column>
        <Column
            field="warehouseName"
            sortable
            :header="$t('labels.shipment.warehouse')"
        >
            <template #filter="{filterModel, filterCallback}">
                <Dropdown
                    v-model="filterModel.value"
                    :options="warehouses"
                    placeholder="Any"
                    optionLabel="name"
                    optionValue="id"
                    @change="filterCallback"
                    class="p-column-filter text-sm"
                    :showClear="true"
                >
                </Dropdown>
            </template>
        </Column>
        <Column
            header="Tracking #"
            field="trackingNumber"
            sortable
            style="min-width: 6rem; max-width: fit-content"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    placeholder="#"
                />
            </template>
            <template #body="{data}">
                <div>
                    {{data.external?.trackingNumber}}
                    <i
                        class="pi pi-instagram ml-1"
                        style="margin-top: 2px"
                        @click="showTrackingLabel(data)"
                        v-tooltip.top="$t('labels.shipment.showShippingCarrierLabel', {carrierName:  data.carrierName})"
                    ></i>
                    <i
                        v-if="editPermissionAvailable && !data.cancelledAtFormatted"
                        @click="voidShipment(data)"
                        class="pi pi-ban ml-2 text-red-600"
                        style="margin-top: 2px; cursor: pointer"
                        v-tooltip.left="$t('labels.shipment.cancelShippingCarrierShipment', {carrierName: data.carrierName})"
                    ></i>
                </div>
                <div
                    class="text-xs fint-bold text-red-600"
                    v-if="data.cancelledAtFormatted"
                >
                    <span style="text-transform: uppercase"
                        >{{$t('labels.shipment.statusOptions.CANCELLED')}}
                        {{data.cancelledAtFormatted}}</span
                    >
                </div>
            </template>
        </Column>
        <Column sortable style="min-width: 9rem; max-width: fit-content">
            <template #body="{data}">
                <div v-if="data.import?.fileName">
                    {{ data.import.fileName }}
                    <i
                        class="pi pi-file-pdf ml-1 text-red-600"
                        @click="showTrackingLabelCombined(data)"
                        v-tooltip.left="$t('labels.shipment.showCombinedLabels')"
                    ></i>
                </div>
                <div class="text-xs">
                    <b>{{ data.runAtFormatted }}</b>
                </div>
                <div class="text-xs">
                    {{ $t('labels.shipment.weight') }}:
                    <span class="font-bold">{{ data.weight }}</span>
                </div>
            </template>
        </Column>
    </DataTable>
</Panel>
<CreateHeadlessShipmentDialog
    :displayDialog="showCreateDialog"
    @close-dialog="onCloseCreateDialog"
></CreateHeadlessShipmentDialog>
