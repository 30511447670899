<PrimeDialog
    :header="$t('labels.softwareUpdate')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '450px'}"
    :modal="true"
    :closable="false"
>
    <div class="confirmation-content">
        <div>
            <i
                class="pi pi-exclamation-triangle"
                style="
                    margin-left: 0px;
                    margin-right: 0.5rem;
                    margin-bottom: 0.3rem;
                    font-size: 1.2rem;
                "
            ></i
            >{{ $t('messages.newSoftwareBrowserReload') }}
        </div>
    </div>
    <template #footer>
        <div class="w-full mt-3 flex justify-content-between">
            <p-button severity="danger" @click="handleConfirmation(false)">
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button severity="success" @click="handleConfirmation">
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </template>
</PrimeDialog>
