<PrimeDialog
    :header="dialogHeader"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '75vw'}"
    @hide="onCancelButtonClicked"
    :modal="true"
    position="top"
>
    <LoadingPlugin v-model:active="loading" />
    <TabView>
        <TabPanel :header="$t('labels.products')">
            <h5 v-if="isFilterRelated">
                {{ $t('messages.changesWillBeAppliedToProducts', { number:
                productTotalRecords}) }}
            </h5>
            <ProductList
                :products="products"
                v-else
                :scrollableHeight="'55vh'"
            />
        </TabPanel>
        <slot name="tab-panel-middle"></slot>
        <TabPanel :header="$t('labels.summary')" :disabled="!showSummaryTab">
            <ScrollPanel style="height: 55vh">
                <Panel
                    :header="$t('labels.mail.execution')"
                    class="mb-3"
                    v-if="canPostponeExecution"
                >
                    <div class="grid formgrid p-fluid">
                        <div class="field col">
                            <label class="ml-2"
                                >{{$t('labels.immediateExecution')}}</label
                            >
                            <Checkbox
                                v-model="immediateExecution"
                                binary
                                class="ml-2 mb-1"
                            />
                        </div>
                        <div class="field col-9">
                            <template v-if="!immediateExecution">
                                <label>{{ $t('labels.at') }} </label>

                                <Calendar
                                    v-model="startTime"
                                    showTime
                                    dateFormat="dd.mm.yy"
                                    :minDate="new Date()"
                                />
                            </template>
                        </div>
                    </div>
                </Panel>
                <slot
                    name="summary-panel-header"
                    :immediateExecution="immediateExecution"
                    :startTime="startTime"
                >
                </slot>
                <slot name="summary-panel-middle"></slot>
                <slot name="summary-panel-footer">
                    <Panel :header="$t('labels.products')">
                        <h5 v-if="isFilterRelated">
                            {{ $t('messages.changesWillBeAppliedToProducts', {
                            number: productTotalRecords}) }}
                        </h5>
                        <ProductList v-else :products="products" />
                    </Panel>
                </slot>
            </ScrollPanel>
            <div class="w-full flex justify-content-between mt-3">
                <p-button severity="danger" @click="onCancelButtonClicked">
                    {{ $t("buttons.cancel") }}
                </p-button>
                <p-button
                    severity="success"
                    @click="onConfirmButtonClicked"
                    v-text="$t('buttons.confirm')"
                />
            </div>
        </TabPanel>
    </TabView>
</PrimeDialog>
