import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48d1d1bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "formgrid grid p-fluid" }
const _hoisted_2 = { class: "field col" }
const _hoisted_3 = {
  key: 0,
  class: "field col"
}
const _hoisted_4 = { class: "formgrid grid p-fluid" }
const _hoisted_5 = { class: "field col" }
const _hoisted_6 = { class: "field col" }
const _hoisted_7 = { class: "formgrid grid p-fluid" }
const _hoisted_8 = { class: "field col" }
const _hoisted_9 = { class: "formgrid grid p-fluid" }
const _hoisted_10 = { class: "field col" }
const _hoisted_11 = { class: "formgrid grid p-fluid" }
const _hoisted_12 = { class: "field col" }
const _hoisted_13 = { class: "field col" }
const _hoisted_14 = { class: "formgrid grid p-fluid" }
const _hoisted_15 = { class: "field col" }
const _hoisted_16 = { class: "field col" }
const _hoisted_17 = { class: "formgrid grid p-fluid" }
const _hoisted_18 = { class: "field col" }
const _hoisted_19 = { class: "field col" }
const _hoisted_20 = { class: "formgrid grid p-fluid" }
const _hoisted_21 = { class: "field col" }
const _hoisted_22 = { class: "field col" }
const _hoisted_23 = {
  key: 1,
  class: "formgrid grid p-fluid"
}
const _hoisted_24 = { class: "field col" }
const _hoisted_25 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[21] || (_cache[21] = $event => ((_ctx.showDialog) = $event)),
    header: _ctx.$t('labels.shipment.createHeadlessShipment'),
    closable: false,
    breakpoints: {'960px': '75vw', '640px': '90vw'},
    style: {width: '640px'},
    modal: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_loading, {
        active: _ctx.loading,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
      }, null, 8, ["active"]),
      _createElementVNode("form", {
        onSubmit: _cache[20] || (_cache[20] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.singleCustomer')), 1),
            _createElementVNode("div", null, [
              _createVNode(_component_InputSwitch, {
                modelValue: _ctx.singleShipment,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.singleShipment) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]),
          (_ctx.singleShipment)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.referenceNumber')), 1),
                _createVNode(_component_InputText, {
                  modelValue: _ctx.v$.referenceNumber.$model,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.referenceNumber.$model) = $event)),
                  class: _normalizeClass({'p-invalid':_ctx.v$.referenceNumber.$invalid && _ctx.submitted})
                }, null, 8, ["modelValue", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.referenceNumber.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.shippingCarrier')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$.shippingCompany.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.shippingCompany.$model) = $event)),
              options: _ctx.availableCarriers,
              optionLabel: "label",
              optionValue: "value",
              onChange: _cache[4] || (_cache[4] = $event => (_ctx.setDropdownValue('shippingCompany', $event))),
              showClear: true,
              class: _normalizeClass({'p-invalid':_ctx.v$.shippingCompany.$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.shippingCompany.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.warehouse')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$.warehouse.$model,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.warehouse.$model) = $event)),
              options: _ctx.warehouses,
              optionLabel: "name",
              optionValue: "id",
              onChange: _cache[6] || (_cache[6] = $event => (_ctx.setDropdownValue('warehouse', $event))),
              showClear: true,
              class: _normalizeClass({'p-invalid':_ctx.v$.warehouse.$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.shippingCompany.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.singleShipment)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.customer')), 1),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.v$.customerName.$model,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$.customerName.$model) = $event)),
                    class: _normalizeClass({'p-invalid':_ctx.v$.customerName.$invalid && _ctx.submitted})
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.customerName.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.address')), 1),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.v$.address.$model,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.v$.address.$model) = $event)),
                    class: _normalizeClass({'p-invalid':_ctx.v$.address.$invalid && _ctx.submitted})
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.address.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.contactPerson')), 1),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.v$.contactPerson.$model,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$.contactPerson.$model) = $event)),
                    class: _normalizeClass({'p-invalid':_ctx.v$.contactPerson.$invalid && _ctx.submitted})
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.contactPerson.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.phoneNumber')), 1),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.v$.phoneNumber.$model,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.v$.phoneNumber.$model) = $event)),
                    class: _normalizeClass({'p-invalid':_ctx.v$.phoneNumber.$invalid && _ctx.submitted})
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.phoneNumber.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.city')), 1),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.v$.city.$model,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.v$.city.$model) = $event)),
                    class: _normalizeClass({'p-invalid':_ctx.v$.city.$invalid && _ctx.submitted})
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.city.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.zipCode')), 1),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.v$.zipCode.$model,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_ctx.v$.zipCode.$model) = $event)),
                    class: _normalizeClass({'p-invalid':_ctx.v$.zipCode.$invalid && _ctx.submitted})
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.zipCode.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.email')), 1),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.v$.emailAddress.$model,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.v$.emailAddress.$model) = $event)),
                    class: _normalizeClass({'p-invalid':_ctx.v$.emailAddress.$invalid && _ctx.submitted})
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.emailAddress.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.country')), 1),
                  _createVNode(_component_p_dropdown, {
                    modelValue: _ctx.v$.countryCode.$model,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.v$.countryCode.$model) = $event)),
                    options: _ctx.countryOptions,
                    filter: "",
                    optionLabel: "label",
                    optionValue: "value",
                    onChange: _cache[15] || (_cache[15] = $event => (_ctx.setDropdownValue('countryCode', $event))),
                    showClear: true,
                    class: _normalizeClass({'p-invalid':_ctx.v$.countryCode.$invalid && _ctx.submitted})
                  }, null, 8, ["modelValue", "options", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.countryCode.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.numberOfLabels')), 1),
                  _createVNode(_component_InputNumber, {
                    locale: _ctx.locale,
                    showButtons: "",
                    min: 1,
                    autocomplete: "off",
                    modelValue: _ctx.v$.packageCount.$model,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.v$.packageCount.$model) = $event)),
                    class: _normalizeClass({'p-invalid':_ctx.v$.packageCount.$invalid && _ctx.submitted})
                  }, null, 8, ["locale", "modelValue", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.packageCount.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.groupedWeight')), 1),
                  _createVNode(_component_InputNumber, {
                    mode: "decimal",
                    locale: _ctx.locale,
                    minFractionDigits: 1,
                    autocomplete: "off",
                    modelValue: _ctx.v$.weight.$model,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => ((_ctx.v$.weight.$model) = $event)),
                    class: _normalizeClass({'p-invalid':_ctx.v$.weight.$invalid && _ctx.submitted})
                  }, null, 8, ["locale", "modelValue", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.weight.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ])
              ])
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.fileWithCustomerAddresses')), 1),
                _createVNode(_component_p_dropdown, {
                  modelValue: _ctx.v$.importId.$model,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => ((_ctx.v$.importId.$model) = $event)),
                  options: _ctx.importOptions,
                  optionLabel: "label",
                  optionValue: "value",
                  onChange: _cache[19] || (_cache[19] = $event => (_ctx.setDropdownValue('importId', $event))),
                  showClear: true,
                  class: _normalizeClass({'p-invalid':_ctx.v$.importId.$invalid && _ctx.submitted})
                }, null, 8, ["modelValue", "options", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.importId.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ])),
        _createElementVNode("div", _hoisted_25, [
          _createVNode(_component_p_button, {
            severity: "danger",
            onClick: _ctx.onCancelClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_p_button, {
            severity: "success",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
            ]),
            _: 1
          })
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}