import {ILayoutConfig} from '@/interfaces/layout/config';

export default {
    changeLayout: (
        state: {
            layoutConfig: ILayoutConfig;
        },
        payload: ILayoutConfig
    ): void => {
        Object.assign(state.layoutConfig, payload);
    },
    setBookmarks: (
        state: {
            bookmarks: Array<{
                id: string;
                entityId: string;
                entityName: string;
                path: string;
            }>;
        },
        payload: Array<{
            id: string;
            entityId: string;
            entityName: string;
            path: string;
        }>
    ): void => {
        state.bookmarks = payload;
    }
};
