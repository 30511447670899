import {computed, ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Button from 'primevue/button';
import ProductList from '@/components/grid/products/list.vue';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import Panel from 'primevue/panel';
import LoadingPlugin from 'vue-loading-overlay';
import ScrollPanel from 'primevue/scrollpanel';
import Checkbox from 'primevue/checkbox';

export default {
    emits: ['close-dialog', 'confirm-clicked'],
    components: {
        PrimeDialog: Dialog,
        TabPanel,
        TabView,
        ProductList,
        'p-button': Button,
        Column,
        Calendar,
        Panel,
        LoadingPlugin,
        ScrollPanel,
        Checkbox
    },
    props: {
        products: Array,
        productTotalRecords: Number,
        displayDialog: Boolean,
        isFilterRelated: Boolean,
        summaryTabVisible: Boolean,
        loading: Boolean,
        canPostponeExecution: Boolean,
        dialogHeader: String
    },
    setup(props: any, context: any) {
        const {
            products,
            productTotalRecords,
            dialogHeader,
            canPostponeExecution
        } = toRefs(props);
        const showDialog = ref(false);
        const isFilterRelated = ref(false);
        const loading = ref(true);
        const immediateExecution = ref(true);
        const startTime = ref(null);
        const summaryTabVisible = ref(null);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            if (!showDialog.value) {
                immediateExecution.value = true;
                startTime.value = null;
            }
            isFilterRelated.value = args.isFilterRelated || false;
            summaryTabVisible.value = args.summaryTabVisible || false;
            loading.value = args.loading || false;
        });

        const onCancelButtonClicked = () => {
            immediateExecution.value = true;
            startTime.value = null;
            context.emit('close-dialog', false);
        };

        const showSummaryTab = computed(() => {
            return (
                summaryTabVisible.value &&
                (isFilterRelated.value ||
                    (products.value && products.value.length > 0))
            );
        });

        const onConfirmButtonClicked = async () => {
            context.emit(
                'confirm-clicked',
                immediateExecution.value ? null : startTime.value
            );
        };

        return {
            products,
            productTotalRecords,
            isFilterRelated,
            showDialog,
            loading,
            onCancelButtonClicked,
            onConfirmButtonClicked,
            showSummaryTab,
            immediateExecution,
            startTime,
            dialogHeader,
            canPostponeExecution
        };
    }
};
