<div>
    <loading v-model:active="loading" />

    <div class="formgrid grid p-fluid">
        <div class="col field">
            <label>{{ $t('labels.sourceProduct') }} </label>
            <AutoComplete
                v-model="v$.productSource.$model"
                :suggestions="filteredProducts"
                :class="{'p-invalid':v$.productSource.$invalid && submitted}"
                @complete="searchProducts($event)"
                dropdown
                optionLabel="label"
                optionValue="value"
            />
            <small
                v-if="(v$.productSource.$invalid && submitted) || v$.productSource.$pending.$response"
                class="p-error"
                >{{v$.productSource.required.$message}}</small
            >
        </div>
        <div class="col field">
            <label>{{ $t('labels.productHome.productType') }} </label>
            <p-dropdown
                v-model="v$.productType.$model"
                :options="[{label: 'OpenBox', value: 'OpenBox'}, {label: 'Used (3 Sterne ★★★)', value: 'Used-3-Stars'}, {label: 'Used (2 Sterne ★★)', value: 'Used-2-Stars'}, {label: 'Used (1 Stern ★)', value: 'Used-1-Star'}, {label: 'ExDemo (Grade A)', value: 'ExDemo-Grade-A'}, {label: 'ExDemo (Grade B)', value: 'ExDemo-Grade-B'}, {label: 'DEMO-POOL', value: 'DEMO-POOL'}, {label: 'LOANER', value: 'KOMMISSIONSWARE'}]"
                optionLabel="label"
                optionValue="value"
                :class="{'p-invalid':v$.productType.$invalid && submitted}"
            >
            </p-dropdown>
            <small
                v-if="(v$.productType.$invalid && submitted) || v$.productType.$pending.$response"
                class="p-error"
                >{{v$.productType.required.$message}}</small
            >
        </div>
    </div>
    <div class="mt-3 flex justify-content-end">
        <p-button
            severity="success"
            v-text="$t('buttons.proceedToAdding')"
            :disabled="!showNextButton"
            @click="handleProceedToAddingClick"
        >
        </p-button>
    </div>
</div>
