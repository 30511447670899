<loading v-model:active="loading" />
<Panel :header="$t('labels.incomingGoods')">
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        paginator
        :rows="20"
        scrollable
        scrollHeight="calc(100vh - 25rem)"
        lazy
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="incomingGoods"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
        @page="onPage($event)"
        @filter="onFilter($event)"
        @sort="onSort($event)"
        @state-restore="onStateRestore"
        stateStorage="local"
        stateKey="incoming-goods-list-state-session"
    >
        <Column style="min-width: 1rem" class="tt-filter-menu-th"
            ><template #filter>
                <FilterMenu
                    parent-component-id="incoming-goods-list"
                    :parent-filters="filters"
                    @clear-filters="clearFilters"
                    @apply-filters="applyFilters"
                /> </template
        ></Column>
        <Column
            field="incomingGoodsNumber"
            sortable
            style="min-width: 8rem"
            :header="$t('labels.incomingGoodsNumber')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="p-column-filter text-sm"
                    placeholder="#"
                />
            </template>
            <template #body="{data}">
                <a
                    :href="data.url + 'webapp/view/shipment/ShipmentDetailArrival.page?entityId=' + data.id"
                    target="_blank"
                >
                    {{data.incomingGoodsNumber}}
                </a>
            </template>
        </Column>
        <Column
            field="createdDate"
            dataType="date"
            :sortable="true"
            :header="$t('labels.createdAt')"
            style="min-width: 10rem"
        >
            <template #filter="{filterModel}">
                <Calendar
                    class="p-column-filter text-xs"
                    v-model="filterModel.value"
                    dateFormat="dd.mm.yy"
                />
            </template>
            <template #body="data">
                {{ new Date(data.data.createdDate).toLocaleDateString("de-DE")
                }}
            </template>
        </Column>
        <Column
            field="sender"
            :header="$t('labels.shipment.sender')"
            style="min-width: 10rem"
            ><template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.shipment.sender')"
                />
            </template>
            <template #body="{data}">
                <a
                    class="text-xs"
                    :href="data.url + 'webapp/view/party/PartyDetail.page?entityId=' + data.recipientParty?.id"
                    target="_blank"
                    >{{ outputSenderInGrid(data) }}</a
                >
            </template>
        </Column>
        <Column
            :header="$t('labels.status')"
            style="min-width: 10rem"
            field="status"
            sortable
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #body="{data}">
                <span
                    :class="{'shipped': 'INCOMING_SHIPPED' === data.status, 'moved-into-store': 'INCOMING_MOVED_INTO_STORE' === data.status, 'cancelled' : 'INCOMING_CANCELLED' === data.status }"
                    >{{$t('labels.ticket.retoure.status.' + data.status)
                    }}</span
                ></template
            ><template #filter="{filterModel, filterCallback}">
                <Dropdown
                    v-model="filterModel.value"
                    :options="['INCOMING_MOVED_INTO_STORE', 'INCOMING_SHIPPED', 'INCOMING_CANCELLED']"
                    placeholder="Any"
                    class="p-column-filter text-sm"
                    @change="filterCallback()"
                >
                    <template #value="slotProps">
                        <span v-if="slotProps.value"
                            >{{$t('labels.ticket.retoure.status.' +
                            slotProps.value) }}</span
                        >
                        <span v-else class="text-sm"
                            >{{slotProps.placeholder}}</span
                        >
                    </template>
                    <template #option="slotProps">
                        <span
                            >{{$t('labels.ticket.retoure.status.' +
                            slotProps.option) }}</span
                        >
                    </template>
                </Dropdown>
            </template></Column
        >
        <Column
            field="salesOrder"
            :header="$t('labels.shipment.orderNumber')"
            style="min-width: 10rem"
        >
            <template #body="{data}">
                <a
                    v-if="data.salesOrder"
                    :href="data.url + 'webapp/view/op/order/salesOrderDetail.page?entityId=' + data.salesOrder.id"
                    target="_blank"
                    >{{ data.salesOrder.orderNumber }}</a
                >
            </template>
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    placeholder="#"
                />
            </template>
        </Column>
        <Column
            :header="$t('labels.incomingGoodsType')"
            style="min-width: 10rem"
            field="incomingGoodsType"
            sortable
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #body="{data}">
                <span
                    :class="{'text-blue-600': 'STANDARD' === data.incomingGoodsType, 'text-purple-600': 'SUPPLIER_COMPENSATION' === data.incomingGoodsType, 'text-orange-600' : 'INTERNAL' === data.incomingGoodsType, 'text-red-600': 'CUSTOMER_RETURN' === data.incomingGoodsType }"
                    >{{$t('labels.ticket.references.incomingGoods.' +
                    data.incomingGoodsType) }}</span
                ></template
            ><template #filter="{filterModel, filterCallback}">
                <Dropdown
                    v-model="filterModel.value"
                    :options="['STANDARD', 'INTERNAL', 'SUPPLIER_COMPENSATION', 'CUSTOMER_RETURN']"
                    placeholder="Any"
                    class="p-column-filter text-sm"
                    @change="filterCallback()"
                >
                    <template #value="slotProps">
                        <span
                            v-if="slotProps.value"
                            :class="{'text-blue-600': 'STANDARD' === slotProps.value, 'text-purple-600': 'SUPPLIER_COMPENSATION' === slotProps.value, 'text-orange-600' : 'INTERNAL' === slotProps.value, 'text-red-600': 'CUSTOMER_RETURN' === slotProps.value }"
                            >{{$t('labels.ticket.references.incomingGoods.' +
                            slotProps.value) }}</span
                        >
                        <span v-else class="text-sm"
                            >{{slotProps.placeholder}}</span
                        >
                    </template>
                    <template #option="slotProps">
                        <span
                            :class="{'text-blue-600': 'STANDARD' === slotProps.option, 'text-purple-600': 'SUPPLIER_COMPENSATION' === slotProps.option, 'text-orange-600' : 'INTERNAL' === slotProps.option, 'text-red-600': 'CUSTOMER_RETURN' === slotProps.option }"
                            >{{$t('labels.ticket.references.incomingGoods.' +
                            slotProps.option) }}</span
                        >
                    </template>
                </Dropdown>
            </template></Column
        >
        <Column
            field="relatedShipmentId"
            :header="$t('labels.incomingGoodsRelatedShipment')"
            style="min-width: 10rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="p-column-filter text-xs"
                    placeholder="#"
                />
            </template>
            <template #body="{data}">
                <a
                    v-if="data.relatedShipment"
                    :href="data.url + 'webapp/view/shipment/ShipmentDetailOutgoing.page?entityId=' + data.relatedShipment.id"
                    target="_blank"
                    >{{ data.relatedShipment.shipmentNumber }}</a
                >
            </template>
        </Column>

        <Column
            field="purchaseOrder"
            :header="$t('labels.incomingGoodsPurchaseOrder')"
            style="min-width: 10rem"
        >
            <template #body="{data}">
                <a
                    v-if="data.purchaseOrder"
                    class="text-xs"
                    :href="data.url + 'webapp/view/purchase/purchaseOrderDetail.page?entityId=' + data.purchaseOrder.id"
                    target="_blank"
                    >{{ data.purchaseOrder.purchaseOrderNumber }}</a
                >
            </template>
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    placeholder="#"
                />
            </template>
        </Column>

        <Column
            field="warehouse"
            :header="$t('labels.ticket.retoure.warehouse')"
            style="min-width: 10rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.shipment.warehouse')"
                />
            </template>
            <template #body="{data}">
                <div v-if="data.warehouse?.name">{{data.warehouse?.name}}</div>
            </template>
        </Column>
        <Column>
            <template #body="{data}">
                <span
                    v-if="editPermissionAvailable && data.status === 'INCOMING_SHIPPED' && data.incomingGoodsType === 'INTERNAL'"
                    v-tooltip.left="$t('labels.bookIncomingGoodsItems')"
                    @click="onInternalBookingClick(data.incomingGoodsNumber, $event)"
                    style="cursor: pointer"
                >
                    <font-awesome-icon :icon="['fas', 'angle-double-down']" />
                </span>
            </template>
        </Column>
    </DataTable>
</Panel>
<BookInternalIncomingGoods
    :display-dialog="showBookIntenalDialog"
    :incoming-goods-number="selectedIncomingGoodsNumber"
    @close-dialog="onCloseInternalBookingDialog"
>
</BookInternalIncomingGoods>
