import {onMounted, ref, watch} from 'vue';
import {useToast} from 'vue-toastification';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Dropdown from 'primevue/dropdown';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import InputNumber from 'primevue/inputnumber';
import AutoComplete from 'primevue/autocomplete';

import {getAllUsingFilters} from '@/services/storage-locations';
import {FilterMatchMode, FilterService} from 'primevue/api';
import {stripTagsAndTruncate} from '@/utils/helpers';
import {bookIntenal, getSingle} from '@/services/incoming-goods';

export default {
    emits: ['close-dialog'],
    props: {
        displayDialog: Boolean,
        incomingGoodsNumber: String
    },
    components: {
        'p-button': Button,
        'p-dropdown': Dropdown,
        PrimeDialog: Dialog,
        DataTable,
        Column,
        InputText,
        InputNumber,
        AutoComplete,
        loading: LoadingPlugin
    },
    setup(props: any, context: any) {
        const incomingGoodsItems = ref([]);
        const submitted = ref(false);
        const toast = useToast();
        const showDialog = ref(false);
        const loading = ref(false);
        const incomingGoodsNumber = ref(null);
        const targetWarehouse = ref(null);

        const originalWarehouseLevels = ref([]);
        const warehouseLevels = ref([]);
        const selectedItems = ref([]);
        const editingRows = ref([]);

        const articleComboFilter = ref('articleComboFilter');

        onMounted(() => {
            FilterService.register(
                articleComboFilter.value,
                (value, filter) => {
                    if (
                        filter === undefined ||
                        filter === null ||
                        filter.trim() === ''
                    ) {
                        return true;
                    }

                    if (value === undefined || value === null) {
                        return false;
                    }

                    const incomingItem = incomingGoodsItems.value.find(
                        (item: any) => item.id === value
                    );

                    return (
                        incomingItem?.article &&
                        (incomingItem.articleNumber.includes(filter) ||
                            (incomingItem.article?.ean || '').includes(
                                filter
                            ) ||
                            (
                                incomingItem.article?.manufacturerPartNumber ||
                                ''
                            ).includes(filter))
                    );
                }
            );
        });

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            if (!showDialog.value) {
                return;
            }
            loading.value = true;
            incomingGoodsNumber.value = args.incomingGoodsNumber;
            getSingle(args.incomingGoodsNumber)
                .then((data) => {
                    if ((data.data.incomingGoodsItems || []).length < 1) {
                        toast.error('Nothing to book.');
                        handleDialogClosing();
                        return;
                    }

                    targetWarehouse.value = data.data.warehouse;

                    incomingGoodsItems.value = [];

                    data.data.incomingGoodsItems.forEach(
                        (incomingGoodsItem: any) => {
                            if (
                                parseInt(incomingGoodsItem?.quantity || 0) < 1
                            ) {
                                return true;
                            }
                            const incomingBooking =
                                data.data.incomingBookings.find(
                                    (bookingItem: any) =>
                                        bookingItem.incomingGoodsItemId ===
                                        incomingGoodsItem.id
                                );

                            incomingGoodsItems.value.push({
                                id: incomingGoodsItem.id,
                                article: incomingGoodsItem.article,
                                articleNumber: incomingGoodsItem.articleNumber,
                                quantity: incomingGoodsItem.quantity
                                    ? parseInt(incomingGoodsItem.quantity)
                                    : null,
                                maxQuantity: incomingGoodsItem.quantity
                                    ? parseInt(incomingGoodsItem.quantity)
                                    : 0,
                                unitName: incomingGoodsItem.unitName,
                                serialNumberRequired:
                                    incomingGoodsItem.article
                                        ?.serialNumberRequired || false,
                                selectedSerialNumbers:
                                    incomingBooking.serialNumbers,
                                originalSerialNumbers:
                                    incomingBooking.serialNumbers,
                                targetWarehouseName: data.data.warehouse?.name,
                                targetWarehouseLevel: incomingBooking.levelId
                            });
                        }
                    );

                    getAllUsingFilters({
                        first: 0,
                        rows: 200,
                        columns: ['id', 'name', 'warehouseId', 'parentLevelId'],
                        filters: {
                            id: {
                                value: incomingGoodsItems.value.map(
                                    (item: any) => item.targetWarehouseLevel
                                ),
                                matchMode: FilterMatchMode.IN
                            },
                            warehouseId: {
                                value: data.data.warehouse?.id,
                                matchMode: FilterMatchMode.EQUALS
                            },
                            stockable: {
                                value: true,
                                matchMode: FilterMatchMode.EQUALS
                            },
                            deleted: {
                                value: false,
                                matchMode: FilterMatchMode.EQUALS
                            }
                        }
                    })
                        .then((data) => {
                            if (data.data) {
                                originalWarehouseLevels.value = data.data.map(
                                    (item: {id: string; name: string}) => {
                                        return {
                                            label: item.name,
                                            value: item.id
                                        };
                                    }
                                );

                                incomingGoodsItems.value.forEach(
                                    (item: any) => {
                                        const whLevel =
                                            originalWarehouseLevels.value.find(
                                                (whl: any) =>
                                                    whl.value ===
                                                    item.targetWarehouseLevel
                                            );
                                        if (whLevel) {
                                            item.targetWarehouseLevel =
                                                Object.assign(
                                                    {},
                                                    {
                                                        value: whLevel.value,
                                                        label: ''
                                                    }
                                                );
                                        }
                                    }
                                );
                            }
                        })
                        .catch((error) => {
                            toast.error(error.message);
                        });
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    handleDialogClosing();
                })
                .finally(() => {
                    loading.value = false;
                });
        });

        const searchWarehouseLevels = (event: any) => {
            getAllUsingFilters({
                first: 0,
                rows: 50,
                columns: ['id', 'name', 'warehouseId', 'parentLevelId'],
                filters: {
                    warehouseId: {
                        value: targetWarehouse.value?.id,
                        matchMode: FilterMatchMode.EQUALS
                    },
                    'or-name': {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    'or-barcode': {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.EQUALS
                    },
                    stockable: {
                        value: true,
                        matchMode: FilterMatchMode.EQUALS
                    },
                    deleted: {
                        value: false,
                        matchMode: FilterMatchMode.EQUALS
                    }
                }
            })
                .then((data) => {
                    if (data.data) {
                        warehouseLevels.value = data.data.map(
                            (item: {id: string; name: string}) => {
                                return {
                                    label: item.name,
                                    value: item.id
                                };
                            }
                        );
                        originalWarehouseLevels.value = Object.values(
                            [
                                ...originalWarehouseLevels.value,
                                ...warehouseLevels.value
                            ].reduce(
                                (acc, obj) => ({...acc, [obj.value]: obj}),
                                {}
                            )
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const handleDialogClosing = (needsRefresh: boolean = false) => {
            incomingGoodsItems.value = [];
            selectedItems.value = [];
            context.emit('close-dialog', {
                needsRefresh
            });
        };

        const handleSubmit = async () => {
            loading.value = true;

            try {
                const bookingResult = await bookIntenal(
                    incomingGoodsNumber.value,
                    selectedItems.value.map((item: any) => {
                        return {
                            id: item.id,
                            articleNumber: item.articleNumber,
                            serialNumbers: item.selectedSerialNumbers,
                            quantity: item.quantity,
                            targetWarehouseLevel:
                                item.targetWarehouseLevel?.value ||
                                item.targetWarehouseLevel
                        };
                    })
                );

                toast.success(
                    i18n.global.t(
                        bookingResult?.data?.fullBooking
                            ? 'messages.incomingBookingFullyBooked'
                            : 'messages.incomingBookingPartiallyBooked'
                    )
                );

                handleDialogClosing(true);
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
            } finally {
                loading.value = false;
            }
        };

        const onCancelButtonClicked = (event: any) => {
            event.preventDefault();
            handleDialogClosing();
        };

        const filterDefintions: any = {
            id: {
                value: null,
                matchMode: articleComboFilter.value
            },
            originalSerialNumbers: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }
        };

        const filters = ref(filterDefintions);

        const resolveWarehouseLevel = (targetWarehouseLevel: any) => {
            return originalWarehouseLevels.value.find(
                (item: any) =>
                    item.value ===
                    (targetWarehouseLevel.value || targetWarehouseLevel)
            )?.label;
        };

        const onCellEditComplete = (event: {data: any; newData: any}) => {
            const incomingBooking = incomingGoodsItems.value.find(
                (item: any) => item.id === event.newData.id
            );
            if (incomingBooking) {
                const existingWarehouse = originalWarehouseLevels.value.find(
                    (item: any) =>
                        item.value ===
                        (event.newData?.targetWarehouseLevel?.value ||
                            event.newData?.targetWarehouseLevel)
                );

                Object.assign(incomingBooking, {
                    quantity: event.newData.quantity,
                    targetWarehouseLevel: existingWarehouse
                        ? {value: existingWarehouse.value, label: ''}
                        : event.data?.targetWarehouseLevel
                });
            }
        };

        const removeSerialNumberItem = (
            itemId: string,
            serialNumber: string
        ) => {
            const incomingBooking = incomingGoodsItems.value.find(
                (item: any) => item.id === itemId
            );
            Object.assign(incomingBooking, {
                selectedSerialNumbers:
                    incomingBooking.selectedSerialNumbers.filter(
                        (sn: string) => sn !== serialNumber
                    )
            });
        };

        const addSerialNumberItem = (itemId: string, serialNumber: string) => {
            const incomingBooking = incomingGoodsItems.value.find(
                (item: any) => item.id === itemId
            );
            incomingBooking.selectedSerialNumbers.push(serialNumber);
        };

        return {
            handleSubmit,
            submitted,
            onCancelButtonClicked,
            locale: i18n.global.locale,
            stripTagsAndTruncate: stripTagsAndTruncate,
            showDialog,
            incomingGoodsItems,
            selectedItems,
            filters,
            loading,
            warehouseLevels,
            resolveWarehouseLevel,
            onCellEditComplete,
            removeSerialNumberItem,
            addSerialNumberItem,
            editingRows,
            searchWarehouseLevels,
            articleComboFilter
        };
    }
};
