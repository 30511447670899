import AppTopbar from './header/header.vue';
import NewSoftwareVersionDialog from '@/components/dialog/general/new-software-version.vue';
import AppFooter from './footer/footer.vue';
import AppMenu from './menu-sidebar/menu-sidebar.vue';
import {getProfile} from '@/services/auth';
import {computed, onMounted, onUnmounted, ref, watch} from 'vue';
import store from '@/store';
import axios from 'axios';
import {v5 as uuidv5} from 'uuid';
import ConfirmDialog from 'primevue/confirmdialog';

import {ILayoutConfig} from '@/interfaces/layout/config';

export default {
    components: {
        NewSoftwareVersionDialog,
        ConfirmDialog,
        AppTopbar,
        AppFooter,
        AppMenu
    },
    setup() {
        const appElement = ref(null);

        const interval = ref(null);

        const displayReloadConfirmationDialog = ref(false);

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        onMounted(async () => {
            localStorage.removeItem('version');
            localStorage.removeItem('last-modified-timestamp');

            try {
                const user = await getProfile();
                store.dispatch('auth/getUser', user);

                if (interval.value !== null) {
                    clearInterval(interval.value);
                }

                interval.value = setInterval(() => {
                    doBrowserReloadIfNeeded(user);
                }, 5 * 1000);
            } catch (error) {
                store.dispatch('auth/logout');
            }
        });

        onUnmounted(() => {
            if (interval.value !== null) {
                clearInterval(interval.value);
            }
        });

        const doBrowserReloadIfNeeded = async (user: any) => {
            const versionHash = localStorage.getItem('version-hash');

            const result = await axios.get('/index.html', {
                headers: {
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                    Expires: '0'
                }
            });

            if (!result?.data) {
                return;
            }

            const hash = uuidv5(
                result.data,
                '1b671a64-40d5-491e-99b0-da01ff1f3341'
            );

            if (!versionHash) {
                localStorage.setItem('version-hash', hash);
                return;
            }

            if (versionHash !== hash) {
                localStorage.setItem('version-hash', hash);
                if (user?.username === 'lager.wiesbaden@teltec.de') {
                    onReloadConfirmed();
                } else {
                    displayReloadConfirmationDialog.value = true;
                }
            }
        };

        const onReloadConfirmed = (confirmed: boolean = true) => {
            if (confirmed) {
                window.location.reload();
            }
        };

        const isSidebarActive = computed(() => {
            return (
                layoutConfig.value.overlayMenuActive ||
                layoutConfig.value.staticMenuMobileActive
            );
        });

        const outsideClickListener = ref(null);

        watch(isSidebarActive, (newVal) => {
            if (newVal) {
                bindOutsideClickListener();
            } else {
                unbindOutsideClickListener();
            }
        });

        const containerClass = computed(() => {
            return {
                'layout-theme-light': layoutConfig.value.darkTheme,
                'layout-theme-dark': layoutConfig.value.darkTheme,
                'layout-overlay': layoutConfig.value.menuMode === 'overlay',
                'layout-static': layoutConfig.value.menuMode === 'static',
                'layout-static-inactive':
                    layoutConfig.value.staticMenuDesktopInactive &&
                    layoutConfig.value.menuMode === 'static',
                'layout-overlay-active': layoutConfig.value.overlayMenuActive,
                'layout-mobile-active':
                    layoutConfig.value.staticMenuMobileActive
            };
        });
        const bindOutsideClickListener = () => {
            if (!outsideClickListener.value) {
                outsideClickListener.value = (event: any) => {
                    if (isOutsideClicked(event)) {
                        store.dispatch('ui/changeLayout', {
                            overlayMenuActive: false,
                            staticMenuMobileActive: false,
                            menuHoverActive: false
                        });
                    }
                };
                document.addEventListener('click', outsideClickListener.value);
            }
        };
        const unbindOutsideClickListener = () => {
            if (outsideClickListener.value) {
                document.removeEventListener(
                    'click',
                    outsideClickListener.value
                );
                outsideClickListener.value = null;
            }
        };
        const isOutsideClicked = (event: any) => {
            const sidebarEl = document.querySelector('.layout-sidebar');
            const topbarEl = document.querySelector('.layout-menu-button');

            return !(
                sidebarEl.isSameNode(event.target) ||
                sidebarEl.contains(event.target) ||
                topbarEl.isSameNode(event.target) ||
                topbarEl.contains(event.target)
            );
        };

        return {
            appElement,
            interval,
            displayReloadConfirmationDialog,
            onReloadConfirmed,
            containerClass
        };
    }
};
