<div id="languageIcon" @click="toggleDataTable">
    <img
        :src="require('@/assets/img/flag_placeholder.png')"
        class="flag"
        :class="flagIcon"
    />
</div>

<OverlayPanel ref="opLanguages" appendTo="body" id="overlay_panel_languages">
    <DataTable :value="languages" :rows="5" responsiveLayout="scroll">
        <Column headerStyle="display:none;"
            ><template #body="{data}">
                <div style="cursor: pointer" @click="changeLanguage(data.key)">
                    <img
                        :src="require('@/assets/img/flag_placeholder.png')"
                        class="flag mr-2"
                        :class="data.flag"
                    />
                    {{ $t(data.label) }}
                </div>
            </template></Column
        >
    </DataTable>
</OverlayPanel>
