import {ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import {
    ekPriceCalculation,
    vkPriceCalculation,
    uvpPriceCalculation
} from '@/utils/helpers';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        'p-button': Button,
        TabPanel,
        TabView
    },
    props: {
        displayDialog: Boolean,
        sourceProduct: Object
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const {sourceProduct} = toRefs(props);
        const choice = ref(null);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
        });

        const closeDialog = () => {
            showDialog.value = false;
            context.emit('close-dialog', {choice: choice.value});
            choice.value = null;
        };

        const handleSelection = (userChoice: string, event: any) => {
            event.preventDefault();
            choice.value = userChoice;
            showDialog.value = false;
        };

        return {
            showDialog,
            sourceProduct,
            ekPriceCalculation,
            vkPriceCalculation,
            uvpPriceCalculation,
            closeDialog,
            handleSelection
        };
    }
};
