import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import Sidebar from 'primevue/sidebar';
import {computed, onMounted, ref, watch} from 'vue';
import store from '@/store';
import {ILayoutConfig} from '@/interfaces/layout/config';

export default {
    emits: ['close-sidebar'],
    props: {
        simple: {
            type: Boolean,
            default: false
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        RadioButton,
        'p-button': Button,
        InputSwitch,
        Sidebar
    },
    setup(props: any, context: any) {
        const scales = ref([10, 11, 12, 13, 14, 15, 16]);
        const visible = ref(false);

        watch(props, (args) => {
            visible.value = args.visible;
        });

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        onMounted(async () => {
            if (!localStorage.getItem('layoutConfig')) {
                return;
            }

            const current = JSON.parse(
                localStorage.getItem('layoutConfig')
            ) as ILayoutConfig;
            if (current?.theme) {
                onChangeTheme(
                    current.theme,
                    current.darkTheme ? 'dark' : '',
                    'lara-light-indigo'
                );
            }

            if (current.scale) {
                store.dispatch('ui/changeLayout', {
                    scale: current.scale
                });
                applyScale();
            }
        });

        const onChangeTheme = (
            theme: string,
            mode: string,
            currentTheme: string
        ) => {
            if (theme === currentTheme) {
                return;
            }
            const elementId = 'theme-css';
            const linkElement = document.getElementById(elementId);
            const cloneLinkElement = linkElement.cloneNode(true) as HTMLElement;
            const newThemeUrl = linkElement
                .getAttribute('href')
                .replace(currentTheme, theme);
            cloneLinkElement.setAttribute('id', elementId + '-clone');
            cloneLinkElement.setAttribute('href', newThemeUrl);
            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', elementId);
                store.dispatch('ui/changeLayout', {
                    theme,
                    darkTheme: mode === 'dark'
                });
            });
            linkElement.parentNode.insertBefore(
                cloneLinkElement,
                linkElement.nextSibling
            );
        };
        const decrementScale = () => {
            store.dispatch('ui/changeLayout', {
                scale: layoutConfig.value.scale - 1
            });
            applyScale();
        };
        const incrementScale = () => {
            store.dispatch('ui/changeLayout', {
                scale: layoutConfig.value.scale + 1
            });
            applyScale();
        };
        const applyScale = () => {
            document.documentElement.style.fontSize =
                layoutConfig.value.scale + 'px';
        };

        const onHide = () => {
            context.emit('close-sidebar');
        };

        return {
            decrementScale,
            incrementScale,
            applyScale,
            onChangeTheme,
            scales,
            visible,
            layoutConfig,
            onHide
        };
    }
};
