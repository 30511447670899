<template v-if="!azureState || isAuthLoading">
    <div
        class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
    >
        <div class="flex flex-column align-items-center justify-content-center">
            <img
                :src="require('@/assets/img/' + logoUrl+ '.png')"
                alt="Teltec logo"
                class="mb-5 w-14rem flex-shrink-0"
            />
            <div
                style="
                    border-radius: 56px;
                    padding: 0.3rem;
                    background: linear-gradient(
                        180deg,
                        var(--primary-color) 10%,
                        rgba(33, 150, 243, 0) 30%
                    );
                "
            >
                <div
                    class="w-full surface-card py-8 px-5 sm:px-8"
                    style="border-radius: 53px"
                >
                    <div class="text-center mb-5">
                        <span class="text-600 font-medium"
                            >{{ $t("labels.signInToStart") }}</span
                        >
                    </div>
                    <form @submit.prevent="fetchRedirectData">
                        <div>
                            <p-button
                                class="w-full p-3 text-xl"
                                type="submit"
                                block="true"
                                :loading="isAuthLoading"
                                :disabled="isAuthLoading"
                            >
                                <span
                                    class="p-button-label"
                                    data-pc-section="label"
                                    >{{ $t("labels.signIn") }}</span
                                >
                            </p-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
