import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const getSingle = (documentId: string): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/documents/' + documentId, {
        responseType: 'blob'
    });
};

export const getPickupDocument = (
    shipmentNumber: string
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/shipments/' + shipmentNumber + '/download-pickup-document',
        {
            responseType: 'blob'
        }
    );
};

export const sendDocumentToRemotePrinter = async (
    documentId: string,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/documents/' + documentId + '/send-to-remote-printer',
        payload
    );
};
