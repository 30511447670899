<PrimeDialog
    :header="$t('labels.ticket.retoure.createSupplierReturn')"
    v-model:visible="showDialog"
    :closable="false"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '640px'}"
    :modal="true"
>
    <loading v-model:active="savingInProgress" />
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="flex mb-3">
            <label style="margin-top: 3px"
                >{{ $t('labels.ticket.retoure.purchaseOrderAsBase') }}</label
            >
            <InputSwitch
                v-model="selectUsingPurchaseOrder"
                @input="setDropdownValue('purchaseOrder', {value: null});setDropdownValue('supplier', {value: null})"
                class="ml-3"
            />
        </div>
        <div class="grid p-fluid">
            <div class="col field">
                <label> {{$t('labels.ticket.retoure.purchaseOrder')}} </label>

                <p-dropdown
                    v-model="v$.purchaseOrder.$model"
                    :options="purchaseOrders"
                    :disabled="!selectUsingPurchaseOrder"
                    optionLabel="label"
                    optionValue="value"
                    :filter="true"
                    class="w-full"
                    @change="setDropdownValue('purchaseOrder', $event)"
                    :showClear="true"
                    :class="{'p-invalid':v$.purchaseOrder.$invalid && submitted}"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.purchaseOrder.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col field">
                <label> {{$t('labels.productAdd.supplier')}} </label>

                <p-dropdown
                    v-model="v$.supplier.$model"
                    :options="selectUsingPurchaseOrder ? (suppliers || []) : (allSuppliers || [])"
                    optionLabel="label"
                    optionValue="value"
                    :disabled="selectUsingPurchaseOrder"
                    :filter="true"
                    class="w-full"
                    @change="setDropdownValue('supplier', $event)"
                    :showClear="true"
                    :class="{'p-invalid':v$.supplier.$invalid && submitted}"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.supplier.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col field">
                <label> {{$t('labels.shipment.warehouse')}} </label>

                <p-dropdown
                    v-model="v$.warehouse.$model"
                    :options="warehouses || []"
                    optionLabel="name"
                    optionValue="id"
                    :filter="true"
                    class="w-full"
                    @change="setDropdownValue('warehouse', $event)"
                    :showClear="true"
                    :class="{'p-invalid':v$.warehouse.$invalid && submitted}"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.warehouse.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col field">
                <label>
                    {{ $t('labels.ticket.additional.externalRMA') }}
                </label>
                <InputText
                    autocomplete="off"
                    v-model="v$.externalRMA.$model"
                    :class="{'p-invalid':v$.externalRMA.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.externalRMA.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="w-full flex justify-content-between">
            <p-button severity="danger" @click="onCancelClick">
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button type="submit" severity="success">
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </form>
</PrimeDialog>
