<div id="platformIcon" @click="toggleDataTable">
    <template v-for="platformItem in platforms">
        <template v-if="platform === platformItem.value">
            <font-awesome-icon :icon="['fas', 'anchor']" />
            &nbsp;&nbsp;{{ platformItem.label }}
        </template>
    </template>
</div>

<OverlayPanel ref="opPlatforms" appendTo="body" id="overlay_panel_platforms">
    <DataTable :value="platforms" :rows="5" responsiveLayout="scroll">
        <Column headerStyle="display:none;"
            ><template #body="{data}">
                <div style="cursor: pointer" @click="changePlatform(data)">
                    {{data.label}}
                </div>
            </template></Column
        >
    </DataTable>
</OverlayPanel>
