<p-dialog
    :header="$t('labels.addImages')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    @hide="closeDialog"
    :modal="true"
    class="text-sm"
>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="formgrid grid p-fluid">
            <div class="field col">
                <label>{{ $t('labels.description') }} </label>
                <InputText
                    :placeholder="$t('labels.description')"
                    autocomplete="off"
                    v-model="v$.title.$model"
                    :class="{'p-invalid':v$.title.$invalid && submitted}"
                />
                <small
                    v-if="(v$.title.$invalid && submitted) || v$.title.$pending.$response"
                    class="p-error"
                    >{{v$.title.required.$message.replace('Value',
                    'Title')}}</small
                >
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col">
                <label>Url</label>
                <InputText
                    autocomplete="off"
                    v-model="v$.url.$model"
                    :class="{'p-invalid':v$.url.$invalid && submitted}"
                />
                <small
                    v-if="((v$.url.required.$invalid && submitted) || v$.url.$pending.$response)"
                    class="p-error"
                    >{{v$.url.required.$message.replace('Value', 'Url')}}</small
                >
                <small
                    v-if="((v$.url.url.$invalid && submitted) || v$.url.$pending.$response)"
                    class="p-error"
                    >{{v$.url.url.$message.replace('Value', 'Url')}}</small
                >
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col">
                <label>{{ $t('labels.or') }}</label>
                <div v-bind="getRootProps()" class="import-wizard-container">
                    <div style="text-align: center">
                        <input v-bind="getInputProps()" />
                        <p v-if="acceptedFiles.length > 0">
                            {{ acceptedFiles.map(u => u.name).join(', ') }}
                        </p>
                        <p v-else>{{ $t("labels.importWizardInfoText") }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-content-end mt-2">
            <p-button
                :disabled="savingInProgress"
                severity="success"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
                type="submit"
            >
            </p-button>
        </div>
    </form>
</p-dialog>
