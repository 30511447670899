import {computed, ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import {stripTagsAndTruncate} from '@/utils/helpers';
import useVuelidate from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import {i18n} from '@/utils/i18n';
import {getAll} from '@/services/metadata';
import {useToast} from 'vue-toastification';
import {addNewRepairQuotation} from '@/services/tickets';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import AutoComplete from 'primevue/autocomplete';
import LoadingPlugin from 'vue-loading-overlay';
export default {
    components: {
        PrimeDialog: Dialog,
        InputText,
        'p-button': Button,
        'p-dropdown': Dropdown,
        'p-fieldset': Fieldset,
        AutoComplete,
        loading: LoadingPlugin
    },
    emits: ['close-dialog'],
    props: {
        displayAddQuotationDialog: Boolean,
        ticketDetails: Object
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const {ticketDetails} = toRefs(props);

        const submitted = ref(false);
        const toast = useToast();
        const warehouseOptions = ref([]);

        const savingInProgress = ref(false);

        getAll(['warehouse'], false, ['id', 'name'])
            .then((data) => {
                if (data.data?.warehouse) {
                    warehouseOptions.value = parseIntoDropdownList(
                        data.data.warehouse
                    );
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });

        watch(props, (args) => {
            showDialog.value = args.displayAddQuotationDialog;
        });

        const ticketCustomer = computed(() => {
            return (
                '[' +
                ticketDetails.value?.customer?.customerNumber +
                '] ' +
                (ticketDetails.value?.customer?.company ||
                    ticketDetails.value?.customer?.firstName +
                        ' ' +
                        ticketDetails.value?.customer?.lastName)
            );
        });

        const closeResponsive = (withReload: boolean = false) => {
            showDialog.value = false;
            state.value = Object.assign({}, formInitialState);
            context.emit('close-dialog', withReload);
        };

        const truncatedSubject = computed(() => {
            return ticketDetails.value?.subject
                ? stripTagsAndTruncate(ticketDetails.value.subject, 50, '...')
                : '';
        });

        const formInitialState: any = {
            warehouseId: null,
            commission: null
        };

        const state = ref(
            Object.assign(formInitialState, {
                commission:
                    'Repair ' +
                    ticketDetails.value?.ticketArticle?.name +
                    ' S/N ' +
                    ticketDetails.value?.customAttributes?.ticket_serialnumber
            })
        );

        const rules = {
            warehouseId: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            commission: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            }
        };

        const v$ = useVuelidate(rules, state);

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            addNewRepairQuotation(state.value, ticketDetails.value.ticketNumber)
                .then((data: any) => {
                    const responseErrorKey = Object.keys(data.data).find(
                        (item: any) => {
                            return data.data.value[item]['error'];
                        }
                    );

                    if (responseErrorKey) {
                        toast.error(data.data.value[responseErrorKey]['error']);
                    } else {
                        closeResponsive(true);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const parseIntoDropdownList = (data: any) => {
            return data.map((item: {id: string; name: string}) => {
                return {
                    label: item.name,
                    value: item.id
                };
            });
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            if (!event.value) {
                return;
            }

            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;
            Object.assign(state.value, temp);
        };

        return {
            showDialog,
            closeResponsive,
            ticketDetails,
            truncatedSubject,
            state,
            v$,
            handleSubmit,
            submitted,
            setDropdownValue,
            warehouseOptions,
            ticketCustomer,
            savingInProgress
        };
    }
};
