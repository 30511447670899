import {onMounted, ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import {useToast} from 'vue-toastification';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import useVuelidate from '@vuelidate/core';
import {helpers, required, requiredIf} from '@vuelidate/validators';
import {createSupplierReturn} from '@/services/tickets';
import Dropdown from 'primevue/dropdown';
import {getAll} from '@/services/metadata';
import {stripTagsAndTruncate} from '@/utils/helpers';
import InputSwitch from 'primevue/inputswitch';
import {getMatchingPurchaseOrders} from '@/services/incoming-goods';

export default {
    emits: ['close-supplier-return-dialog'],
    components: {
        PrimeDialog: Dialog,
        InputText,
        'p-button': Button,
        'p-dropdown': Dropdown,
        loading: LoadingPlugin,
        InputSwitch
    },
    props: {
        ticketDetails: Object,
        retoure: Object,
        displayDialog: Boolean
    },
    setup(props: any, context: any) {
        const {ticketDetails, retoure} = toRefs(props);
        const showDialog = ref(false);
        const toast = useToast();
        const savingInProgress = ref(false);
        const submitted = ref(false);
        const suppliers = ref([]);
        const warehouses = ref([]);
        const allSuppliers = ref([]);
        const purchaseOrders = ref([]);
        const selectUsingPurchaseOrder = ref(true);
        const loading = ref(false);

        const state = ref({
            purchaseOrder: null,
            supplier: null,
            warehouse: null,
            externalRMA: null
        });

        onMounted(() => {
            getAll(['warehouse'], false).then((data: any) => {
                warehouses.value = data.data['warehouse'];
            });
            getAll(['supplier'], false).then((data: any) => {
                allSuppliers.value = data.data['supplier']
                    .map(
                        (item: {
                            id: string;
                            name: string;
                            supplierNumber: string;
                            currencyId: string;
                            currencyName: string;
                            company2: string;
                        }) => {
                            return {
                                label:
                                    stripTagsAndTruncate(
                                        [item.name, item.company2].join(' '),
                                        150,
                                        '...'
                                    ) +
                                    ' (' +
                                    item.supplierNumber +
                                    ') ',
                                value: item.id
                            };
                        }
                    )
                    .sort((a: any, b: any) => {
                        const nameA = a.label.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.label.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    });
            });
        });

        const rules = {
            purchaseOrder: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return selectUsingPurchaseOrder.value;
                    })
                )
            },
            supplier: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return !selectUsingPurchaseOrder.value;
                    })
                )
            },
            warehouse: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            externalRMA: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, async (args) => {
            showDialog.value = args.displayDialog;
            if (
                state.value.externalRMA === null &&
                ticketDetails?.value?.customAttributes?.ticket_external_rma
            ) {
                state.value.externalRMA =
                    ticketDetails.value.customAttributes.ticket_external_rma;
            }

            if (showDialog.value) {
                loading.value = true;
                if (args.retoure?.warehouse?.id) {
                    state.value.warehouse = args.retoure.warehouse.id;
                }

                try {
                    const matchingPurchaseOrders =
                        await getMatchingPurchaseOrders(
                            args.retoure?.incomingGoodsNumber
                        );

                    purchaseOrders.value = matchingPurchaseOrders.data.map(
                        (item: {
                            id: string;
                            purchaseOrderNumber: string;
                            supplier: {
                                id: string;
                                name: string;
                            };
                        }) => {
                            return {
                                label:
                                    item.purchaseOrderNumber +
                                    ' (' +
                                    item.supplier.name +
                                    ') ',
                                value: item.id,
                                supplier: item.supplier
                            };
                        }
                    );

                    suppliers.value = matchingPurchaseOrders.data.map(
                        (item: {
                            supplier: {
                                id: string;
                                name: string;
                                supplierNumber: string;
                                currencyId: string;
                                currencyName: string;
                                company2: string;
                            };
                        }) => {
                            return {
                                label:
                                    stripTagsAndTruncate(
                                        [
                                            item.supplier.name,
                                            item.supplier.company2
                                        ].join(' '),
                                        150,
                                        '...'
                                    ) +
                                    ' (' +
                                    item.supplier.supplierNumber +
                                    ') ',
                                value: item.supplier.id
                            };
                        }
                    );

                    suppliers.value = suppliers.value.filter((a, i) => {
                        return (
                            suppliers.value.findIndex(
                                (s) => a.value === s.value
                            ) === i
                        );
                    });
                } finally {
                    loading.value = false;
                }
            }
        });

        const onCancelClick = (event: any) => {
            event.preventDefault();
            showDialog.value = false;
            submitted.value = false;
            state.value = {
                purchaseOrder: null,
                supplier: null,
                warehouse: null,
                externalRMA: null
            };
            context.emit('close-supplier-return-dialog');
        };

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            try {
                savingInProgress.value = true;
                await createSupplierReturn(
                    ticketDetails.value.ticketNumber,
                    retoure.value.incomingGoodsNumber,
                    state.value
                );
                toast.success(
                    i18n.global.t('messages.changesSavedSuccessfully')
                );
                context.emit('close-supplier-return-dialog', true);
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
                context.emit('close-supplier-return-dialog');
            } finally {
                submitted.value = false;
                state.value = {
                    purchaseOrder: null,
                    supplier: null,
                    warehouse: null,
                    externalRMA: null
                };
                savingInProgress.value = false;
                showDialog.value = false;
            }
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;

            if ('purchaseOrder' === fieldName) {
                const found = purchaseOrders.value.find(
                    (item) => item.value === event.value
                );
                if (found?.supplier?.id) {
                    temp['supplier'] = found.supplier.id;
                }
            }

            Object.assign(state.value, temp);
        };

        return {
            showDialog,
            loading,
            selectUsingPurchaseOrder,
            purchaseOrders,
            suppliers,
            warehouses,
            allSuppliers,
            onCancelClick,
            handleSubmit,
            state,
            v$,
            submitted,
            savingInProgress,
            setDropdownValue
        };
    }
};
