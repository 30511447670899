<loading v-model:active="loading" />
<Panel :header="$t('labels.purchaseOrders')">
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        :paginator="true"
        scrollable
        scrollHeight="calc(100vh - 23rem)"
        :rows="10"
        :lazy="true"
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="purchaseOrders"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
        @page="onPage($event)"
        @filter="onFilter($event)"
        @sort="onSort($event)"
        @state-restore="onStateRestore"
        stateStorage="local"
        stateKey="purchase-order-list-state-session"
    >
        <Column
            field="purchaseOrderNumber"
            :sortable="true"
            style="min-width: 7rem"
            :header="$t('labels.purchaseOrder.purchaseOrderNumber')"
        >
            <template #body="{data}">
                <a
                    :href="data.url + 'webapp/view/purchase/purchaseOrderDetail.page?entityId=' + data.id"
                    target="_blank"
                    >{{ data.purchaseOrderNumber }}</a
                >
                <div class="text-xs">
                    <b>{{formatter.format(data.netAmount)}}</b>
                </div>
                <div class="text-xs">
                    Anzahl der Positionen:
                    <b>{{(data.purchaseOrderItems || []).length}}</b>
                </div>
            </template>
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    placeholder="#"
                />
            </template>
        </Column>
        <Column
            :header="$t('labels.productAdd.supplier')"
            style="min-width: 7rem"
        >
            <template #body="{data}">
                <div v-if="data.supplier">
                    <a
                        :href="data.url + 'webapp/view/party/PartyDetail.page?entityId=' + data.supplier.id"
                        target="_blank"
                        >{{ data.supplier.name }}
                        [{{data.supplier.supplierNumber}}]</a
                    >
                </div>
            </template>
        </Column>
        <Column
            style="min-width: 7rem"
            field="recipient"
            :header="$t('labels.shipment.recipient')"
        >
            <template #body="{data}">
                <div>
                    <div>{{data.deliveryAddress?.company}}</div>
                    <div class="text-xs">{{data.deliveryAddress?.street1}}</div>
                    <div class="text-xs">
                        {{data.deliveryAddress?.zipcode}}
                        {{data.deliveryAddress?.city}}
                    </div>
                    <div class="text-xs">
                        {{countries.getName(data.deliveryAddress?.countryCode,
                        locale)}}
                    </div>
                    <div
                        class="text-xs"
                        v-if="data.deliveryAddress?.phoneNumber"
                    >
                        {{data.deliveryAddress?.phoneNumber}}
                    </div>
                </div>
            </template>
        </Column>
        <Column
            field="createdDate"
            dataType="date"
            :sortable="true"
            :header="$t('labels.createdAt')"
            style="min-width: 4rem; max-width: fit-content"
        >
            <template #filter="{filterModel}">
                <Calendar
                    class="p-column-filter text-xs"
                    v-model="filterModel.value"
                    dateFormat="dd.mm.yy"
                />
            </template>
            <template #body="{data}"> {{ data.createdAtFormatted }} </template>
        </Column>

        <Column
            :header="$t('labels.status')"
            style="max-width: 12rem"
            field="status"
            :sortable="true"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #body="{data}">
                <span
                    :class="{'text-green-200': 'ORDER_ENTRY_IN_PROGRESS' === data.status, 'text-orange-600': 'ORDER_DOCUMENTS_PRINTED' === data.status, 'text-blue-600' : 'CONFIRMED' === data.status, 'text-green-600' : 'CLOSED' === data.status, 'text-red-600' : 'CANCELLED' === data.status }"
                    >{{$t('labels.purchaseOrder.statusOptions.' + data.status)
                    }}</span
                ></template
            ><template #filter="{filterModel,filterCallback}">
                <Dropdown
                    v-model="filterModel.value"
                    :options="['ORDER_ENTRY_IN_PROGRESS', 'ORDER_DOCUMENTS_PRINTED', 'CONFIRMED', 'CLOSED', 'CANCELLED']"
                    placeholder="Any"
                    class="p-column-filter text-xs"
                    @change="filterCallback()"
                    :showClear="true"
                >
                    <template #value="slotProps">
                        <span
                            :class="{'text-green-200': 'ORDER_ENTRY_IN_PROGRESS' === slotProps.value, 'text-orange-600': 'ORDER_DOCUMENTS_PRINTED' === slotProps.value, 'text-blue-600' : 'CONFIRMED' === slotProps.value, 'text-green-600' : 'CLOSED' === slotProps.value, 'text-red-600' : 'CANCELLED' === slotProps.value }"
                            v-if="slotProps.value"
                            >{{$t('labels.purchaseOrder.statusOptions.' +
                            slotProps.value)}}</span
                        >
                        <span v-else class="text-sm"
                            >{{slotProps.placeholder}}</span
                        >
                    </template>
                    <template #option="slotProps">
                        <span
                            :class="{'text-green-200': 'ORDER_ENTRY_IN_PROGRESS' === slotProps.option, 'text-orange-600': 'ORDER_DOCUMENTS_PRINTED' === slotProps.option, 'text-blue-600' : 'CONFIRMED' === slotProps.option, 'text-green-600' : 'CLOSED' === slotProps.option, 'text-red-600' : 'CANCELLED' === slotProps.option }"
                            >{{$t('labels.purchaseOrder.statusOptions.' +
                            slotProps.option)}}</span
                        >
                    </template>
                </Dropdown>
            </template></Column
        >
        <Column
            :header="$t('labels.purchaseOrder.purchaseOrderType')"
            style="max-width: 12rem"
            field="purchaseOrderType"
            :sortable="true"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #body="{data}">
                <span
                    :class="{'text-orange-600': 'SALES_ORDER' === data.purchaseOrderType, 'text-blue-600' : 'NORMAL' === data.purchaseOrderType, 'text-green-200' : 'TRIANGULAR' === data.purchaseOrderType, 'text-red-600' : 'BLANKET_PURCHASE_ORDER' === data.purchaseOrderType }"
                    >{{$t('labels.purchaseOrder.purchaseOrderTypeOptions.' +
                    data.purchaseOrderType)}}</span
                ></template
            >
            <template #filter="{filterModel, filterCallback}">
                <Dropdown
                    v-model="filterModel.value"
                    :options="['NORMAL', 'SALES_ORDER', 'TRIANGULAR', 'BLANKET_PURCHASE_ORDER']"
                    placeholder="Any"
                    class="p-column-filter text-xs"
                    @change="filterCallback()"
                    :showClear="true"
                >
                    <template #value="slotProps">
                        <span
                            :class="{'text-orange-600': 'SALES_ORDER' === slotProps.value, 'text-blue-600' : 'NORMAL' === slotProps.value, 'text-green-200' : 'TRIANGULAR' === slotProps.value, 'text-red-600' : 'BLANKET_PURCHASE_ORDER' === slotProps.value }"
                            v-if="slotProps.value"
                            >{{$t('labels.purchaseOrder.purchaseOrderTypeOptions.'
                            + slotProps.value)}}</span
                        >
                        <span v-else class="text-sm"
                            >{{slotProps.placeholder}}</span
                        >
                    </template>
                    <template #option="slotProps">
                        <span
                            :class="{'text-orange-600': 'SALES_ORDER' === slotProps.option, 'text-blue-600' : 'NORMAL' === slotProps.option, 'text-green-200' : 'TRIANGULAR' === slotProps.option, 'text-red-600' : 'BLANKET_PURCHASE_ORDER' === slotProps.option }"
                            >{{$t('labels.purchaseOrder.purchaseOrderTypeOptions.'
                            + slotProps.option)}}</span
                        >
                    </template>
                </Dropdown>
            </template></Column
        >
        <Column
            field="warehouse"
            :sortable="true"
            style="min-width: 9rem"
            :header="$t('labels.shipment.warehouse')"
        >
            <template #body="{data}"> {{ data.warehouse?.name }}</template>
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.shipment.warehouse')"
                />
            </template>
        </Column>

        <Column style="min-width: 60px; max-width: 'fit-content'">
            <template #body="{data}">
                <div class="button-container">
                    <router-link
                        :to="{
                                    name: 'PurchaseOrderDetails',
                                    params: {purchaseOrderNumber: data.purchaseOrderNumber}
                                }"
                        ><i class="pi pi-pencil text-sm mr-2 text-color"></i
                    ></router-link>
                </div>
            </template>
        </Column>
    </DataTable>
</Panel>
