<loading v-model:active="isLoading" />
<Panel :header="$t('labels.exportWizard')">
    <div class="mb-3 p-fluid">
        <label>{{ $t("labels.chooseWizardWorkflow") }}</label>
        <div class="mt-2">
            <TreeSelect
                v-model="identifier"
                :options="exportWizardOptions"
                selectionMode="single"
                :placeholder="$t('labels.none')"
                @change="onChange"
            />
        </div>
    </div>

    <component
        :is="comp"
        v-if="identifier && componentName"
        @data-entered="onRequiredDataEntered"
    />

    <div v-if="identifier">
        <div class="flex justify-content-end">
            <p-button @click="initiateExport" :disabled="!exportButtonEnabled">
                {{ $t("buttons.initiateExport") }}
            </p-button>
        </div>
    </div>
</Panel>
