<i
    class="pi pi-filter text-green-300"
    style="padding-left: 10px; padding-top: 5px; cursor: pointer"
    @click="toggle"
    aria-haspopup="true"
    aria-controls="overlay_tmenu"
></i>
<TieredMenu ref="menu" id="overlay_tmenu" :model="items" popup />
<AddFilterDialog
    :display-dialog="displayAddFilterDialog"
    :saving-in-progress="savingInProgress"
    @close-dialog="onCloseAddDialog"
>
</AddFilterDialog>
