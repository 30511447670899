<div class="wrapper">
    <div class="layout-wrapper" :class="containerClass">
        <app-topbar></app-topbar>
        <div class="layout-sidebar">
            <app-menu></app-menu>
        </div>
        <div class="layout-main-container">
            <div class="layout-main">
                <router-view></router-view>
            </div>
            <app-footer></app-footer>
        </div>
        <div class="layout-mask"></div>
    </div>
    <NewSoftwareVersionDialog
        :displayDialog="displayReloadConfirmationDialog"
        @confirmed="onReloadConfirmed"
    ></NewSoftwareVersionDialog>
    <ConfirmDialog />
</div>
