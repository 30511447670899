<p-dialog
    :header="$t('labels.addVideo')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    @hide="closeDialog"
    :modal="true"
    class="text-sm"
>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="grid formgrid p-fluid">
            <div class="field col">
                <label>Url</label>
                <InputText
                    autocomplete="off"
                    v-model="v$.url.$model"
                    :class="{'p-invalid':v$.url.$invalid && submitted}"
                />
                <small
                    v-if="((v$.url.required.$invalid && submitted) || v$.url.$pending.$response)"
                    class="p-error"
                    >{{v$.url.required.$message.replace('Value', 'Url')}}</small
                >
                <small
                    v-if="((v$.url.url.$invalid && submitted) || v$.url.$pending.$response)"
                    class="p-error"
                    >{{v$.url.url.$message.replace('Value', 'Url')}}</small
                >
            </div>
        </div>
        <div class="flex justify-content-end">
            <p-button
                :disabled="savingInProgress"
                severity="success"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
                type="submit"
            >
            </p-button>
        </div>
    </form>
</p-dialog>
