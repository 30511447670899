<loading v-model:active="loading" />
<Panel :header="$t('labels.productsWithIncompleteMeasures')">
    <div class="grid">
        <div class="col-3">
            <label class="font-semibold">
                {{$t('labels.shipment.warehouseLevel')}}
            </label>

            <TreeSelect
                v-model="singleStoragePlace"
                :options="storagePlaces"
                @nodeExpand="onNodeExpand"
                @nodeSelect="onNodeSelect"
                @change="onChangeStorageLocation"
                class="p-column-filter text-sm mt-2 w-full"
            />
        </div>
        <div class="col"></div>
    </div>
    <template v-if="singleStoragePlace">
        <DataTable
            v-if="storagePlaceTotalRecords > 0 && storagePlaceTotalRecords <= 300"
            class="p-datatable-sm text-sm"
            stripedRows
            scrollable
            scrollHeight="calc(100vh - 23rem)"
            paginator
            :rows="50"
            ref="dt"
            dataKey="id"
            :value="products"
            responsiveLayout="scroll"
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rows-per-page-options="[50,100,200]"
            current-page-report-template="{first} to {last} of {totalRecords}"
            v-model:filters="nonLazyFilters"
            filterDisplay="row"
            @row-click="onRowClick($event)"
        >
            <Column
                field="article.articleNumber"
                :sortable="true"
                style="min-width: 9rem"
                :header="$t('labels.articleNumber')"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-sm"
                        :placeholder="$t('labels.articleNumber')"
                    />
                </template>
                <template #body="{data}">
                    {{data.article?.articleNumber}}
                </template>
            </Column>

            <Column
                field="article.name"
                :sortable="true"
                style="min-width: 9rem"
                :header="$t('labels.name')"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-sm"
                        :placeholder="$t('labels.name')"
                    />
                </template>
                <template #body="{data}"> {{data.article?.name}} </template>
            </Column>
            <Column
                field="article.ean"
                :sortable="true"
                style="min-width: 9rem"
                :header="$t('labels.ean')"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-sm"
                        :placeholder="$t('labels.ean')"
                    />
                </template>
                <template #body="{data}"> {{data.article?.ean}} </template>
            </Column>
            <Column
                field="article.manufacturerPartNumber"
                :sortable="true"
                style="min-width: 9rem"
                :header="$t('labels.mpn')"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-sm"
                        :placeholder="$t('labels.mpn')"
                    />
                </template>
                <template #body="{data}">
                    {{data.article?.manufacturerPartNumber}}
                </template>
            </Column>
            <Column
                field="serialNumber"
                style="min-width: 9rem"
                :header="$t('labels.productHome.serialNumberExtern')"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-sm"
                        :placeholder="$t('labels.productHome.serialNumberExtern')"
                    />
                </template>
            </Column>
            <Column
                style="min-width: 9rem"
                :header="$t('labels.shipment.warehouse')"
            >
                <template #body="{data}"> {{data.warehouse?.name}} </template>
            </Column>
            <Column
                style="min-width: 9rem"
                :header="$t('labels.shipment.warehouseLevel')"
            >
                <template #body="{data}">
                    {{data.storagePlace?.name}}
                </template>
            </Column>
            <Column
                field="article.measurementVerified"
                dataType="boolean"
                bodyClass="text-center"
                style="max-width: 7rem"
                :header="$t('labels.productHome.measurementVerified')"
            >
                <template #filter="{filterModel, filterCallback}">
                    <TriStateCheckbox
                        v-model="filterModel.value"
                        @change="filterCallback()"
                    />
                </template>
                <template #body="{data}">
                    <i
                        class="pi"
                        :class="{'text-green-600 pi-check-circle': data.article?.measurementVerified, 'text-red-600 pi-times-circle':!data.article?.measurementVerified}"
                    ></i>
                </template>
            </Column>

            <Column v-if="showDimensionColumn" style="min-width: 6rem">
                <template #body="{data}">
                    <div class="text-xs">
                        {{data.article?.articleGrossWeight ? ('G: ' +
                        (data.article.articleGrossWeight).toFixed(1) + ' kg') :
                        '' }}
                    </div>
                    <div class="text-xs">
                        {{data.article?.articleHeight ? ('H: ' +
                        (data.article.articleHeight * 100).toFixed(1) + ' cm') :
                        '' }}
                    </div>
                    <div class="text-xs">
                        {{data.article?.articleWidth ? ('B: ' +
                        (data.article.articleWidth * 100).toFixed(1) + ' cm') :
                        '' }}
                    </div>
                    <div class="text-xs">
                        {{data.article?.articleLength ? ('L: ' +
                        (data.article.articleLength * 100).toFixed(1) + ' cm') :
                        '' }}
                    </div>
                </template>
            </Column>
            <Column style="min-width: 7rem">
                <template #body="{data}" v-if="editPermissionAvailable">
                    <router-link
                        :to="{
                                        name: 'ProductsWithIncompleteDimensionsDetails',
                                        params: {id: data.articleNumber},
                                        state: {originalData: JSON.stringify({singleStoragePlace, path: selectedNode?.path})}
                                    }"
                        ><i
                            class="pi pi-pencil text-color"
                            v-tooltip.left="$t('labels.articleDetails')"
                        ></i
                    ></router-link>
                </template>
            </Column>
        </DataTable>
        <DataTable
            v-else
            class="p-datatable-sm text-sm"
            stripedRows
            scrollable
            scrollHeight="calc(100vh - 23rem)"
            paginator
            lazy
            :rows="10"
            ref="dt"
            dataKey="id"
            :totalRecords="totalRecords"
            :value="products"
            responsiveLayout="scroll"
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rows-per-page-options="[10,20,50]"
            current-page-report-template="{first} to {last} of {totalRecords}"
            v-model:filters="filters"
            filterDisplay="row"
            @row-click="onRowClick($event)"
            @page="onPage"
            @filter="onFilter"
            @sort="onSort"
        >
            <Column
                field="article.articleNumber"
                :sortable="true"
                style="min-width: 9rem"
                :header="$t('labels.articleNumber')"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-sm"
                        :placeholder="$t('labels.articleNumber')"
                    />
                </template>
                <template #body="{data}">
                    {{data.article?.articleNumber}}
                </template>
            </Column>

            <Column
                field="article.name"
                :sortable="true"
                style="min-width: 9rem"
                :header="$t('labels.name')"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-sm"
                        :placeholder="$t('labels.name')"
                    />
                </template>
                <template #body="{data}"> {{data.article?.name}} </template>
            </Column>
            <Column
                field="article.ean"
                :sortable="true"
                style="min-width: 9rem"
                :header="$t('labels.ean')"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-sm"
                        :placeholder="$t('labels.ean')"
                    />
                </template>
                <template #body="{data}"> {{data.article?.ean}} </template>
            </Column>
            <Column
                field="article.manufacturerPartNumber"
                :sortable="true"
                style="min-width: 9rem"
                :header="$t('labels.mpn')"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-sm"
                        :placeholder="$t('labels.mpn')"
                    />
                </template>
                <template #body="{data}">
                    {{data.article?.manufacturerPartNumber}}
                </template>
            </Column>
            <Column
                field="serialNumber"
                style="min-width: 9rem"
                :header="$t('labels.productHome.serialNumberExtern')"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-sm"
                        :placeholder="$t('labels.productHome.serialNumberExtern')"
                    />
                </template>
            </Column>
            <Column
                style="min-width: 9rem"
                :header="$t('labels.shipment.warehouse')"
            >
                <template #body="{data}"> {{data.warehouse?.name}} </template>
            </Column>
            <Column
                style="min-width: 9rem"
                :header="$t('labels.shipment.warehouseLevel')"
            >
                <template #body="{data}">
                    {{data.storagePlace?.name}}
                </template>
            </Column>
            <Column
                dataType="boolean"
                bodyClass="text-center"
                style="max-width: 7rem"
                :header="$t('labels.productHome.measurementVerified')"
            >
                <template #body="{data}">
                    <i
                        class="pi"
                        :class="{'text-green-600 pi-check-circle': data.article?.measurementVerified, 'text-red-600 pi-times-circle':!data.article?.measurementVerified}"
                    ></i>
                </template>
            </Column>

            <Column v-if="showDimensionColumn" style="min-width: 6rem">
                <template #body="{data}">
                    <div class="text-xs">
                        {{data.article?.articleGrossWeight ? ('G: ' +
                        (data.article.articleGrossWeight).toFixed(1) + ' kg') :
                        '' }}
                    </div>
                    <div class="text-xs">
                        {{data.article?.articleHeight ? ('H: ' +
                        (data.article.articleHeight * 100).toFixed(1) + ' cm') :
                        '' }}
                    </div>
                    <div class="text-xs">
                        {{data.article?.articleWidth ? ('B: ' +
                        (data.article.articleWidth * 100).toFixed(1) + ' cm') :
                        '' }}
                    </div>
                    <div class="text-xs">
                        {{data.article?.articleLength ? ('L: ' +
                        (data.article.articleLength * 100).toFixed(1) + ' cm') :
                        '' }}
                    </div>
                </template>
            </Column>
            <Column style="min-width: 7rem">
                <template #body="{data}" v-if="editPermissionAvailable">
                    <router-link
                        :to="{
                                        name: 'ProductsWithIncompleteDimensionsDetails',
                                        params: {id: data.articleNumber},
                                        state: {originalData: JSON.stringify({singleStoragePlace, path: selectedNode?.path})}
                                    }"
                        ><i
                            class="pi pi-pencil text-color"
                            v-tooltip.left="$t('labels.articleDetails')"
                        ></i
                    ></router-link>
                </template>
            </Column>
        </DataTable>
    </template>
</Panel>
