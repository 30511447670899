import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a7a1dfc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "grid p-fluid"
}
const _hoisted_2 = { class: "col field" }
const _hoisted_3 = {
  key: 1,
  class: "grid p-fluid"
}
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = {
  key: 2,
  class: "grid p-fluid"
}
const _hoisted_6 = { class: "col field" }

export function render(_ctx, _cache) {
  const _component_p_textarea = _resolveComponent("p-textarea")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.ticketDetails.customAttributes.service_repair_description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.service.repairDescription')), 1),
            _createVNode(_component_p_textarea, {
              rows: "4",
              disabled: true,
              value: _ctx.ticketDetails.customAttributes.service_repair_description
            }, null, 8, ["value"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ticketDetails.customAttributes.service_internal_error_description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.service.internalErrorDescription')), 1),
            _createVNode(_component_p_textarea, {
              rows: "4",
              disabled: true,
              value: _ctx.ticketDetails.customAttributes.service_internal_error_description
            }, null, 8, ["value"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ticketDetails.customAttributes.service_customer_error_description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.service.customerErrorDescription')), 1),
            _createVNode(_component_p_textarea, {
              rows: "4",
              disabled: true,
              value: _ctx.ticketDetails.customAttributes.service_customer_error_description
            }, null, 8, ["value"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}