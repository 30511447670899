import {toRefs} from 'vue';
import Panel from 'primevue/panel';

export default {
    components: {
        Panel
    },
    props: {
        ticketDetails: Object
    },
    setup(props: any) {
        const {ticketDetails} = toRefs(props);

        return {
            ticketDetails
        };
    }
};
