import {ref, onMounted, computed} from 'vue';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import LoadingPlugin from 'vue-loading-overlay';
import {FilterMatchMode} from 'primevue/api';
import Button from 'primevue/button';
import {useToast} from 'vue-toastification';
import {getAll, remove} from '@/services/print-configurations';
import AddOrEditPrintConfigurationDialog from '@/components/dialog/print-configurations/add-or-edit-print-configuration.vue';
import store from '@/store';
import {useConfirm} from 'primevue/useconfirm';
import {i18n} from '@/utils/i18n';
import Panel from 'primevue/panel';

export default {
    components: {
        DataTable,
        Column,
        InputText,
        Panel,
        loading: LoadingPlugin,
        Dropdown,
        AddOrEditPrintConfigurationDialog,
        'p-button': Button
    },
    setup() {
        onMounted(() => {
            filters.value = Object.assign({}, filterDefintions);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'id',
                sortOrder: 1,
                filters: filters.value
            };

            loadLazyData();
        });

        const dt = ref();
        const loading = ref(false);
        const totalRecords = ref(0);
        const toast = useToast();
        const confirm = useConfirm();
        const lazyParams = ref(null);
        const showCreatePrintConfigurationDialog = ref(false);
        const existingConfiguration = ref(null);

        const systemLogs = ref();

        const filterDefintions: any = {
            type: {value: null, matchMode: FilterMatchMode.CONTAINS}
        };

        const filters = ref(filterDefintions);

        const loadLazyData = () => {
            loading.value = true;

            getAll(lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    systemLogs.value = data.data.items;
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const onPage = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = filters.value;
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onConfigurationAddEditClick = (data: any) => {
            showCreatePrintConfigurationDialog.value = true;
            existingConfiguration.value = data || null;
        };

        const onCloseCreateDialog = (reload: boolean = false) => {
            showCreatePrintConfigurationDialog.value = false;
            existingConfiguration.value = null;
            if (reload) {
                loadLazyData();
            }
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return (
                user?.permissions?.indexOf('print-configurations-edit') !== -1
            );
        });

        const removeConfiguration = (data: any) => {
            confirm.require({
                message: i18n.global.t('messages.deleteConfirmation', {
                    item: [
                        data.hardwareId,
                        data.printerName,
                        data.attributeValue
                    ].join(' | ')
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    loading.value = true;
                    remove(data.id)
                        .then(() => {
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );
                            loadLazyData();
                        })
                        .catch((error) => {
                            loading.value = false;
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        });
                }
            });
        };

        return {
            systemLogs,
            dt,
            totalRecords,
            loading,
            filters,
            onPage,
            onFilter,
            onSort,
            showCreatePrintConfigurationDialog,
            onConfigurationAddEditClick,
            onCloseCreateDialog,
            editPermissionAvailable,
            removeConfiguration,
            existingConfiguration
        };
    }
};
